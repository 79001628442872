import { useEffect, useRef } from 'react';

const useClickOutsideComponent = (
  onClickOutside: () => void,
  enabled = true
) => {
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    };

    if (enabled) document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, enabled]);

  return { componentRef };
};

export default useClickOutsideComponent;
