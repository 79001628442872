import { Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import { BasicTag } from '../../../../../../../components';
import Icon from '../../../../../../../components/Icon';
import { DATE_FORMAT } from '../../../../../../../constants';
import { BlockUI } from '../../../../../../../helpers';
import { UserActivityLog } from '../../../../../../../models';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../../../../../pages/Users/constants';
import {
  groupByDate,
  groupByDateHeader,
} from '../../../../../../../utils/groupByDate';
import StatusFlag from '../../../../../../components/ProfileNotifications/components/StatusFlag';
import { StyledUserActivityLogs } from './UserActivityLogs.styled';

export type UserActivityLogsProps = PropsWithChildren<{}>;

export type UserActivityTableData = {
  id: number;
  date: string;
  action: JSX.Element;
  performedBy: JSX.Element | null;
  property: JSX.Element | null;
};

const EXCLUDE_TABLE_COLUMNS: (keyof UserActivityTableData)[] = ['id', 'date'];

const TABLE_COLUMNS = {
  id: 'id',
  date: 'Date',
  action: 'Action',
  performedBy: 'Performed By',
  property: 'Message',
};

const EVENT_COLORS = {
  'MODIFY': 'var(--BLUE-00)',
  'INSERT': 'var(--GREEN-12)',
  'REMOVE': 'var(--RED-EB)',
};

const UserActivityLogs: FC<UserActivityLogsProps> = () => {
  const { userId } = useParams();

  const {
    data: { data = [] } = {},
    isLoading,
    isValidating,
    error,
  } = useSWR<{ data: UserActivityLog[] }>(
    API_ROUTES.ACTIVITY_LOGS_ROUTES.USER(userId!)
  );

  const tableData = useMemo(() => {
    const groupedData = groupByDate(data);

    return Object.entries(groupedData).map(([date, logs], groupIndex) => (
      <div className="esg-user-activity-logs__tables" key={groupIndex}>
        <p className="esg-user-activity-logs__tables__date">
          {groupByDateHeader(date)}
        </p>
        <Table<UserActivityTableData>
          tableId={`user-activity-logs-table-${groupIndex}`}
          tableData={logs.map((log, index) => ({
            id: groupIndex * 1000 + index,
            date: dayjs(log.timestamp).format(`${DATE_FORMAT} h:mm A`),
            performedBy: (
              <div className="esg-user-activity-logs__user">
                <StatusFlag isRead={false} />
                <Icon name="user" />
                {log.performedBy.email}
              </div>
            ),
            action: (
              <div className="esg-user-activity-logs__action">
                <BasicTag text={log.event} color={EVENT_COLORS[log.event]} />
              </div>
            ),
            property: (
              <div className="esg-user-activity-logs__property">
                Updated property <strong>{log.property}</strong>
                to{' '}
                <BasicTag
                  text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[log.newValue?.name]}
                  color={log.newValue?.color}
                />
              </div>
            ),
          }))}
          excludeColumns={EXCLUDE_TABLE_COLUMNS}
          disableSort
          translationKeys={TABLE_COLUMNS}
        />
      </div>
    ));
  }, [data]);

  return (
    <StyledUserActivityLogs className="esg-user-activity-logs">
      <h2>Activity Logs</h2>

      <BlockUI
        loading={isLoading || isValidating}
        fallbackCondition={error || !data?.length}
      >
        {tableData}
      </BlockUI>
    </StyledUserActivityLogs>
  );
};

export default UserActivityLogs;
