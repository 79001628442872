import { useEffect } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../api/routes';

export function useFileDownload(fileKey?: string) {
  //downolad file
  const {
    data: downloadUrl,
    isLoading: isLoadingDownloadUrl,
    error: downloadUrlError,
  } = useSWR<{ data: { downloadUrl: string } }>(
    fileKey &&
      `${API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_DOWNLOAD_URL(fileKey)}`
  );

  useEffect(() => {
    if (downloadUrl?.data?.downloadUrl) {
      const link = document.createElement('a');

      link.href = downloadUrl.data.downloadUrl;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  }, [downloadUrl]);

  return { downloadUrl, isLoadingDownloadUrl, downloadUrlError };
}
