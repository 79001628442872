import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledProfileAssignment = styled.div`
  width: 100%;
  padding: ${theme.sizes.SIZE_32};
  width: 100%;
`;

export { StyledProfileAssignment };
