import { GlowScroll } from '@faxi/web-component-library';
import { APP_URI } from 'config';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCampaignProvider } from '../../../../providers/Campaign';
import { findCampaignItem } from '../../../../utils';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import CampaignEntity from '../CampaignEntity';

const Topic: FC = () => {
  const { topic = '', campaign = '' } = useParams<{
    topic: string;
    campaign: string;
  }>();

  const { rootCampaign } = useCampaignProvider();

  const campaignItem = useMemo(
    () => findCampaignItem(topic, rootCampaign),
    [rootCampaign, topic]
  );

  const { mutating } = useCampaignFormEntitySubmit(topic, campaignItem);

  const navigateTo = useCallback(
    (name: string) =>
      APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC.replace(
        ':campaign',
        campaign
      )
        .replace(':topic', topic)
        .replace(':subtopic', name),
    [campaign, topic]
  );

  return (
    <GlowScroll variant="gray">
      <CampaignEntity
        // TODO
        campaignItem={campaignItem as any}
        navigateTo={navigateTo}
        entityType="subtopic"
        modalProps={{ loading: mutating }}
      />
    </GlowScroll>
  );
};

export default Topic;
