import { GlowScroll, LinkParserWrapper } from '@faxi/web-component-library';
import { useGetComments } from 'api';
import { API_ROUTES } from 'api/routes';
import { NoData, ViewGuard } from 'components';
import {
  ApiData,
  Comment as TypeComment,
  CommentType,
  DataModuleElementDrawerComponentProps,
  PermissionSections,
  User,
} from 'models';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../../api/hooks/useMutation';
import InfiniteScrollContainer from '../../../../../components/_molecules/InfiniteScrollContainer';
import { BlockUI } from '../../../../../helpers';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import { useRootProvider } from '../../../../../providers/Root';
import {
  generateOptimisticComment,
  optimisticUpdateCommentsCache,
  updateWithErrorComment,
} from '../../utils';
import Comment from '../Comment';
import PostComment from '../PostComment';
import { StyledModuleElementComments } from './ModuleElementComments.styled';

const ModuleElementComments: FC<DataModuleElementDrawerComponentProps> = ({
  elementId,
  organisationSessionId,
  updateNumberOfUnreadComments,
}) => {
  //TODO: change when we migrate drawer to sessions
  const { campaignItemId = '' } = useParams();

  const {
    items,
    nextPage,
    isLoading,
    mutate: mutateComments,
    isValidating,
    setSize,
  } = useGetComments(organisationSessionId, campaignItemId, elementId, {
    revalidateFirstPage: false,
    revalidateAll: false,
  });

  const { user: currentUser } = useRootProvider();

  const {
    data: { data: users } = { data: [] },
    isValidating: isValidatingPotentialMentions,
  } = useSWR<ApiData<User[]>, Error>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.POTENTIAL_MENTIONS(
      organisationSessionId,
      campaignItemId,
      elementId
    )
  );

  const { trigger } = useMutation<ApiData<TypeComment>>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION_FORM_ELEMENT_COMMENTS(
      organisationSessionId,
      campaignItemId,
      elementId
    ),
    { revalidate: false }
  );

  const { isFetching, setContainerRef, containerRef } =
    useInfiniteScroll<HTMLDivElement>({
      hasNextPage: Boolean(nextPage),
      isLoading: isValidating,
      loadMore: () => setSize((prev) => prev + 1),
    });

  const { trigger: readComments, isMutating } = useMutation<
    ApiData<{ numberOfUnreadComments: number }>
  >(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION_FORM_ELEMENT_COMMENTS_READ(
      organisationSessionId,
      campaignItemId,
      elementId
    ),
    { revalidate: false }
  );

  const onDeleteFailedComment = useCallback(
    (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => comment.id !== item.id),
            },
          })),
        { revalidate: false }
      );
    },
    [mutateComments]
  );

  const postComment = useCallback(
    async (message: CommentType[]) => {
      try {
        containerRef?.scroll({ top: 0, behavior: 'smooth' });

        await trigger({ data: { content: message }, method: 'POST' });
      } catch (e) {
        const comment = generateOptimisticComment(message, currentUser!);

        mutateComments((prev) => updateWithErrorComment(comment, prev), {
          revalidate: false,
        });
      }
    },
    [containerRef, currentUser, mutateComments, trigger]
  );

  const onPostComment = useCallback(
    async (message: CommentType[]) => {
      const comment = generateOptimisticComment(message, currentUser!);

      mutateComments((prev) => optimisticUpdateCommentsCache(comment, prev), {
        revalidate: false,
      });

      await postComment(message);
    },
    [currentUser, mutateComments, postComment]
  );

  const onRetryComment = useCallback(
    async (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => item.id !== comment.id),
            },
          })),
        { revalidate: false }
      );

      await onPostComment(comment.content);
    },
    [mutateComments, onPostComment]
  );

  const markAsRead = useCallback(
    async (unreadIds: string[]) => {
      const { data } = await readComments({
        method: 'POST',
        data: { readComments: unreadIds },
      });

      await mutateComments(
        (prev) =>
          prev
            ? prev.map(({ data }) => ({
                data: {
                  ...data,
                  items: data.items.map((item) => ({
                    ...item,
                    isRead: unreadIds.includes(`${item.id}`)
                      ? true
                      : item.isRead,
                  })),
                },
              }))
            : [],
        { revalidate: false }
      );
      updateNumberOfUnreadComments?.(data.numberOfUnreadComments);
    },
    [mutateComments, readComments, updateNumberOfUnreadComments]
  );

  useEffect(() => {
    if (isValidating || isMutating) return;

    const unreadIds = items
      ?.filter(({ isRead }) => !isRead)
      .map(({ id }) => id.toString());

    if (unreadIds?.length) {
      markAsRead(unreadIds);
    }
  }, [isMutating, isValidating, items, markAsRead]);

  return (
    <StyledModuleElementComments className="esg-module-element-comments">
      <BlockUI loading={isLoading || isValidatingPotentialMentions}>
        <GlowScroll variant="gray">
          <InfiniteScrollContainer
            ref={setContainerRef}
            className="esg-module-element-comments__comments"
            isFetching={isFetching}
          >
            {items.length > 0 ? (
              items?.map((comment) => (
                <LinkParserWrapper key={comment.id}>
                  <Comment
                    comment={comment}
                    isError={!!comment?.isError && comment.elementId === 'temp'}
                    onDelete={onDeleteFailedComment}
                    onRetry={onRetryComment}
                  />
                </LinkParserWrapper>
              ))
            ) : (
              <NoData title="No comments" iconName="message" />
            )}
          </InfiniteScrollContainer>
        </GlowScroll>
        <ViewGuard
          section={PermissionSections.COMMENTS}
          permissions={['create']}
        >
          <PostComment onSubmit={onPostComment} users={users} />
        </ViewGuard>
      </BlockUI>
    </StyledModuleElementComments>
  );
};

export default ModuleElementComments;
