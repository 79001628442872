import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormBuilderPreviewModal = styled(Modal)`
  input {
    width: ${theme.sizes.SIZE_256};
  }

  hr.page-break {
    visibility: hidden;
  }

  hr {
    border-top: 1px solid var(--GRAY-EF);
    height: 1px;
    width: 100%;
  }

  .wcl-overlay__content .wcl-modal.wcl-modal--position-top {
    min-width: 210mm;
    max-width: 210mm;

    overflow-x: auto;
  }

  .wcl-modal__header {
    background-color: var(--PRIMARY_1_1);
    padding: ${theme.sizes.SIZE_24};
    flex-direction: row-reverse;
    margin-bottom: 0;

    &__titles {
      display: none;
    }
  }

  .wcl-modal__main {
    padding-top: 0;
  }

  .esg-form-builder-preview-modal {
    &__header-actions {
      ${flex('row', 'flex-end', 'center')};
      gap: ${theme.sizes.SIZE_20};
      width: 100%;
    }

    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};
      margin: ${theme.sizes.SIZE_24} 0;

      .kinto-status-element > div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_8};
      }

      > i {
        ${fontSize(theme.fontSizes.FONT_34)};
      }

      h1 {
        ${fontSize(theme.fontSizes.FONT_30)};
      }
    }

    &__subheader {
      color: var(--GRAY-98);
      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__content {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_24};
      margin-top: ${theme.sizes.SIZE_16};

      h3,
      p {
        margin: 0;
      }
    }

    &__no-data {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_20};
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__actions {
      ${flex('row', 'flex-end', 'center')};
      margin-top: ${theme.sizes.SIZE_48};
      gap: ${theme.sizes.SIZE_16};
      button {
        pointer-events: none;
      }
    }
  }
`;

export { StyledFormBuilderPreviewModal };
