import { SidebarItem } from '@faxi/web-component-library';

import { AnimatedLogo } from '../../../components';
import { INameExtended } from '../../../components/Icon';
import { APP_URI } from '../../../config';
import { PermissionSections } from '../../../models';
import { EsgSidebarItem } from '../types';

export const SIDEBAR_ARIA_LABELS = {
  ariaDeleteLabel: 'Delete Label',
  ariaCloseMenu: 'Collapse sidebar',
  ariaOpenMenu: 'Expand sidebar',
  ariaExpand: 'Expand sidebar',
  ariaCollapse: 'Collapse sidebar',
  ariaLogoLabel: 'ESG Navigator logo',
};

export const DEFAULT_SIDEBAR_PROPS = {
  searchPlaceholder: '',
  noResultsLabel: '',
  logoutText: 'Log Out',
  topItemsTitle: '',
  AnimatedLogoComponent: AnimatedLogo,
  initialExpanded: false,
};

export const SIDEBAR_MAIN_ITEMS: EsgSidebarItem[] = [
  {
    id: '1',
    name: 'Sessions',
    url: APP_URI.SESSIONS,
    iconName: 'chart-bar',
    permissionGroup: [PermissionSections.SESSION],
  },
  {
    id: '2',
    name: 'Organisations',
    url: APP_URI.ORGANISATIONS,
    iconName: 'buildings',
    permissionGroup: [PermissionSections.ORGANISATION],
  },
  {
    id: '3',
    name: 'Campaigns',
    url: APP_URI.CAMPAIGNS,
    iconName: 'leaf',
    permissionGroup: [PermissionSections.CAMPAIGN],
  },
  {
    id: '4',
    name: 'Users',
    url: APP_URI.USERS,
    iconName: 'users',
    permissionGroup: [PermissionSections.USER],
  },
  {
    id: '5',
    name: 'XBRL Taxonomies',
    url: APP_URI.XBRL,
    iconName: 'tag',
    permissionGroup: [PermissionSections.TAXONOMY],
  },
  {
    id: '6',
    name: 'Assignments',
    url: APP_URI.ASSIGNMENTS,
    iconName: 'clipboard-list',
    permissionGroup: [PermissionSections.ASSIGNMENT],
  },
  {
    id: '7',
    name: 'Integrations',
    url: APP_URI.INTEGRATIONS,
    iconName: 'gears',
    permissionGroup: [PermissionSections.API_INTEGRATION],
  },
];

export const SIDEBAR_BOTTOM_ITEMS: SidebarItem<INameExtended>[] = [
  {
    name: 'Personal Details',
    url: APP_URI.PERSONAL_DETAILS,
    id: 'personal-details',
  },
  { name: 'Notifications', url: APP_URI.NOTIFICATIONS, id: 'notifications' },
  { name: 'Reminders', url: APP_URI.REMINDERS, id: 'reminders' },
  { name: 'Role History', url: APP_URI.ROLE_HISTORY, id: 'role-history' },
];
