import { Expander } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledDeadlineExpander = styled(Expander)`
  .expander__content {
    padding: 3px;
  }
`;

export { StyledDeadlineExpander };
