import { Button } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { CommentType, User, UserMention } from 'models';
import { FC, PropsWithChildren, useMemo, useRef, useState } from 'react';

import { generateFullName } from '../../../../../utils';
import ContentEditableWithMentions from '../ContentEditableWithMentions';
import { ContentEditableRef } from '../ContentEditableWithMentions/ContentEditableWithMentions.component';
import { StyledPostComment } from './PostComment.styled';

export type PostCommentProps = PropsWithChildren<{
  onSubmit: (message: CommentType[]) => Promise<void>;
  users: User[];
}>;

const mapUsersToMentions = (users: User[]): UserMention[] =>
  users.map(({ id, firstName, lastName, roles }) => ({
    id,
    name: generateFullName(firstName, lastName),
    role: roles[0],
  }));

const PostComment: FC<PostCommentProps> = ({ onSubmit, users }) => {
  const [message, setMessage] = useState<CommentType[]>([]);

  const mentions = useMemo(() => mapUsersToMentions(users), [users]);

  const contentEditableRef = useRef<ContentEditableRef>(null);

  return (
    <StyledPostComment className="esg-post-comment">
      <ContentEditableWithMentions
        ref={contentEditableRef}
        mentions={mentions}
        onContentEditableChange={setMessage}
      />
      <Button
        className="esg-post-comment__send"
        icon={<Icon name="paper-plane" />}
        onClick={async () => {
          contentEditableRef?.current?.clearValue?.();
          const tempMessage = [...message];

          setMessage([]);
          await onSubmit(tempMessage);
        }}
        disabled={
          !message?.length ||
          (message?.length === 1 && message[0].value.trim() === '')
        }
      />
    </StyledPostComment>
  );
};

export default PostComment;
