import { css } from 'styled-components';

const textareaStyles = css`
  .textarea {
    .textarea__container {
      width: 100%;
      &__placeholder {
        width: calc(100% - 30px);
      }
    }
  }
`;

export default textareaStyles;
