import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTableModuleSizing = styled.div`
  ${flex('column')};

  .esg-table-module-sizing {
    &__add-cols {
      ${flex('row')};
    }

    &__add-rows {
      width: 100%;
    }
  }

  button.button--ghost {
    padding: ${theme.sizes.SIZE_6};
    border-radius: 0;

    background-color: var(--GRAY-FB);

    &:hover {
      background-color: var(--GRAY-EF);
    }
  }
`;

export { StyledTableModuleSizing };
