import dayjs from 'dayjs';
import { ActivityLog, CampaignSessionTreeNode, Progress } from 'models';

import { groupByDate } from '../../../utils/groupByDate';

export const findCurrentSessionEntry = (
  root: CampaignSessionTreeNode,
  currentId: string
): CampaignSessionTreeNode | undefined => {
  if (currentId === root?.id) {
    return root;
  }
  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findCurrentSessionEntry(child as any, currentId);
    if (result) {
      return result;
    }
  }
};

export const findAllAncestorNames = (
  root: CampaignSessionTreeNode,
  currentId: string
): string[] | undefined => {
  if (currentId === root?.id) {
    return [root.name];
  }

  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findAllAncestorNames(child as any, currentId);
    if (result !== undefined) {
      return [root.name, ...result];
    }
  }
};

// TODO: remove when API done
export const mockProgress = (): Progress => ({
  progress: Math.random() * 100,
  total: 100,
  completed: Math.random() * 100,
});

export const mockedApprovals = Array.from(Array(100).keys()).map(() =>
  mockProgress()
);

export const reduceLogsByDate = (activityLogs: ActivityLog[]) => {
  const grouped = groupByDate<ActivityLog>(activityLogs);

  return Object.entries(grouped).map(([date, logs]) => ({
    date: dayjs(date).toDate(),
    activityLogs: logs,
  }));
};

export const flatMapRecursive = <T extends { elements?: T[] }>(
  array: T[]
): T[] =>
  array.flatMap((element) => [
    element,
    ...flatMapRecursive(element.elements ?? []),
  ]);
