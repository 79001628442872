import { getColor, Tooltip } from '@faxi/web-component-library';
import { FormField, useFormContextValues } from '@faxi/web-form';
import { InputField, SelectField } from 'components';
import Icon from 'components/Icon';
import { useHeightAnimationArrayElements } from 'hooks';
import { DataModuleEnum, DropdownDataModule } from 'models';
import { FC, useMemo } from 'react';
import { generateModuleFieldName, generateOptionsByLabels } from 'utils';

import { StyledDropdownPreview } from './DropdownPreview.styled';

export type DropdownPreviewProps = DropdownDataModule;

const DropdownPreview: FC<DropdownPreviewProps> = ({
  title,
  id,
  config,
  conditionalElements,
  disabled,
  moduleElement: ModuleElement,
}) => {
  const finalOptions = useMemo(() => {
    if (!config) return [];
    const shouldAddOtherOption = config.other_conditions || config.other;

    const options = [
      ...config.options,
      ...(shouldAddOtherOption ? [{ label: 'Other' }] : []),
    ];

    return generateOptionsByLabels(options);
  }, [config]);

  const dropdownName = generateModuleFieldName(id, DataModuleEnum.DROPDOWN);
  const value = useFormContextValues(dropdownName);

  const shouldRenderOtherInput = value?.[dropdownName] === 'Other';

  const formValue = useFormContextValues(dropdownName)?.[dropdownName];

  const module = useMemo(() => {
    const conditionalElementId = finalOptions.find(
      (o) => o.value === formValue
    )?.key;

    return conditionalElementId && conditionalElements?.[conditionalElementId];
  }, [conditionalElements, finalOptions, formValue]);

  const { elementPreviewWrapperRef } =
    useHeightAnimationArrayElements(formValue);

  return (
    <StyledDropdownPreview className="esg-dropdown-preview">
      <div className="esg-dropdown-preview__content">
        <div className="esg-dropdown-preview__content__title">
          <div>{title || 'Dropdown field'}</div>

          {config?.helperText && (
            <Tooltip content={config.helperText}>
              <span tabIndex={0} role="tooltip" aria-label={config.helperText}>
                <Icon name="circle-info" color={getColor('--GRAY-40')} />
              </span>
            </Tooltip>
          )}
        </div>

        <div className="esg-dropdown-preview__content__fields">
          <FormField
            name={dropdownName}
            autoComplete="off"
            component={SelectField}
            options={finalOptions}
            placeholder={config?.placeholder}
            className="esg-dropdown-preview__dropdown"
            renderAsPortal
            searchable
            disabled={disabled}
          />

          {shouldRenderOtherInput && (
            <FormField
              name={`${dropdownName}Other`}
              component={InputField}
              placeholder="Specify Here"
            />
          )}
        </div>
      </div>

      <div
        className="esg-dropdown-preview__conditional-el"
        ref={elementPreviewWrapperRef}
      >
        {module && ModuleElement && (
          <ModuleElement
            modulesType="preview-module"
            type={module.type}
            module={module}
          />
        )}
      </div>
    </StyledDropdownPreview>
  );
};

export default DropdownPreview;
