import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledXBRLSelectionFields = styled.div`
  ${flex('row', 'space-between', 'flex-start')};
  width: 100%;
  margin-top: ${theme.sizes.SIZE_32};

  .esg-xbrl-selection-fields {
    &__label {
      padding-top: ${theme.sizes.SIZE_8};
      min-width: ${theme.sizes.SIZE_128};
    }
  }

  .esg-select-field {
    width: ${theme.sizes.SIZE_320};
    margin-left: auto;
  }
`;

export { StyledXBRLSelectionFields };
