import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDueReminderFlag = styled.div`
  ${flex('row', 'center', 'center')};
  gap: ${theme.sizes.SIZE_8};

  cursor: pointer;

  border-radius: ${theme.sizes.SIZE_8};
  border: solid 1px var(--GRAY-C1);
  padding: ${theme.sizes.SIZE_6} ${theme.sizes.SIZE_10};
  min-width: ${theme.sizes.SIZE_120};
`;

export { StyledDueReminderFlag };
