import { Button, Dropdown, DropdownRef } from '@faxi/web-component-library';
import classNames from 'classnames';
import InlineEditable from 'components/_molecules/InlineEditable';
import isEqual from 'lodash.isequal';
import {
  InlineModuleElement,
  PermissionSections,
  TableCell,
  TableDataModule,
} from 'models';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useUserPermissions } from '../../../../../hooks';
import Icon from '../../../../Icon';
import { TableModuleSizing } from './components';
import TableConfigModal from './components/TableConfigModal';
import { StyledTableCanvasModule } from './TableCanvasModule.styled';
import { getMaxRowLabel, moveItem } from './utils';
import { generateExcelColumnLabels } from './utils/generateExcelColumnLabels';

export type TableCanvasModuleProps = InlineModuleElement<TableDataModule>;

const generateNewTableCell = (label: string, splitTimes = 1) => ({
  id: uuidv4(),
  label,
  splitTimes,
});

const TABLE_ROWS: TableCell[] = [
  generateNewTableCell(''),
  generateNewTableCell('1'),
  generateNewTableCell('2'),
  generateNewTableCell('3'),
  generateNewTableCell('4'),
];

const TABLE_COLUMNS: TableCell[] = [
  generateNewTableCell(''),
  generateNewTableCell('A'),
  generateNewTableCell('B'),
  generateNewTableCell('C'),
  generateNewTableCell('D'),
];

export const DEFAULT_TABLE_CONFIG = {
  rows: TABLE_ROWS,
  columns: TABLE_COLUMNS,
};

const TableCanvasModule: FC<TableCanvasModuleProps> = ({
  showIcon = true,
  id,
  onSave,
  config,
  ...rest
}) => {
  const hasCampaignPermissions = useUserPermissions(
    PermissionSections.CAMPAIGN
  );

  const [rows, setRows] = useState<TableCell[]>(config?.rows || TABLE_ROWS);
  const [columns, setColumns] = useState<TableCell[]>(
    config?.columns || TABLE_COLUMNS
  );
  const [initialState, setInitialState] = useState(
    config ?? DEFAULT_TABLE_CONFIG
  );
  const [hasChanges, setHasChanges] = useState(false);

  const dropdownRef = useRef<DropdownRef>(null);

  useEffect(() => {
    setHasChanges(!isEqual(initialState, { rows, columns }));
  }, [rows, columns, initialState]);

  const closeDropdown = useCallback(
    () => dropdownRef.current?.setOpen(false),
    []
  );

  const addRow = useCallback(
    (index: number) => {
      const newRow = generateNewTableCell('New Row');
      const updatedRows = [...rows];

      updatedRows.splice(index + 1, 0, newRow);
      setRows(updatedRows);
      closeDropdown();
    },
    [closeDropdown, rows]
  );

  const deleteRow = useCallback(
    (id: string) => {
      if (rows.length > 2) {
        setRows((old) => old.filter((row) => row.id !== id));
        closeDropdown();
      }
    },
    [closeDropdown, rows]
  );

  const addColumn = useCallback(
    (index: number) => {
      const newColumn = generateNewTableCell('New Column');
      const updatedColumns = [...columns];

      updatedColumns.splice(index + 1, 0, newColumn);
      setColumns(updatedColumns);
      closeDropdown();
    },
    [closeDropdown, columns]
  );

  const deleteColumn = useCallback(
    (id: string) => {
      if (columns.length > 2) {
        setColumns((old) => old.filter((column) => column.id !== id));
        closeDropdown();
      }
    },
    [closeDropdown, columns]
  );

  const moveTableItem = useCallback(
    (
      array: TableCell[],
      index: number,
      direction: 'up' | 'down' | 'left' | 'right',
      setState: Dispatch<SetStateAction<TableCell[]>>
    ) => {
      setState(moveItem(array, index, direction, 1));
      closeDropdown();
    },
    [closeDropdown]
  );

  const moveRowUp = useCallback(
    (index: number) => moveTableItem(rows, index, 'up', setRows),
    [moveTableItem, rows]
  );

  const moveRowDown = useCallback(
    (index: number) => moveTableItem(rows, index, 'down', setRows),
    [moveTableItem, rows]
  );

  const moveColumnLeft = useCallback(
    (index: number) => moveTableItem(columns, index, 'left', setColumns),
    [moveTableItem, columns]
  );

  const moveColumnRight = useCallback(
    (index: number) => moveTableItem(columns, index, 'right', setColumns),
    [moveTableItem, columns]
  );

  const editRowItem = useCallback((rowId: string, label: string) => {
    setRows((old) =>
      old.map((oldRow) => (oldRow.id === rowId ? { ...oldRow, label } : oldRow))
    );
  }, []);

  const editColumnItem = useCallback((columnId: string, label: string) => {
    setColumns((old) =>
      old.map((oldColumn) =>
        oldColumn.id === columnId ? { ...oldColumn, label } : oldColumn
      )
    );
  }, []);

  const addNewColumns = useCallback(
    (length: number) =>
      setColumns((old) => {
        const oldColumnLabels = old.map(({ label }) => label);
        const newColumnLabels = generateExcelColumnLabels(
          oldColumnLabels,
          length
        );

        const newColumns = Array.from({ length }, (_, index) =>
          generateNewTableCell(newColumnLabels[old.length + index])
        );

        return [...old, ...newColumns];
      }),
    []
  );

  const addNewRows = useCallback(
    (length: number) =>
      setRows((old) => {
        const oldRowLabels = old.map(({ label }) => label);
        const maxExistingNumber = getMaxRowLabel(oldRowLabels);

        const newRows = Array.from({ length }, (_, index) =>
          generateNewTableCell((maxExistingNumber + index + 1).toString())
        );

        return [...old, ...newRows];
      }),
    []
  );

  const saveChanges = () => {
    onSave?.({ id, ...rest, config: { rows, columns } }, true);
    setInitialState({ rows, columns });
    setHasChanges(false);
  };

  const cancelChanges = () => {
    setRows(initialState.rows);
    setColumns(initialState.columns);
    setHasChanges(false);
  };

  return (
    <StyledTableCanvasModule
      className="esg-table-canvas-module"
      data-no-dnd="true"
    >
      <InlineEditable
        onSave={onSave}
        placeholderText="Click to enter/edit table label"
        {...rest}
      />

      <TableModuleSizing
        rowsLength={rows.length - 1}
        columnsLength={columns.length - 1}
        onAddColumns={addNewColumns}
        onAddRows={addNewRows}
        disabled={!hasCampaignPermissions(['update'])}
      >
        <div className="esg-table-canvas-module__table">
          {rows.map(
            (
              { id: rowId, label: rowLabel, splitTimes: splitTimesRow },
              rowIndex
            ) => (
              <div key={rowId} className="esg-table-canvas-module__table__row">
                {columns.map(
                  (
                    {
                      id: columnId,
                      label: columnLabel,
                      splitTimes: splitTimesColumn,
                    },
                    columnIndex
                  ) =>
                    rowIndex + columnIndex > 0 &&
                    (rowIndex === 0 || columnIndex === 0) ? (
                      <Dropdown
                        ref={dropdownRef}
                        data-no-dnd="true"
                        openPosition="top-left"
                        className={classNames(
                          'esg-table-canvas-module__table__row__item',
                          'esg-table-canvas-module__table__row__item--heading'
                        )}
                        key={`${id}_row_${rowId}_column_${columnId}`}
                        body={
                          rowIndex === 0 ? (
                            <TableConfigModal
                              disabled={!hasCampaignPermissions(['update'])}
                              splitItem={(splitTimes) => {
                                setColumns((old) =>
                                  old.map((oldColumn) =>
                                    oldColumn.id === columnId
                                      ? { ...oldColumn, splitTimes }
                                      : oldColumn
                                  )
                                );
                              }}
                              itemLabel={columnLabel}
                              type="column"
                              onOpenModal={() =>
                                dropdownRef.current?.setOpen(false)
                              }
                              editItem={(newLabel) =>
                                editColumnItem(columnId, newLabel)
                              }
                              addItem={() => addColumn(columnIndex)}
                              deleteItem={() => deleteColumn(columnId)}
                              moveItemPrev={() => moveColumnLeft(columnIndex)}
                              moveItemNext={() => moveColumnRight(columnIndex)}
                            />
                          ) : (
                            <TableConfigModal
                              disabled={!hasCampaignPermissions(['update'])}
                              onOpenModal={() =>
                                dropdownRef.current?.setOpen(false)
                              }
                              splitItem={(splitTimes) => {
                                setRows((old) =>
                                  old.map((oldRow) =>
                                    oldRow.id === rowId
                                      ? { ...oldRow, splitTimes }
                                      : oldRow
                                  )
                                );
                              }}
                              editItem={(newLabel) =>
                                editRowItem(rowId, newLabel)
                              }
                              itemLabel={rowLabel}
                              addItem={() => addRow(rowIndex)}
                              deleteItem={() => deleteRow(rowId)}
                              moveItemPrev={() => moveRowUp(rowIndex)}
                              moveItemNext={() => moveRowDown(rowIndex)}
                            />
                          )
                        }
                        renderAsPortal
                        trigger={
                          <div className="esg-table-canvas-module__table__row__item">
                            {rowIndex + columnIndex === 0
                              ? ''
                              : rowIndex === 0
                                ? columnLabel
                                : rowLabel}
                          </div>
                        }
                      />
                    ) : (
                      Array.from({ length: splitTimesColumn }).map(
                        (_, splitColumnsIndex) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: `${100 / splitTimesColumn}%`,
                              cursor: 'default',
                            }}
                            key={`${id}_row_${rowIndex}_column_${columnIndex}_cell_${splitColumnsIndex}`}
                          >
                            {Array.from({ length: splitTimesRow }).map(
                              (_, splitRowsIndex) => (
                                <div
                                  className={classNames(
                                    'esg-table-canvas-module__table__row__item',
                                    {
                                      'esg-table-canvas-module__table__row__item--heading':
                                        columnIndex === 0 && rowIndex !== 0,
                                    }
                                  )}
                                  key={`${id}_row_${rowIndex}_column_${columnIndex}_cell_${splitColumnsIndex}_subcell_${splitRowsIndex}`}
                                >
                                  {rowIndex + columnIndex > 0 && ''}
                                </div>
                              )
                            )}
                          </div>
                        )
                      )
                    )
                )}
              </div>
            )
          )}
        </div>
      </TableModuleSizing>

      {hasChanges && (
        <div className="esg-table-canvas-module__actions">
          <Button
            onClick={saveChanges}
            icon={<Icon name="check" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
          <Button
            onClick={cancelChanges}
            icon={<Icon name="xmark" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
        </div>
      )}
    </StyledTableCanvasModule>
  );
};

export default memo(TableCanvasModule);
