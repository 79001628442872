import { DragEndEvent, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useUtilities } from '@faxi/web-component-library';
import {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';

import { useURLCampaignItemId } from '../../../hooks';
import { extractFromDndModule } from '../../../pages/Campaigns/components/Dnd/utils';
import { useCampaignItemProvider } from '../../../pages/Campaigns/context/CampaignItem';
import { useFormBuilder } from '../../../pages/Campaigns/context/FormBuilder';
import { generateModulesFromCampaignItem } from '../../../pages/Campaigns/context/FormBuilder/FormBuilder.provider';
import { prepareCampaignItemElements } from '../../../pages/Campaigns/utils';
import { useCampaignProvider } from '../../../providers/Campaign';
import Icon from '../../Icon';
import { StyledBuilderCanvas } from './BuilderCanvas.styled';
import CanvasModuleWrapper from './components/CanvasModuleWrapper';
import ModuleElement from './components/ModuleElement';
import { useModuleActionsProvider } from './providers/ModuleActions.provider';

export type BuilderCanvasRef = {
  handleDragEnd: ({ active, over }: DragEndEvent) => Promise<void>;
};

const BuilderCanvas: ForwardRefRenderFunction<
  BuilderCanvasRef,
  { hasUpdatePermission?: boolean }
> = ({ hasUpdatePermission }, ref) => {
  const { modules, setModules } = useFormBuilder();
  const { campaignItemId } = useURLCampaignItemId();

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const { rootCampaign } = useCampaignProvider();
  const { editCampaignItem } = useCampaignItemProvider();

  const itemIds = useMemo(
    () => modules.map(({ id }) => `canvas-module_${id}`),
    [modules]
  );

  const handleDragEnd = useCallback(
    async ({ active }: DragEndEvent) => {
      showOverlay('body');

      const activeModuleId = extractFromDndModule('id', active.id);
      const activeModuleType = extractFromDndModule('dnd-type', active.id);

      try {
        await editCampaignItem(
          rootCampaign!.id,
          campaignItemId,
          prepareCampaignItemElements(modules),
          ({ elements }) => {
            setModules(generateModulesFromCampaignItem(elements));
          }
        );

        showSnackBar({
          text:
            activeModuleType === 'canvas-module'
              ? 'Successfully reordered elements.'
              : `Successfully added ${activeModuleId} data module.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [
      campaignItemId,
      editCampaignItem,
      hideOverlay,
      modules,
      rootCampaign,
      setModules,
      showOverlay,
      showSnackBar,
    ]
  );

  useImperativeHandle(ref, () => ({ handleDragEnd }), [handleDragEnd]);

  const { setNodeRef } = useDroppable({ id: 'canvas-module_drop-area' });

  const {
    handleDuplicateModule,
    handleDeleteModule,
    handleUpdateModule,
    handleConfigModule,
  } = useModuleActionsProvider();

  return (
    <StyledBuilderCanvas className="esg-builder-canvas" id="builder-canvas">
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {modules.map((module) => (
          <CanvasModuleWrapper
            module={module}
            key={module.id}
            onConfig={handleConfigModule}
            onClickDelete={() => handleDeleteModule(module)}
            onDuplicate={handleDuplicateModule}
            hasUpdatePermission={hasUpdatePermission}
          >
            <ModuleElement
              type={module.type}
              module={module}
              onModuleUpdate={handleUpdateModule}
              isInlineEditableDisabled={!hasUpdatePermission}
            />
          </CanvasModuleWrapper>
        ))}
      </SortableContext>

      <div className="esg-builder-canvas__drop-area" ref={setNodeRef}>
        {!modules.length && (
          <Fragment>
            There are no modules, add your first module from the menu on the
            right.
            <div className="esg-builder-canvas__drop-area__drop-modules">
              <Icon name="arrow-down-to-arc" />
              Drop Modules
            </div>
          </Fragment>
        )}
      </div>
    </StyledBuilderCanvas>
  );
};

export default memo(forwardRef(BuilderCanvas));
