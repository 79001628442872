import { RadioGroup } from '@faxi/web-component-library';
import { FormField, useFormContextValues } from '@faxi/web-form';
import { useHeightAnimationArrayElements } from 'hooks';
import { ChoiceDataModule, DataModuleEnum } from 'models';
import { FC, useMemo } from 'react';
import { generateModuleFieldName, generateOptionsByLabels } from 'utils';

import { StyledChoicePreview } from './ChoicePreview.styled';

export type ChoicePreviewProps = ChoiceDataModule;

const ChoicePreview: FC<ChoicePreviewProps> = ({
  title,
  id,
  config,
  conditionalElements,
  moduleElement: ModuleElement,
  disabled,
}) => {
  const finalOptions = useMemo(
    () => generateOptionsByLabels(config?.options as { label: string }[]),
    [config]
  );

  const name = generateModuleFieldName(id, DataModuleEnum.CHOICE);

  const formValue = useFormContextValues(name)?.[name];

  const module = useMemo(() => {
    const conditionalElementId = finalOptions.find(
      (o) => o.value === formValue
    )?.key;

    return conditionalElementId && conditionalElements?.[conditionalElementId];
  }, [conditionalElements, finalOptions, formValue]);

  const { elementPreviewWrapperRef } =
    useHeightAnimationArrayElements(formValue);

  return (
    <StyledChoicePreview className="esg-choice-preview">
      <div className="esg-choice-preview__title">
        {title || 'Choose Between'}
      </div>

      <FormField
        name={name}
        component={RadioGroup}
        orientation="column"
        options={finalOptions}
        disabled={disabled}
      />

      <div
        ref={elementPreviewWrapperRef}
        className="esg-choice-preview__conditional-el"
      >
        {module && ModuleElement && (
          <ModuleElement
            modulesType="preview-module"
            type={module.type}
            module={module}
          />
        )}
      </div>
    </StyledChoicePreview>
  );
};

export default ChoicePreview;
