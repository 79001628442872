import { ButtonProps, useUtilities } from '@faxi/web-component-library';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../api/hooks/useMutation';
import { API_ROUTES } from '../api/routes';
import Icon from '../components/Icon';
import { APP_URI } from '../config';
import {
  ApiData,
  Assignment,
  Contributor,
  PermissionSections,
} from '../models';
import authBus from '../modules/authBus';
import { useRootProvider } from '../providers/Root';
import { mapUsersToAssignees } from '../utils';
import useSWRCache from './useSWRCache';
import useUserPermissions from './useUserPermissions';
import useUserRoles from './useUserRoles';

const useContributors = ({
  organisationSessionId,
  sectionId,
  blockEndpoint,
}: Partial<{
  organisationSessionId: string;
  sectionId: string;
  blockEndpoint: boolean;
}> = {}) => {
  const { campaignItemId, sessionId = '' } = useParams();

  const navigate = useNavigate();

  const { users, user: currentUser } = useRootProvider();

  const { isAdminOrSA } = useUserRoles();

  const hasAssignmentPermission = useUserPermissions(
    PermissionSections.ASSIGNMENT
  );
  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const endpointUrl = useMemo(() => {
    const baseUrl = `${API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CONTRIBUTORS_LIST(sessionId)}?`;
    const searchParams = new URLSearchParams();

    if (organisationSessionId) {
      searchParams.append('organisationSessionId', organisationSessionId);
    }

    if (campaignItemId) {
      searchParams.append('campaignItemId', campaignItemId);
    }

    if (sectionId) {
      searchParams.append('sectionId', sectionId);
    }

    return `${baseUrl}${searchParams.toString()}`;
  }, [campaignItemId, organisationSessionId, sectionId, sessionId]);

  const { mutateCacheKeys } = useSWRCache();

  const deleteContributor = useMutation('DELETE_CONTRIBUTOR');

  const {
    data: { data: apiAssignees } = {},
    mutate: mutateAssignees,
    isLoading: isAssignmentsLoading,
  } = useSWR<ApiData<Assignment[]>>(!blockEndpoint && endpointUrl);

  const assignContributors = useMutation<ApiData<Assignment[]>>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CONTRIBUTORS_LIST(sessionId)
  );

  const assignedUsers = useMemo(
    () => (apiAssignees ?? []).map(({ assignee }) => assignee),
    [apiAssignees]
  );

  const assignees = useMemo(
    () => mapUsersToAssignees(assignedUsers),
    [assignedUsers]
  );

  const contributors = useMemo<Contributor[]>(() => {
    const filteredUsers = users.filter(
      (user) =>
        !assignedUsers.some((assignedUser) => assignedUser.id === user.id)
    );

    return mapUsersToAssignees(filteredUsers);
  }, [assignedUsers, users]);

  const revalidateAsignees = useCallback(() => {
    mutateCacheKeys(
      (key) =>
        (key.includes(
          API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CONTRIBUTORS_LIST(sessionId)
        ) &&
          key !== endpointUrl) ||
        key.endsWith('/assignees')
    );
  }, [endpointUrl, mutateCacheKeys, sessionId]);

  const updateAssignees = useCallback(
    async (newAssignments: Assignment[]) => {
      revalidateAsignees();
      await mutateAssignees(
        (prev) => ({ data: [...(prev?.data ?? []), ...newAssignments] }),
        { revalidate: false }
      );
      authBus.broadcastEvent('activity-logs-start-poll');

      showSnackBar({
        text:
          newAssignments.length > 1
            ? `Successfully added contributors.`
            : `${newAssignments[0].assignee.firstName} ${newAssignments[0].assignee.lastName} successfully added as contributor.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [mutateAssignees, revalidateAsignees, showSnackBar]
  );

  const nodeMenuItems = useCallback(
    (contributorId: string, name: string) =>
      hasAssignmentPermission(['update']) &&
      (contributorId !== currentUser.id || isAdminOrSA)
        ? ([
            {
              children: 'Delete Assignment',
              icon: <Icon name="xmark" />,
              variant: 'delete-ghost',
              onClick: async (e) => {
                await prompts.delete({
                  type: 'delete',
                  confirmButtonText: 'Delete',
                  cancelButtonText: 'Cancel',
                  title: `Delete ${name}`,
                  content: `Are you sure you want to delete ${name} as assignee?`,
                  buttonIcon: 'trash-can',
                  iconPosition: 'left',
                  confirmButtonVariant: 'delete-ghost',
                  titleIcon: <Icon name="triangle-exclamation" />,
                  triggerRef: e.target as HTMLButtonElement,
                  onConfirm: async () => {
                    showOverlay('body');
                    await deleteContributor.trigger({
                      method: 'DELETE',
                      url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CONTRIBUTOR(
                        sessionId,
                        contributorId
                      ),
                      params: {
                        organisationSessionId,
                        campaignItemId,
                        sectionId,
                      },
                    });
                    revalidateAsignees();

                    await mutateAssignees(
                      (prev) => ({
                        data: (prev?.data ?? []).filter(
                          (el) => el.assigneeId !== contributorId
                        ),
                      }),
                      { revalidate: false }
                    );

                    authBus.broadcastEvent('activity-logs-start-poll');

                    showSnackBar({
                      text: `${name} successfully deleted as contributor.`,
                      variant: 'success',
                      actionButtonText: 'Dismiss',
                    });

                    hideOverlay('body');
                  },
                });
              },
            },
            {
              children: 'Open User Profile',
              icon: <Icon name="arrow-up-right-from-square" />,
              variant: 'ghost',
              onClick: () => {
                navigate(`${APP_URI.USERS}/${contributorId}`);
              },
            },
          ] as ButtonProps[])
        : [],
    [
      campaignItemId,
      currentUser.id,
      deleteContributor,
      hasAssignmentPermission,
      hideOverlay,
      isAdminOrSA,
      mutateAssignees,
      organisationSessionId,
      prompts,
      revalidateAsignees,
      sectionId,
      sessionId,
      showOverlay,
      showSnackBar,
      navigate,
    ]
  );

  return {
    assignedUsers,
    isAssignmentsLoading,
    contributors,
    assignees,
    assignContributors,
    deleteContributor,
    nodeMenuItems,
    updateAssignees,
  };
};

export default useContributors;
