import { Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { isNotificationAssignment, Notification, Notifications } from 'models';
import { FC, PropsWithChildren } from 'react';

import { BasicTag } from '../../../components';
import UserImage from '../../../components/_atoms/UserImage';
import HaveNotificationsIconWrapper from '../../../components/_molecules/HaveNotificationsIconWrapper';
import { BlockUI } from '../../../helpers';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import { formatRelativeDate } from '../../../utils';
import { StyledProfileNotifications } from './ProfileNotifications.styled';

dayjs.extend(isToday);

const mapNotificationsToTableData = (notifications: Notification[]) =>
  notifications.map(({ fromUser, isRead, action, entity }, ind) => ({
    id: ind,
    user: (
      <div className="esg-profile-notifications__user">
        <div className="esg-profile-notifications__user__image-name">
          <div className="esg-profile-notifications__user__image-name__img-container">
            <UserImage
              name={`${fromUser.firstName} ${fromUser.lastName}`}
              src={fromUser.img}
            />
            {fromUser.roles && (
              <BasicTag
                text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[fromUser.roles[0].name]}
                color={fromUser.roles[0].color}
              />
            )}
          </div>
          <HaveNotificationsIconWrapper haveNotification={!isRead}>
            <span>{`${fromUser.firstName} ${fromUser.lastName}`}</span>
          </HaveNotificationsIconWrapper>
        </div>
      </div>
    ),
    action: <div>{action}</div>,
    element: (
      <div className="esg-profile-notifications__element">
        {(isNotificationAssignment(entity)
          ? entity.entity?.title
          : entity.input?.title) || '-'}
      </div>
    ),
    task: '-',
  }));

type ProfileNotificationsProps = PropsWithChildren<{
  notifications: Notifications;
}>;

const ProfileNotifications: FC<ProfileNotificationsProps> = ({
  notifications,
}) => (
  <StyledProfileNotifications className="esg-profile-notifications">
    <h2>Notifications</h2>

    <BlockUI fallbackCondition={!notifications.length}>
      {notifications?.map(({ notifications, date }, index) => (
        <div className="esg-profile-notifications__on-date" key={index}>
          <div className="esg-profile-notifications__on-date__date">
            {formatRelativeDate(date)}
          </div>

          <Table
            tableData={mapNotificationsToTableData(notifications)}
            tableId="esg-notifications"
            disableSort
            excludeColumns={['id']}
            translationKeys={{
              id: 'ID',
              user: 'User',
              action: 'Action',
              element: 'Element',
              task: 'Task',
            }}
          />
        </div>
      ))}
    </BlockUI>
  </StyledProfileNotifications>
);

export default ProfileNotifications;
