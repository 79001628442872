import { Location, NavigateFunction } from 'react-router-dom';

import { CampaignItem, CampaignWithItems, TreeNodeElement } from '../models';
import { campaignPathCheckpoints } from './mapCampaignToTreeNodeElement';

export const mapBaseCampaignToTreeNodeElement = (
  {
    id,
    name,
    createdAt,
    updatedAt,
    children,
    type,
  }: CampaignWithItems | CampaignItem,
  navigate: NavigateFunction,
  location: Location,
  depth = 0,
  path = ''
): TreeNodeElement => ({
  id,
  name,
  createdAt,
  updatedAt,
  children:
    type === 'data_collection'
      ? undefined
      : children?.map((c) =>
          mapBaseCampaignToTreeNodeElement(
            c,
            navigate,
            location,
            depth + 1,
            `${path}/${campaignPathCheckpoints[depth]}/${id}`
          )
        ),
  iconName: type === 'data_collection' ? 'clipboard-list' : 'leaf',
  emphasized: type === 'data_collection',
  to: `${path}/${campaignPathCheckpoints[depth]}/${id}${type === 'data_collection' ? '?form=yes' : ''}`,
});
