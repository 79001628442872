import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserDetails = styled.div`
  ${flex('column')};

  width: 100%;
  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_48};
`;

export { StyledUserDetails };
