import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFileUploadModalField = styled.div`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_8};

  .button {
    font-size: ${theme.fontSizes.FONT_14};
  }
`;

export { StyledFileUploadModalField };
