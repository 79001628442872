import { APP_URI } from 'config';
import { Navigate, RouteObject } from 'react-router-dom';

import { ForgotPassword, Login, ResetPassword } from './pages';

export default [
  { path: APP_URI.AUTH_LOGIN, element: <Login /> },
  { path: APP_URI.AUTH_FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: APP_URI.AUTH_RESET_PASSWORD, element: <ResetPassword /> },
  { index: true, element: <Navigate to={APP_URI.AUTH_LOGIN} replace /> },
] as RouteObject[];
