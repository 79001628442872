import { ApiData, Organisation, PermissionSections, User } from 'models';
import { createContext } from 'react';
import { KeyedMutator } from 'swr';

export type RootContextProps = {
  userTokenInformation?: {
    permissions: Record<PermissionSections, Array<string>>;
    firstName: string;
    lastName: string;
    userRouterPermissions: PermissionSections[];
    id: string;
    email: string;
  };

  rootOrganisation?: Organisation;
  user: User;
  users: User[];
  loadingUser: boolean;
  errorUser: boolean;
  validatingUser: boolean;
  logoutUser: () => Promise<void>;
  mutateUserCache: KeyedMutator<ApiData<User>>;
};

export default createContext<RootContextProps>({
  loadingUser: true,
  errorUser: false,
  logoutUser: () => new Promise(() => {}),
  validatingUser: false,
  users: [],
  user: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    roles: [],
    organisation: { id: '', name: '' },
  },
  mutateUserCache: (data: any) => data,
});
