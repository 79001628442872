import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormStatus = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_20};

  .esg-form-status {
    &__field {
      ${flex('row', 'space-between', 'baseline')};
      gap: ${theme.sizes.SIZE_32};

      .esg-select-field {
        width: ${theme.sizes.SIZE_200};
      }
    }
  }
`;
export { StyledFormStatus };
