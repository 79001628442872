import FileInput from 'components/_atoms/FileInput';
import InlineEditable from 'components/_molecules/InlineEditable';
import { InlineModuleElement, UploadDataModule } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type UploadCanvasModuleProps = InlineModuleElement<UploadDataModule>;

const UploadCanvasModule: FC<UploadCanvasModuleProps> = ({
  showIcon = true,
  ...rest
}) => {
  return (
    <StyledModuleContainer className="esg-upload-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit upload label'}
        {...rest}
      />
      {showIcon && <FileInput label="Upload Document" disabled />}
    </StyledModuleContainer>
  );
};

export default memo(UploadCanvasModule);
