import { UserAssignementsTreeNodeProps } from '../../../../../../../../models/Assignments';
import CompanyTreeNode from '../../../../../../../../pages/Sessions/components/CompanyTreeNode';

export const USER_ASSIGNMENT_TREE_NODE_DATA_WITHOUT_PARENTS: UserAssignementsTreeNodeProps =
  {
    id: '1',
    element: 'All',
    name: 'All',
    progress: { progress: 85, total: 100, completed: 85 },
    children: [
      {
        id: '2',
        element: 'Environment',
        name: 'Environment',
        progress: { progress: 2, total: 8, completed: 6 },
        children: [
          {
            id: '3',
            element: 'E1 - Climate Change',
            name: 'E1 - Climate Change',
            progress: { progress: 3, total: 5, completed: 85 },
            children: [
              {
                id: '4',
                element: 'E1 - 1 - Climate Change Adaptation',
                name: 'E1 - 1 - Climate Change Adaptation',
                progress: { progress: 4, total: 4, completed: 85 },
              },
              {
                id: '5',
                element: 'E1 - 2 - Climate Change Mitigation',
                name: 'E1 - 2 - Climate Change Mitigation',
                progress: { progress: 4, total: 4, completed: 85 },
              },
            ],
          },
          {
            id: '6',
            element: 'E2 - Pollution',
            name: 'E2 - Pollution',
            progress: { progress: 3, total: 5, completed: 85 },
            children: [
              {
                id: '7',
                element: 'E2 - 1 - Pollution of air',
                name: 'E2 - 1 - Pollution of air',
                progress: { progress: 4, total: 4, completed: 85 },
              },
              {
                id: '8',
                element: 'E2 - 2 - Pollution of water',
                name: 'E2 - 2 - Pollution of water',
                progress: { progress: 4, total: 4, completed: 85 },
              },
            ],
          },
        ],
      },
      {
        id: '9',
        element: 'Social',
        name: 'Social',
        progress: { progress: 1, total: 6, completed: 85 },
        children: [
          {
            id: '10',
            element: 'Freedom of Expression',
            name: 'Freedom of Expression',
            progress: { progress: 2, total: 7, completed: 6 },
            children: [
              {
                id: '11',
                element: 'E1 - 1 - Right to Education',
                name: 'E1 - 1 - Right to Education',
                progress: { progress: 3, total: 3, completed: 3 },
              },
              {
                id: '12',
                element: 'E2 - 2 - Protection from Discrimination',
                name: 'E2 - 2 - Protection from Discrimination',
                progress: { progress: 4, total: 5, completed: 85 },
              },
            ],
          },
          {
            id: '13',
            element: 'E2 - Social Welfare',
            name: 'E2 - Social Welfare',
            progress: { progress: 1, total: 3, completed: 85 },
            children: [
              {
                id: '14',
                element: 'E2 - 1 - Community Support',
                progress: { progress: 2, total: 2, completed: 6 },
                name: 'E2 - 1 - Community Support',
              },
            ],
          },
        ],
      },
    ],
  };

const createTreeWithCompanyNodes = (
  node: UserAssignementsTreeNodeProps
): UserAssignementsTreeNodeProps => {
  node.element = <CompanyTreeNode {...node} />;
  node.children = node.children?.map((child) =>
    createTreeWithCompanyNodes(child)
  );

  return node;
};

const USER_ASSIGNMENT_NODE_DATA = createTreeWithCompanyNodes(
  USER_ASSIGNMENT_TREE_NODE_DATA_WITHOUT_PARENTS
);

export { USER_ASSIGNMENT_NODE_DATA };
