import { FC, PropsWithChildren } from 'react';

import { ApprovalChip } from '../../../../components/_molecules/ApprovalProgressBar/components';
import Icon from '../../../../components/Icon';
import { ActivityLogStatus, FormStatus } from '../../../../models';
import { StyledStatusChangeLog } from './StatusChangeLog.styled';

export type StatusChangeLogProps = PropsWithChildren<{
  oldStatus?: ActivityLogStatus;
  newStatus?: ActivityLogStatus;
}>;

const LOG_TO_FORM_STATUS_MAPPER: Record<
  ActivityLogStatus,
  FormStatus | undefined
> = {
  [ActivityLogStatus.None]: undefined,
  [ActivityLogStatus.Completed]: FormStatus.Completed,
  [ActivityLogStatus.ToReview]: FormStatus.InReview,
  [ActivityLogStatus.Approved]: FormStatus.Approved,
  [ActivityLogStatus.Audited]: FormStatus.Audit,
};

const StatusChangeLog: FC<StatusChangeLogProps> = ({
  oldStatus = ActivityLogStatus.None,
  newStatus = ActivityLogStatus.None,
}) => {
  return (
    <StyledStatusChangeLog className="esg-status-change-log">
      <ApprovalChip
        status={LOG_TO_FORM_STATUS_MAPPER[oldStatus]}
        noLabelText={ActivityLogStatus.None}
      />
      <Icon name="arrow-right" />
      <ApprovalChip
        status={LOG_TO_FORM_STATUS_MAPPER[newStatus]}
        noLabelText={ActivityLogStatus.None}
      />
    </StyledStatusChangeLog>
  );
};

export default StatusChangeLog;
