import { SelectOption } from '@faxi/web-component-library';

import { INameExtended } from '../components/Icon';
import { BaseModel, BaseModelExtended } from './BaseModel';
import { CampaignItem } from './Campaign';
import { Company, CompanyChild } from './Company';
import { StatusProgresses } from './FormProgressStatus';
import { Progress } from './Progress';
import { User } from './User';

export enum FormStatus {
  InProgress = 'In Progress',
  InReview = 'In Review',
  Completed = 'Completed',
  Approved = 'Approved',
  Audit = 'Audit',
}

export type SessionAPI = {
  name: 'string';
  companyId: 'string';
  campaignId: 'string';
  selectedElementsIds: ['string'];
};

export type SessionSettings = {
  organisationId: string;
  organisationPath: string;
  isIncluded: boolean;
  dataCollectionElements: {
    id: string;
    path: string;
    isIncluded: boolean;
    exclusionJustification?: string;
  }[];
};

export type Session = BaseModelExtended & {
  id: string;
  status: CampaignSessionStatus;
  campaignId: string;
  campaignName: string;
  settings: SessionSettings[];
  startDate: string;
  endDate: string;
  createdBy: string;
  updatedBy?: string;
  rootOrganisationId: string;
  progress: Progress;
  taxonomyId?: string;
  statusProgresses: StatusProgresses;
};

export enum CampaignSessionStatus {
  Active = 'active',
  Finished = 'finished',
  Draft = 'draft',
}

export type CampaignSessionTreeNode = {
  id: string;
  organisationSessionId: string;
  name: string;
  companySessionId?: string;
  status: CampaignSessionStatus;
  description: string;
  path: { ids: string[]; names: string[] };
  organisation: Company;
  campaign?: CampaignItem;
  parent?: { id: string; name: string };
  iconName?: INameExtended;
  children?: CampaignSessionTreeNode[];
};

export type SessionsPageParams = { sessionId: string };

export type SessionTree = BaseModelExtended & {
  campaign: CampaignItem;
  children: SessionTree[];
  company: CompanyChild;
  status: CampaignSessionStatus;
  organisationSessionId: string;
};

export type RunSessionData = { companyIds: Array<string> };

export type SessionForm = {
  rootOrganisationId: string;
  organisationId: string;
  campaignId: string;
  sessionId: string;
  organisationSessionId: string;
  dataCollectionElementId: string;
  dataCollectionElementPath: string;
  status: `${FormStatus}`;
  createdBy: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt: string;
  deadline?: string;
};

export enum AssignmentType {
  Session = 'session',
  OrganisationSession = 'organisation_session',
  CampaignItem = 'campaign_item',
  Section = 'section',
}

export type Assignment = BaseModel & {
  organisationId: string;
  assigneeId: string;
  assignorId: string;
  sessionId: string;
  campaignId: string;
  assignmentType: AssignmentType;
  isInherited: boolean;
  assignee: User;
  assignor: User;
};

export type AssignContributorsForm = { contributors: SelectOption<string>[] };

export enum AssignTarget {
  Organisation = 'Organisation',
  Session = 'Session',
  Form = 'Form',
  CampaignItem = 'Campaign Item',
  Section = 'Section',
}
