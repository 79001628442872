import { BaseModel } from './BaseModel';
import { AssignmentType } from './Session';
import { User } from './User';

export type Notifications = { date: Date; notifications: Notification[] }[];

enum NotificationAction {
  Mention = 'Mentioned you',
  Assign = 'Assigned you',
}

export type MentionNotificationEntity = {
  input: { title: string; path: Record<string, string> };
  comment: { createdAt: string };
};

export type AssignmentNotificationEntity = {
  entity: { path: Record<string, string>; title: string };
  type: AssignmentType;
};

export type BaseNotification = BaseModel & {
  fromUser: User;
  fromUserId: string;
  isRead: boolean;
  message: string;
  recepientId: string;
};

export type MentionNotification = BaseNotification & {
  action: NotificationAction.Mention;
  entity: MentionNotificationEntity;
};

export type AssignmentNotification = BaseNotification & {
  action: NotificationAction.Assign;
  entity: AssignmentNotificationEntity;
};

export type Notification = AssignmentNotification | MentionNotification;

export const isNotificationAssignment = (
  entity: AssignmentNotificationEntity | MentionNotificationEntity
): entity is AssignmentNotificationEntity => 'entity' in entity;
