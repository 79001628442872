import { useSessionProvider } from 'providers/Session';
import React, { useContext, useMemo } from 'react';

import SelectedSessionContext from './SelectedSession.context';

type SelectedSessionProviderProps = {
  sessionId: string;
  children: React.ReactNode;
};

const SelectedSessionProvider: React.FC<SelectedSessionProviderProps> = ({
  sessionId,
  children,
}) => {
  const { sessions } = useSessionProvider();

  const selectedSession = useMemo(
    () => sessions.find((session) => session.id === sessionId),
    [sessions, sessionId]
  );

  if (!selectedSession) return <></>;

  return (
    <SelectedSessionContext.Provider value={selectedSession}>
      {children}
    </SelectedSessionContext.Provider>
  );
};
const useSelectedSession = () => useContext(SelectedSessionContext);

export { SelectedSessionProvider, useSelectedSession };
