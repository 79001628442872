import { Button } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import {
  DataModuleEnum,
  InputFieldTypes,
  InputModuleConfig,
} from '../../../../../models';
import InputValueChange from '../InputValueChange';
import ValueChangePreview from '../ValueChangePreview';
import { StyledValueChangeLog } from './ValueChangeLog.styled';

export type ValueChangeLogProps = PropsWithChildren<{
  oldValue?: string;
  newValue?: string;
  config?: Record<string, any>;
  oldConfig?: Record<string, any>;
  type: DataModuleEnum;
}>;

const ValueChangeLog: FC<ValueChangeLogProps> = ({
  oldValue,
  newValue,
  config,
  oldConfig,
  type,
}) => {
  const shouldRenderDropdown =
    [
      DataModuleEnum.FORMULA,
      DataModuleEnum.CHECKLIST,
      DataModuleEnum.TABLE,
    ].includes(type) ||
    (type === DataModuleEnum.INPUT &&
      (config as InputModuleConfig)?.type === InputFieldTypes.SIMPLE_FIELD);

  return (
    <StyledValueChangeLog className="esg-value-change-log">
      {shouldRenderDropdown ? (
        <ValueChangePreview
          trigger={
            <Button
              className="esg-value-change-log__modal-trigger"
              variant="ghost"
              onClick={(e) => e.stopPropagation()}
            >
              See changes
            </Button>
          }
          type={type}
          oldValue={oldValue}
          newValue={newValue}
          config={config}
          oldConfig={oldConfig}
          renderAsPortal
        />
      ) : (
        <InputValueChange
          oldValue={oldValue}
          newValue={newValue}
          type={type}
          config={config}
        />
      )}
    </StyledValueChangeLog>
  );
};

export default ValueChangeLog;
