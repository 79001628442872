import { useModalUtilities, useUtilities } from '@faxi/web-component-library';
import {
  CampaignItem,
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigMapperType,
  ModuleConfigType,
} from 'models';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useURLCampaignItemId } from '../../../../hooks';
import { findModule } from '../../../../pages/Campaigns/components/Dnd/utils';
import ModuleConfiguration from '../../../../pages/Campaigns/components/ModuleConfiguration';
import { useCampaignItemProvider } from '../../../../pages/Campaigns/context/CampaignItem';
import { useFormBuilder } from '../../../../pages/Campaigns/context/FormBuilder';
import { generateModulesFromCampaignItem } from '../../../../pages/Campaigns/context/FormBuilder/FormBuilder.provider';
import {
  dataModuleTextMapper,
  prepareCampaignItemElements,
} from '../../../../pages/Campaigns/utils';
import { useCampaignProvider } from '../../../../providers/Campaign';
import { findCampaignItem } from '../../../../utils';
import modifyModules from '../../../../utils/modifyModules';
import ModuleActionsContext, {
  ModuleActionsContextType,
} from './ModuleActions.context';

type ModuleActionsProviderProps = {
  children: ReactNode;
  configurableModules: ModuleConfigMapperType;
  level: 1 | 2 | 3;
};

export const ModuleActionsProvider: FC<ModuleActionsProviderProps> = ({
  level,
  configurableModules,
  children,
}) => {
  const { currentSubtopicLevelId } = useURLCampaignItemId(level);

  const { editCampaignItem, duplicateCampaignItem } = useCampaignItemProvider();

  const { rootCampaign } = useCampaignProvider();

  const campaignItem = useMemo(
    () =>
      findCampaignItem(currentSubtopicLevelId, rootCampaign) as
        | CampaignItem
        | undefined,
    [rootCampaign, currentSubtopicLevelId]
  );

  const { open, openModal, closeModal } = useModalUtilities();

  const [activeModuleConfig, setActiveModuleConfig] =
    useState<ModuleConfig<ModuleConfigType, DataModuleEnum>>();

  const { modules, setModules } = useFormBuilder();
  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const handleDuplicateModule = useCallback(
    async (module: IDataModule) => {
      showOverlay('body');

      try {
        await duplicateCampaignItem(module, ({ elements }) => {
          setModules(generateModulesFromCampaignItem(elements));
        });

        showSnackBar({
          text: `Successfully duplicated ${dataModuleTextMapper[module.type].toLowerCase()}${module.title ? ' ' + module.title : ''}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [duplicateCampaignItem, hideOverlay, setModules, showOverlay, showSnackBar]
  );

  const handleDeleteModule = useCallback(
    async (module: IDataModule) => {
      showOverlay('body');

      const newArr = modifyModules.deleteModuleById(modules, module);

      try {
        await editCampaignItem(
          rootCampaign!.id,
          campaignItem!.id,
          prepareCampaignItemElements(newArr)
        );
        setModules(newArr);

        showSnackBar({
          text: `Successfully deleted ${dataModuleTextMapper[module.type].toLowerCase()}${module.title ? ` ${module.title}` : ''}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [
      editCampaignItem,
      hideOverlay,
      modules,
      setModules,
      showOverlay,
      showSnackBar,
      rootCampaign,
      campaignItem,
    ]
  );

  const handleUpdateModule = useCallback(
    async (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => {
      const updatedModules = modifyModules.updateModuleById(modules, module);

      const moduleSelector = `[id='canvas-module_${module.id}']`;

      try {
        showOverlay(moduleSelector);

        await editCampaignItem(
          rootCampaign!.id,
          campaignItem!.id,
          prepareCampaignItemElements(updatedModules)
        );
        setModules(updatedModules);

        showSnackBar({
          text: `Successfully updated ${module.type} module.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay(moduleSelector);
      }
    },
    [
      modules,
      showOverlay,
      editCampaignItem,
      setModules,
      showSnackBar,
      hideOverlay,
      rootCampaign,
      campaignItem,
    ]
  );

  const handleConfigModule = useCallback(
    (module: IDataModule<DataModuleEnum>) => {
      setActiveModuleConfig(findModule(module.id, modules).module);
      openModal();
    },
    [modules, openModal]
  );

  return (
    <ModuleActionsContext.Provider
      value={{
        campaignItem,
        handleDuplicateModule,
        handleDeleteModule,
        handleUpdateModule,
        handleConfigModule,
      }}
    >
      {children}
      {open && activeModuleConfig && (
        <ModuleConfiguration
          moduleConfig={activeModuleConfig}
          onSubmit={handleUpdateModule}
          onClose={closeModal}
          configurableModules={configurableModules}
        />
      )}
    </ModuleActionsContext.Provider>
  );
};

export const useModuleActionsProvider = (): ModuleActionsContextType =>
  useContext(ModuleActionsContext);
