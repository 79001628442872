import {
  GlowScroll,
  ModalProps,
  useQueryParams,
} from '@faxi/web-component-library';
import { FC, PropsWithChildren, useMemo } from 'react';

import InfiniteScrollContainer from '../../../../../components/_molecules/InfiniteScrollContainer';
import { ActivityLog } from '../../../../../models';
import { reduceLogsByDate } from '../../../../../pages/Sessions/utils';
import ModuleElementActivityLog from '../ModuleElementActivityLog';
import { StyledFormActivityModal } from './FormActivityModal.styled';

export type FormActivityModalProps = ModalProps &
  PropsWithChildren<{
    logs: ActivityLog[];
    isFetching: boolean;
    setContainerRef: (element: HTMLDivElement) => void;
  }>;

const FormActivityModal: FC<FormActivityModalProps> = ({
  isFetching,
  setContainerRef,
  logs,
  ...rest
}) => {
  const asctivityLogs = useMemo(() => reduceLogsByDate(logs), [logs]);
  const { setQueryParam } = useQueryParams();

  return (
    <StyledFormActivityModal
      title="History"
      className="esg-form-activity-modal"
      {...rest}
    >
      <GlowScroll variant="gray">
        <InfiniteScrollContainer ref={setContainerRef} isFetching={isFetching}>
          <div className="esg-form-activity-modal--logs-container">
            {asctivityLogs?.map(({ date, activityLogs }, index) => (
              <ModuleElementActivityLog
                key={index}
                date={date}
                activityLogs={activityLogs}
                dontShowChange
                onClick={(elementId) => {
                  setQueryParam('elementId', elementId, true);
                  rest.onClose?.();
                }}
              />
            ))}
          </div>
        </InfiniteScrollContainer>
      </GlowScroll>
    </StyledFormActivityModal>
  );
};

export default FormActivityModal;
