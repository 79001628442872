import { useCallbackRef } from '@faxi/web-component-library';
import { useCallback, useEffect, useState } from 'react';

interface UseInfiniteScrollProps {
  hasNextPage: boolean;
  isLoading: boolean;
  loadMore: () => void;
}

const useInfiniteScroll = <T extends HTMLElement>({
  hasNextPage,
  isLoading,
  loadMore,
}: UseInfiniteScrollProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [containerRef, setContainerRef] = useCallbackRef<T>();

  const handleScroll = useCallback(() => {
    if (!containerRef || isLoading || !hasNextPage || isFetching) return;

    const { scrollHeight, scrollTop, offsetHeight } = containerRef;
    const isBottomReached =
      Math.abs(scrollHeight - offsetHeight - scrollTop) < 1;

    if (isBottomReached) {
      setIsFetching(true);
      loadMore();
    }
  }, [containerRef, hasNextPage, isLoading, isFetching, loadMore]);

  useEffect(() => {
    if (!containerRef) return;

    containerRef.addEventListener('scroll', handleScroll);

    return () => containerRef.removeEventListener('scroll', handleScroll);
  }, [containerRef, handleScroll]);

  useEffect(() => {
    if (!isLoading) {
      setIsFetching(false);
    }
  }, [isLoading]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return { isFetching, setContainerRef, containerRef };
};

export default useInfiniteScroll;
