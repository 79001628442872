import { PermissionSections, RouterPermissionSections } from 'models';

import { APP_URI } from '../config';

export const PERMISSIONS_SECTION_ROUTER_MAPPER: Record<
  RouterPermissionSections,
  string
> = {
  [PermissionSections.ORGANISATION]: APP_URI.ORGANISATIONS,
  [PermissionSections.USER]: APP_URI.USERS,
  [PermissionSections.CAMPAIGN]: APP_URI.CAMPAIGNS,
  [PermissionSections.SESSION]: APP_URI.SESSIONS,
  [PermissionSections.ASSIGNMENT]: APP_URI.ASSIGNMENTS,
  [PermissionSections.SESSION_STATUS]: APP_URI.SESSIONS,
  [PermissionSections.SESSION_DATA_COLLECTION_INPUT]: APP_URI.SESSIONS,
  [PermissionSections.FILE]: APP_URI.SESSIONS,
  [PermissionSections.API_INTEGRATION]: APP_URI.INTEGRATIONS,
  [PermissionSections.TAXONOMY]: APP_URI.XBRL,
};

export const INITIAL_PERMISSIONS: Record<PermissionSections, Array<string>> = {
  [PermissionSections.ORGANISATION]: [],
  [PermissionSections.USER]: [],
  [PermissionSections.CAMPAIGN]: [],
  [PermissionSections.SESSION]: [],
  [PermissionSections.ROLE]: [],
  [PermissionSections.COMMENTS]: [],
  [PermissionSections.LOGS]: [],
  [PermissionSections.ASSIGNMENT]: [],
  [PermissionSections.SESSION_STATUS]: [],
  [PermissionSections.SESSION_DATA_COLLECTION_INPUT]: [],
  [PermissionSections.FILE]: [],
  [PermissionSections.API_INTEGRATION]: [],
  [PermissionSections.TAXONOMY]: [],
};

export const ROUTER_PERMISSION_SECTIONS: Record<PermissionSections, boolean> = {
  [PermissionSections.ORGANISATION]: true,
  [PermissionSections.USER]: true,
  [PermissionSections.CAMPAIGN]: true,
  [PermissionSections.SESSION]: true,
  [PermissionSections.ROLE]: false,
  [PermissionSections.COMMENTS]: false,
  [PermissionSections.LOGS]: true,
  [PermissionSections.ASSIGNMENT]: true,
  [PermissionSections.SESSION_STATUS]: false,
  [PermissionSections.SESSION_DATA_COLLECTION_INPUT]: false,
  [PermissionSections.FILE]: false,
  [PermissionSections.API_INTEGRATION]: true,
  [PermissionSections.TAXONOMY]: true,
};
