import { User } from '../models';
import { generateFullName } from './generateFullName';

export const mapUsersToAssignees = (users: User[]) =>
  users.map(({ id, firstName, lastName, roles }) => ({
    id,
    name: generateFullName(firstName, lastName),
    role: roles[0],
    src: '',
  }));
