import { Modal, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormActivityModal = styled(Modal)`
  &.wcl-overlay[class] {
    .wcl-modal {
      width: ${theme.sizes.SIZE_752};
      max-width: unset;
    }
  }

  .esg-form-activity-modal--logs-container {
    ${flex('column', 'flex-start', 'stretch')};

    .esg-module-element-activity-log__log {
      cursor: pointer;
    }

    gap: ${theme.sizes.SIZE_24};
    padding: ${theme.sizes.SIZE_24};
  }

  .wcl-modal__main {
    padding: unset;
    height: ${theme.sizes.SIZE_504};
  }
`;

export { StyledFormActivityModal };
