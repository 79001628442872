import {
  Button,
  GlowScroll,
  useQueryParams,
  useUtilities,
} from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import MissingInfoTooltip from '../../../../components/_molecules/MissingInfoTooltip';
import ModuleElement from '../../../../components/_organisms/BuilderCanvas/components/ModuleElement';
import { BlockUI } from '../../../../helpers';
import { useUserPermissions } from '../../../../hooks';
import { useFormFileUpload } from '../../../../hooks/useFormFileUpload';
import {
  DataModuleEnum,
  ModuleWithConfig,
  PermissionSections,
} from '../../../../models';
import { FormStatusEnum } from '../../../../models/FormProgressStatus';
import authBus from '../../../../modules/authBus';
import { generateInitialFormModulesData } from '../../../../utils';
import { transformFormData } from '../../../../utils/transformFormData';
import { useFormModules } from '../../context/FormModules';
import { useLockDrawer } from '../../context/LockDrawer';
import { flatMapRecursive } from '../../utils';
import LockDrawerButtons from '../LockDrawerButtons';
import SessionCampaignHeader from '../SessionCampaignHeader';
import { StyledSessionCampaignForm } from './SessionCampaignForm.styled';

const SessionCampaignForm: FC<{
  formName: string;
  formDescription?: string;
}> = ({ formName, formDescription }) => {
  const { campaignItemId, companyId, sessionId } = useParams();

  const { params: { elementId } = {}, removeQueryParam } = useQueryParams();

  const {
    modules,
    form,
    error,
    formApiEndpoint,
    isLoadingForm,
    organisationSessionId,
  } = useFormModules();

  const { setActiveModule, openDrawer } = useLockDrawer();

  const { isLoadingFiles, initialFiles, submitFileUpload } = useFormFileUpload(
    sessionId!,
    companyId!,
    organisationSessionId,
    campaignItemId!,
    'form_attachment'
  );

  const initialFormData = useMemo(
    () => generateInitialFormModulesData(modules, initialFiles),
    [initialFiles, modules]
  );

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const { trigger: submitSessionForm } = useMutation(
    formApiEndpoint,
    {
      onSuccess: () =>
        showSnackBar({
          text: 'Form successfully submitted!',
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  const hasSessionsPermission = useUserPermissions(PermissionSections.SESSION);

  const canUpdateForm =
    hasSessionsPermission(['update']) &&
    form?.status !== FormStatusEnum.Completed;

  useEffect(() => {
    if (elementId && modules?.length) {
      setActiveModule(
        flatMapRecursive(modules).find(({ id }) => id === elementId)
      );
      openDrawer();
      removeQueryParam('elementId', true);
    }
  }, [elementId, modules, openDrawer, removeQueryParam, setActiveModule]);

  return (
    <StyledSessionCampaignForm className="esg-session-campaign-form">
      <BlockUI
        loading={isLoadingForm || isLoadingFiles}
        error={!!error}
        blocked={!canUpdateForm}
      >
        <Form
          className="esg-session-campaign-form__form"
          initialData={initialFormData}
          onSubmit={async (data) => {
            if (!form) return;
            try {
              showOverlay('body');
              const finalData = transformFormData(data);
              await submitFileUpload(finalData.elements, companyId!, 'root');
              await submitSessionForm({ method: 'POST', data: finalData });

              showSnackBar(
                {
                  text: "Heads up, your progress might not update right away. You may need to refresh the app as it's updated in the background.",
                  variant: 'alert',
                  actionButtonText: 'Dismiss',
                },
                { disappearAfter: 5000 }
              );

              authBus.broadcastEvent('activity-logs-start-poll');
              await mutate(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE());
            } catch (e) {
              console.error(e);
            } finally {
              hideOverlay('body');
            }
          }}
        >
          <SessionCampaignHeader
            iconName="clipboard-list"
            name={formName}
            description={formDescription}
          />
          <GlowScroll variant="gray">
            <div>
              {modules.map((module) => (
                <div
                  key={module.id}
                  className="esg-session-campaign-form__form__container"
                >
                  {(module as ModuleWithConfig).config?.isDataLineageRequired &&
                    !module.dataLineageInformation &&
                    ![
                      DataModuleEnum.SECTION,
                      DataModuleEnum.TEXT_BOX,
                      DataModuleEnum.FORMULA,
                      DataModuleEnum.DIVIDER,
                    ].includes(module.type) && (
                      <MissingInfoTooltip
                        module={module}
                        key={module.id}
                        setActiveModule={setActiveModule}
                        openDrawer={openDrawer}
                      />
                    )}
                  <div className="esg-session-campaign-form__form__container__module">
                    <ModuleElement
                      modulesType="preview-module"
                      type={module.type}
                      module={module}
                      key={module.id}
                      isInlineEditableDisabled={module.isLocked}
                      setActiveModule={setActiveModule}
                      openDrawer={openDrawer}
                    />
                    {module.type !== DataModuleEnum.DIVIDER && (
                      <LockDrawerButtons module={module} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </GlowScroll>

          <div className="esg-session-campaign-form__form__actions">
            <Button
              type="submit"
              disabled={
                !modules.length || form?.status === FormStatusEnum.Completed
              }
            >
              Submit
            </Button>
          </div>
        </Form>
      </BlockUI>
    </StyledSessionCampaignForm>
  );
};

export default SessionCampaignForm;
