import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { StyledBasicTag } from './BasicTag.styled';

export type BasicTagProps = PropsWithChildren<{
  text: string;
  color: string;
  isSup?: boolean;
  className?: string;
}>;

const BasicTag: FC<BasicTagProps> = ({
  text,
  color,
  isSup = false,
  className,
}) =>
  isSup ? (
    <sup>{text}</sup>
  ) : (
    <StyledBasicTag
      className={classNames('esg-basic-tag', className)}
      $bgColor={color}
    >
      {text}
    </StyledBasicTag>
  );

export default BasicTag;
