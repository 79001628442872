import { Button, Tooltip } from '@faxi/web-component-library';
import { FC } from 'react';

import { DataModuleEnum, IDataModule } from '../../../models';
import Icon from '../../Icon';
import { StyledMissingInfoTooltip } from './MissingInfoTooltip.styled';

type MissingInfoTooltipProps = {
  module: IDataModule<DataModuleEnum>;
  setActiveModule?: (module: IDataModule) => void;
  openDrawer?: () => void;
};

const MissingInfoTooltip: FC<MissingInfoTooltipProps> = ({
  module,
  setActiveModule,
  openDrawer,
}) => {
  return (
    <StyledMissingInfoTooltip className="esg-missing-info-tooltip">
      <Tooltip
        appendTo="parent"
        theme="custom-tooltip"
        content={
          <div className="esg-missing-info-tooltip__container">
            <div className="esg-missing-info-tooltip__container__header">
              <Icon
                name="solid-circle-exclamation"
                className="circle-exclamation-icon"
              />
              Data Lineage
            </div>
            Data information is missing.
            <div>
              Please fill up relevant information or upload support
              documentation in the
              <strong> module drawer</strong>, under
              <strong> data lineage</strong> tab.
            </div>
            <Button
              variant="outline"
              icon={<Icon name="sidebar-flip" />}
              onClick={() => {
                setActiveModule?.(module);
                openDrawer?.();
              }}
            >
              Open Module Drawer
            </Button>
          </div>
        }
      >
        <div>
          <Icon
            name="solid-circle-exclamation"
            className="circle-exclamation-icon"
          />
        </div>
      </Tooltip>
    </StyledMissingInfoTooltip>
  );
};

export default MissingInfoTooltip;
