import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserAssignmentsByOrganisation = styled.div`
  ${flex('row')};
  ${size('100%')};

  .esg-collapsible-side-panel {
    padding: ${theme.sizes.SIZE_24};
  }

  .esg-user-assignments-by-organisation {
    &__side-panel {
      &__content {
        ${flex('column')};
        font-size: ${theme.fontSizes.FONT_14};
        gap: ${theme.sizes.SIZE_16};

        min-width: ${theme.sizes.SIZE_304};

        > p {
          font-size: ${theme.fontSizes.FONT_16};
          font-weight: 600;
        }

        > button {
          font-size: ${theme.fontSizes.FONT_14};
        }
      }
    }

    &__trees {
      padding: ${theme.sizes.SIZE_24};
      width: 100%;
      overflow: auto;
    }
  }
`;

export { StyledUserAssignmentsByOrganisation };
