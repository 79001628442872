import {
  Divider,
  OverlayRef,
  StatusElement,
  TabOption,
  Tabs,
} from '@faxi/web-component-library';
import { NotificationBadgeWrapper } from 'components';
import {
  DataModuleElementDrawerComponentProps,
  DataModuleEnum,
  IDataModule,
  PermissionSections,
} from 'models';
import {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';

import Icon from '../../../../../components/Icon';
import { FEATURES } from '../../../../../config';
import { useUserPermissions } from '../../../../../hooks';
import ModuleElementActivity from '../ModuleElementActivity';
import ModuleElementComments from '../ModuleElementComments';
import ModuleElementDataConsolidation from '../ModuleElementDataConsolidation';
import ModuleElementDataLineage from '../ModuleElementDataLineage';
import ModuleElementDetails from '../ModuleElementDetails';
import { StyledModuleElementDrawer } from './ModuleElementDrawer.styled';

export type ModuleElementDrawerProps = PropsWithChildren<{
  module?: IDataModule;
  onUpdateNumberOfUnreadComments?: (unreadComments: number, id: string) => void;
}>;

export type ModuleElemetDrawerTab =
  | 'activity'
  | 'comments'
  | 'details'
  | 'data-consolidation'
  | 'data-lineage';

export const TAB_MAPPER: Record<
  ModuleElemetDrawerTab,
  FC<DataModuleElementDrawerComponentProps>
> = {
  activity: ModuleElementActivity,
  comments: ModuleElementComments,
  details: ModuleElementDetails,
  'data-consolidation': ModuleElementDataConsolidation,
  'data-lineage': ModuleElementDataLineage,
};

const ModuleElementDrawer: ForwardRefRenderFunction<
  OverlayRef,
  ModuleElementDrawerProps
> = (props, ref) => {
  const {
    module: {
      title,
      id = '',
      organisationSessionId = '',
      numberOfUnreadComments,
      isLocked,
      organisationId,
      type,
    } = {},
    onUpdateNumberOfUnreadComments,
  } = props;

  const [activeTab, setActiveTab] = useState<ModuleElemetDrawerTab>('activity');

  const hasCommentPermission = useUserPermissions(PermissionSections.COMMENTS);
  const hasLogPermission = useUserPermissions(PermissionSections.LOGS);

  const DrawerSection = useMemo(() => {
    if (
      [DataModuleEnum.SECTION, DataModuleEnum.TEXT_BOX].includes(type!) &&
      activeTab === 'data-consolidation'
    ) {
      setActiveTab('details');
      return TAB_MAPPER['details'];
    }
    if (
      [
        DataModuleEnum.SECTION,
        DataModuleEnum.TEXT_BOX,
        DataModuleEnum.FORMULA,
      ].includes(type!) &&
      activeTab === 'data-lineage'
    ) {
      setActiveTab('details');
      return TAB_MAPPER['details'];
    }

    if (!hasCommentPermission(['read']) && activeTab === 'comments') {
      setActiveTab('details');
      return TAB_MAPPER['details'];
    }

    if (!hasLogPermission(['read']) && activeTab === 'activity') {
      setActiveTab('details');
      return TAB_MAPPER['details'];
    }

    return TAB_MAPPER[activeTab];
  }, [activeTab, hasLogPermission, hasCommentPermission, type]);

  const drawerTabs: TabOption<ModuleElemetDrawerTab>[] = useMemo(
    () => [
      ...(FEATURES.SESSION_ACTIVITY_LOGS && hasLogPermission(['read'])
        ? [
            {
              iconName: 'wave-pulse-regular',
              label: 'Activity Log',
              value: 'activity',
            } as TabOption<ModuleElemetDrawerTab>,
          ]
        : []),
      ...(hasCommentPermission(['read'])
        ? [
            {
              iconNode: (
                <NotificationBadgeWrapper
                  key={numberOfUnreadComments?.toString()}
                  count={numberOfUnreadComments ?? 0}
                >
                  <Icon name="message" />
                </NotificationBadgeWrapper>
              ),
              label: 'Comments',
              value: 'comments',
            } as TabOption<ModuleElemetDrawerTab>,
          ]
        : []),
      { iconName: 'plus', label: 'Details', value: 'details' },
      ...(![DataModuleEnum.SECTION, DataModuleEnum.TEXT_BOX].includes(type!)
        ? [
            {
              iconName: 'database',
              label: 'Data Consolidation',
              value: 'data-consolidation' as ModuleElemetDrawerTab,
            },
          ]
        : []),
      ...(![
        DataModuleEnum.SECTION,
        DataModuleEnum.TEXT_BOX,
        DataModuleEnum.FORMULA,
      ].includes(type!)
        ? [
            {
              iconName: 'magnifying-glass-waveform',
              label: 'Data Lineage',
              value: 'data-lineage' as ModuleElemetDrawerTab,
            },
          ]
        : []),
    ],
    [hasCommentPermission, hasLogPermission, numberOfUnreadComments, type]
  );

  return (
    <StyledModuleElementDrawer
      ref={ref}
      title={
        <div className="esg-module-element-drawer__title">
          <Icon name="sidebar-flip" />
          {title ?? 'Element Drawer'}
          {isLocked && (
            <StatusElement
              status="rejected"
              icon="lock"
              small
              className="esg-module-element-drawer__title__status"
            >
              Locked
            </StatusElement>
          )}
        </div>
      }
      placement="Right"
      className="esg-module-element-drawer"
    >
      <Tabs<ModuleElemetDrawerTab>
        value={activeTab}
        className="esg-module-element-drawer__tabs"
        tabs={drawerTabs}
        onChange={setActiveTab}
        changeOrientationOnResponsive={false}
      />
      <Divider />
      <DrawerSection
        elementId={id}
        updateNumberOfUnreadComments={(unreadComments) => {
          onUpdateNumberOfUnreadComments?.(unreadComments, id);
        }}
        organisationSessionId={organisationSessionId}
        organisationId={organisationId!}
        isLocked={isLocked!}
        type={type}
      />
    </StyledModuleElementDrawer>
  );
};

export default forwardRef(ModuleElementDrawer);
