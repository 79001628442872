import { useUtilities } from '@faxi/web-component-library';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../api/routes';
import { SelectField } from '../../../../../components';
import { useSWRCache, useUserRoles } from '../../../../../hooks';
import { Progress } from '../../../../../models';
import {
  APPROVAL_OPTIONS,
  AUDIT_OPTIONS,
  FormApprovalEnum,
  FormAuditEnum,
  FormStatusEnum,
  STATUS_OPTIONS,
} from '../../../../../models/FormProgressStatus';
import { StyledFormStatus } from './FormStatus.styled';

export type FormStatusProps = {
  organisationSessionId: string;
  campaignItem: {
    status: FormStatusEnum;
    approval: FormApprovalEnum;
    audit: FormAuditEnum;
    progress: Progress;
  };
};

const checkIfCompletionIsDisabled = (
  campaignItem: FormStatusProps['campaignItem'],
  isRegularOrAdminOrSA: boolean
) => {
  const isInProgress = campaignItem?.progress?.progress !== 100;
  const isCompletedButPendingReview =
    campaignItem?.status === FormStatusEnum.Completed &&
    campaignItem?.audit !== FormAuditEnum.None &&
    campaignItem?.approval !== FormApprovalEnum.None;

  return isInProgress || isCompletedButPendingReview || !isRegularOrAdminOrSA;
};

const checkIfApprovalIsDisabled = (
  campaignItem: FormStatusProps['campaignItem'],
  isAdminOrSA: boolean
) => {
  const isIncomplete = campaignItem?.status !== FormStatusEnum.Completed;
  const isAudited = campaignItem?.audit === FormAuditEnum.Audited;

  return isIncomplete || isAudited || !isAdminOrSA;
};

const checkIfAuditIsDisabled = (
  campaignItem: FormStatusProps['campaignItem'],
  isAuditorOrSA: boolean
) => {
  const isUnapproved = campaignItem?.approval !== FormApprovalEnum.Approved;

  return isUnapproved || !isAuditorOrSA;
};

const FormStatus: FC<FormStatusProps> = ({
  organisationSessionId,
  campaignItem,
}) => {
  const { campaignItemId = '' } = useParams();
  const { showSnackBar } = useUtilities();
  const { mutateCacheKeys } = useSWRCache();

  const { isAdminOrSA, isAuditorOrSA, isRegularOrAdminOrSA } = useUserRoles();

  const { trigger: confirmStatus } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM_STATUS(
      organisationSessionId,
      campaignItemId
    ),
    {
      onSuccess: () => {
        showSnackBar(
          {
            text: "Heads up, your status might not update right away. You may need to refresh the app as it's updated in the background.",
            variant: 'alert',
            actionButtonText: 'Dismiss',
          },
          { disappearAfter: 5000 }
        );
        showSnackBar({
          text: 'Successfully changed form status.',
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        mutateCacheKeys(
          (key) =>
            key ===
              API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
                organisationSessionId,
                campaignItemId
              ) || key.endsWith('/organisation-sessions')
        );
      },
    },
    true
  );

  const { trigger: confirmApprovalStatus } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM_APPROVAL_STATUS(
      organisationSessionId,
      campaignItemId
    ),
    {
      onSuccess: () => {
        showSnackBar({
          text: 'Successfully changed approval status.',
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        showSnackBar(
          {
            text: "Heads up, your approval status might not update right away. You may need to refresh the app as it's updated in the background.",
            variant: 'alert',
            actionButtonText: 'Dismiss',
          },
          { disappearAfter: 5000 }
        );
        mutateCacheKeys(
          (key) =>
            key ===
              API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
                organisationSessionId,
                campaignItemId
              ) || key.endsWith('/organisation-sessions')
        );
      },
    },
    true
  );

  const { trigger: confirmAuditStatus } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM_AUDIT_STATUS(
      organisationSessionId,
      campaignItemId
    ),
    {
      onSuccess: () => {
        showSnackBar({
          text: 'Successfully changed audit status.',
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        showSnackBar(
          {
            text: "Heads up, your audit status might not update right away. You may need to refresh the app as it's updated in the background.",
            variant: 'alert',
            actionButtonText: 'Dismiss',
          },
          { disappearAfter: 5000 }
        );
        mutateCacheKeys(
          (key) =>
            key ===
              API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
                organisationSessionId,
                campaignItemId
              ) || key.endsWith('/organisation-sessions')
        );
      },
    },
    true
  );

  return (
    <StyledFormStatus className="esg-form-status">
      <div className="esg-form-status__field">
        Completion
        <SelectField
          title="Completion"
          options={STATUS_OPTIONS}
          onChange={async (status) => {
            if (campaignItem?.status === status) return;

            confirmStatus({ method: 'POST', data: { status } });
          }}
          value={campaignItem?.status}
          disabled={checkIfCompletionIsDisabled(
            campaignItem,
            isRegularOrAdminOrSA
          )}
        />
      </div>
      <div className="esg-form-status__field">
        Approval
        <SelectField
          title="Approval"
          options={APPROVAL_OPTIONS(
            campaignItem?.approval === FormApprovalEnum.None
          )}
          onChange={async (approval) => {
            if (campaignItem?.approval === approval) return;

            confirmApprovalStatus({ method: 'POST', data: { approval } });
          }}
          value={campaignItem?.approval}
          disabled={checkIfApprovalIsDisabled(campaignItem, isAdminOrSA)}
        />
      </div>
      <div className="esg-form-status__field">
        Audit
        <SelectField
          title="Audit"
          options={AUDIT_OPTIONS(campaignItem?.audit === FormAuditEnum.None)}
          onChange={async (audit) => {
            if (campaignItem?.audit === audit) return;

            confirmAuditStatus({ method: 'POST', data: { audit } });
          }}
          value={campaignItem?.audit}
          disabled={checkIfAuditIsDisabled(campaignItem, isAuditorOrSA)}
        />
      </div>
    </StyledFormStatus>
  );
};

export default FormStatus;
