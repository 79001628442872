import { FC } from 'react';

import { BlockUI } from '../../../../helpers';
import { CampaignSessionStatus } from '../../../../models';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import { SessionDashboardCard } from '../SessionDashboard/components';
import { StyledSessionCompanyBrowser } from './SessionCompanyBrowser.styled';

const SessionCompanyBrowser: FC = () => {
  const { isLoading, error, sessionCompanies } = useSessionBrowser();
  return (
    <StyledSessionCompanyBrowser
      title="Companies"
      className="esg-session-company-browser"
      direction="column"
    >
      <BlockUI loading={isLoading} fallbackCondition={error}>
        <div className="esg-session-company-browser__content">
          {sessionCompanies?.map((data) => (
            <SessionDashboardCard
              key={data.id}
              {...data}
              loading={data.status === CampaignSessionStatus.Draft}
              loadingText="Preparing Forms..."
            />
          ))}
        </div>
      </BlockUI>
    </StyledSessionCompanyBrowser>
  );
};

export default SessionCompanyBrowser;
