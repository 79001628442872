import { theme } from '@faxi/web-component-library';
import { flex, laptop } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled, { css } from 'styled-components';

const StyledSessionDashboard = styled(BaseLayout)`
  .esg-base-layout {
    &__content {
      width: 100%;

      ${laptop(css`
        grid-template-columns: 1fr;
      `)}
    }
  }

  .esg-session-dashboard {
    &__container {
      width: 100%;
      ${flex('column')};
      min-height: fit-content;

      &__description {
        margin-bottom: ${theme.sizes.SIZE_32};
      }

      &__charts {
        width: fit-content;
        ${flex('row')};
        flex-wrap: wrap;
        gap: ${theme.sizes.SIZE_40};
        margin-bottom: ${theme.sizes.SIZE_64};

        h2 {
          margin: 0 0 ${theme.sizes.SIZE_32} 0;
        }

        &__chart {
          width: ${theme.sizes.SIZE_320};
        }
      }

      &__info {
        ${flex('column')};
        font-size: ${theme.fontSizes.FONT_18};
        gap: ${theme.sizes.SIZE_12};
      }
    }
  }
`;

export { StyledSessionDashboard };
