import { MenuProps, MenuRef } from '@faxi/web-component-library';
import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import Icon from '../../Icon';
import { StyledThreeDotMenu } from './ThreeDotMenu.styled';

export type ThreeDotMenuProps = PropsWithChildren<
  Omit<MenuProps, 'triggerTitle'>
>;

const ThreeDotMenu: ForwardRefRenderFunction<MenuRef, ThreeDotMenuProps> = (
  props,
  ref
) => (
  <StyledThreeDotMenu
    ref={ref}
    gutter={2}
    className="esg-three-dot-menu"
    portalClassName="esg-three-dot-menu-content"
    triggerTitle=""
    triggerProps={{
      variant: 'ghost',
      className: 'esg-three-dot-menu__dots',
      icon: <Icon name="ellipsis-vertical" />,
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
      },
    }}
    {...props}
  />
);

export default forwardRef(ThreeDotMenu);
