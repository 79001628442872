import { useQueryParams, useUtilities } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../api/routes';
import { FormFooter, InputField } from '../../../../../components';
import PasswordField from '../../../../../components/_fields/PasswordField';
import { APP_URI } from '../../../../../config';
import { useCallbackAsync, useValidations } from '../../../../../hooks';
import { StyledResetPassword } from './ResetPassword.styled';

const ResetPassword: FC = () => {
  const { validations } = useValidations();

  const {
    params: { email },
  } = useQueryParams<{ email: string }>();
  const { showSnackBar } = useUtilities();

  const { trigger } = useMutation<void>(API_ROUTES.USERS.RESET_PASSWORD);

  const navigate = useNavigate();

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    callback: async (data: {
      email: string;
      confirmationCode: string;
      password: string;
      repeatPassword: string;
    }) => {
      const { confirmationCode, password: newPassword, email } = data;
      await trigger({
        method: 'POST',
        data: { confirmationCode, newPassword, email },
      });
      showSnackBar({
        text: `Your password has been updated successfully.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
      navigate(APP_URI.AUTH_LOGIN);
    },
  });

  return (
    <StyledResetPassword
      className="esg-reset-password"
      onSubmit={handleSubmit}
      initialData={{ email }}
    >
      <h2 className="esg-reset-password__password-title">Reset Password</h2>
      <p className="esg-reset-password__password-content">
        Enter your confirmation code along with new password
      </p>
      <FormField
        name="email"
        type="text"
        placeholder="Email"
        autoComplete="off"
        component={InputField}
        validate={validations.email}
        disabled={!!email}
      />
      <FormField
        name="confirmationCode"
        type="text"
        autoComplete="off"
        placeholder="Confirmation Code"
        component={InputField}
        validate={validations.confirmationCode}
      />
      <FormField
        name="password"
        placeholder="New Password"
        component={PasswordField}
        validate={validations.password}
      />
      <FormField
        name="repeatPassword"
        placeholder="Repeat Password"
        component={PasswordField}
        validate={validations.repeatPassword}
      />
      <FormFooter submitLabel="Reset Password" />
      <Link to="../" className="auth-text">
        I Remembered It
      </Link>
    </StyledResetPassword>
  );
};

export default ResetPassword;
