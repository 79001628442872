import { Button } from '@faxi/web-component-library';
import classNames from 'classnames';
import { Comment as TypeComment } from 'models';
import { FC, Fragment, PropsWithChildren, useMemo } from 'react';
import { formatRelativeOrFullDate } from 'utils';

import Icon from '../../../../../components/Icon';
import UserMentionOption from '../UserMentionOption';
import { StyledComment } from './Comment.styled';

export type CommentProps = PropsWithChildren<{
  comment: TypeComment;
  isError: boolean;
  onRetry: (comment: TypeComment) => void;
  onDelete: (comment: TypeComment) => void;
}>;

const Comment: FC<CommentProps> = ({
  comment,
  isError = false,
  onRetry,
  onDelete,
}) => {
  const {
    content,
    author: { roles, lastName, firstName } = {},
    createdAt = '',
    isRead,
  } = comment;

  const parsedContentIntoCommentWithMetntions = useMemo(() => {
    if (!content?.length) return '';

    return content.map(({ meta: { type, entityId }, value }) =>
      type === 'mention' ? <span key={entityId ?? value}>@{value}</span> : value
    );
  }, [content]);

  return (
    <StyledComment
      $isError={isError}
      $isRead={isRead}
      className={classNames('esg-comment', { 'esg-comment--error': isError })}
      tabIndex={0}
    >
      <div className="esg-comment__author">
        <UserMentionOption
          name={`${firstName} ${lastName}`}
          role={roles?.[0]}
        />
        {isError ? (
          <Fragment>
            <p>
              <Icon name="triangle-exclamation" />
              Message Not Delivered.
            </p>
            <Button
              variant="outline"
              onClick={() => onRetry(comment)}
              icon={<Icon name="arrow-rotate-right" />}
            >
              Retry
            </Button>
            <Button
              variant="outline"
              onClick={() => onDelete(comment)}
              icon={<Icon name="trash-can" />}
            >
              Delete
            </Button>
          </Fragment>
        ) : (
          <span>{formatRelativeOrFullDate(createdAt)}</span>
        )}
      </div>
      <div className="esg-comment__message">
        {parsedContentIntoCommentWithMetntions}
      </div>
    </StyledComment>
  );
};

export default Comment;
