import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import EntityFormModal, {
  EntityFormModalProps,
} from 'components/_forms/EntityFormModal';
import { Organisation } from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { API_ROUTES } from '../../../../../api/routes';
import { useOrganisationProvider } from '../../../../../providers/Organisation';
import { useRootProvider } from '../../../../../providers/Root';

type OrganisationFormType = Pick<Organisation, 'name' | 'description'>;

function OrganisationModal<T extends Record<string, any>>(
  props: Omit<EntityFormModalProps<T>, 'onSubmit' | 'fieldsConfiguration'>
) {
  const { initialData, onClose, ...rest } = props;

  const { mutateOrganisations } = useOrganisationProvider();

  const { showSnackBar } = useUtilities();

  const {
    user: {
      organisation: { id: rootOrganisationId },
    },
  } = useRootProvider();

  const { organisationId } = useParams<{ organisationId: string }>();

  const showSnackMessage = useCallback(
    (data: Organisation) => {
      showSnackBar({
        text: `${data.name} ${initialData ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [initialData, showSnackBar]
  );

  const onSuccess = useCallback(
    (response: { data: Organisation }) => {
      mutateOrganisations();
      showSnackMessage(response.data);
      onClose?.();
    },
    [mutateOrganisations, showSnackMessage, onClose]
  );

  const { trigger: updateCompany, isMutating: updateMutating } =
    api.useMutation(API_ROUTES.ORGANISATIONS.ORGANISATION(initialData?.id), {
      onSuccess,
    });

  const { trigger: createCompany, isMutating: createMutating } =
    api.useMutation<{ data: Organisation }>(
      API_ROUTES.ORGANISATIONS.ORGANISATION(
        organisationId || rootOrganisationId
      ),
      { onSuccess }
    );

  const loading = useMemo(
    () => updateMutating || createMutating,
    [createMutating, updateMutating]
  );

  const handleSubmit = useCallback(
    async (data: OrganisationFormType) => {
      await (initialData ? updateCompany : createCompany)({
        method: initialData ? 'PATCH' : 'POST',
        data,
      });
    },
    [initialData, updateCompany, createCompany]
  );

  return (
    <EntityFormModal
      className="esg-company-modal"
      loading={loading}
      initialData={
        initialData
          ? { name: initialData.name, description: initialData.description }
          : undefined
      }
      title={initialData ? `Edit ${initialData.name}` : 'Create Organisation'}
      onSubmit={handleSubmit}
      onClose={onClose}
      fieldsConfiguration={{
        NAME: !initialData,
        DESCRIPTION: true,
        EMAIL: false,
        ROLE: false,
        TYPE: false,
        CAMPAIGN: false,
        TAXONOMY_ID: false,
        TAXONOMY_GROUP: false,
      }}
      {...rest}
    />
  );
}

export default OrganisationModal;
