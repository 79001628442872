import styled from 'styled-components';

const StyledEllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export { StyledEllipsisText };
