import { UserImage } from 'components';
import { ActivityLog } from 'models';
import { LOG_ACTION_MAPPER } from 'pages/Sessions/constants';
import { FC } from 'react';
import { formatRelativeOrFullDate, generateFullName } from 'utils';

import { generateActivityLogName, isStatusChangeAction } from '../../utils';
import { StyledFormActivityLog } from './FormActivityLog.styled';

export type FormActivityLogProps = ActivityLog & {
  onClick?: (elementId: string) => void;
};

const FormActivityLog: FC<FormActivityLogProps> = ({
  performedBy: { firstName, lastName, img },
  action,
  type,
  title,
  timestamp,
  path,
  onClick,
}) => (
  <StyledFormActivityLog
    className="esg-form-activity-log"
    onClick={() => {
      if (!path.elementId) return;
      onClick?.(path.elementId);
    }}
  >
    <div className="esg-form-activity-log__top">
      <div className="esg-form-activity-log__top__name">
        <UserImage src={img} name={generateFullName(firstName, lastName)} />
        <span>{generateFullName(firstName, lastName)}</span>
      </div>
      <span className="esg-form-activity-log__top__date">
        {formatRelativeOrFullDate(timestamp)}
      </span>
    </div>
    <div className="esg-form-activity-log__bottom">
      {LOG_ACTION_MAPPER[action]}
      {!isStatusChangeAction(action)
        ? ` in ${generateActivityLogName(title, type)}`
        : ''}
    </div>
  </StyledFormActivityLog>
);

export default FormActivityLog;
