import { Tabs } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import { SessionCard } from '../../../../../../../components';
import { APP_URI } from '../../../../../../../config';
import { BlockUI } from '../../../../../../../helpers';
import { useLayoutPreviewMode } from '../../../../../../../hooks';
import { previewTabs } from '../../../../../../../hooks/useLayoutPreviewMode';
import { LayoutPreview, Session } from '../../../../../../../models';
import { StyledUserAssignmentSessions } from './UserAssignmentSessions.styled';

export type UserAssignmentSessionsProps = PropsWithChildren<{
  className?: string;
}>;

const UserAssignmentSessions: FC<UserAssignmentSessionsProps> = ({
  className,
}) => {
  const { view, setView } = useLayoutPreviewMode('list');

  const {
    data: { data: sessions = [] } = {},
    isLoading,
    error,
  } = useSWR<{ data: Session[] }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ASSIGNMENTS_BY_SESSION()
  );

  const navigate = useNavigate();

  const onSelectCard = useCallback(
    (session: Session) => navigate(`${APP_URI.ASSIGNMENTS}/${session.id}`),
    [navigate]
  );

  return (
    <StyledUserAssignmentSessions
      className={classNames('esg-user-assignment-sessions', className)}
    >
      <div className="esg-user-assignment-sessions__actions">
        <h2>Sessions</h2>
        <Tabs<LayoutPreview>
          value={view}
          labelPosition="right"
          tabs={previewTabs}
          onChange={setView}
          changeOrientationOnResponsive={false}
        />
      </div>

      <BlockUI
        loading={isLoading}
        fallbackCondition={!sessions.length}
        error={error}
      >
        <div className="esg-user-assignment-sessions__cards" data-view={view}>
          {sessions.map((session) => (
            <SessionCard
              onSelect={() => onSelectCard(session)}
              key={session.id}
              cardData={session}
            />
          ))}
        </div>
      </BlockUI>
    </StyledUserAssignmentSessions>
  );
};

export default UserAssignmentSessions;
