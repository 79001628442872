import { v4 as uuidv4 } from 'uuid';

import {
  ActivityAction,
  ActivityLog,
  Comment,
  CommentType,
  DataModuleEnum,
  PaginatedApiData,
  User,
} from '../../../../models';

export const generateOptimisticComment = (
  message: CommentType[],
  author: User
): Comment => ({
  id: uuidv4(),
  author,
  content: message,
  authorId: author.id,
  elementId: 'temp',
  createdAt: new Date().toISOString(),
  isRead: true,
});

export const optimisticUpdateCommentsCache = (
  comment: Comment,
  commentsApiData?: PaginatedApiData<Comment>[]
): PaginatedApiData<Comment>[] | undefined => {
  if (!commentsApiData?.length) return;

  return [
    {
      data: {
        ...commentsApiData[0].data,
        items: [comment, ...commentsApiData[0].data.items.slice()],
      },
    },
    ...commentsApiData.slice(1),
  ];
};

export const updateWithErrorComment = (
  comment: Comment,
  commentsApiData?: PaginatedApiData<Comment>[]
): PaginatedApiData<Comment>[] | undefined => {
  if (!commentsApiData?.length) return;

  return [
    {
      data: {
        ...commentsApiData[0].data,
        items: [
          { ...comment, isError: true },
          ...commentsApiData[0].data.items.slice(1),
        ],
      },
    },
    ...commentsApiData.slice(1),
  ];
};

export const generateActivityLogName = (
  title?: string,
  type?: DataModuleEnum
) => `${title || '-'} ${type ? `(${type})` : ''}`;

export const isValueChangeAction = (action: ActivityAction) =>
  [
    ActivityAction.InputUpdated,
    ActivityAction.InputEntered,
    ActivityAction.InputCleared,
  ].includes(action);

export const isStatusChangeAction = (action: ActivityAction) =>
  [
    ActivityAction.FormStatusUpdated,
    ActivityAction.FormApprovalUpdated,
    ActivityAction.FormAuditUpdated,
  ].includes(action);

const ALL_HANDLED_LOGS = Object.values(ActivityAction);

export const isLogValid = (log: ActivityLog) =>
  ALL_HANDLED_LOGS.includes(log.action);
