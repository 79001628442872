import { Breadcrumb, StatusElementStatus } from '@faxi/web-component-library';
import Icon, { INameExtended } from 'components/Icon';
import {
  CampaignItem,
  CampaignItemType,
  CampaignItemTypeDetailed,
  CampaignWithItems,
  DataModuleEnum,
  ElementsOmitIdElements,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import { v4 as uuidv4 } from 'uuid';

import { campaignPathCheckpoints } from '../../utils';

export const dataModuleTextMapper: Record<DataModuleEnum, string> = {
  'section': 'Section',
  'text-box': 'Text Box',
  'switch': 'Switch',
  'upload': 'Upload',
  'input': 'Input',
  'choice': 'Choice',
  'formula': 'Formula',
  'integration': 'Integration',
  'checklist': 'Checklist',
  'divider': 'Divider',
  'dropdown': 'Dropdown',
  'table': 'Table',
};

export const mapCampaignItemTypeToStatus: Record<
  CampaignItemType | CampaignItemTypeDetailed,
  StatusElementStatus
> = {
  'campaign': 'active',
  'topic': 'draft',
  'subtopic': 'draft',
  'subsubtopic': 'draft',
  'subsubsubtopic': 'draft',
  'data_collection': 'approved',
};

export const mapCampaignItemTypeToIcon: Record<
  CampaignItemType | CampaignItemTypeDetailed,
  INameExtended
> = {
  'campaign': 'leaf',
  'topic': 'leaf',
  'subtopic': 'leaf',
  'subsubtopic': 'leaf',
  'subsubsubtopic': 'leaf',
  'data_collection': 'clipboard-list',
};

export const mapCampaignItemTypeToText: Record<
  CampaignItemType | CampaignItemTypeDetailed,
  string
> = {
  'campaign': 'Campaign',
  'topic': 'Topic',
  'subtopic': 'Sub topic',
  'subsubtopic': 'Sub sub topic',
  'subsubsubtopic': 'Sub sub sub topic',
  'data_collection': 'Data Collection',
};

export const mapCampaignItemPathLengthToCampaignItemTypeDetailed = (
  pathLength: number
): CampaignItemTypeDetailed => {
  switch (pathLength) {
    case 0:
      return 'campaign';
    case 1:
      return 'topic';
    case 2:
      return 'subtopic';
    case 3:
      return 'subsubtopic';
    default:
      return 'subsubsubtopic';
  }
};

export const mapCampaignEntityTypeToText: Record<
  CampaignItemTypeDetailed,
  string
> = {
  'campaign': 'Campaign',
  'topic': 'Topic',
  'subtopic': 'Sub Topic',
  'subsubtopic': 'Sub Sub Topic',
  'subsubsubtopic': 'Sub Sub Topic',
};

export const mapDataModuleTypeToIcon: Record<DataModuleEnum, INameExtended> = {
  'section': 'rectangle',
  'text-box': 'text',
  'switch': 'toggle-on',
  'upload': 'upload',
  'input': 'input-pipe',
  'choice': 'ballot-check',
  'formula': 'function',
  'divider': 'horizontal-rule',
  'checklist': 'square-check',
  'integration': 'gears',
  'dropdown': 'caret-down',
  'table': 'table',
};

export const mapDataModuleTypeToTitle: Record<DataModuleEnum, string> = {
  'text-box': 'Text Configuration',
  'switch': 'Switch Configuration',
  'upload': 'Upload Document Configuration',
  'input': 'Input Field Configuration',
  'choice': 'Choice Configuration',
  'formula': 'Formula Configuration',
  'checklist': 'Checklist Configuration',
  'dropdown': 'Dropdown Configuration',
  'integration': 'Integration Configuration',
  'divider': 'Divider Configuration',
  'section': 'Section Configuration',
  'table': 'Table Configuration',
};

export const campaignItemTypeSelectOptions = (level: number) => [
  {
    value: 'data_collection',
    label: 'Form',
    icon: <Icon name="clipboard-list" />,
  },
  {
    value: 'topic',
    label: `Sub${Array.from({ length: level + 1 }).reduce((acc) => acc + ' sub', '')} topic`,
    icon: <Icon name="clipboard" />,
  },
];

/**
 * Campaign names and IDs are separated with "#" character
 *
 * e.g. campaignNamedPath = "name1#name2#name3"
 * e.g. campaignPath = "id1#id2#id3"
 */
export const generateCampaignItemCrumbs = (
  rootCampaign: CampaignWithItems | undefined,
  campaignItem: CampaignItem | undefined
): Breadcrumb[] => {
  const campaignsPageCrumb = {
    text: 'Campaigns',
    href: `/${campaignPathCheckpoints[0]}`,
    id: 'campaigns-link',
  };

  if (!campaignItem || !rootCampaign) return [campaignsPageCrumb];

  const { id, name } = campaignItem;
  let href = '';

  href = `${href}/${campaignPathCheckpoints[0]}/${rootCampaign.id}`;

  const rootCampaignPage = { text: rootCampaign.name, href };

  const { campaignNamedPath, campaignPath } = campaignItem;

  if (rootCampaign.id === id) return [campaignsPageCrumb, rootCampaignPage];

  if (!campaignNamedPath || !campaignPath)
    return [campaignsPageCrumb, rootCampaignPage, { text: name, href: id, id }];

  const names = campaignNamedPath.split('#');
  const ids = campaignPath.split('#');

  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  return [
    campaignsPageCrumb,
    rootCampaignPage,
    ...namesTemp.map((name, index) => {
      href = `${href}/${campaignPathCheckpoints[index + 1]}/${idsTemp[index]}`;

      return { text: name, href, id: idsTemp[index] };
    }),
  ];
};

export const prepareCampaignItemElements = (
  elements: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): { elements: ElementsOmitIdElements[] } => ({
  elements: elements.map(
    ({ type, title, id, conditionalElements, config, elements }, index) => ({
      index,
      type,
      title,
      id: !Object.values(DataModuleEnum).includes(id as DataModuleEnum)
        ? id.split('+')[0]
        : uuidv4(),
      ...(conditionalElements && { conditionalElements }),
      ...(config && { config }),
      ...(type === 'section' && prepareCampaignItemElements(elements || [])),
    })
  ),
});
