import { Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import { BasicTag } from '../../../../../../../components';
import UserImage from '../../../../../../../components/_atoms/UserImage';
import { BlockUI } from '../../../../../../../helpers';
import { UserActivityLog } from '../../../../../../../models';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../../../../../pages/Users/constants';
import { useRootProvider } from '../../../../../../../providers/Root';
import { generateFullName } from '../../../../../../../utils';
import {
  groupByDate,
  groupByDateHeader,
} from '../../../../../../../utils/groupByDate';
import StatusFlag from '../../../../../../components/ProfileNotifications/components/StatusFlag';
import { StyledUserRoleHistory } from './UserRoleHistory.styled';

dayjs.extend(isToday);

type UserRoleHistoryTableData = {
  id: number;
  user: JSX.Element;
  action: string;
  newRole: JSX.Element;
  timestamp: string;
};

const actionLabels: Record<string, string> = {
  'assign': 'Assigned you to',
  'role-update': 'Updated your role to',
  'mark': 'Marked',
};

const EXCLUDE_COLUMNS: (keyof UserRoleHistoryTableData)[] = ['id', 'timestamp'];

const TRANSLATION_KEYS = {
  id: 'ID',
  user: 'User',
  action: 'Action',
  newRole: 'New Role',
  timestamp: 'Timestamp',
};

const UserRoleHistory: FC = () => {
  const { user } = useRootProvider();

  const {
    data: { data = [] } = {},
    isLoading,
    error,
  } = useSWR<{ data: UserActivityLog[] }>(
    API_ROUTES.ACTIVITY_LOGS_ROUTES.USER(user.id)
  );

  const groupedData = groupByDate(
    data.map(
      (
        { timestamp, performedBy: { firstName, lastName, img }, newValue },
        index
      ) => ({
        timestamp,
        id: index,
        user: (
          <div className="esg-role-history__user">
            <StatusFlag isRead={false} />
            <UserImage name={generateFullName(firstName, lastName)} src={img} />
            <span>{generateFullName(firstName, lastName)}</span>
            {/* <BasicTag
          text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[newValue.name]}
          color={newValue.color} TODO: add when it is ready on backend
        /> */}
          </div>
        ),
        action: actionLabels['role-update'],
        newRole: (
          <BasicTag
            text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[newValue.name]}
            color={newValue.color}
          />
        ),
      })
    )
  );

  return (
    <StyledUserRoleHistory className="esg-role-history">
      <h2>Your Role History</h2>
      <BlockUI loading={isLoading} fallbackCondition={!data.length || error}>
        {Object.entries(groupedData).map(([date, data], index) => (
          <div key={index} className="esg-role-history__table">
            <p>{groupByDateHeader(date)}</p>
            <Table<UserRoleHistoryTableData>
              tableId={`esg-role-history-table-${index}`}
              tableData={data}
              excludeColumns={EXCLUDE_COLUMNS}
              disableSort
              translationKeys={TRANSLATION_KEYS}
            />
          </div>
        ))}
      </BlockUI>
    </StyledUserRoleHistory>
  );
};

export default UserRoleHistory;
