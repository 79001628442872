import {
  Calendar,
  CalendarProps,
  CalendarValue,
  config,
  DropdownProps,
  DropdownRef,
  Input,
  InputProps,
  useCallbackRef,
} from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';

import { DATE_FORMAT } from '../../../constants';
import Icon from '../../Icon';
import { PortalContainer, StyledCalendarField } from './CalendarField.styled';
import { CALENDAR_LABELS } from './utils';

export type CalendarFieldProps = {
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  renderAsPortal?: boolean;
  dateFormat?: string;
  onSetDate?: (date: Dayjs | null) => void;
  onChange?: (date: Dayjs | null) => void;
  openAtToday?: boolean;
} & Omit<InputProps, 'value' | 'onBlur'> &
  Pick<
    CalendarProps,
    'disableFuture' | 'disablePast' | 'mode' | 'value' | 'disabledDates'
  > &
  Pick<DropdownProps, 'openPosition'> &
  FieldProps<string, (event: string) => void>;

const CalendarField: FC<CalendarFieldProps> = (props) => {
  const {
    dirty,
    dateFormat = DATE_FORMAT,
    className,
    placeholder,
    renderAsPortal = true,
    leftLimitDate,
    rightLimitDate,
    value: pValue,
    disableFuture,
    disabledDates,
    disablePast,
    required,
    openPosition,
    error,
    touched,
    onSetDate,
    onChange,
    onBlur,
    disabled,
    openAtToday,
    ...rest
  } = props;

  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();
  const hasError = useMemo(() => error && touched, [error, touched]);

  const valueDate = useMemo(
    () =>
      pValue && pValue !== '-' ? dayjs(pValue, config.dateFormat) : undefined,
    [pValue]
  );

  const value = useMemo(
    () =>
      pValue && pValue !== '-'
        ? dayjs(pValue, config.dateFormat).format(dateFormat)
        : undefined,
    [dateFormat, pValue]
  );

  const closeDropdown = useCallback(() => {
    dropdown?.trigger.focus();
    dropdown?.setOpen(false);
  }, [dropdown]);

  const handleOnChange = useCallback(
    (date?: CalendarValue) => {
      if (!date) return;

      onSetDate?.(date as Dayjs);
      onChange?.(date as Dayjs);
    },
    [onSetDate, onChange]
  );

  return (
    <StyledCalendarField
      ref={dropdownRef}
      hasCloudArrow={false}
      openPosition={openPosition}
      renderAsPortal={renderAsPortal}
      portalStyle={PortalContainer}
      disabled={disabled}
      trigger={
        <Input
          value={value}
          readOnly={true}
          error={hasError}
          errorText={String(error)}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          {...(required && { requiredLabel: 'Required' })}
          prefixIcon={
            <Icon
              name="calendar-days"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          }
          {...rest}
        />
      }
      body={
        <Calendar
          keepFocus={dropdown?.open}
          labels={CALENDAR_LABELS}
          dateFormat={dateFormat}
          disablePast={disablePast}
          disableFuture={disableFuture}
          leftLimitDate={leftLimitDate}
          disabledDates={disabledDates}
          rightLimitDate={rightLimitDate}
          value={valueDate}
          onClose={closeDropdown}
          onChange={handleOnChange}
          openAtToday={openAtToday}
        />
      }
    />
  );
};

export default CalendarField;
