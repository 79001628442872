import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUsersPreview = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_24};

  &[data-view='list'] {
    ${flex('column')};
  }

  &[data-view='grid'] {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${theme.sizes.SIZE_288}, 1fr)
    );
  }

  .esg-users-preview {
    &__tabs {
      .wcl-tabs__option__icon {
        margin: 0;
      }
    }

    &__empty-folder {
      ${size('100%')};
      ${flex('column', 'center', 'center')};

      background-color: red;
    }

    &__cards {
      max-width: none;
    }
  }
`;

export { StyledUsersPreview };
