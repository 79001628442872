import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSelectUnit = styled.div`
  ${flex('row')};
  gap: ${theme.sizes.SIZE_32};

  .esg-select-unit {
    &__fields {
      ${flex('column', 'flex-end', 'center')};
      gap: ${theme.sizes.SIZE_16};
      width: ${theme.sizes.SIZE_320};
      margin-left: auto;

      .esg-select-field {
        width: 100%;
      }
    }
  }
`;

export { StyledSelectUnit };
