const APP_URI = {
  BASE: '/',

  ASSIGNMENTS: '/assignments',

  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_RESET_PASSWORD: '/auth/reset-password',

  NEW_PASSWORD_REQUIRED: '/setup-password',

  DASHBOARD: '/dashboard',

  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_CAMPAIGN: '/campaigns/:campaign',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC: '/campaigns/:campaign/topics/:topic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic/subsubsubsubtopics/:subsubsubsubtopic',

  ORGANISATIONS: '/organisations',
  ORGANISATIONS_ORGANISATION: '/organisations/:organisationId',

  SESSIONS: '/sessions',
  SESSIONS_SESSION: ':sessionId',
  SESSIONS_SESSION_RUN: 'run',
  SESSIONS_SESSION_DASHBOARD: 'dashboard',
  SESSIONS_SESSION_DASHBOARD_COMPANY: ':companyId',
  SESSIONS_SESSION_DASHBOARD_COMPANY_FORM: ':formId',
  SESSIONS_SESSION_ACTIVITY: 'activity',
  SESSIONS_SESSION_MEMBERS: 'members',
  SESSIONS_SESSION_CAMPAIGN_BROWSER: 'campaign-browser',
  SESSIONS_SESSION_DETAILS_REPORT: 'report',
  SESSIONS_SESSION_DETAILS_DOCUMENTS: 'documents',
  SESSIONS_SESSION_DETAILS_DMA_EXCLUSION: 'dma-exclusion',

  SESSION_REPORT_COMPANY: 'companies/:company',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC: 'companies/:company/topics/:topic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',

  USERS: '/users',
  USER_ROLES: '/users/user-roles',
  USER_BY_ID_PROFILE: '/users/:userId',

  XBRL: '/xbrl',

  ADMIN: '/admin',

  PROFILE: '/profile',
  ACCOUNT_SETTINGS: '/profile/account-settings',
  PERSONAL_DETAILS: '/profile/personal-details',
  NOTIFICATIONS: '/profile/notifications',
  REMINDERS: '/profile/reminders',
  ROLE_HISTORY: '/profile/role-history',

  INTEGRATIONS: '/integrations',
};

export { APP_URI };
