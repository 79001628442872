import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { DataModuleEnum } from 'models';
import { FC, memo } from 'react';

import { useFormBuilder } from '../../../../context/FormBuilder';
import {
  dataModuleTextMapper,
  mapDataModuleTypeToIcon,
} from '../../../../utils';
import { animateLayoutChanges } from '../../utils';
import * as Styled from './DataModule.styled';

const DataModule: FC<{ type: DataModuleEnum; isGrabbing?: boolean }> = (
  props
) => {
  const { type, isGrabbing } = props;

  const { selectDataModule, selectedDataModule } = useFormBuilder();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: `data-module_${type}`, animateLayoutChanges });

  return (
    <Styled.StyledDataModule
      onClick={() => selectDataModule?.(type)}
      onKeyDown={(e) => {
        if (e.code === 'Space') {
          e.preventDefault();
          selectDataModule?.(type);
        }
      }}
      className={classNames('esg-data-module-tool', {
        'esg-data-module-tool--active': selectedDataModule === type,
        'esg-data-module-tool--is-dragging': isDragging,
        'esg-data-module-tool--is-grabbing': isGrabbing,
      })}
      ref={setNodeRef}
      id={`data-module_${type}`}
      style={{ transition, transform: CSS.Translate.toString(transform) }}
      {...attributes}
      {...listeners}
    >
      <Icon name={mapDataModuleTypeToIcon[type]} />
      <p>{dataModuleTextMapper[type]}</p>
    </Styled.StyledDataModule>
  );
};

export default memo(DataModule);
