import { Table } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import { ApprovalProgressBar } from '../../../components';
import { Reminder } from '../../subrouters/Users/subrouters/UserProfile/pages/UserReminders/mock';
import StatusFlag from '../ProfileNotifications/components/StatusFlag';
import DueReminderFlag from './components';
import { StyledProfileReminders } from './ProfileReminders.styled';

export type ProfileRemindersProps = PropsWithChildren<{
  reminders: Reminder[];
}>;

const mapRemindersToTableData = (reminders: Reminder[]) =>
  reminders.map(({ title, from, isRead, progress, dueDate }, index) => ({
    id: index,
    title: (
      <div className="esg-profile-reminders__title">
        <StatusFlag isRead={isRead} />
        {title}
      </div>
    ),
    from,
    progress: (
      <ApprovalProgressBar
        status={progress.progress === 100 ? 'Completed' : 'In Progress'}
        progress={progress}
      />
    ),
    dueDate: <DueReminderFlag dueDate={dueDate} />,
  }));

const ProfileReminders: FC<ProfileRemindersProps> = (props) => {
  const { reminders } = props;

  // TODO
  // Reminders are mocked. (Missing BE for functionalities)
  return (
    <StyledProfileReminders className="esg-profile-reminders">
      <h2>Reminders</h2>

      <Table
        tableData={mapRemindersToTableData(reminders)}
        tableId="esg-reminders"
        disableSort
        excludeColumns={['id']}
        translationKeys={{
          title: 'Title',
          dueDate: 'Due Date',
          from: 'From',
          id: 'ID',
          progress: 'Progress',
        }}
      />
    </StyledProfileReminders>
  );
};

export default ProfileReminders;
