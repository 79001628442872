import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseLayout = styled.main`
  ${flex('column')};

  .esg-base-layout {
    &__content {
      ${flex('column')};
      ${size('100%')};

      overflow: auto;

      h2 {
        ${fontSize('28px', '40px')};

        font-weight: 600;
      }

      .esg-sub-sub-topic {
        flex: 1;
      }
    }
  }
`;

export { StyledBaseLayout };
