import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

const StyledTableCanvasModule = styled(StyledModuleContainer)`
  min-height: 200px;
  ${flex('column', 'initial', 'stretch')};
  padding: 0 0 ${theme.sizes.SIZE_10};

  .esg-inline-editable {
    width: 100%;
  }

  .esg-table-canvas-module {
    &__table {
      width: 100%;
      position: relative;
      border: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);

      &__row {
        ${flex('row', 'space-evenly')};

        &:first-of-type {
          font-weight: 500;
          background-color: var(--GRAY-FB);
        }

        &__item {
          ${flex('row', 'center', 'center')};
          width: 100%;
          min-height: ${theme.sizes.SIZE_48};
          border-right: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);
          border-bottom: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);

          &--heading {
            border: none;

            &:first-of-type {
              font-weight: 500;
              background-color: var(--GRAY-FB);
            }

            &:last-of-type {
              border-bottom: none;
              border-right: none;
            }

            &:hover {
              background-color: var(--GRAY-EF);
            }
          }
        }
      }
    }

    &__actions {
      ${flex('row', 'flex-end', 'center')};
      gap: ${theme.sizes.SIZE_8};

      button {
        width: 2rem;
        height: 2rem;
        padding: 0;
        min-height: 32px;
      }
    }
  }
`;

const StyledTableColumnRowActions = styled.div`
  ${flex('row')};
  gap: ${theme.sizes.SIZE_10};
  padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_12};

  .button {
    ${size(theme.sizes.SIZE_24, theme.sizes.SIZE_32)};
  }
`;

export { StyledTableCanvasModule, StyledTableColumnRowActions };
