import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

/**
 * Checks if a module's ID is not of the specified type.
 */
export const moduleIdIsNotOfType =
  (type?: DataModuleEnum) =>
  (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) =>
    module.id !== type;

/**
 * Filters out modules and their elements of a specific type.
 */
export const filterModulesAndElementsByType =
  (type?: DataModuleEnum) =>
  (
    old: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
    old
      .filter(moduleIdIsNotOfType(type))
      .map(({ elements, ...rest }) => ({
        ...rest,
        elements: elements
          ? filterModulesAndElementsByType(type)(elements)
          : elements,
      }));

export type ModuleIdLevel = { id: string; level: number };

/**
 * Get all module IDs including IDs from nested elements with level.
 */
export const getAllModuleIdsWithLevelInfo = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[],
  level = 0
): ModuleIdLevel[] =>
  modules.reduce<{ id: string; level: number }[]>((acc, curr) => {
    acc.push({ id: curr.id, level });
    if (curr.elements)
      acc = acc.concat(getAllModuleIdsWithLevelInfo(curr.elements, level + 1));

    return acc;
  }, []);
