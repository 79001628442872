import { Heading } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import Icon, { INameExtended } from '../../../../components/Icon';
import { StyledSessionCampaignHeader } from './SessionCampaignHeader.styled';

export type SessionCampaignHeaderProps = PropsWithChildren<{
  iconName?: INameExtended;
  name: string;
  description?: string;
}>;

const SessionCampaignHeader: FC<SessionCampaignHeaderProps> = ({
  iconName = 'leaf',
  name,
  description,
}) => {
  return (
    <StyledSessionCampaignHeader className="esg-session-campaign-header">
      <div className="esg-session-campaign-header__header">
        <Icon name={iconName} />
        <Heading level="1">{name}</Heading>
      </div>
      <div className="esg-session-campaign-browser__content__header__sub">
        {description || 'No description'}
      </div>
    </StyledSessionCampaignHeader>
  );
};

export default SessionCampaignHeader;
