import { getTextFromReactNode } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { CheckboxListField } from 'components';
import { CheckListDataModule, DataModuleEnum } from 'models';
import { FC, useMemo } from 'react';
import { generateModuleFieldName, generateOptionsByLabels } from 'utils';

import { StyledChecklistPreview } from './ChecklistPreview.styled';

export type ChecklistPreviewProps = CheckListDataModule;

const ChecklistPreview: FC<ChecklistPreviewProps> = ({
  title,
  id,
  config,
  conditionalElements,
  moduleElement: ModuleElement,
  disabled,
}) => {
  const finalOptions = useMemo(
    () => generateOptionsByLabels(config?.options as { label: string }[]),
    [config]
  );

  return (
    <StyledChecklistPreview className="esg-checklist-preview">
      <div className="esg-checklist-preview__title">{title || 'Checklist'}</div>
      <div className="esg-checklist-preview__content">
        <FormField
          name={generateModuleFieldName(id, DataModuleEnum.CHECKLIST)}
          component={CheckboxListField}
          disabled={disabled}
          options={finalOptions.map((v) => {
            const module = conditionalElements?.[v.key];

            return {
              ...v,
              label: getTextFromReactNode(v.label),
              conditionalEl: module && ModuleElement && (
                <ModuleElement
                  modulesType="preview-module"
                  module={module}
                  type={module.type}
                />
              ),
            };
          })}
        />
      </div>
    </StyledChecklistPreview>
  );
};

export default ChecklistPreview;
