import { theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledProfileNotifications = styled.div`
  padding: ${theme.sizes.SIZE_32};

  .wcl-table-container {
    table {
      td {
        padding: ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_12};
        min-width: ${theme.sizes.SIZE_256};
        width: 25%;

        .esg-have-notifications-icon-wrapper::before {
          background-color: var(--BLUE-00);
          transform: unset;
          right: -${theme.sizes.SIZE_12};
        }
      }
    }
  }

  .wcl-table__data__title {
    white-space: normal;
  }

  .esg-profile-notifications {
    &__on-date {
      &__date {
        ${fontSize(theme.fontSizes.FONT_16, theme.fontSizes.FONT_20)};
        color: var(--GRAY-70);
        margin-bottom: ${theme.sizes.SIZE_24};
        margin-top: ${theme.sizes.SIZE_40};
      }
    }

    &__user {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_32};

      &__image-name {
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_12};

        &__img-container {
          position: relative;

          .esg-user-image {
            ${size(theme.sizes.SIZE_48)};
            min-width: ${theme.sizes.SIZE_48};
            font-size: ${theme.sizes.SIZE_32};
          }

          .esg-basic-tag {
            ${fontSize(theme.fontSizes.FONT_12, theme.fontSizes.FONT_12)};
            ${position('absolute', `left 50% bottom -${theme.sizes.SIZE_12}`)};
            transform: translate(-50%);
            width: fit-content;
            z-index: 1;
            padding: ${theme.sizes.SIZE_4};
          }
        }
      }
    }

    &__element,
    &__task {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_12};
    }
  }
`;

export { StyledProfileNotifications };
