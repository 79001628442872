import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const StyledAssignmentsLayout = styled.div`
  ${flex('column')};
  ${size('100%', '100vh')};

  position: relative;

  .esg-assignments-layout {
    &__content {
      ${flex('column')};
      ${size('100%')};

      gap: ${theme.sizes.SIZE_24};

      overflow: auto;
    }
  }

  h2 {
    margin: 0;
    font-weight: 600;
  }
`;
