import api from 'api';
import { CampaignItem, CampaignWithItems, IDataModule } from 'models';
import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import { useSWRCache, useURLCampaignItemId } from '../../../../hooks';
import CampaignItemContext, {
  CampaignItemContextProps,
} from './CampaignItem.context';

type CampaignItemProviderProps = { children?: ReactNode };

export type CampaignItemNameDesc = {
  name: string;
  description?: string;
  type?: string;
};

const CampaignItemProvider: FC<CampaignItemProviderProps> = (props) => {
  const { children } = props;

  const { search } = useLocation();

  const { campaignItemId, campaign } = useURLCampaignItemId();

  const { mutateCacheKeys } = useSWRCache();

  const isForm = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get('form') === 'yes';
  }, [search]);

  const {
    data: { data: dataCollection } = {},
    isLoading: isLoadingCampaignItem,
    isValidating: isValidatingCampaignItem,
    mutate: mutateCampaignItem,
  } = useSWR<{ data: CampaignItem }>(
    isForm && campaignItemId && campaign
      ? API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(campaign, campaignItemId)
      : null
  );

  const { trigger: campaignItemTrigger, isMutating: isMutatingCampaignItem } =
    api.useMutation<{ data: CampaignItem }>(
      API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM('', '')
    );

  const editCampaignItem = useCallback(
    async (
      campaignId: string,
      campaignItemId: string,
      data: Partial<CampaignWithItems | CampaignItem>,
      onSuccess?: (data: CampaignItem) => void
    ) => {
      if (!campaignId || !campaignItemId) return;

      delete data.id;
      delete data.type;

      if ('taxonomyFieldGroupId' in data && !data.taxonomyFieldGroupId)
        delete data.taxonomyFieldGroupId;

      const res = await campaignItemTrigger(
        {
          url: API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(campaignId, campaignItemId),
          method: 'PATCH',
          data,
        },
        {
          onSuccess: ({ data }) => {
            onSuccess?.(data);

            mutateCacheKeys((key) =>
              key.startsWith(
                API_ROUTES.CAMPAIGNS.CAMPAIGN_DATA_COLLECTION_ELEMENTS(
                  campaignId
                )
              )
            );
          },
        }
      );

      mutateCampaignItem(res, { revalidate: false });
    },
    [campaignItemTrigger, mutateCacheKeys, mutateCampaignItem]
  );

  const createCampaignItem = useCallback(
    async (
      campaignId: string,
      data: Partial<CampaignWithItems & { campaignItemId: string }>
    ) => {
      if ('taxonomyFieldGroupId' in data && !data.taxonomyFieldGroupId)
        delete data.taxonomyFieldGroupId;

      await campaignItemTrigger({
        url: API_ROUTES.CAMPAIGNS.CAMPAIGN(campaignId),
        method: 'POST',
        data,
      });
    },
    [campaignItemTrigger]
  );

  const duplicateCampaignItem = useCallback(
    async (data: IDataModule, onSuccess?: (data: CampaignItem) => void) => {
      await campaignItemTrigger(
        {
          method: 'POST',
          data,
          url: API_ROUTES.CAMPAIGNS.DUPLICATE(
            campaign,
            campaignItemId,
            data.id.split('+')[0]
          ),
        },
        {
          onSuccess: ({ data }) => {
            onSuccess?.(data);
            mutateCampaignItem({ data }, { revalidate: false });
          },
        }
      );
    },
    [campaign, campaignItemId, campaignItemTrigger, mutateCampaignItem]
  );

  const deleteCampaignItem = useCallback(
    async (campaignId: string, campaignItemId: string) => {
      await campaignItemTrigger(
        {
          url: API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(campaignId, campaignItemId),
          method: 'DELETE',
        },
        { revalidate: false }
      );
    },
    [campaignItemTrigger]
  );

  return (
    <CampaignItemContext.Provider
      value={{
        isMutatingCampaignItem,
        isLoadingCampaignItem,
        isValidatingCampaignItem,
        dataCollection,
        isForm,
        editCampaignItem,
        createCampaignItem,
        duplicateCampaignItem,
        deleteCampaignItem,
        mutateCampaignItem,
      }}
    >
      {children}
    </CampaignItemContext.Provider>
  );
};

const useCampaignItemProvider = (): CampaignItemContextProps =>
  useContext(CampaignItemContext);

export { CampaignItemProvider, useCampaignItemProvider };
