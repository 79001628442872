import { ButtonProps, Input } from '@faxi/web-component-library';
import { AssignedContributor } from 'components';
import Icon from 'components/Icon';
import { FC, PropsWithChildren, useState } from 'react';

import { Contributor } from '../../../../../models';
import { StyledViewAllContributorsModal } from './ViewAllContributorsModal.styled';

export type ViewAllContributorsModalProps = PropsWithChildren<{
  closeModal: () => void;
  assignees: Contributor[];
  nodeMenuItems: (contributorId: string, name: string) => ButtonProps[];
}>;

const fuzzySearch = (input: string) => {
  const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  return escapedInput
    .split('')
    .map((char) => `${char}+?`) // Allow characters with optional matches
    .join('');
};

const ViewAllContributorsModal: FC<ViewAllContributorsModalProps> = (props) => {
  const { assignees, closeModal, nodeMenuItems } = props;

  const [search, setSearch] = useState<string>('');

  return (
    <StyledViewAllContributorsModal
      onClose={closeModal}
      className="esg-view-all-contributors-modal"
      title="Contributors"
    >
      <div className="esg-view-all-contributors-modal--main-wrapper">
        <Input
          placeholder="Search"
          value={search}
          prefixIcon={<Icon name="magnifying-glass" />}
          onChange={setSearch}
        />
        <div className="esg-view-all-contributors-modal__assignees">
          {assignees
            .filter((assignee) =>
              assignee.name?.match(new RegExp(fuzzySearch(search), 'i'))
            )
            .map(
              (assignee) => (
                <AssignedContributor
                  key={assignee.id}
                  {...assignee}
                  menuItems={nodeMenuItems(assignee.id, assignee.name)}
                />
              ),
              []
            )}
        </div>
      </div>
    </StyledViewAllContributorsModal>
  );
};

export default ViewAllContributorsModal;
