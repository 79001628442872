import classNames from 'classnames';
import { FC } from 'react';

import {
  DataModuleEnum,
  ModuleElementDetailsProps,
} from '../../../../../models';
import FormulaPreview from '../FormulaPreview';
import { StyledDetailsHeader } from '../ModuleElementDetails/ModuleElementDetails.styled';
import { StyledFormulaDetailsPreview } from './FormulaDetailsPreview.styled';

const FormulaDetailsPreview: FC<
  ModuleElementDetailsProps<DataModuleEnum.FORMULA>
> = (props) => {
  const {
    calculation: { formula, inputs },
  } = props;

  return (
    <StyledFormulaDetailsPreview className="esg-formula-details-preview">
      <StyledDetailsHeader>Formula</StyledDetailsHeader>

      <FormulaPreview calculation={{ formula, inputs }} />
      {!!inputs.length && (
        <div className="esg-formula-details-preview__inputs--wrapper">
          <div className="esg-formula-details-preview__inputs">
            {inputs.map(({ id, title, config, value }) => (
              <div
                key={id}
                className="esg-formula-details-preview__inputs__input"
              >
                <span className="esg-formula-details-preview__inputs__input__title">
                  {title}
                </span>
                <span
                  className={classNames(
                    'esg-formula-details-preview__inputs__input__value',
                    {
                      'esg-formula-details-preview__inputs__input__value--empty':
                        !value,
                    }
                  )}
                >
                  {value
                    ? `${[value, config?.unit || ''].join(' ').trim()}`
                    : 'Value not entered yet'}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </StyledFormulaDetailsPreview>
  );
};

export default FormulaDetailsPreview;
