import { useUtilities } from '@faxi/web-component-library';
import { FC } from 'react';

import useMutation from '../../../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../../../api/routes';
import { User } from '../../../../../../../models';
import authBus, { AUTH_BUS_EVENTS } from '../../../../../../../modules/authBus';
import { useRootProvider } from '../../../../../../../providers/Root';
import ProfileDetails from '../../../../../../components/ProfileDetails';
import UserProfileActions from '../../../../components/UserProfileActions';
import { StyledUserDetails } from './UserDetails.styled';

const UserDetails: FC = () => {
  const { user, mutateUserCache } = useRootProvider();

  const { showSnackBar } = useUtilities();

  const { trigger: updateProfile, isMutating: isUpdateProfileMutating } =
    useMutation<{ data: User }>(API_ROUTES.USERS.PROFILE, {
      onSuccess: ({ data }) => {
        mutateUserCache(
          (prevCache) => ({ data: { ...prevCache?.data, ...data } }),
          { revalidate: false }
        );
        showSnackBar({
          text: `Successfully updated ${data.firstName} ${data.lastName}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      },
      revalidate: false,
    });

  const { trigger: changePassword, isMutating: isChangePasswordMutating } =
    useMutation<void>(API_ROUTES.USERS.CHANGE_PASSWORD, { revalidate: false });

  return (
    <StyledUserDetails>
      <ProfileDetails user={user} />
      <UserProfileActions
        user={user}
        isLoading={isChangePasswordMutating || isUpdateProfileMutating}
        onEdit={async (data) => {
          await updateProfile({ data, method: 'PATCH' });
        }}
        onChangePassword={async ({ oldPassword, password }) => {
          await changePassword({
            method: 'PATCH',
            data: { oldPassword, newPassword: password },
          });
          authBus.broadcastEvent(AUTH_BUS_EVENTS.LOGOUT, {
            withoutApiCall: true,
          });
          showSnackBar({
            text: 'Successfully updated password.',
            variant: 'success',
            actionButtonText: 'Dismiss',
          });
        }}
        isEditingOwnProfile
      />
    </StyledUserDetails>
  );
};

export default UserDetails;
