import { FC, Fragment } from 'react';

import ApprovalProgressBar from '../../../../../components/_molecules/ApprovalProgressBar';
import { FormStatus } from '../../../../../models';
import { StatusProgresses } from '../../../../../models/FormProgressStatus';

type StatusProgressProp = { statusProgresses?: StatusProgresses };

const StatusProgress: FC<StatusProgressProp> = ({ statusProgresses }) => (
  <Fragment>
    {statusProgresses &&
      Object.entries(statusProgresses).map(([key, progress]) => (
        <ApprovalProgressBar
          key={key}
          title={key.charAt(0).toUpperCase() + key.slice(1)}
          status={
            progress?.progress === 100
              ? key === 'completion'
                ? FormStatus.Completed
                : key === 'approval'
                  ? FormStatus.Approved
                  : FormStatus.Audit
              : FormStatus.InProgress
          }
          progress={progress}
        />
      ))}
  </Fragment>
);

export default StatusProgress;
