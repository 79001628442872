import { FC, PropsWithChildren } from 'react';

import { StyledHaveNotificationsIconWrapper } from './HaveNotificationsIconWrapper.styled';

export type HaveNotificationsIconWrapperProps = PropsWithChildren<{
  count?: number;
  haveNotification?: boolean;
}>;

const HaveNotificationsIconWrapper: FC<HaveNotificationsIconWrapperProps> = ({
  children,
  count,
  haveNotification,
}) => {
  if (!haveNotification) {
    return children;
  }

  return (
    <StyledHaveNotificationsIconWrapper
      $hasCount={Boolean(count)}
      data-count={count}
      className="esg-have-notifications-icon-wrapper"
    >
      {children}
    </StyledHaveNotificationsIconWrapper>
  );
};

export default HaveNotificationsIconWrapper;
