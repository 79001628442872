import { FieldProps, FormField, FormFieldProps } from '@faxi/web-form';
import { FC, useMemo } from 'react';

export type ConditionalFieldProps<T extends Record<string, any>> =
  FormFieldProps<T> & FieldProps & { condition: boolean };

function ConditionalField<T extends Record<string, any> = any>(
  props: ConditionalFieldProps<T>
) {
  const { condition, component: Component, ...rest } = props;

  const ConditionalComponent = useMemo(
    () => (condition ? FormField : Component) as FC<FieldProps<T>>,
    [Component, condition]
  );

  return (
    <ConditionalComponent
      {...(condition && { component: Component })}
      {...rest}
    />
  );
}

export default ConditionalField;
