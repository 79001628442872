import { Button, useModalUtilities } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import { ViewGuard } from '../../../../../components';
import Icon from '../../../../../components/Icon';
import {
  AssignContributorsForm,
  AssignTarget,
  Contributor,
  PermissionSections,
} from '../../../../../models';
import AssignContributorsModal from '../AssignContributorsModal';

export type AssignContributorsProps = PropsWithChildren<{
  btnText?: string;
  className?: string;
  assignTarget?: AssignTarget;
  onSubmit?: (data: AssignContributorsForm) => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  contributors: Contributor[];
}>;

// TODO: this needs clean-up, rush for demo
const AssignContributors: FC<AssignContributorsProps> = ({
  btnText = 'Assign',
  assignTarget = AssignTarget.Form,
  className,
  disabled,
  loading,
  contributors,
  onSubmit,
}) => {
  const { open, openModal, closeModal } = useModalUtilities();

  return (
    <ViewGuard section={PermissionSections.ASSIGNMENT} permissions={['update']}>
      <Button
        icon={<Icon name="plus" />}
        variant="outline"
        onClick={openModal}
        className={className}
        disabled={disabled}
      >
        {btnText}
      </Button>
      {open && (
        <AssignContributorsModal
          contributors={contributors}
          loading={loading}
          renderAsPortal
          onClose={() => {
            closeModal();
          }}
          onSubmit={async (data) => {
            await onSubmit?.(data);
            closeModal();
          }}
          assignTarget={assignTarget}
        />
      )}
    </ViewGuard>
  );
};

export default AssignContributors;
