import { ApiData } from './ApiData';

export enum PermissionSections {
  ORGANISATION = 'organisation',
  USER = 'user',
  CAMPAIGN = 'campaign',
  SESSION = 'session',
  ROLE = 'role',
  COMMENTS = 'comment',
  LOGS = 'activity_log',
  ASSIGNMENT = 'assignment',
  SESSION_STATUS = 'session_status',
  SESSION_DATA_COLLECTION_INPUT = 'session_data_collection_input',
  FILE = 'file',
  API_INTEGRATION = 'api_integration',
  TAXONOMY = 'taxonomy',
}

export type RouterPermissionSections = Exclude<
  PermissionSections,
  | PermissionSections.ROLE
  | PermissionSections.COMMENTS
  | PermissionSections.LOGS
>;

export type PermissionFormSections = Exclude<
  PermissionSections,
  PermissionSections.ROLE
>;

export type AccessPermissions = 'create' | 'update' | 'delete' | 'read';

export type Permissions = ApiData<{ id: string; name: string }[]>;
