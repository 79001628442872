import {
  Breadcrumbs,
  Button,
  Divider,
  GlowScroll,
  Heading,
  Input,
  Tooltip,
  useUtilities,
} from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { APP_URI, FEATURES } from 'config';
import { FC, useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import {
  ApprovalProgressBar,
  BasicTreeView,
  ViewGuard,
} from '../../../../components';
import { ApiData, PermissionSections } from '../../../../models';
import StatusProgress from '../../../../routes/subrouters/Sessions/components/StatusProgress';
import { useSelectedSession } from '../../context/SelectedSession';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import SessionSidebarLink, {
  SessionSidebarLinkProps,
} from './components/SessionSidebarLink/SessionSidebarLink.component';
import { StyledSessionSidebar } from './SessionSidebar.styled';

const SESSION_SIDEBAR_LINKS: (SessionSidebarLinkProps & {
  permissionSection?: PermissionSections;
})[] = [
  {
    url: APP_URI.SESSIONS_SESSION_DASHBOARD,
    text: 'Dashboard',
    leftIcon: 'grid',
  },
  ...(FEATURES.SESSION_ACTIVITY_LOGS
    ? [
        {
          url: APP_URI.SESSIONS_SESSION_ACTIVITY,
          text: 'Activity',
          leftIcon: 'wave-pulse',
          permissionSection: PermissionSections.LOGS,
        } as const,
      ]
    : []),
  {
    url: APP_URI.SESSIONS_SESSION_MEMBERS,
    text: 'Contributors',
    leftIcon: 'users',
  },
  {
    url: APP_URI.SESSIONS_SESSION_CAMPAIGN_BROWSER,
    text: 'Browser',
    leftIcon: 'folder-tree',
    rightIcon: 'chevron-right',
  },

  {
    permissionSection: PermissionSections.FILE,
    url: APP_URI.SESSIONS_SESSION_DETAILS_DOCUMENTS,
    text: 'Documents',
    leftIcon: 'cabinet-filling',
  },

  {
    url: APP_URI.SESSIONS_SESSION_DETAILS_DMA_EXCLUSION,
    text: 'DMA Exclusion',
    leftIcon: 'ban',
  },
];

type SessionSidebarProps = { isBrowserView?: boolean };

const SessionSidebar: FC<SessionSidebarProps> = ({ isBrowserView = false }) => {
  const selectedSession = useSelectedSession();

  const { selectedCompany: { organisationSessionId, name } = {} } =
    useSessionBrowser(false);

  const { companyId = '' } = useParams();

  const { trigger } = useMutation<ApiData<string>>(
    organisationSessionId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.GENERATE_REPORT(organisationSessionId)
  );

  const location = useLocation();
  const navigate = useNavigate();
  const { showOverlay, hideOverlay } = useUtilities();

  const [search, setSearch] = useState<string>();

  const { sessionCompanyTreeView, sessionCampaignTreeView } =
    useSessionBrowser(false);

  const basicTreeViewData = companyId
    ? sessionCampaignTreeView
    : sessionCompanyTreeView;

  const generateDraftReport = useCallback(async () => {
    showOverlay('body');
    try {
      const { data } = await trigger({ method: 'GET' });

      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = data;
      downloadAnchor.click();
      downloadAnchor.remove();
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('body');
    }
  }, [hideOverlay, showOverlay, trigger]);

  return (
    <StyledSessionSidebar
      className="esg-session-sidebar"
      expandDirection="right"
    >
      <div className="esg-session-sidebar__nav-container">
        <div className="esg-session-sidebar__nav-container__top">
          <Breadcrumbs
            splitter="/"
            crumbs={[
              { text: 'Sessions', href: APP_URI.SESSIONS },
              { text: selectedSession!.name, href: location.pathname },
            ]}
          />

          <Heading level="1">{selectedSession!.name}</Heading>

          <Divider />
        </div>
        {!isBrowserView && (
          <div className="esg-session-sidebar__nav-container__not-browser-view">
            <div className="esg-session-sidebar__nav-container__not-browser-view__details">
              {/* <div className="esg-session-sidebar__nav-container__details__date">
                <div>Session started</div>
                <div>
                  {dayjs(selectedSession?.startDate).format(DATE_FORMAT)}
                </div>
              </div>
              <div className="esg-session-sidebar__nav-container__details__date">
                <div>Session Ends</div>
                <div>{dayjs(selectedSession?.endDate).format(DATE_FORMAT)}</div>
              </div> */}
              <ApprovalProgressBar
                title="Progress"
                status="In Progress"
                progress={selectedSession?.progress}
              />
              <StatusProgress
                statusProgresses={selectedSession?.statusProgresses}
              />
            </div>
            <Divider />
          </div>
        )}

        {!isBrowserView ? (
          <div className="esg-session-sidebar__nav-container__not-browser-view">
            <div className="esg-session-sidebar__nav-container__not-browser-view__subtitle">
              Browser
            </div>
            <div className="esg-session-sidebar__nav-container__not-browser-view__links">
              {SESSION_SIDEBAR_LINKS.map(({ permissionSection, ...link }) =>
                permissionSection ? (
                  <ViewGuard
                    key={link.url}
                    permissions={['read']}
                    section={permissionSection}
                  >
                    <SessionSidebarLink {...link} />
                  </ViewGuard>
                ) : (
                  <SessionSidebarLink key={link.url} {...link} />
                )
              )}
            </div>
          </div>
        ) : (
          <GlowScroll variant="gray">
            <div className="esg-session-sidebar__nav-container__nav">
              <Button
                variant="ghost"
                onClick={() => navigate(-1)}
                icon={<Icon name="chevron-left" />}
              >
                Back
              </Button>
              <Input
                className="esg-session-sidebar__nav-container__nav__search"
                prefixIcon={<Icon name="magnifying-glass" />}
                placeholder="Search"
                {...(search && {
                  suffixIcon: (
                    <Button
                      variant="ghost"
                      aria-label="Clear all"
                      icon={<Icon name="xmark" />}
                      onClick={() => setSearch('')}
                    />
                  ),
                })}
                value={search}
                onChange={setSearch}
              />
              <div className="esg-session-sidebar__nav-container__subtitle">
                {!companyId ? 'Company Browser' : 'Campaign Browser'}
              </div>

              {basicTreeViewData && <BasicTreeView data={basicTreeViewData} />}
            </div>
          </GlowScroll>
        )}
        {companyId && (
          <div className="esg-session-sidebar__nav-container__report-download">
            <Button
              variant="outline"
              icon={<Icon name="download" />}
              onClick={generateDraftReport}
              // TODO
              // disabled={!organisationSessionId || !selectedSession?.taxonomyId}
              disabled={!organisationSessionId}
            >
              Download Draft Report
            </Button>
            <Tooltip
              content={`${selectedSession?.taxonomyId ? 'This is an XBRL-based session. ' : ''}The downloaded report draft will be for ${name}. Once the session is complete, you will be able to download the full report.`}
            >
              <span
                tabIndex={0}
                role="tooltip"
                aria-label={`${selectedSession?.taxonomyId ? 'This is an XBRL-based session. ' : ''}The downloaded report draft will be for ${name}. Once the session is complete, you will be able to download the full report.`}
              >
                <Icon
                  name="circle-info"
                  className="esg-session-sidebar__nav-container__report-download__tooltip-icon"
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </StyledSessionSidebar>
  );
};

export default SessionSidebar;
