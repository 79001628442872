import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaign-sessions/${apiVersion}`;

export const CAMPAIGN_SESSIONS_ROUTES = {
  BASE: (search?: string) =>
    `${baseUrl}${search ? '?searchString=' + search : ''}`,

  SESSION: (sessionId: string) => `${baseUrl}/${sessionId}`,

  CAMPAIGN_SESSION_FORM: (sessionId: string, formId: string) =>
    `${baseUrl}/${sessionId}/forms/${formId}`,

  CAMPAIGN_SESSION_RUN: (id: string) => `${baseUrl}/${id}/run`,

  CAMPAIGN_SESSION_SUBMIT_FORM: (organisationId: string) =>
    `${baseUrl}/${organisationId}/submit-form`,

  // ORGANISATIONS

  CAMPAIGN_SESSION_INCLUDE: (sessionId: string, organisationId: string) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/include`,

  CAMPAIGN_SESSION_EXCLUDE: (sessionId: string, organisationId: string) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/exclude`,

  CAMPAIGN_SESSION_ORGANISATION_DATA_COLLECTION_ELEMENTS: (
    sessionId: string,
    organisationId: string
  ) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/data-collection-elements`,

  // DMA JUSTIFICATIONS

  CAMPAIGN_SESSION_DMA_EXCLUSION: (sessionId: string) =>
    `${baseUrl}/${sessionId}/dma-justifications`,

  // FILES

  CAMPAIGN_SESSIONS_FILES_UPLOAD: `${baseUrl}/files/create-upload-urls`,

  CAMPAIGN_SESSIONS_CONFIRM_UPLOAD: (
    sessionId: string,
    organisationId: string
  ) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/files/confirm-upload`,

  CAMPAIGN_SESSION_DOCUMENTS: (sessionId: string) =>
    `${baseUrl}/${sessionId}/files`,

  CAMPAIGN_SESSION_DOWNLOAD_URL: (fileKey: string) =>
    `${baseUrl}/files/create-download-url?fileKey=${fileKey}`,

  CAMPAIGN_SESSION_FORM_DOCUMENTS: (
    sessionId: string,
    organisationId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/forms/${dataCollectionFormId}/files`,

  CAMPAIGN_SESSION_FORM_DOCUMENTS_DELETE: (fileId: string) =>
    `${baseUrl}/files/${fileId}`,

  // ASSIGNMENTS

  ASSIGNMENTS_BY_SESSION: () => `${baseUrl}/assignments/by-session`,

  ASSIGNMENTS_BY_ORGANISATION: (sessionId: string) =>
    `${baseUrl}/${sessionId}/assignments/by-organisation`,

  CONTRIBUTORS_LIST: (sessionId: string) =>
    `${baseUrl}/${sessionId}/assignments`,

  CONTRIBUTOR: (sessionId: string, userId: string) =>
    `${baseUrl}/${sessionId}/users/${userId}/assignments`,

  // ORGANISATION SESSIONS

  ORGANISATION_SESSIONS: (sessionId: string) =>
    `${baseUrl}/${sessionId}/organisation-sessions`,

  ORGANISATION_SESSION_TOGGLE_LOCK: (
    organisationSessionId: string,
    dataCollectionFormId: string,
    elementId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/elements/${elementId}/lock`,

  ORGANISATION_SESSION_DATA_CONSOLIDATION: (
    organisationSessionId: string,
    dataCollectionFormId: string,
    elementId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/elements/${elementId}/data-consolidation`,

  ORGANISATION_SESSION_DATA_LINEAGE: (
    organisationSessionId: string,
    dataCollectionFormId: string,
    elementId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/elements/${elementId}/data-lineage`,

  ORGANISATION_SESSION_FORM: (
    organisationSessionId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}`,

  ORGANISATION_SESSION_FORM_STATUS: (
    organisationSessionId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/status`,

  ORGANISATION_SESSION_FORM_APPROVAL_STATUS: (
    organisationSessionId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/approval`,

  ORGANISATION_SESSION_FORM_AUDIT_STATUS: (
    organisationSessionId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/audit`,

  ORGANISATION_SESSION_CAMPAIGN_ITEM_DEADLINE: (
    organisationSessionId: string,
    dataCollectionFormId: string,
    campaignItemType: 'forms' | 'topics' = 'forms'
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/${campaignItemType}/${dataCollectionFormId}/deadline`,

  ORGANISATION_SESSION_FORM_SECTION_DEADLINE: (
    organisationSessionId: string,
    dataCollectionFormId: string,
    sectionId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}/sections/${sectionId}/deadline`,

  ORGANISATION_SESSION_CAMPAIGN_ITEM: (
    organisationSessionId: string,
    campaignItemId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/topics/${campaignItemId}`,

  SESSION_FORM_ELEMENT_COMMENTS: (
    sessionId: string,
    campaignItemId: string,
    elementId: string,
    limit?: number
  ) =>
    `${baseUrl}/organisation-sessions/${sessionId}/forms/${campaignItemId}/elements/${elementId}/comments${limit ? '?limit=' + limit : ''}`,

  SESSION_FORM_ELEMENT_COMMENTS_READ: (
    sessionId: string,
    campaignItemId: string,
    elementId: string
  ) =>
    `${baseUrl}/organisation-sessions/${sessionId}/forms/${campaignItemId}/elements/${elementId}/comments/read`,

  POTENTIAL_MENTIONS: (
    organisationSessionId: string,
    formId: string,
    elementId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${formId}/elements/${elementId}/assignees`,

  DETAILS: (organisationSessionId: string, formId: string, elementId: string) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${formId}/elements/${elementId}/details`,

  GENERATE_REPORT: (organisationSessionId: string) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/report`,
};
