import { InputDataModule, InputModuleConfig } from 'models';
import { FC } from 'react';

import SelectUnit from '../../../../../../../components/_fields/SelectUnit';
import QuickConfigPreview from '../QuickConfigPreview';
import { SimpleInputField } from '../SimpleInput/SimpleInput.component';
import { StyledNumericInput } from './NumericInput.styled';

export type NumericInputProps = InputDataModule;

const NumericInput: FC<NumericInputProps> = (props) => {
  return (
    <StyledNumericInput className="esg-numeric-input">
      <QuickConfigPreview {...props} />

      <SimpleInputField
        switchLabel="Tooltip Helper Text"
        configKey="helperText"
        placeholder="Helper Text"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="Custom Placeholder"
        configKey="placeholder"
        placeholder="Placeholder"
        config={props.config as InputModuleConfig}
      />

      <SelectUnit
        className="esg-numeric-input__unit"
        configKey="unit"
        config={props.config as InputModuleConfig}
      />
    </StyledNumericInput>
  );
};

export default NumericInput;
