import { GlowScroll } from '@faxi/web-component-library';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { APP_URI } from '../../../../config';
import { useURLCampaignItemId } from '../../../../hooks';
import { CampaignItemURLParam } from '../../../../models';
import { useCampaignProvider } from '../../../../providers/Campaign';
import { findCampaignItem } from '../../../../utils';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import { campaignItemTypeSelectOptions } from '../../utils';
import CampaignEntity from '../CampaignEntity';

type SubTopicProps = { level: 0 | 1 | 2 | 3 };

const SubTopic: FC<SubTopicProps> = ({ level }) => {
  const {
    subsubsubtopic = '',
    subsubtopic = '',
    subtopic = '',
    campaign = '',
    topic = '',
  } = useParams<CampaignItemURLParam>();

  const { currentSubtopicLevelId } = useURLCampaignItemId(level);

  const { rootCampaign } = useCampaignProvider();

  const campaignItem = useMemo(
    () => findCampaignItem(currentSubtopicLevelId, rootCampaign),
    [currentSubtopicLevelId, rootCampaign]
  );

  const { mutating } = useCampaignFormEntitySubmit(
    currentSubtopicLevelId,
    campaignItem
  );

  const navigateTo = useCallback(
    (name: string) => {
      const replacePathParams = (url: string, level: number) => {
        const keys = [':subsubtopic', ':subsubsubtopic', ':subsubsubsubtopic'];

        let finalUrl = url
          .replace(':campaign', campaign)
          .replace(':topic', topic)
          .replace(':subtopic', subtopic);

        if ([1, 2].includes(level)) {
          finalUrl = finalUrl.replace(':subsubtopic', subsubtopic);
        }

        if (level === 2) {
          finalUrl = finalUrl.replace(':subsubsubtopic', subsubsubtopic);
        }

        return finalUrl.replace(keys[level], name);
      };

      const appUris = [
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC,
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC,
      ];

      return replacePathParams(appUris[level], level);
    },
    [campaign, level, subsubsubtopic, subsubtopic, subtopic, topic]
  );

  return (
    <GlowScroll variant="gray">
      <CampaignEntity
        // TODO
        campaignItem={campaignItem as any}
        navigateTo={navigateTo}
        entityType="subsubtopic"
        modalProps={{
          chooseType: true,
          loading: mutating,
          fieldProps: {
            type: {
              options:
                level < 2
                  ? campaignItemTypeSelectOptions(level)
                  : [campaignItemTypeSelectOptions(level)[0]],
            },
          },
        }}
      />
    </GlowScroll>
  );
};

export default SubTopic;
