import { useCallbackRef } from '@faxi/web-component-library';
import { FormField, useFormContextValues } from '@faxi/web-form';
import isEmpty from 'lodash.isempty';
import { DataModuleEnum, UploadDataModule } from 'models';
import { FC, useMemo } from 'react';

import { useHeightAnimation } from '../../../../../../hooks';
import { generateModuleFieldName } from '../../../../../../utils';
import FileUploadModalField from '../../../../../_fields/FileUploadModalField';
import { StyledUploadPreview } from './UploadPreview.styled';

export type UploadPreviewProps = UploadDataModule;

const UploadPreview: FC<UploadPreviewProps> = ({
  title,
  id,
  conditionalElements,
  disabled,
  moduleElement: ModuleElement,
}) => {
  const name = generateModuleFieldName(id, DataModuleEnum.UPLOAD);
  const formValue = useFormContextValues(name)?.[name];

  const module = useMemo(
    () => !isEmpty(formValue) && conditionalElements?.['file-is-uploaded'],
    [conditionalElements, formValue]
  );

  const [uploadPreviewField] = useCallbackRef<HTMLDivElement>();

  useHeightAnimation({ element: uploadPreviewField, isOpen: !!module });

  return (
    <StyledUploadPreview className="esg-upload-preview">
      <div className="esg-upload-preview__main">
        <div className="esg-upload-preview__main__title">
          {title || 'Upload field'}
        </div>
        <FormField
          value={formValue || []}
          name={name}
          component={FileUploadModalField}
          disabled={disabled}
          confirmBtnText="Submit"
          acceptedFileTypesText="Accepted file types: "
          maxFileSize={100}
          multiple={true}
          maxFiles={3}
          dropZoneText="Drag & drop files here or "
          clickToUploadText="click to upload"
          uploadingText="Uploading"
          filesCountError="Maximum number of files exceeded. Max allowed is 3."
          fileTypeError="File type not allowed"
          fileSizeError="File size too large"
          alreadyExistError="File with this name already exists"
          acceptedFileTypes={[
            'application/pdf',
            'image/png',
            'image/jpeg',
            'video/quicktime',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          ]}
          showPreview={false}
        />
      </div>
      <div className="esg-upload-preview__conditional-el">
        {module && ModuleElement && (
          <ModuleElement
            modulesType="preview-module"
            type={module.type}
            module={module}
          />
        )}
      </div>
    </StyledUploadPreview>
  );
};

export default UploadPreview;
