import { GlowScroll } from '@faxi/web-component-library';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useInfiniteFetch } from '../../../../../api';
import { API_ROUTES } from '../../../../../api/routes';
import { ContributorsList } from '../../../../../components';
import InfiniteScrollContainer from '../../../../../components/_molecules/InfiniteScrollContainer';
import { FEATURES } from '../../../../../config';
import { BlockUI } from '../../../../../helpers';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import {
  ActivityLog,
  ApiData,
  DataModuleElementDrawerComponentProps,
  User,
} from '../../../../../models';
import { reduceLogsByDate } from '../../../../../pages/Sessions/utils';
import { mapUsersToAssignees } from '../../../../../utils';
import ModuleElementActivityLog from '../ModuleElementActivityLog';
import { StyledModuleElementActivity } from './ModuleElementActivity.styled';

const ModuleElementActivity: FC<DataModuleElementDrawerComponentProps> = ({
  organisationSessionId,
  elementId,
}) => {
  const { campaignItemId = '' } = useParams();

  const { items, nextPage, isValidating, setSize, isLoading } =
    useInfiniteFetch<ActivityLog>(
      FEATURES.SESSION_ACTIVITY_LOGS
        ? API_ROUTES.ACTIVITY_LOGS_ROUTES.ELEMENT(
            organisationSessionId,
            campaignItemId,
            elementId
          )
        : '',
      { revalidateOnMount: true }
    );

  const { isFetching, setContainerRef } = useInfiniteScroll<HTMLDivElement>({
    hasNextPage: Boolean(nextPage),
    isLoading: isValidating,
    loadMore: () => setSize((prev) => prev + 1),
  });

  const {
    data: { data: users } = { data: [] },
    isValidating: isValidatingPotentialMentions,
  } = useSWR<ApiData<User[]>, Error>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.POTENTIAL_MENTIONS(
      organisationSessionId,
      campaignItemId,
      elementId
    )
  );

  const logs = useMemo(() => reduceLogsByDate(items), [items]);

  return (
    <StyledModuleElementActivity className="esg-module-element-activity">
      <div className="esg-module-element-activity__contributors">
        <div>Contributors:</div>
        <BlockUI
          loading={isValidatingPotentialMentions}
          onlySpinner
          fallbackCondition={!users.length}
          fallback={<div>No one assigned.</div>}
        >
          <ContributorsList contributors={mapUsersToAssignees(users)} />
        </BlockUI>
      </div>
      <BlockUI loading={isLoading} fallbackCondition={!items.length}>
        <GlowScroll variant="gray">
          <InfiniteScrollContainer
            ref={setContainerRef}
            isFetching={isFetching}
          >
            {logs?.map(({ date, activityLogs }, index) => (
              <ModuleElementActivityLog
                date={date}
                key={index}
                activityLogs={activityLogs}
              />
            ))}
          </InfiniteScrollContainer>
        </GlowScroll>
      </BlockUI>
    </StyledModuleElementActivity>
  );
};

export default ModuleElementActivity;
