import { useModalUtilities } from '@faxi/web-component-library';
import classNames from 'classnames';
import {
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigMapperType,
  ModuleConfigType,
} from 'models';
import { FC, ReactNode, useCallback, useMemo } from 'react';

import { findModule } from '../../../pages/Campaigns/components/Dnd/utils';
import ModuleConfiguration from '../../../pages/Campaigns/components/ModuleConfiguration';
import { useModulesStore } from '../../../store/modulesStore';
import ModuleElement from '../../_organisms/BuilderCanvas/components/ModuleElement';
import SelectField from '../SelectField';
import { SelectFieldProps } from '../SelectField/SelectField.component';
import { ConditionalCanvasModule } from './components';
import { DEFAULT_MODULES_OPTIONS } from './modulesOptions';
import { StyledSelectModuleField } from './SelectModuleField.styled';
import {
  generateModuleData,
  removeModuleElement,
  updateModuleElement,
} from './utils';

export type SelectModuleFieldProps = {
  label: ReactNode;
  className?: string;
  name: string;
  parentId: string;
  fullWidth?: boolean;
  options?: { label: string; value: string; icon: ReactNode }[];
  configurableModules: ModuleConfigMapperType;
} & Pick<SelectFieldProps, 'placeholder'>;

export type SwitchConfig = { xbrl: string; defaultValue: string };

const SelectModuleField: FC<SelectModuleFieldProps> = ({
  label,
  options = DEFAULT_MODULES_OPTIONS,
  className,
  name,
  parentId,
  placeholder = 'Select Module',
  fullWidth = false,
  configurableModules,
  ...rest
}) => {
  const { localFormModules, setLocalFormModules: setCurrentModules } =
    useModulesStore();

  const { open, openModal, closeModal } = useModalUtilities();

  const activeConditionalModule = useMemo(
    () =>
      findModule(parentId, localFormModules).module?.conditionalElements?.[
        name
      ],
    [localFormModules, name, parentId]
  );

  const handleUpdateModule = useCallback(
    (
      updateFn: (
        module: ModuleConfig<ModuleConfigType, DataModuleEnum>
      ) => ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined
    ) => {
      setCurrentModules(
        updateModuleElement(parentId, name, updateFn)(localFormModules)
      );
    },
    [setCurrentModules, parentId, name, localFormModules]
  );

  const handleRemoveModule = useCallback(() => {
    setCurrentModules(removeModuleElement(parentId, name)(localFormModules));
  }, [setCurrentModules, parentId, name, localFormModules]);

  return (
    <StyledSelectModuleField
      className={classNames('esg-select-module-field', className)}
      $fullWidth={fullWidth}
    >
      <p className="esg-select-module-field__label">{label}</p>

      {!activeConditionalModule && (
        <SelectField
          className="esg-select-module-field__select"
          options={options}
          onChange={(selectedModule) =>
            handleUpdateModule(() =>
              generateModuleData(selectedModule as DataModuleEnum)
            )
          }
          value={activeConditionalModule?.type}
          renderAsPortal
          placeholder={placeholder}
          {...rest}
        />
      )}

      {activeConditionalModule && (
        <ConditionalCanvasModule
          module={activeConditionalModule}
          key={activeConditionalModule.id}
          onConfig={() => openModal()}
          onClickDelete={() => handleRemoveModule()}
        >
          <ModuleElement
            type={activeConditionalModule.type}
            module={{ ...activeConditionalModule, showIcon: false }}
            onModuleUpdate={({ title }) =>
              handleUpdateModule((v) => ({ ...v, title }))
            }
          />
        </ConditionalCanvasModule>
      )}

      {open && (
        <ModuleConfiguration
          moduleConfig={{ ...activeConditionalModule, hasConditions: false }}
          onSubmit={(values) => {
            handleUpdateModule((v) => ({ ...v, ...values }));
            closeModal();
          }}
          onClose={() => closeModal()}
          renderAsPortal
          configurableModules={configurableModules}
        />
      )}
    </StyledSelectModuleField>
  );
};

export default SelectModuleField;
