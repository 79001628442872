import { Button, Divider, useUtilities } from '@faxi/web-component-library';
import {
  FieldArray,
  FieldArrayItem,
  FormContext,
  FormField,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { SelectField } from 'components';
import InlineEditableField from 'components/_fields/InlineEditableField';
import Icon from 'components/Icon';
import { ChoiceDataModule, ChoiceModuleConfig } from 'models';
import { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SelectFieldProps } from '../../../../../components/_fields/SelectField/SelectField.component';
import { useRemoveConditionalModule } from '../../../../../hooks';
import { SimpleInputField } from '../BaseInputFieldConfiguration/components/SimpleInput/SimpleInput.component';
import { StyledBaseChoiceFieldConfiguration } from './BaseChoiceFieldConfiguration.styled';

export type BaseChoiceFieldConfigurationProps =
  PropsWithChildren<ChoiceDataModule>;

const validations = {
  label: [validators.general.required('Label is required')],
};

const BaseChoiceFieldConfiguration: FC<BaseChoiceFieldConfigurationProps> = (
  props
) => {
  const { id, config, hasConditions = true, renderModuleField } = props;

  const { updateValueField } = useContext(FormContext);
  const { options, defaultValue } =
    (useFormContextValues('options', 'defaultValue') as {
      options: { label: string }[];
      defaultValue?: string;
    }) || {};

  const baseClass = 'esg-choice-field-configuration';

  const { showSnackBar } = useUtilities();

  const selectDefaultValueOptions = useMemo(
    () =>
      options
        ? options
            ?.filter((el) => !!el?.label)
            ?.map((el) => ({ ...el, value: el?.label }))
        : [],
    [options]
  );

  const handleNoSave = useCallback(
    (index: number, remove: (index: number) => void) =>
      (text: string | undefined) => {
        if (!text) remove(index);
        else
          showSnackBar({
            text: `Can't create duplicate labels.`,
            variant: 'error',
            actionButtonText: 'Dismiss',
          });
      },
    [showSnackBar]
  );

  const shouldSave = useCallback(
    (fields: FieldArrayItem[]) => (text: string) => {
      if (!text) return false;

      return fields?.filter(({ value }) => text === value?.label).length <= 1;
    },
    []
  );

  const { handleRemoveModule } = useRemoveConditionalModule(id);

  return (
    <StyledBaseChoiceFieldConfiguration className={baseClass}>
      <FieldArray name="options">
        {({ fields, add, remove }) => (
          <div className={`${baseClass}__fields`}>
            {fields?.map(({ name, value }, index) => (
              <div
                className={`${baseClass}__fields__field`}
                key={`${value?.key}__${index}`}
              >
                <div className={`${baseClass}__fields__field__radio`} />
                <FormField
                  name={`${name}.label`}
                  component={InlineEditableField}
                  validate={validations.label}
                  placeholderText="Click here to edit label"
                  handleNoSave={handleNoSave(index, remove)}
                  shouldSave={shouldSave(fields)}
                />

                {renderModuleField?.(value.key)}

                <Button
                  variant="ghost"
                  onClick={() => {
                    hasConditions && handleRemoveModule(value.key);
                    remove(index);

                    if (defaultValue === value.label)
                      updateValueField('defaultValue', undefined, true);
                  }}
                >
                  Delete Choice
                </Button>
              </div>
            ))}
            <Button
              variant="ghost"
              icon={<Icon name="plus" />}
              iconPosition="left"
              className="esg-ghost-button-padded"
              onClick={() => add({ key: uuidv4() })}
            >
              Add Option
            </Button>
          </div>
        )}
      </FieldArray>

      <Divider />

      <SimpleInputField<ChoiceModuleConfig, SelectFieldProps>
        switchLabel="Default Selected Option"
        configKey="defaultValue"
        placeholder="Default Value"
        renderAsPortal
        component={SelectField}
        options={selectDefaultValueOptions}
        config={config as ChoiceModuleConfig}
      />
    </StyledBaseChoiceFieldConfiguration>
  );
};

export default BaseChoiceFieldConfiguration;
