import InlineEditable from 'components/_molecules/InlineEditable';
import { InlineModuleElement, IntegrationDataModule } from 'models';
import { FC, memo } from 'react';

import { BlockUI } from '../../../../../helpers';
import { useApiIntegrations } from '../../../../../hooks';
import { StyledIntegrationCanvasModule } from './IntegrationCanvasModule.styled';

export type IntegrationCanvasModuleProps =
  InlineModuleElement<IntegrationDataModule>;

const IntegrationCanvasModule: FC<IntegrationCanvasModuleProps> = ({
  showIcon,
  config,
  ...props
}) => {
  const {
    isLoadingApiIntegrations,
    isLoadingApiIntegrationFields,
    isValidatingApiIntegrations,
    isValidatingApiIntegrationFields,
    selectedIntegration,
    selectedIntegrationField,
  } = useApiIntegrations(
    config?.apiIntegrationId,
    config?.apiIntegrationFieldId
  );

  return (
    <StyledIntegrationCanvasModule className="esg-integration-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText="Click to enter/edit integration label"
        {...props}
      />

      <BlockUI
        onlySpinner
        loading={
          isLoadingApiIntegrations ||
          isLoadingApiIntegrationFields ||
          isValidatingApiIntegrations ||
          isValidatingApiIntegrationFields
        }
      >
        <strong>Integration</strong>
        <span className="esg-integration-canvas-module__selected-integration">
          {selectedIntegration?.name || 'Not selected'}
        </span>
        <strong>Field</strong>
        <span className="esg-integration-canvas-module__selected-field">
          {selectedIntegrationField?.name || 'Not selected'}
        </span>
      </BlockUI>
    </StyledIntegrationCanvasModule>
  );
};

export default memo(IntegrationCanvasModule);
