import { useUtilities } from '@faxi/web-component-library';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../api/hooks/useMutation';
import { API_ROUTES } from '../../api/routes';
import { APP_URI } from '../../config';
import { ApiData, User } from '../../models';
import authBus, { AUTH_BUS_EVENTS } from '../../modules/authBus';
import credentialsService from '../../services/credentialsService';
import storageService from '../../services/storageService';
import { getErrorMessage, getUserInformationFromToken } from '../../utils';
import RootContext, { RootContextProps } from './Root.context';

type RootProviderProps = { children?: ReactNode };

type LogoutParams = { withoutApiCall: boolean };

const RootProvider: FC<RootProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const [userTokenInformation, setUserTokenInformation] =
    useState<RootContextProps['userTokenInformation']>();

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const { trigger: logout } = useMutation<void>(API_ROUTES.USERS.LOGOUT);

  const {
    data: { data: user } = {},
    isLoading,
    error,
    isValidating,
    mutate: mutateUserCache,
  } = useSWR<ApiData<User>, Error>(API_ROUTES.USERS.PROFILE);

  const { data: { data: users } = { data: [] }, isLoading: isUsersLoading } =
    useSWR<ApiData<User[]>, Error>(API_ROUTES.USERS.USER);

  const updateUserInformation = useCallback((token?: string) => {
    const info = getUserInformationFromToken(token);

    setUserTokenInformation(info);
  }, []);

  const logoutUser = useCallback(
    async (data?: LogoutParams) => {
      if (!credentialsService.token) return;

      try {
        showOverlay('body');
        if (!data?.withoutApiCall) await logout({ method: 'DELETE' });
      } catch (e) {
        showSnackBar({
          text: getErrorMessage(e),
          variant: 'error',
          actionButtonText: 'Dismiss',
        });
      } finally {
        localStorage.clear();
        updateUserInformation();
        hideOverlay('body');
        navigate(APP_URI.AUTH_LOGIN);
      }
    },
    [
      hideOverlay,
      logout,
      navigate,
      showOverlay,
      showSnackBar,
      updateUserInformation,
    ]
  );

  useEffect(() => {
    const userIdToken = storageService.getItem<string>('ID_TOKEN');
    updateUserInformation(userIdToken);

    const unsubscribeUpdateUser = authBus.addEventListener<string>(
      AUTH_BUS_EVENTS.UPDATE_USER,
      updateUserInformation
    );

    return () => {
      unsubscribeUpdateUser();
    };
  }, [updateUserInformation]);

  useEffect(() => {
    const unsubscribeLogout = authBus.addEventListener<LogoutParams>(
      AUTH_BUS_EVENTS.LOGOUT,
      logoutUser
    );

    return () => {
      unsubscribeLogout();
    };
  }, [logoutUser]);

  return (
    <RootContext.Provider
      value={{
        // force existence because of blockUI
        user: user!,
        users: users!,
        userTokenInformation,
        loadingUser: isLoading || isUsersLoading,
        validatingUser: isValidating,
        errorUser: !!error,
        logoutUser,
        mutateUserCache,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

const useRootProvider = (): RootContextProps => useContext(RootContext);

export { RootProvider, useRootProvider };
