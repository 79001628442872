import { FormField } from '@faxi/web-form';
import classNames from 'classnames';
import { DataModuleEnum, TableDataModule } from 'models';
import { FC, useEffect, useState } from 'react';

import { generateModuleFieldName } from '../../../../../../utils';
import InlineEditable from '../../../../../_molecules/InlineEditable';
import { DEFAULT_TABLE_CONFIG } from '../../TableCanvasModule/TableCanvasModule.component';
import { StyledTablePreview } from './TablePreview.styled';

export type TablePreviewProps = TableDataModule & {
  onChange?: (value: TableDataModule['value']) => void;
};

const TablePreview: FC<TablePreviewProps> = ({
  id,
  title,
  config,
  onChange,
  value,
}) => {
  const [cellValues, setCellValues] = useState<{ [key: string]: string }>(
    value
  );

  const { rows, columns } = config || DEFAULT_TABLE_CONFIG;

  const handleCellChange = (key: string, value: string) => {
    const newCellValues = { ...cellValues, [key]: value };

    onChange?.(newCellValues);
    setCellValues(newCellValues);
  };

  useEffect(() => {
    setCellValues(value);
  }, [value]);

  return (
    <StyledTablePreview className="esg-table-preview">
      <div className="esg-table-preview__title">{title || 'Table field'}</div>

      <div className="esg-table-preview__table">
        {rows.map(
          (
            { label: rowLabel, splitTimes: splitTimesRow, id: rowId },
            rowIndex
          ) => (
            <div
              key={`${rowId}_row_${rowIndex}`}
              className="esg-table-preview__table__row"
              style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
            >
              {columns.map(
                (
                  {
                    label: columnLabel,
                    splitTimes: splitTimesColumn,
                    id: colId,
                  },
                  columnIndex
                ) =>
                  rowIndex === 0 || columnIndex === 0 ? (
                    <div
                      className={classNames(
                        'esg-table-preview__table__row__item',
                        'esg-table-preview__table__row__item--heading'
                      )}
                      key={colId}
                    >
                      {rowIndex + columnIndex === 0
                        ? ''
                        : rowIndex === 0
                          ? columnLabel
                          : rowLabel}
                    </div>
                  ) : (
                    Array.from({ length: splitTimesColumn }).map(
                      (_, splitColumnsIndex) => {
                        const cellKey = `row_${rowId}_column_${colId}_cell_${splitColumnsIndex}`;
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: `${100 / splitTimesColumn}%`,
                            }}
                            key={cellKey}
                          >
                            {Array.from({ length: splitTimesRow }).map(
                              (_, splitRowsIndex) => {
                                const subCellKey = `${cellKey}_subcell_${splitRowsIndex}`;
                                return (
                                  <div
                                    className="esg-table-preview__table__row__item"
                                    key={subCellKey}
                                    style={{ height: '100%' }}
                                  >
                                    <InlineEditable
                                      onSave={() => {}}
                                      onChange={(value) =>
                                        handleCellChange(subCellKey, value)
                                      }
                                      value={cellValues[subCellKey] || ''}
                                      placeholderText={
                                        cellValues[subCellKey] || '\u00A0'
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )
                  )
              )}
            </div>
          )
        )}
      </div>
    </StyledTablePreview>
  );
};

export type TablePreviewFieldProps = { id: string } & TablePreviewProps;

const TablePreviewField: FC<TablePreviewFieldProps> = ({ id, ...rest }) => (
  <FormField
    id={id}
    name={generateModuleFieldName(id, DataModuleEnum.TABLE)}
    component={TablePreview}
    {...rest}
  />
);

export default TablePreviewField;
