import classNames from 'classnames';
import Icon, { INameExtended } from 'components/Icon';
import { FC, memo, PropsWithChildren } from 'react';

import { StyledNoData } from './NoData.styled';

export type ErrorFallbackProps = PropsWithChildren<{
  title?: string;
  iconName?: INameExtended;
  className?: string;
}>;

const NoData: FC<ErrorFallbackProps> = ({
  title = 'No data',
  iconName = 'folder-open',
  className,
}) => (
  <StyledNoData className={classNames('esg-error-fallback', className)}>
    <Icon name={iconName} />
    <p>{title}</p>
  </StyledNoData>
);

export default memo(NoData);
