import { validationRegexes, validators } from '@faxi/web-form';
import { useMemo } from 'react';
import { equalField } from 'validators';

const PASSWORD_MIN_LENGTH = 8;

const maxValue = (maxValue: number, msg: string) => (value: number) => {
  if (value && value > maxValue) {
    return msg;
  }
  return '';
};

const minValue = (minValue: number, msg: string) => (value: number) => {
  if (value && value < minValue) {
    return msg;
  }
  return '';
};

export default function useValidations() {
  const validations = useMemo(
    () => ({
      email: [
        validators.general.required('Email is required'),
        validators.general.regex(
          validationRegexes.workEmail,
          'Please enter a valid email address'
        ),
      ],
      password: [
        validators.general.minLength(
          PASSWORD_MIN_LENGTH,
          `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`
        ),
        validators.password.mustHaveLowerCase(
          'Password must contain lower case'
        ),
        validators.password.mustHaveNumber('Password must contain number'),
        validators.password.mustHaveUpperCase(
          'Password must contain upper case'
        ),
        validators.general.regex(
          /[^\w]/,
          'Password must contain special character'
        ),
      ],
      repeatPassword: [
        validators.general.required('Please confirm your password'),
        equalField('Password must match', 'password'),
      ],
      name: [
        validators.general.required('Name is required'),
        validators.general.regex(
          /^[^#\\/]*$/,
          'Characters # \\ / are not allowed.'
        ),
        validators.general.regex(
          /^\s+$/,
          'You may not enter only space characters.',
          true
        ),
      ],
      description: [
        validators.general.maxLength(
          150,
          'Description can only have a maximum of 150 characters.'
        ),
        validators.general.regex(
          /^\s+$/,
          'You may not enter only space characters.',
          true
        ),
      ],
      campaign: validators.general.required('Campaign is required'),
      type: validators.general.required('Type is required'),
      role: validators.general.required('Role is required'),
      confirmationCode: validators.general.required(
        'Confirmation code is required'
      ),
      xbrlTaxonomies: validators.general.required('Taxonomies are required'),
      deadline: validators.general.required('Deadline is required.'),
      contributors: validators.general.requiredArray(
        'Contributors are required.'
      ),
      splitTimes: [
        validators.general.required('Split times is required'),
        minValue(1, 'Minimum value is 1'),
        maxValue(10, 'Maximum value is 10'),
      ],
      tableSize: (max: number) => [
        validators.general.required('Size is required'),
        minValue(1, 'Minimum value is 1'),
        maxValue(max, `Maximum value is ${max}`),
      ],
      tableLabel: [
        validators.general.required('Table label is required'),
        validators.general.regex(
          /^[a-zA-Z0-9 ]+$/,
          'Table label can only contain letters, numbers, and spaces'
        ),
      ],
      taxonomyFieldGroupId: validators.general.required(
        'Taxonomy field group is required'
      ),
    }),
    []
  );

  return { validations };
}
