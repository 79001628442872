import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledEmailInput = styled.div`
  ${flex('column')};

  margin-top: ${theme.sizes.SIZE_32};
`;

export { StyledEmailInput };
