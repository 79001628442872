import { Progress } from './Progress';

export enum FormProgressEnum {
  InProgress = 'In Progress',
  Completed = 'Completed',
}

export enum FormStatusEnum {
  None = 'None',
  Completed = 'Completed',
}

export enum FormApprovalEnum {
  None = 'None',
  ToReview = 'To Review',
  Approved = 'Approved',
}

export enum FormAuditEnum {
  None = 'None',
  ToReview = 'To Review',
  Audited = 'Audited',
}

export const STATUS_OPTIONS = [
  { label: FormStatusEnum.None, value: FormStatusEnum.None },
  { label: FormStatusEnum.Completed, value: FormStatusEnum.Completed },
];

export const APPROVAL_OPTIONS = (showNone = true) => [
  ...(showNone
    ? [{ label: FormApprovalEnum.None, value: FormApprovalEnum.None }]
    : []),
  { label: FormApprovalEnum.ToReview, value: FormApprovalEnum.ToReview },
  { label: FormApprovalEnum.Approved, value: FormApprovalEnum.Approved },
];

export const AUDIT_OPTIONS = (showNone = true) => [
  ...(showNone
    ? [{ label: FormAuditEnum.None, value: FormAuditEnum.None }]
    : []),
  { label: FormAuditEnum.ToReview, value: FormAuditEnum.ToReview },
  { label: FormAuditEnum.Audited, value: FormAuditEnum.Audited },
];

export type StatusProgresses = {
  approval: Progress;
  audit: Progress;
  completion: Progress;
};
