import Icon from 'components/Icon';
import { TreeNodeElement } from 'models';
import { FC, memo } from 'react';

export type LeafIconProps = { node: TreeNodeElement };

const LeafIcon: FC<LeafIconProps> = ({ node: { color, iconName } }) => (
  <Icon className="esg-leaf-icon" color={color} name={iconName || 'file'} />
);

export default memo(LeafIcon);
