import { FormField } from '@faxi/web-form';
import { InputField } from 'components';
import { DataModuleEnum, IntegrationDataModule } from 'models';
import { FC } from 'react';

import { BlockUI } from '../../../../../../helpers';
import { useApiIntegrations } from '../../../../../../hooks';
import { generateModuleFieldName } from '../../../../../../utils';
import { StyledIntegrationPreview } from './IntegrationPreview.styled';

export type IntegrationPreviewProps = IntegrationDataModule;

const IntegrationPreview: FC<IntegrationPreviewProps> = ({
  title,
  config,
  id,
  disabled,
}) => {
  const {
    isLoadingApiIntegrations,
    isLoadingApiIntegrationFields,
    isValidatingApiIntegrations,
    isValidatingApiIntegrationFields,
    selectedIntegration,
    selectedIntegrationField,
  } = useApiIntegrations(
    config?.apiIntegrationId,
    config?.apiIntegrationFieldId
  );

  return (
    <StyledIntegrationPreview className="esg-integration-preview">
      <div className="esg-integration-preview__title">
        {title || 'Integration field'}
      </div>

      <BlockUI
        loading={
          isLoadingApiIntegrations ||
          isLoadingApiIntegrationFields ||
          isValidatingApiIntegrations ||
          isValidatingApiIntegrationFields
        }
      >
        {selectedIntegrationField ? (
          <FormField
            name={generateModuleFieldName(id, DataModuleEnum.INTEGRATION)}
            label={selectedIntegration?.name}
            placeholder={selectedIntegrationField?.name}
            component={InputField}
            autoComplete="off"
            disabled={disabled}
          />
        ) : (
          'Not selected'
        )}
      </BlockUI>
    </StyledIntegrationPreview>
  );
};

export default IntegrationPreview;
