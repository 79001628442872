import { APP_URI } from 'config';
import { RunSession, SessionDashboard, SessionPage, Sessions } from 'pages';
import { RouteObject } from 'react-router-dom';

import { PermissionSections } from '../../../models';
import {
  SessionCompanyBrowser,
  SessionDMAExclusion,
} from '../../../pages/Sessions/components';
import SessionActivity from '../../../pages/Sessions/components/SessionActivity';
import SessionCampaignBrowser from '../../../pages/Sessions/components/SessionCampaignBrowser';
import SessionContributors from '../../../pages/Sessions/components/SessionContributors';
import SessionDetails from '../../../pages/Sessions/components/SessionDetails';
import SessionDocuments from '../../../pages/Sessions/components/SessionDocuments';

export default [
  { path: '', element: <Sessions /> },
  {
    path: APP_URI.SESSIONS_SESSION,
    element: <SessionPage />,
    children: [
      { path: APP_URI.SESSIONS_SESSION_RUN, element: <RunSession /> },
      {
        path: '',
        element: <SessionDetails />,
        children: [
          {
            path: APP_URI.SESSIONS_SESSION_DASHBOARD,
            element: <SessionDashboard />,
          },
          {
            permissionsGroup: [PermissionSections.LOGS],
            path: APP_URI.SESSIONS_SESSION_ACTIVITY,
            element: <SessionActivity />,
          },
          {
            path: APP_URI.SESSIONS_SESSION_MEMBERS,
            element: <SessionContributors />,
          },
          {
            path: APP_URI.SESSIONS_SESSION_DETAILS_DOCUMENTS,
            element: <SessionDocuments />,
          },
          {
            path: APP_URI.SESSIONS_SESSION_DETAILS_DMA_EXCLUSION,
            element: <SessionDMAExclusion />,
          },
          {
            path: APP_URI.SESSIONS_SESSION_CAMPAIGN_BROWSER,
            children: [
              { path: '', element: <SessionCompanyBrowser /> },
              { path: ':companyId', element: <SessionCampaignBrowser /> },

              {
                path: ':companyId/item/:campaignItemId',
                element: <SessionCampaignBrowser />,
              },
            ],
          },
        ],
      },
    ],
  },
  {},
] as RouteObject[];
