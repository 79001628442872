import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import { DataModuleEnum, IDataModule, SessionForm } from '../../../../models';
import FormModulesContext, {
  FormModulesContextProps,
} from './FormModules.context';

type FormModulesProps = {
  children?: ReactNode;
  organisationSessionId: string;
  campaignItemId: string;
};

/**
 * Recursively filters out "SECTION" modules that are empty or contain no non-empty nested modules.
 *
 * @param elements - An array of IDataModule objects to filter. Defaults to an empty array.
 * @returns A filtered array of IDataModule objects where:
 *  - Non-"SECTION" modules are preserved.
 *  - "SECTION" modules are included only if they contain non-empty nested elements.
 */
const filterNonEmptyModules = (
  elements: IDataModule<DataModuleEnum>[] = []
): IDataModule<DataModuleEnum>[] =>
  elements
    .filter(({ type, elements }) => {
      if (type === DataModuleEnum.SECTION) {
        const filteredNestedElements = elements
          ? filterNonEmptyModules(elements)
          : [];

        return filteredNestedElements.length > 0;
      }

      return true;
    })
    .map((element) => ({
      ...element,
      ...(element.type === DataModuleEnum.SECTION && {
        elements: filterNonEmptyModules(element.elements || []).sort(
          ({ index: index1 }, { index: index2 }) => (index1 < index2 ? -1 : 1)
        ),
      }),
    }));

const FormModules: FC<FormModulesProps> = (props) => {
  const { children, organisationSessionId, campaignItemId } = props;

  const [modules, setModules] = useState<IDataModule[]>([]);

  const apiEndpoint =
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
      organisationSessionId,
      campaignItemId
    );

  const {
    data: { data: form } = {},
    isLoading: isLoadingForm,
    isValidating: isValidatingForm,
    error,
  } = useSWR<{ data: SessionForm & { elements: IDataModule[] } }>(apiEndpoint);

  useEffect(() => {
    if (form?.elements) {
      setModules(
        filterNonEmptyModules(form.elements).sort(
          ({ index: index1 }, { index: index2 }) => (index1 < index2 ? -1 : 1)
        )
      );
    }
  }, [form?.elements]);

  return (
    <FormModulesContext.Provider
      value={{
        setModules,
        isLoadingForm,
        isValidatingForm,
        modules,
        error,
        formApiEndpoint: apiEndpoint,
        form,
        organisationSessionId,
        campaignItemId,
      }}
    >
      {children}
    </FormModulesContext.Provider>
  );
};

const useFormModules = (): FormModulesContextProps =>
  useContext(FormModulesContext);

export { FormModules, useFormModules };
