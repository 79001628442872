import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserPermissions = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_24};

  width: 100%;
  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_48};
`;

export { StyledUserPermissions };
