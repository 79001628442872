import {
  Button,
  Calendar as WclCalendar,
  CalendarProps as WclCalendarProps,
  CalendarValue,
  config,
  Dropdown,
  DropdownProps,
  DropdownRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';

import { PortalContainer } from '../../../../../components/_fields/CalendarField/CalendarField.styled';
import { CALENDAR_LABELS } from '../../../../../components/_fields/CalendarField/utils';
import Icon from '../../../../../components/Icon';
import { DATE_FORMAT } from '../../../../../constants';
import DueReminderFlag from '../../../../components/ProfileReminders/components';
import { StyledDeadlineCalendar } from './DeadlineCalendar.styled';

type DeadlineCalendarProps = {
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  renderAsPortal?: boolean;
  dateFormat?: string;
  onChange?: (date: Dayjs | null) => void;
  openAtToday?: boolean;
  disabled?: boolean;
  triggerText: string;
} & Pick<
  WclCalendarProps,
  | 'disableFuture'
  | 'disablePast'
  | 'mode'
  | 'value'
  | 'disabledDates'
  | 'leftLimitDate'
  | 'rightLimitDate'
> &
  Pick<DropdownProps, 'openPosition'>;

const DeadlineCalendar: FC<DeadlineCalendarProps> = (props) => {
  const {
    dateFormat = DATE_FORMAT,
    renderAsPortal = true,
    disablePast = true,
    openAtToday = true,
    leftLimitDate,
    rightLimitDate,
    value,
    disableFuture,
    disabledDates,
    openPosition,
    onChange,
    disabled,
    triggerText,
  } = props;

  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();

  const { prompts } = useUtilities();

  const displayValue = useMemo(
    () =>
      value
        ? dayjs(value as Dayjs, config.dateFormat).format(dateFormat)
        : undefined,
    [dateFormat, value]
  );

  const closeDropdown = useCallback(() => {
    dropdown?.trigger.focus();
    dropdown?.setOpen(false);
  }, [dropdown]);

  const handleOnChange = useCallback(
    (date?: CalendarValue) => {
      if (!date && !value) return;

      const oldDate = value as Dayjs | undefined;
      const newDate = date as Dayjs | null;

      const isDateSame = oldDate && newDate && newDate.isSame(oldDate, 'date');

      if (isDateSame) return;

      dropdown?.setOpen(false);

      prompts.standard({
        type: 'standard',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        title: `${newDate ? 'Set' : 'Clear'} Deadline`,
        content: `Are you sure you want to ${newDate ? 'set' : 'clear'} the deadline${newDate ? ` to` : ''} ${dayjs(newDate || oldDate).format(DATE_FORMAT)}?`,
        iconPosition: 'left',
        titleIcon: <Icon name="calendar-days" />,
        onConfirm: () => onChange?.(newDate),
      });
    },
    [value, prompts, onChange, dropdown]
  );

  return (
    <StyledDeadlineCalendar>
      <Dropdown
        ref={dropdownRef}
        className="esg-deadline-calendar"
        hasCloudArrow={false}
        openPosition={openPosition}
        renderAsPortal={renderAsPortal}
        portalStyle={PortalContainer}
        disabled={disabled}
        trigger={
          !displayValue ? (
            <Button
              disabled={disabled}
              icon={<Icon name="calendar-days" />}
              variant="outline"
            >
              {triggerText}
            </Button>
          ) : (
            // we use `DueReminderFlag` component to change or clear deadline, so I cannot pass it as trigger
            // because it will open Dropdown component when user try to clear deadline (stop-propagation doesn't fix it))
            <div className="esg-deadline-calendar__hidden-trigger" />
          )
        }
        body={
          <WclCalendar
            keepFocus={dropdown?.open}
            labels={CALENDAR_LABELS}
            dateFormat={dateFormat}
            disablePast={disablePast}
            disableFuture={disableFuture}
            leftLimitDate={leftLimitDate}
            disabledDates={disabledDates}
            rightLimitDate={rightLimitDate}
            value={value}
            onClose={closeDropdown}
            onChange={handleOnChange}
            openAtToday={openAtToday}
          />
        }
      />

      {displayValue && (
        <div className="calendar-trigger-with-value">
          <div>
            <Icon name="calendar-days" />
            <div>{displayValue}</div>
          </div>
          <DueReminderFlag
            onClick={() => dropdown?.setOpen(true)}
            dueDate={dayjs(value as Dayjs).toDate()}
            onDelete={() => handleOnChange?.(undefined)}
          />
        </div>
      )}
    </StyledDeadlineCalendar>
  );
};

export default DeadlineCalendar;
