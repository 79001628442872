import dayjs from 'dayjs';
import { FC, PropsWithChildren } from 'react';

import Icon from '../../../../../components/Icon';
import {
  DataModuleEnum,
  InputFieldTypes,
  InputModuleConfig,
} from '../../../../../models';
import { StyledInputValueChange } from './InputValueChange.styled';

export type InputValueChangeProps = PropsWithChildren<{
  oldValue?: string;
  newValue?: string;
  config?: Record<string, any>;
  type: DataModuleEnum;
}>;

const formatValue = (
  value?: any,
  type?: DataModuleEnum,
  config?: Record<string, any>
) => {
  if (!value) return '-';

  switch (type) {
    case DataModuleEnum.SWITCH:
      return value ? 'On' : 'Off';

    case DataModuleEnum.INPUT:
      if ((config as InputModuleConfig)?.type === InputFieldTypes.DATE_FIELD) {
        return dayjs(value).format('DD / MM / YYYY');
      }

      return value;

    case DataModuleEnum.UPLOAD:
      if (!value?.length) {
        return '-';
      }

      return (value as { path: string }[])
        .map(({ path }) => path?.slice(2))
        .join(' || ');

    default:
      return value;
  }
};

const InputValueChange: FC<InputValueChangeProps> = ({
  oldValue,
  type,
  config,
  newValue,
}) => {
  return (
    <StyledInputValueChange className="esg-input-value-change">
      <span className="esg-input-value-change__old">
        {formatValue(oldValue, type, config)}
      </span>{' '}
      <Icon name="arrow-right" />{' '}
      <span className="esg-input-value-change__new">
        {formatValue(newValue, type, config)}
      </span>
    </StyledInputValueChange>
  );
};

export default InputValueChange;
