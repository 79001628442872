import { Icon, Table, Tooltip } from '@faxi/web-component-library';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import UserImage from '../../../../components/_atoms/UserImage';
import { BlockUI } from '../../../../helpers';
import { DMAExclusionTableData, ExclusionData } from '../../../../models';
import SessionHeader from '../SessionHeader';
import { StyledSessionDMAExclusion } from './SessionDMAExclusion.styled';

const EXCLUDE_COLUMNS: (keyof DMAExclusionTableData)[] = ['id'];

const TRANSLATION_KEYS = {
  id: 'Item ID',
  item: 'Excluded Item',
  user: 'Excluded By',
  exclusionJustification: 'Exclusion Reason',
};

const mapDMAExclusionsToTableData = (exclusionData: ExclusionData) =>
  Object.values(exclusionData).map(
    ({ organisationName, excludedElements }) => ({
      organisationName,
      data: excludedElements.map(
        ({ excludedBy, exclusionJustification, namedPath }, index) => ({
          id: index,
          item: (
            <Tooltip
              content={`Excluded item path: ${namedPath.replaceAll('#', ' / ')}`}
            >
              <div className="esg-session-exclusion__item">
                <Icon name="file" /> {namedPath.split('#').at(-1)}
              </div>
            </Tooltip>
          ),
          user: (
            <div className="esg-session-exclusion__user">
              <UserImage
                src={excludedBy.image}
                name={`${excludedBy.firstName} ${excludedBy.lastName}`}
              />
              {excludedBy.firstName} {excludedBy.lastName}
            </div>
          ),
          exclusionJustification,
        })
      ),
    })
  );

const SessionDMAExclusion: FC = () => {
  const { sessionId } = useParams();

  const {
    data: { data: exclusionData } = {},
    isLoading,
    error,
  } = useSWR<{ data: ExclusionData }>(
    sessionId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_DMA_EXCLUSION(
        sessionId
      )
  );

  return (
    <StyledSessionDMAExclusion className="esg-session-exclusion">
      <SessionHeader heading="DMA Exclusion" icon="ban" />
      <BlockUI
        loading={isLoading}
        fallbackCondition={
          !(exclusionData && Object.keys(exclusionData).length)
        }
        error={error}
      >
        {exclusionData &&
          mapDMAExclusionsToTableData(exclusionData).map(
            ({ organisationName, data }, index) => (
              <div key={index} className="esg-session-exclusion__table">
                {organisationName}
                <Table
                  tableId={`session-exclusion__table--${index}`}
                  tableData={data}
                  excludeColumns={EXCLUDE_COLUMNS}
                  disableSort
                  translationKeys={TRANSLATION_KEYS}
                />
              </div>
            )
          )}
      </BlockUI>
    </StyledSessionDMAExclusion>
  );
};

export default SessionDMAExclusion;
