import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

// TODO: clean this up
const StyledSessionContributors = styled.div`
  ${flex('column', 'flex-start', 'stretch')};
  padding: ${theme.sizes.SIZE_24};
  gap: ${theme.sizes.SIZE_48};
  flex: 1;

  .esg-session-contributors {
    &__header-container {
      ${flex('row', 'space-between', 'center')};
    }

    &__header {
      ${flex('column', 'center', 'flex-start')};
      gap: ${theme.sizes.SIZE_32};
    }

    &__content {
      height: 100%;

      .esg-empty-folder {
        margin: auto;
      }
    }
  }
`;

export { StyledSessionContributors };
