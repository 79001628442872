import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledMissingInfoTooltip = styled.div`
  .tippy-box[data-theme~='custom-tooltip'] {
    width: ${theme.sizes.SIZE_374};

    .esg-missing-info-tooltip__container {
      ${flex('column', 'center', 'flex-start')};
      gap: ${theme.sizes.SIZE_16};

      &__header {
        ${flex('row')};
        gap: ${theme.sizes.SIZE_10};
      }
    }

    .button {
      align-self: center;
    }
  }
`;
export { StyledMissingInfoTooltip };
