import {
  DataModuleEnum,
  ElementsOmitIdElements,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { useCampaignItemProvider } from '../CampaignItem';
import FormBuilderContext, {
  FormBuilderContextProps,
} from './FormBuilder.context';

type FormBuilderProps = { children?: ReactNode };

export const generateModulesFromCampaignItem = (
  elements?: ElementsOmitIdElements[]
): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
  elements
    ?.map(({ index, type, title = '', id, elements, ...rest }) => ({
      id: `${id}+${type}`,
      index,
      type,
      title,
      elements: generateModulesFromCampaignItem(elements),
      ...rest,
    }))
    .sort(({ index: index1 }, { index: index2 }) =>
      index1 < index2 ? -1 : 1
    ) || [];

const FormBuilderProvider: FC<FormBuilderProps> = (props) => {
  const { children } = props;

  const { isMutatingCampaignItem } = useCampaignItemProvider();

  const { dataCollection: dataCollectionElement } = useCampaignItemProvider();

  const [selectedDataModule, setSelectedDataModule] = useState<string>();

  const [modules, setModules] = useState<
    ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  >([]);

  const selectDataModule = (dataModule: string) =>
    setSelectedDataModule((prev) =>
      prev === dataModule ? undefined : dataModule
    );

  useEffect(() => {
    if (!dataCollectionElement?.elements) return;

    setModules(generateModulesFromCampaignItem(dataCollectionElement.elements));
  }, [dataCollectionElement]);

  return (
    <FormBuilderContext.Provider
      value={{
        loadingNewModule: isMutatingCampaignItem,
        selectedDataModule,
        selectDataModule,
        modules,
        setModules,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

const useFormBuilder = (): FormBuilderContextProps =>
  useContext(FormBuilderContext);

export { FormBuilderProvider, useFormBuilder };
