import { GlowScroll } from '@faxi/web-component-library';
import { FC, Fragment, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SessionCard } from '../../../../components';
import { BlockUI } from '../../../../helpers';
import CampaignBrowserSidePanel from '../../../../routes/subrouters/Sessions/components/CampaignBrowserSidePanel';
import { FormModules } from '../../context/FormModules';
import { LockDrawer } from '../../context/LockDrawer';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import SessionCampaignForm from '../SessionCampaignForm';
import SessionCampaignHeader from '../SessionCampaignHeader';
import { StyledSessionCampaignBrowser } from './SessionCampaignBrowser.styled';

const SessionCampaignBrowser: FC = () => {
  const {
    sessionCampaignItemChildren,
    selectedCampaignItem,
    sessionCompanies,
  } = useSessionBrowser(false);

  const navigate = useNavigate();
  const { sessionId = '', companyId = '', campaignItemId = '' } = useParams();

  const companyOrganisationSessionId = useMemo(
    () =>
      sessionCompanies?.find((company) => company.id === companyId)
        ?.organisationSessionId ?? '',
    [companyId, sessionCompanies]
  );

  return (
    <StyledSessionCampaignBrowser className="esg-session-campaign-browser">
      <BlockUI loading={false}>
        {selectedCampaignItem && (
          <div className="esg-session-campaign-browser__content">
            {!selectedCampaignItem.isForm ? (
              sessionCampaignItemChildren && (
                <Fragment>
                  <SessionCampaignHeader
                    name={`${selectedCampaignItem.name}`}
                    iconName={
                      !selectedCampaignItem.organisationSessionId
                        ? 'building'
                        : 'leaf'
                    }
                    description={selectedCampaignItem.description}
                  />
                  <GlowScroll variant="gray">
                    <div className="esg-session-campaign-browser__content__cards">
                      {sessionCampaignItemChildren.map(
                        ({ cardData: { status, ...cardData }, iconName }) => (
                          <SessionCard
                            key={cardData.id}
                            onSelect={() => {
                              const isDataCollectionElement =
                                cardData.type === 'data_collection';

                              navigate(
                                `/sessions/${sessionId}/campaign-browser/${companyId}/item/${cardData.id}${isDataCollectionElement ? '?form=yes' : ''}`
                              );
                            }}
                            iconName={iconName}
                            cardData={cardData}
                          />
                        )
                      )}
                    </div>
                  </GlowScroll>
                </Fragment>
              )
            ) : (
              <FormModules
                organisationSessionId={`${selectedCampaignItem.organisationSessionId}`}
                campaignItemId={campaignItemId}
              >
                <LockDrawer>
                  <SessionCampaignForm
                    formName={`${selectedCampaignItem.name}`}
                    formDescription={selectedCampaignItem.description}
                  />
                </LockDrawer>
              </FormModules>
            )}
          </div>
        )}
        <CampaignBrowserSidePanel
          organisationSessionId={companyOrganisationSessionId}
        />
      </BlockUI>
    </StyledSessionCampaignBrowser>
  );
};

export default SessionCampaignBrowser;
