import { FC } from 'react';
import { NavLink, Outlet, To, useParams } from 'react-router-dom';

import { CollapsibleSidePanel } from '../../components';
import { USER_BY_ID_CRUMBS } from '../../constants';
import { StyledUserProfile } from './UserProfile.styled';

export type UserProfileProps = { navLinks: { label: string; to: To }[] };

const UserProfile: FC<UserProfileProps> = ({ navLinks }) => {
  const { userId } = useParams<{ userId?: string }>();
  return (
    <StyledUserProfile
      title="Profile"
      className="esg-user-profile"
      padding={0}
      breadcrumbs={userId ? USER_BY_ID_CRUMBS : []}
    >
      <CollapsibleSidePanel expandDirection="right">
        <nav>
          {navLinks.map(({ label, to }) => (
            <NavLink key={label} to={to}>
              {label}
            </NavLink>
          ))}
        </nav>
      </CollapsibleSidePanel>
      <Outlet />
    </StyledUserProfile>
  );
};

export default UserProfile;
