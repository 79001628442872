import {
  Button,
  ModalProps,
  ModalRef,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { FolderNavHeader, NoData, SectionCard, ViewGuard } from 'components';
import { EntityFormModal } from 'components/_forms';
import { EntityFormFieldProps } from 'components/_forms/EntityFormModal/EntityFormModal.component';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import {
  CampaignCommon,
  CampaignItem,
  CampaignItemTypeDetailed,
  PermissionSections,
} from 'models';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { API_ROUTES } from '../../../../api/routes';
import { BlockUI } from '../../../../helpers';
import {
  useDeleteCampaignEntity,
  useSWRCache,
  useURLCampaignItemId,
  useUserPermissions,
} from '../../../../hooks';
import { useCampaignProvider } from '../../../../providers/Campaign';
import { useCampaignItemProvider } from '../../context/CampaignItem';
import {
  generateCampaignItemCrumbs,
  mapCampaignEntityTypeToText,
  mapCampaignItemPathLengthToCampaignItemTypeDetailed,
  mapCampaignItemTypeToIcon,
  mapCampaignItemTypeToStatus,
  mapCampaignItemTypeToText,
} from '../../utils';
import { StyledCampaignEntity } from './CampaignEntity.styled';

export type CampaignEntityProps = {
  campaignItem?: CampaignItem;
  entityType: CampaignItemTypeDetailed;
  modalProps?: ModalProps & EntityFormFieldProps & { chooseType?: boolean };
  navigateTo?: (v: string) => To;
};

const CampaignEntity: FC<CampaignEntityProps> = (
  props: CampaignEntityProps
) => {
  const { modalProps, campaignItem, entityType, navigateTo } = props;

  const hasPermission = useUserPermissions(PermissionSections.CAMPAIGN);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showSnackBar } = useUtilities();

  const [activeCampaignItem, setActiveCampaignItem] =
    useState<CampaignCommon>();

  const { mutateCacheKeys } = useSWRCache();
  const { campaignItemId } = useURLCampaignItemId();

  const {
    rootCampaign,
    isMutatingRootCampaign,
    editCampaign,
    mutateRootCampaign,
    isLoadingRootCampaign,
    isValidatingRootCampaign,
    createCampaign,
    deleteCampaign,
  } = useCampaignProvider();

  const {
    createCampaignItem,
    editCampaignItem,
    deleteCampaignItem,
    isMutatingCampaignItem,
  } = useCampaignItemProvider();

  const onDeleteSubmit = useCallback(
    async (id: string, item?: CampaignCommon) => {
      if (!item) return;

      if (item.type === 'campaign') {
        await deleteCampaign(id);
      } else {
        await deleteCampaignItem(rootCampaign!.id, id);
      }
    },
    [deleteCampaign, deleteCampaignItem, rootCampaign]
  );

  const { onDelete } = useDeleteCampaignEntity(onDeleteSubmit);

  const modalRef = useRef<ModalRef>(null);

  const crumbs = useMemo(
    () => generateCampaignItemCrumbs(rootCampaign, campaignItem),
    [campaignItem, rootCampaign]
  );

  const openEditModal = useCallback(
    (item: CampaignItem) => {
      setActiveCampaignItem(item);
      modalRef?.current?.open();
    },
    [setActiveCampaignItem]
  );

  const buttonNoMargin = useMemo(
    () => pathname === APP_URI.CAMPAIGNS,
    [pathname]
  );

  const entityTitle = useMemo(
    () => `Create New ${mapCampaignEntityTypeToText[entityType]}`,
    [entityType]
  );

  const onSubmit = useCallback(
    async (data: Record<string, any>) => {
      if (activeCampaignItem) {
        if (activeCampaignItem.type === 'campaign') {
          await editCampaign(activeCampaignItem.id, data);

          mutateCacheKeys(
            (key) =>
              key === API_ROUTES.CAMPAIGNS.CAMPAIGN(activeCampaignItem.id)
          );
        } else {
          await editCampaignItem(rootCampaign!.id, activeCampaignItem.id, data);

          mutateCacheKeys(
            (key) =>
              key ===
              API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(
                rootCampaign!.id,
                campaignItemId
              )
          );
          await mutateRootCampaign();
        }
      } else {
        if (!rootCampaign) {
          await createCampaign(data);
        } else {
          await createCampaignItem(rootCampaign.id, {
            ...data,
            campaignItemId:
              campaignItemId !== rootCampaign.id ? campaignItemId : '',
            type: data.type || 'topic',
          });
          await mutateRootCampaign();
        }

        // reason: we need to mutate `API_ROUTES.CAMPAIGNS.BASE(true)` in order to work on
        // creating a new session (there we need to select campaign)
        mutateCacheKeys((key) => key === API_ROUTES.CAMPAIGNS.BASE(true));
      }

      showSnackBar({
        text: `${data.name} campaign successfully ${activeCampaignItem ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [
      activeCampaignItem,
      showSnackBar,
      editCampaign,
      editCampaignItem,
      rootCampaign,
      mutateRootCampaign,
      mutateCacheKeys,
      createCampaign,
      createCampaignItem,
      campaignItemId,
    ]
  );

  return (
    <BlockUI loading={isLoadingRootCampaign}>
      <StyledCampaignEntity className="esg-campaign-entity">
        {campaignItem && campaignItem.id && (
          <FolderNavHeader
            crumbs={crumbs}
            title={campaignItem.name}
            description={campaignItem.description}
            updateDescription={() => openEditModal(campaignItem)}
            icon="leaf"
            hasActions={hasPermission(['update'])}
          />
        )}

        <ViewGuard
          section={PermissionSections.CAMPAIGN}
          permissions={['create']}
        >
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={() => modalRef?.current?.open()}
            className={classNames('esg-campaign-entity__add', {
              'esg-campaign-entity__add--no-margin': buttonNoMargin,
            })}
          >
            {entityTitle}
          </Button>
        </ViewGuard>

        <EntityFormModal
          ref={modalRef}
          title={
            !activeCampaignItem
              ? entityTitle
              : `Edit ${activeCampaignItem.name}`
          }
          onSubmit={onSubmit}
          initialData={activeCampaignItem}
          onClose={() => setActiveCampaignItem(undefined)}
          taxonomyId={rootCampaign?.taxonomyId}
          {...modalProps}
          fieldsConfiguration={{
            TYPE:
              (modalProps?.chooseType && !activeCampaignItem) ||
              (!!activeCampaignItem?.type && entityType === 'subsubtopic'),
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            CAMPAIGN: false,
            TAXONOMY_GROUP: activeCampaignItem?.type === 'data_collection',
            TAXONOMY_ID:
              entityType === 'campaign' ||
              activeCampaignItem?.type === 'campaign',
          }}
          loading={
            isMutatingRootCampaign ||
            isMutatingCampaignItem ||
            isValidatingRootCampaign
          }
        />

        {campaignItem?.children?.length ? (
          <div className="esg-campaign-entity__items">
            {campaignItem.children.map((item) => {
              const { name, type, id, description, path } = item;

              const typeDetailed =
                type === 'data_collection'
                  ? 'data_collection'
                  : mapCampaignItemPathLengthToCampaignItemTypeDetailed(
                      path?.length || 0
                    );

              return (
                <SectionCard
                  view="list"
                  iconName="leaf"
                  key={id}
                  withMenu={hasPermission(['delete', 'update'])}
                  title={name}
                  description={description}
                  {...(type && {
                    status: {
                      children: mapCampaignItemTypeToText[typeDetailed],
                      status: mapCampaignItemTypeToStatus[typeDetailed],
                      icon: mapCampaignItemTypeToIcon[typeDetailed],
                    },
                  })}
                  onDelete={() => {
                    if (hasPermission(['delete'])) {
                      onDelete(id, name, item)();
                    }
                  }}
                  onSelect={() => {
                    navigateTo &&
                      navigate(
                        `${navigateTo(id)}${type === 'data_collection' ? '?form=yes' : ''}`
                      );
                  }}
                  onEdit={
                    hasPermission(['update'])
                      ? // TODO
                        () => openEditModal(item as any)
                      : undefined
                  }
                  onAddDescriptionCallback={() =>
                    // TODO
                    openEditModal(item as any)
                  }
                  hasActions={hasPermission(['update'])}
                />
              );
            })}
          </div>
        ) : (
          <div className="esg-campaign-entity__empty">
            <NoData />
            {!hasPermission(['update']) &&
              'Contact the admin for creating new elements'}
          </div>
        )}
      </StyledCampaignEntity>
    </BlockUI>
  );
};

export default CampaignEntity;
