import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementActivity = styled.div`
  ${flex('column')};

  flex: 1;
  gap: ${theme.sizes.SIZE_24};

  .esg-module-element-activity {
    &__contributors {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
      margin-top: ${theme.sizes.SIZE_20};

      gap: ${theme.sizes.SIZE_12};
    }
  }
`;

export { StyledModuleElementActivity };
