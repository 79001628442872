import { DataModuleEnum } from 'models';

import Icon from '../../Icon';

const DEFAULT_MODULES_OPTIONS = [
  {
    label: 'Text Box',
    value: DataModuleEnum.TEXT_BOX,
    icon: <Icon name="text" />,
  },
  {
    label: 'Switch',
    value: DataModuleEnum.SWITCH,
    icon: <Icon name="toggle-on" />,
  },
  {
    label: 'Upload',
    value: DataModuleEnum.UPLOAD,
    icon: <Icon name="upload" />,
  },
  {
    label: 'Input',
    value: DataModuleEnum.INPUT,
    icon: <Icon name="input-pipe" />,
  },
  {
    label: 'Choice',
    value: DataModuleEnum.CHOICE,
    icon: <Icon name="ballot-check" />,
  },
  {
    label: 'Checklist',
    value: DataModuleEnum.CHECKLIST,
    icon: <Icon name="square-check" />,
  },
  {
    label: 'Divider',
    value: DataModuleEnum.DIVIDER,
    icon: <Icon name="horizontal-rule" />,
  },
  {
    label: 'Integration',
    value: DataModuleEnum.INTEGRATION,
    icon: <Icon name="gears" />,
  },
  {
    label: 'Dropdown',
    value: DataModuleEnum.DROPDOWN,
    icon: <Icon name="caret-down" />,
  },
  { label: 'Table', value: DataModuleEnum.TABLE, icon: <Icon name="table" /> },
];

export { DEFAULT_MODULES_OPTIONS };
