import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/activity-logs/${apiVersion}`;

export const ACTIVITY_LOGS_ROUTES = {
  USER: (userId: string) => `${baseUrl}/user-roles/${userId}`,

  SESSION: (sessionId: string) =>
    `${baseUrl}/campaign-sessions/${sessionId}/activity-logs`,

  FORM: (orgSessionId: string, formId: string) =>
    `${baseUrl}/organisation-sessions/${orgSessionId}/forms/${formId}/activity-logs`,

  ELEMENT: (orgSessionId: string, formId: string, elementId: string) =>
    `${baseUrl}/organisation-sessions/${orgSessionId}/forms/${formId}/elements/${elementId}/activity-logs`,
};
