import {
  Button,
  ButtonProps,
  Checkbox,
  MenuRef,
  useCallbackRef,
  useEventListener,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { useHeightAnimation } from 'hooks';
import { CheckStatus, TreeNodeElement } from 'models';
import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { arePathsEqual } from '../../../../../utils';
import Icon from '../../../../Icon';
import ThreeDotMenu from '../../../ThreeDotMenu';
import { LeafIcon, NodeIcon } from './components';
import { StyledTreeNode } from './TreeNode.styled';

export type TreeNodeProps = {
  onCheck?: (
    node: TreeNodeElement,
    checked: TreeNodeElement['checked'],
    e: ChangeEvent<HTMLInputElement> | undefined
  ) => void;
  activeNodeId?: string;
  className?: string;
  level: number;
  node: TreeNodeElement & { canDelete?: boolean };
  withMenu?: boolean;
  showRootNode?: boolean;
  onDelete?: (node: TreeNodeElement) => void;
  onDuplicate?: (node: TreeNodeElement) => void;
  onAction?: (node: TreeNodeElement) => void;
};

const TreeNode: FC<TreeNodeProps> = (props) => {
  const {
    level,
    node,
    activeNodeId,
    withMenu = false,
    className,
    showRootNode = true,
    onCheck,
    onDelete,
    onDuplicate,
    onAction,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const menuRef = useRef<MenuRef>(null);
  const hoveringRef = useRef<boolean>(false);

  const [container, containerRef] = useCallbackRef<HTMLDivElement>();
  const [subFolders, subFoldersRef] = useCallbackRef<HTMLDivElement>();

  const [hovering, setHovering] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useHeightAnimation({ element: subFolders, isOpen: isExpanded });

  const handleToggle = useCallback(() => setIsExpanded((prev) => !prev), []);

  const handleCheck = (
    checked: boolean,
    e: ChangeEvent<HTMLInputElement> | undefined
  ) => {
    const checkStatus = checked ? CheckStatus.Checked : CheckStatus.Unchecked;
    onCheck?.(node, checkStatus, e);
  };

  const checkClosingMenu = useCallback(() => {
    if (hoveringRef.current) return;
    setHovering(false);
  }, []);

  const nodeMenuItems = useMemo(() => {
    const onDuplicateAction: ButtonProps[] = onDuplicate
      ? [
          {
            children: 'Duplicate',
            icon: <Icon name="copy" />,
            variant: 'ghost',
            onClick: (e: any) => {
              e.stopPropagation();

              onDuplicate?.(node);
              checkClosingMenu();
            },
          },
        ]
      : [];

    const onDeleteAction: ButtonProps[] =
      [undefined, true].includes(node.canDelete) && onDelete
        ? [
            {
              children: 'Delete',
              icon: <Icon name="trash-can" className="color-secondary" />,
              variant: 'delete-ghost',
              onClick: (e: any) => {
                e.stopPropagation();
                onDelete?.(node);
                checkClosingMenu();
              },
            },
          ]
        : [];

    return [...onDuplicateAction, ...onDeleteAction];
  }, [node, checkClosingMenu, onDelete, onDuplicate]);

  useEventListener({
    condition: withMenu,
    element: container,
    listener: 'mouseenter',
    callback: () => {
      hoveringRef.current = true;
      setHovering(true);
    },
  });

  useEventListener({
    condition: withMenu,
    element: container,
    listener: 'mouseleave',
    callback: () => {
      hoveringRef.current = false;

      if (menuRef.current?.open) return;
      setHovering(false);
    },
  });

  return (
    <StyledTreeNode
      className={classNames('esg-tree-node-component', className)}
      $level={level}
    >
      {(showRootNode ?? level > 1) && (
        <div className="esg-tree-node-component__base-node">
          {onCheck && (
            <Checkbox
              checked={[
                CheckStatus.Indeterminate,
                CheckStatus.Checked,
              ].includes(node.checked!)}
              indeterminate={node.checked === CheckStatus.Indeterminate}
              onChange={handleCheck}
            />
          )}
          <div
            tabIndex={node.disabled || node.action?.disabledStyle ? -1 : 0}
            ref={containerRef}
            onKeyDown={(e) => {
              if (node.disabled || node.action?.disabledStyle) return;

              if (['Enter', 'Space'].includes(e.code)) {
                node.to ? navigate(node.to) : handleToggle();
              }
            }}
            data-uniq-id={node.uniqName}
            className={classNames('esg-tree-node-component__icon', {
              'esg-tree-node-component__icon--leaf': !node.children,
              'esg-tree-node-component__icon--active':
                node.id === activeNodeId ||
                (node.to && arePathsEqual(node.to, location.pathname)),
              'esg-tree-node-component__icon--disabled':
                node.disabled || node.action?.disabledStyle,
              'esg-tree-node-component__icon--emphasized': node.emphasized,
            })}
            {...(!(node.disabled || node.action?.disabledStyle) && {
              onClick: () => {
                node.to ? navigate(node.to) : handleToggle();
              },
            })}
          >
            {node.children?.length ? (
              <NodeIcon
                node={node}
                isOpen={isExpanded}
                onClick={handleToggle}
              />
            ) : (
              <LeafIcon node={node} />
            )}
            {node.element ?? node.name}
            {withMenu && hovering && !!nodeMenuItems.length && (
              <ThreeDotMenu
                ref={menuRef}
                className="esg-tree-node-component__icon__menu"
                menuItems={nodeMenuItems}
                onClose={checkClosingMenu}
              />
            )}
            {node.action && (
              <Button
                className={classNames('esg-tree-node-component__icon__action', {
                  'esg-tree-node-component__icon__action--show-on-hover':
                    node.action.showOnHover,
                })}
                iconPosition="left"
                icon={<Icon name={node.action.icon} />}
                onClick={(e) => {
                  onAction?.(node);
                  node.action?.onClick(e);
                }}
              >
                {node.action.message}
              </Button>
            )}
          </div>
        </div>
      )}

      {!!node.children?.length && (
        <div
          ref={subFoldersRef}
          className="esg-tree-node-component__sub-folders"
        >
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              activeNodeId={activeNodeId}
              node={child}
              withMenu={withMenu}
              level={level + 1}
              onCheck={onCheck}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
              onAction={onAction}
            />
          ))}
        </div>
      )}
    </StyledTreeNode>
  );
};

export default memo(TreeNode);
