import {
  Modal,
  ModalProps,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, useCallback } from 'react';

import { FileUploadField, TextareaField } from '../../../../../../components';
import { handleUploadFieldErrors } from '../../../../../../components/_fields/FileUploadField/FileUploadField.component';
import { DEFAULT_ACCEPTED_FILE_TYPES } from '../../../../../../constants';
import { useUserPermissions } from '../../../../../../hooks';
import { PermissionSections, TreeNodeElement } from '../../../../../../models';
import { FormActions } from '../../../../../../styles';

export type DataCollectElementForm = {
  exclusionJustification: string;
  justificationFiles: File[];
};

export type ExcludeItemModalProps = PropsWithChildren<{
  onSubmit: ({
    exclusionJustification,
    justificationFiles,
  }: DataCollectElementForm) => Promise<void>;
  node: TreeNodeElement;
}> &
  ModalProps;

const VALIDATIONS = {
  exclusionJustification: validators.general.required(
    'Justification is required'
  ),
};

const ExcludeCampaignItemModal: FC<ExcludeItemModalProps> = ({
  className,
  defaultOpen = true,
  conditionallyControlled = true,
  onSubmit,
  node,
  onClose,
  ...rest
}) => {
  const [formRef, setFormRef] = useCallbackRef<FormRef>();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        ref={setFormRef}
        children={children}
        className={className}
        onSubmit={onSubmit}
      />
    ),
    [onSubmit, setFormRef]
  );

  const [FormButtons] = useFormButtons({
    cancelLabel: 'Cancel',
    submitLabel: 'Submit',
  });

  const hasFilesPermission = useUserPermissions(PermissionSections.FILE);

  return (
    <Modal
      {...rest}
      onClose={onClose}
      defaultOpen={defaultOpen}
      conditionallyControlled={conditionallyControlled}
      className={classNames(className, 'esg-exclude-item-modal')}
      childrenWrapper={formWrapper}
      footer={
        <FormActions>
          <FormButtons.Cancel variant="outline" onClick={() => onClose?.()} />
          <FormButtons.Submit
            type="submit"
            disabled={formRef?.disableSubmitButton || !formRef?.asyncFormValid}
          />
        </FormActions>
      }
      title={`Exclude ${node.name}`}
    >
      <FormField
        label="Why are you excluding this item?"
        component={TextareaField}
        name="exclusionJustification"
        placeholder="Justification"
        autoComplete="off"
        verticalResize
        required
        requiredLabel="Required"
        validate={VALIDATIONS.exclusionJustification}
      />

      {hasFilesPermission(['create']) && (
        <FormField
          component={FileUploadField}
          name="justificationFiles"
          acceptedFileTypesText="Accepted file types: "
          maxFileSize={100}
          multiple={true}
          maxFiles={3}
          dropZoneText="Drag & drop files here or "
          clickToUploadText="click to upload"
          uploadingText="Uploading"
          filesCountError="Maximum number of files exceeded. Max allowed is 3."
          fileTypeError="File type not allowed"
          fileSizeError="File size too large"
          alreadyExistError="File with this name already exists"
          acceptedFileTypes={DEFAULT_ACCEPTED_FILE_TYPES}
          handleErrors={handleUploadFieldErrors(formRef, 'justificationFiles')}
        />
      )}
    </Modal>
  );
};

export default ExcludeCampaignItemModal;
