import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { v4 as uuidv4 } from 'uuid';

export const generateModuleData = (
  selectedModule?: DataModuleEnum
): ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined =>
  selectedModule && {
    index: 0,
    id: uuidv4(),
    type: selectedModule,
    title: '',
    hasConditions: false,
  };
