import {
  applyRef,
  ModalProps,
  ModalRef,
  SelectOption,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormField, FormProps, FormRef } from '@faxi/web-form';
import { InputField, SelectField, TextareaField } from 'components/_fields';
import { InputFieldProps } from 'components/_fields/InputField/InputField.component';
import { SelectFieldProps } from 'components/_fields/SelectField/SelectField.component';
import { TextareaFieldProps } from 'components/_fields/TextareaField/TextareaField.component';
import {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { useUserPermissions, useValidations } from '../../../hooks';
import {
  PermissionSections,
  XBRLTaxonomy,
  XBRLTaxonomyFieldGroup,
} from '../../../models';
import Icon from '../../Icon';
import FormFooter from '../FormFooter';
import { StyledEntityFormModal } from './EntityFormModal.styled';

export type EntityFormFieldProps = {
  fieldProps?: Record<
    string,
    InputFieldProps & TextareaFieldProps & SelectFieldProps
  >;
};

// order of these dictates the order in UI
enum FIELD_NAMES {
  TYPE,
  NAME,
  CAMPAIGN,
  EMAIL,
  DESCRIPTION,
  ROLE,
  TAXONOMY_ID,
  TAXONOMY_GROUP,
}

type IFormFieldMapper = (
  initialData: { [key: string]: any } | undefined,
  fieldProps: EntityFormFieldProps['fieldProps'],
  validations: Record<string, any>,
  taxonomyId?: string,
  renderXBRLGroup?: boolean
) => Record<keyof typeof FIELD_NAMES, ReactNode>;

const formFieldMapper: IFormFieldMapper = (
  initialData,
  fieldProps,
  validations,
  taxonomyId,
  renderXBRLGroup
) => ({
  TYPE: (
    <FormField
      key="type"
      name="type"
      component={SelectField}
      placeholder="Type"
      autoComplete="off"
      options={[]}
      validate={!initialData ? validations.type : []}
      disabled={!!initialData}
      {...fieldProps?.['type']}
    />
  ),
  ROLE: (
    <FormField
      key="role"
      name="role"
      component={SelectField}
      placeholder="Role"
      autoComplete="off"
      options={[]}
      validate={validations.role}
      {...fieldProps?.['role']}
    />
  ),
  NAME: (
    <FormField
      key="name"
      name="name"
      component={InputField}
      autoComplete="off"
      placeholder="Name"
      validate={validations.name}
      {...fieldProps?.['name']}
    />
  ),
  CAMPAIGN: (
    <Fragment key="campaign">
      <FormField
        name="campaign"
        component={SelectField}
        options={[]}
        placeholder="Choose Campaign"
        autoComplete="off"
        disabled={!!initialData}
        validate={validations.campaign}
        {...fieldProps?.['campaign']}
        aria-describedby="campaign-description"
        searchable
      />
      <div id="campaign-description" className="campaign-description">
        <Icon name="circle-info" />
        <div>
          The selected campaign will be attached to this session and will be
          propagated to companies and subcompanies.
        </div>
      </div>
    </Fragment>
  ),
  EMAIL: (
    <FormField
      key="email"
      name="email"
      component={InputField}
      autoComplete="off"
      placeholder="Email"
      validate={validations.email}
      {...fieldProps?.['email']}
    />
  ),
  DESCRIPTION: (
    <FormField
      key="description"
      name="description"
      component={TextareaField}
      autoComplete="off"
      max={150}
      placeholder="Description"
      validate={validations.description}
      {...fieldProps?.['description']}
    />
  ),
  TAXONOMY_ID: (
    <FormField
      key="taxonomyId"
      name="taxonomyId"
      component={SelectField}
      autoComplete="off"
      placeholder="Select XBRL Taxonomy"
      options={[]}
      hasClearAction
      clearTitle="Unselect"
      disabled={!!taxonomyId}
      validate={validations.taxonomyId}
      searchable
      {...fieldProps?.['taxonomyId']}
    />
  ),
  TAXONOMY_GROUP: renderXBRLGroup && (
    <div>
      <FormField
        key="taxonomyFieldGroupId"
        name="taxonomyFieldGroupId"
        component={SelectField}
        autoComplete="off"
        placeholder="Select Taxonomy Field Group"
        options={[]}
        hasClearAction
        clearTitle="Unselect"
        disabled={initialData?.taxonomyFieldGroupId || !taxonomyId}
        searchable
        {...fieldProps?.['taxonomyFieldGroupId']}
      />

      {!initialData?.taxonomyFieldGroupId && (
        <div className="esg-helper-text">
          {!taxonomyId
            ? 'This field is available only when a taxonomy is assigned to the campaign.'
            : "Until a taxonomy group is selected, you won't be able to tag fields and the taxonomy will not be valid."}
        </div>
      )}
    </div>
  ),
});

export type EntityFormModalProps<T extends Record<string, any>> =
  PropsWithChildren<
    ModalProps &
      Omit<FormProps<T>, 'onSubmit'> & {
        onSubmit: (
          data: T,
          e?: React.FormEvent<Element>
        ) => Promise<FormRef | void>;
        initialData?: { [key: string]: any };
        fieldsConfiguration: Record<keyof typeof FIELD_NAMES, boolean>;
      } & EntityFormFieldProps & { taxonomyId?: string }
  >;

const EntityFormModal: ForwardRefRenderFunction<
  ModalRef,
  EntityFormModalProps<Record<string, any>>
> = <T extends Record<string, any>>(
  props: EntityFormModalProps<T>,
  ref: Ref<ModalRef>
) => {
  const {
    children,
    initialData,
    onSubmit,
    fieldProps,
    fieldsConfiguration = {
      TYPE: false,
      NAME: true,
      DESCRIPTION: true,
      EMAIL: false,
      ROLE: false,
      CAMPAIGN: false,
      TAXONOMY_ID: false,
      TAXONOMY_GROUP: false,
    },
    taxonomyId: pTaxonomyId,
    loading,
    ...rest
  } = props;

  const { validations } = useValidations();

  const modalRef = useRef<ModalRef>();
  const [canFetchTaxonomies, setCanFetchTaxonomies] = useState(false);

  const taxonomyId = useMemo(
    () => pTaxonomyId || initialData?.taxonomyId,
    [initialData?.taxonomyId, pTaxonomyId]
  );

  const {
    data: { data: taxonomies = [] } = {},
    isLoading: isLoadingTaxonomies,
    isValidating: isValidatingTaxonomies,
  } = useSWR<{ data: XBRLTaxonomy[] }>(
    canFetchTaxonomies &&
      fieldsConfiguration.TAXONOMY_ID &&
      API_ROUTES.CAMPAIGNS.XBRL_TAXONOMIES
  );

  const taxonomiesOptions = useMemo<SelectOption[]>(
    () =>
      taxonomies.map(({ id, meta: { name } = {} }) => ({
        value: id,
        label: name,
      })),
    [taxonomies]
  );

  const initialDataUsed = useMemo(
    () =>
      initialData
        ? {
            ...(fieldsConfiguration.NAME && { name: initialData.name }),
            description: initialData.description,
            ...(fieldsConfiguration.TYPE && { type: initialData.type }),
            ...(fieldsConfiguration.EMAIL && { email: initialData.email }),
            ...(fieldsConfiguration.ROLE && { role: initialData.role }),
            ...(fieldsConfiguration.CAMPAIGN && {
              campaign: initialData.campaign,
            }),
            ...(fieldsConfiguration.DESCRIPTION && {
              description: initialData.description,
            }),
            ...(fieldsConfiguration.TAXONOMY_ID && {
              // TODO: check this again, unselect does not work in `Select` component...
              taxonomyId: taxonomyId || '',
            }),
            ...(fieldsConfiguration.TAXONOMY_GROUP && {
              // TODO: if there is a problem above, we have the same issue here
              taxonomyFieldGroupId: initialData.taxonomyFieldGroupId || '',
            }),
          }
        : undefined,
    [fieldsConfiguration, initialData, taxonomyId]
  );

  const hasTaxonomyPermissions = useUserPermissions(
    PermissionSections.TAXONOMY
  );

  const {
    data: { data: xbrlFieldsGroup = [] } = {},
    isLoading: isLoadingFieldsGroup,
    isValidating: isValidatingFieldsGroup,
  } = useSWR<{ data: XBRLTaxonomyFieldGroup[] }>(
    hasTaxonomyPermissions(['read']) &&
      taxonomyId &&
      API_ROUTES.CAMPAIGNS.XBRL_TAXONOMIES_FIELD_GROUPS(taxonomyId)
  );

  const [form, formRef] = useCallbackRef<FormRef>();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        ref={formRef}
        children={children}
        className={className}
        initialData={initialDataUsed}
        onSubmit={async (data, e) => {
          try {
            await onSubmit(data, e);
            modalRef.current?.close();
            setCanFetchTaxonomies(false);
          } catch (e) {
            console.error(e);
          }
        }}
      />
    ),
    [formRef, initialDataUsed, onSubmit]
  );

  const selectedEntityType = useMemo(
    () => form?.fields?.type?.value,
    [form?.fields?.type?.value]
  );

  return (
    <StyledEntityFormModal
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      loading={loading}
      onOpen={() => setCanFetchTaxonomies(true)}
      onClose={() => setCanFetchTaxonomies(false)}
      closeOnEscape
      childrenWrapper={formWrapper}
      className="esg-entity-form-modal"
      conditionallyControlled={false}
      footer={<FormFooter modal={modalRef as RefObject<ModalRef>} />}
      {...rest}
    >
      {children}

      {(Object.values(FIELD_NAMES) as (keyof typeof FIELD_NAMES)[]).map(
        (name) => {
          const component = formFieldMapper(
            initialData,
            {
              ...fieldProps,
              taxonomyId: {
                options: taxonomiesOptions,
                loading: isLoadingTaxonomies || isValidatingTaxonomies,
              },
              taxonomyFieldGroupId: {
                options: xbrlFieldsGroup.map(({ id, label, name }) => ({
                  value: id,
                  label: label || name,
                })),
                loading: isLoadingFieldsGroup || isValidatingFieldsGroup,
              },
            },
            validations,
            taxonomyId,
            selectedEntityType === 'data_collection'
          )[name];

          return fieldsConfiguration[name] ? component : null;
        }
      )}
    </StyledEntityFormModal>
  );
};

export default forwardRef(EntityFormModal) as <T extends Record<string, any>>(
  props: EntityFormModalProps<T> & { ref?: React.Ref<ModalRef> }
) => ReactElement;
