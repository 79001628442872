import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementDataConsolidation = styled.div`
  ${fontSize(theme.fontSizes.FONT_14)};
  ${flex('column', 'flex-start')};
  gap: ${theme.sizes.SIZE_32};
`;
export { StyledModuleElementDataConsolidation };
