import { APP_URI } from 'config';
import { Navigate, RouteObject } from 'react-router-dom';

import { OrganisationManagement } from '../../../components';
import OrganisationsWrapper from './layouts/OrganisationsWrapper';

export default [
  {
    path: '',
    element: <OrganisationsWrapper />,
    children: [
      { path: '', element: <OrganisationManagement /> },
      {
        path: APP_URI.ORGANISATIONS_ORGANISATION,
        element: <OrganisationManagement />,
      },
    ],
  },
  { path: '*', element: <Navigate to="" /> },
] as RouteObject[];
