import { Button, GlowScroll } from '@faxi/web-component-library';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import {
  BasicTreeView,
  CollapsibleSidePanel,
} from '../../../../../../../components';
import Icon from '../../../../../../../components/Icon';
import { APP_URI } from '../../../../../../../config';
import { BlockUI } from '../../../../../../../helpers';
import { Session, TreeNodeElement } from '../../../../../../../models';
import { AssignmentAPI } from '../../../../../../../models/Assignments';
import CompanyTreeNode from '../../../../../../../pages/Sessions/components/CompanyTreeNode';
import { StyledUserAssignmentsByOrganisation } from './UserAssignmentsByOrganisation.styled';

export type UserAssignmentsByOrganisationProps = PropsWithChildren<{}>;

const createTreeWithCompanyNodes =
  (
    path: string,
    organisation: AssignmentAPI['organisation'],
    assignmentId: string
  ) =>
  (node: AssignmentAPI['campaign']): AssignmentAPI['campaign'] => {
    const { id, name, progress } = node;

    node.element = (
      <CompanyTreeNode
        id={id}
        name={node.type === 'campaign' ? organisation.name : name}
        progress={progress}
      />
    );

    if (node.type === 'campaign') {
      node.to = `${path}/campaign-browser/${organisation.id}`;
    } else if (path.includes('/item')) {
      node.to = `${path.substring(0, path.lastIndexOf('/') + 1)}${node.id}`;
    } else {
      node.to = `${path}/item/${node.id}`;
    }

    node.assignmentId = assignmentId;
    node.iconName = node?.children?.length ? 'leaf' : 'clipboard-list';

    node.children = node?.children?.map(
      createTreeWithCompanyNodes(node.to, organisation, assignmentId)
    );

    return node;
  };

const UserAssignmentsByOrganisation: FC<
  UserAssignmentsByOrganisationProps
> = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const navigate = useNavigate();

  const {
    data: { data: sessions = [] } = {},
    isLoading: isLoadingSessions,
    error: errorSessions,
  } = useSWR<{ data: Session[] }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ASSIGNMENTS_BY_SESSION()
  );

  const {
    data: { data: assignments = [] } = {},
    isLoading: isLoadingAssignments,
    error: errorAssignments,
  } = useSWR<{ data: AssignmentAPI[] }>(
    sessionId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ASSIGNMENTS_BY_ORGANISATION(sessionId)
  );

  const organisationsTrees: (TreeNodeElement & { assignmentId: string })[] =
    useMemo(
      () =>
        assignments.map(({ id, organisation, campaign }) =>
          createTreeWithCompanyNodes(
            `/sessions/${sessionId}`,
            organisation,
            id
          )(campaign)
        ),
      [assignments, sessionId]
    );

  const sessionTree: TreeNodeElement = useMemo(
    () => ({
      id: 'root',
      name: 'Root',
      children: sessions.map(({ id, name }) => ({
        id,
        name,
        to: `/assignments/${id}`,
        iconName: 'chart-bar',
      })),
    }),
    [sessions]
  );

  return (
    <StyledUserAssignmentsByOrganisation className="esg-user-assignments-by-organisation">
      <CollapsibleSidePanel
        expandDirection="right"
        className="esg-user-assignments-by-organisation__side-panel"
      >
        <div className="esg-user-assignments-by-organisation__side-panel__content">
          <BlockUI
            loading={isLoadingSessions}
            fallbackCondition={!sessions.length}
            error={errorSessions}
          >
            <Button
              variant="ghost"
              onClick={() => navigate(APP_URI.ASSIGNMENTS)}
              icon={<Icon name="chevron-left" />}
            >
              Back
            </Button>

            <p>Sessions</p>

            <BasicTreeView
              withMenu
              data={sessionTree}
              showRootNode={false}
              activeNodeId={sessionId}
            />
          </BlockUI>
        </div>
      </CollapsibleSidePanel>

      <BlockUI
        loading={isLoadingAssignments}
        fallbackCondition={!assignments.length}
        error={errorAssignments}
      >
        <GlowScroll variant="gray">
          <div className="esg-user-assignments-by-organisation__trees">
            {organisationsTrees.map((organisationTree) => (
              <BasicTreeView
                key={organisationTree.assignmentId}
                data={organisationTree}
              />
            ))}
          </div>
        </GlowScroll>
      </BlockUI>
    </StyledUserAssignmentsByOrganisation>
  );
};

export default UserAssignmentsByOrganisation;
