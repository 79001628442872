import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUploadPreview = styled.div`
  ${flex('column', 'space-between')};
  ${fontSize(theme.fontSizes.FONT_14)};
  gap: ${theme.sizes.SIZE_20};
  padding: 2px 0;
  width: 100%;

  .esg-upload-preview {
    &__main,
    &__conditional-el {
      ${flex('row', 'space-between', 'center')};
      width: 100%;
    }

    &__main {
      &__title {
        font-size: ${theme.fontSizes.FONT_16};
      }
    }

    &__file-input {
      width: ${theme.sizes.SIZE_256};

      label {
        justify-content: center;
      }
    }
  }

  .button {
    width: ${theme.sizes.SIZE_224};
  }
`;

const StyledConditionalElement = styled.div`
  overflow: hidden;
`;

export { StyledConditionalElement, StyledUploadPreview };
