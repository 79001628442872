import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDropdownPreview = styled.div`
  ${flex('column', 'space-between', 'center')};
  gap: ${theme.sizes.SIZE_20};
  width: 100%;

  .esg-dropdown-preview {
    &__content {
      ${flex('row', 'space-between', 'center')};
      gap: ${theme.sizes.SIZE_20};
      width: 100%;

      &__title {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_12};
        font-size: ${theme.fontSizes.FONT_16};
        font-weight: 500;

        span {
          ${flex('row', 'center', 'center')};
          flex: 0 0 ${theme.sizes.SIZE_16};
        }
      }

      &__fields {
        ${flex('column')};
        gap: ${theme.sizes.SIZE_20};
      }
    }

    &__conditional-el {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_16};
      width: 100%;
    }
  }
`;

export { StyledDropdownPreview };
