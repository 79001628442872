import { useModalUtilities, useUtilities } from '@faxi/web-component-library';
import { useCallback } from 'react';
import { mutate } from 'swr';

import useMutation from '../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../api/routes';
import { IDataModule } from '../../../models';
import authBus from '../../../modules/authBus';

const useLock = ({
  organisationSessionId,
  campaignItemId = '',
  setAsActiveModule,
}: {
  organisationSessionId: string;
  campaignItemId?: string;
  setAsActiveModule: (module: IDataModule) => void;
}) => {
  const { showSnackBar } = useUtilities();
  const {
    open: isOpen,
    openModal: openLockFieldModal,
    closeModal: closeLockFieldModal,
  } = useModalUtilities();

  const { trigger: toggleLockingField } = useMutation(
    'toggle-locking-fields',
    {
      onSuccess: () =>
        showSnackBar({
          text: 'Field lock status changed successfully!',
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  const handleToggleLockField = useCallback(
    async (id: string, isLocked: boolean, isLockPopupDisabled: boolean) => {
      await toggleLockingField({
        url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_TOGGLE_LOCK(
          organisationSessionId,
          campaignItemId!,
          id
        ),
        method: 'POST',
        data: { isLocked, isLockPopupDisabled },
      });

      authBus.broadcastEvent('activity-logs-start-poll');

      mutate(
        API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
          organisationSessionId,
          campaignItemId
        )
      );
    },
    [campaignItemId, organisationSessionId, toggleLockingField]
  );

  const handleLockClick = useCallback(
    (module: IDataModule) => {
      if (module.isLockPopupDisabled) {
        handleToggleLockField(
          module.id,
          !module.isLocked,
          module.isLockPopupDisabled
        );
      } else {
        setAsActiveModule(module);
        openLockFieldModal();
      }
    },
    [handleToggleLockField, openLockFieldModal, setAsActiveModule]
  );

  return {
    handleLockClick,
    handleToggleLockField,
    closeLockFieldModal,
    isOpen,
  };
};

export default useLock;
