import { ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { FormFooter, SwitchField, XBRLSelectionFields } from 'components';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import {
  CampaignItem,
  CheckListModuleConfig,
  DataModuleEnum,
  FormulaModuleConfig,
  IDataModule,
  ModuleConfig,
  ModuleConfigMapperType,
  ModuleConfigType,
} from 'models';
import {
  FC,
  Fragment,
  PropsWithChildren,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import storageService from '../../../../services/storageService';
import { useModulesStore } from '../../../../store/modulesStore';
import { useCampaignItemProvider } from '../../context/CampaignItem';
import { generateModulesFromCampaignItem } from '../../context/FormBuilder/FormBuilder.provider';
import { mapDataModuleTypeToIcon, mapDataModuleTypeToTitle } from '../../utils';
import { findModule } from '../Dnd/utils';
import { StyledModuleConfiguration } from './ModuleConfiguration.styled';

const CONFIG_MODULE_TEXT: Partial<Record<DataModuleEnum, string>> = {
  [DataModuleEnum.INPUT]: 'Set up input field configuration',
  [DataModuleEnum.CHOICE]: 'Set up choices with options',
  [DataModuleEnum.CHECKLIST]: 'Set up checklist with options',
  [DataModuleEnum.DROPDOWN]: 'Set up options for dropdown',
  [DataModuleEnum.SWITCH]: 'Set up options for switch',
  [DataModuleEnum.INTEGRATION]: 'Set up external integration',
  [DataModuleEnum.TEXT_BOX]: 'Set up format of text',
  [DataModuleEnum.FORMULA]: 'Set up the formula',
};

export type ModuleConfigurationProps<
  T,
  S extends DataModuleEnum,
> = PropsWithChildren<ModalProps> & {
  moduleConfig: ModuleConfig<T, S>;
  onSubmit?: (config: ModuleConfig<ModuleConfigType, S>) => void;
  configurableModules: ModuleConfigMapperType;
};

export type ModuleFormFooterProps<T, S extends DataModuleEnum> = {
  moduleConfig: ModuleConfig<T, S>;
  modalRef: RefObject<ModalRef>;
};

function ModuleFormFooter<
  T extends Record<string, any>,
  S extends DataModuleEnum,
>({ moduleConfig, modalRef }: ModuleFormFooterProps<T, S>) {
  const { dataCollection } = useCampaignItemProvider();

  const { localFormModules } = useModulesStore();

  const initialModuleAPI = useMemo(
    () =>
      dataCollection &&
      findModule(
        moduleConfig.id,
        generateModulesFromCampaignItem(
          (dataCollection as CampaignItem)?.elements
        )
      ).module,
    [dataCollection, moduleConfig]
  );

  const formModule = useMemo(
    () => findModule(moduleConfig.id, localFormModules).module,
    [localFormModules, moduleConfig.id]
  );

  const isModuleChanged = useMemo(
    () => !isEqual(formModule, initialModuleAPI),
    [formModule, initialModuleAPI]
  );

  return (
    <FormFooter
      modal={modalRef}
      submitIcon="check"
      submitLabel="Done"
      cancelLabel="Cancel"
      isModuleChanged={isModuleChanged}
    />
  );
}

function ModuleConfiguration<
  T extends Record<string, any>,
  S extends DataModuleEnum,
>(props: ModuleConfigurationProps<T, S>) {
  const { moduleConfig, onSubmit, configurableModules, ...rest } = props;

  const { dataCollection } = useCampaignItemProvider();

  const { campaign = '' } = useParams<{ campaign: string }>();

  // TODO: think about better way of getting formula properties
  //
  const { data: { data: dataCollectionElements } = { data: [] } } = useSWR<{
    data?: (IDataModule & { campaignId: string; campaignItemId: string })[];
  }>(
    moduleConfig.type === DataModuleEnum.FORMULA &&
      API_ROUTES.CAMPAIGNS.CAMPAIGN_DATA_COLLECTION_ELEMENTS(campaign)
  );

  const { type } = moduleConfig;
  const modalRef = useRef<ModalRef>(null);

  const initialData = useMemo(
    () => ({
      ...moduleConfig.config,
      ...(moduleConfig?.config?.minDate && {
        minDate: dayjs(moduleConfig.config.minDate),
      }),
      ...(moduleConfig?.config?.maxDate && {
        maxDate: dayjs(moduleConfig.config.maxDate),
      }),
    }),
    [moduleConfig]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={async (config: ModuleConfigType, e) => {
          e.stopPropagation();
          const storageModules =
            storageService.getItem<
              ModuleConfig<ModuleConfigType, DataModuleEnum>[]
            >('MODULES');

          const moduleConditionalElements = findModule(
            moduleConfig.id,
            storageModules
          ).module?.conditionalElements;

          if (
            'isDataLineageRequired' in config &&
            config.isDataLineageRequired === ''
          ) {
            delete config.isDataLineageRequired;
          }

          let finalConfig = config;

          if (moduleConfig.type === DataModuleEnum.CHECKLIST) {
            finalConfig = {
              ...config,
              defaultValue: (config as CheckListModuleConfig).defaultValue?.map(
                ({ value }) => value
              ),
            } as ModuleConfigType;
          }

          if (moduleConfig.type === DataModuleEnum.FORMULA) {
            finalConfig = {
              ...config,
              formula: ((config as FormulaModuleConfig).formula || []).map(
                (formulaItem) => {
                  const dataCollectionElement = dataCollectionElements?.find(
                    ({ title }) => title === formulaItem.value
                  );

                  const { id: dataCollectionElementId, campaignItemId } =
                    dataCollectionElement ?? {};

                  return {
                    ...formulaItem,
                    campaignItemId,
                    dataCollectionElementId,
                  };
                }
              ),
            };
          }

          onSubmit?.({
            ...moduleConfig,
            config: finalConfig,
            conditionalElements: moduleConditionalElements || {},
          });
          modalRef.current?.close();
        }}
      />
    ),
    [initialData, moduleConfig, onSubmit, dataCollectionElements]
  );

  const ModuleConfig = useMemo(
    () =>
      configurableModules[type] as FC<
        ModuleConfig<T, typeof type> & { initialData: Record<string, any> }
      >,
    [type, configurableModules]
  );

  return (
    <StyledModuleConfiguration
      ref={modalRef}
      hasCloseButton={false}
      title={`${mapDataModuleTypeToTitle[type]}: ${moduleConfig.title}`}
      icon={<Icon name={mapDataModuleTypeToIcon[type]} />}
      className="esg-module-configuration"
      childrenWrapper={formWrapper}
      renderAsPortal
      defaultOpen={false}
      footer={
        <ModuleFormFooter moduleConfig={moduleConfig} modalRef={modalRef} />
      }
      {...rest}
    >
      <p className="esg-module-configuration__title">
        {CONFIG_MODULE_TEXT[type]}
      </p>

      <ModuleConfig {...moduleConfig} initialData={initialData} />

      {/* input config has two steps, thus in this case xbrl selection is deffered to BaseInputFieldConfiguration */}
      {moduleConfig.type !== DataModuleEnum.INPUT && (
        <Fragment>
          {![DataModuleEnum.TEXT_BOX, DataModuleEnum.FORMULA].includes(
            moduleConfig.type
          ) && (
            <FormField
              component={SwitchField}
              label="Is Data Lineage required?"
              className="esg-module-configuration__data-lineage-required"
              name="isDataLineageRequired"
            />
          )}
          <XBRLSelectionFields
            className="esg-module-configuration__xbrl"
            taxonomyFieldGroupId={dataCollection?.taxonomyFieldGroupId}
            selectedPackage={moduleConfig.config?.xbrl_package}
          />
        </Fragment>
      )}
    </StyledModuleConfiguration>
  );
}

export default ModuleConfiguration;
