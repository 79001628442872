import { GlowScroll, Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useInfiniteFetch } from '../../../../api';
import { API_ROUTES } from '../../../../api/routes';
import UserImage from '../../../../components/_atoms/UserImage';
import InfiniteScrollContainer from '../../../../components/_molecules/InfiniteScrollContainer';
import { FEATURES } from '../../../../config';
import { BlockUI } from '../../../../helpers';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { ActivityLog } from '../../../../models';
import ValueChangeLog from '../../../../routes/subrouters/Sessions/components/ValueChangeLog';
import {
  generateActivityLogName,
  isLogValid,
  isStatusChangeAction,
  isValueChangeAction,
} from '../../../../routes/subrouters/Sessions/utils';
import { formatRelativeDate, generateFullName } from '../../../../utils';
import { LOG_ACTION_MAPPER } from '../../constants';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import { reduceLogsByDate } from '../../utils';
import SessionHeader from '../SessionHeader';
import StatusChangeLog from '../StatusChangeLog';
import { StyledSessionActivity } from './SessionActivity.styled';

dayjs.extend(isToday);

const mapActivitiesToTableData = (activities: ActivityLog[]) =>
  activities.map(
    (
      {
        performedBy: { firstName, lastName, img },
        action,
        type,
        title,
        timestamp,
        newConfig,
        oldValue,
        newValue,
        oldConfig,
      },
      index
    ) => ({
      id: index,
      user: (
        <div className="esg-session-activity__user">
          <div className="esg-session-activity__user__image-name">
            <UserImage src={img} name={generateFullName(firstName, lastName)} />
            <span>{generateFullName(firstName, lastName)}</span>
          </div>
        </div>
      ),
      name: generateActivityLogName(title, type),
      action: LOG_ACTION_MAPPER[action],
      change:
        isValueChangeAction(action) && type ? (
          <ValueChangeLog
            type={type}
            config={newConfig}
            oldValue={oldValue}
            newValue={newValue}
            oldConfig={oldConfig}
          />
        ) : isStatusChangeAction(action) ? (
          <StatusChangeLog oldStatus={oldValue} newStatus={newValue} />
        ) : (
          '-'
        ),
      time: dayjs(timestamp).format('HH:mm'),
    })
  );

const SessionActivity: FC = () => {
  const { sessionId = '' } = useParams();

  const navigate = useNavigate();

  const { isLoading, nextPage, isValidating, setSize, items } =
    useInfiniteFetch<ActivityLog>(
      FEATURES.SESSION_ACTIVITY_LOGS
        ? API_ROUTES.ACTIVITY_LOGS_ROUTES.SESSION(sessionId)
        : ''
    );

  const { isFetching, setContainerRef } = useInfiniteScroll<HTMLDivElement>({
    hasNextPage: Boolean(nextPage),
    isLoading: isValidating,
    loadMore: () => setSize((prev) => prev + 1),
  });

  const { sessionCompanies } = useSessionBrowser();

  const handleRowClick = useCallback(
    (activityLog: ActivityLog) => {
      const {
        path: { organisationSessionId, dataCollectionElementId, elementId },
      } = activityLog;

      const company = sessionCompanies?.find(
        (company) => company.organisationSessionId === organisationSessionId
      );

      if (!company || !dataCollectionElementId) return;

      navigate(
        `/sessions/${sessionId}/campaign-browser/${company.id}/item/${dataCollectionElementId}?form=yes${elementId ? `&elementId=${elementId}` : ''}`
      );
    },
    [navigate, sessionCompanies, sessionId]
  );

  const logs = useMemo(() => reduceLogsByDate(items), [items]);

  return (
    <BlockUI loading={isLoading} fallbackCondition={!items.length}>
      <SessionHeader heading="Activity" />
      <GlowScroll variant="gray">
        <InfiniteScrollContainer ref={setContainerRef} isFetching={isFetching}>
          <StyledSessionActivity className="esg-session-activity">
            {logs?.map(({ activityLogs, date }, index) => (
              <div className="esg-session-activity__log" key={index}>
                <div className="esg-session-activity__log__date">
                  {formatRelativeDate(date)}
                </div>
                <Table
                  onRowClick={(index) => handleRowClick(activityLogs[index])}
                  tableData={mapActivitiesToTableData(
                    activityLogs.filter(isLogValid)
                  )}
                  tableId="esg-notifications"
                  disableSort
                  excludeColumns={['id']}
                  translationKeys={{
                    id: 'ID',
                    user: 'User',
                    name: 'Module Name',
                    action: 'Action',
                    change: 'Change',
                    time: 'Time',
                  }}
                />
              </div>
            ))}
          </StyledSessionActivity>
        </InfiniteScrollContainer>
      </GlowScroll>
    </BlockUI>
  );
};

export default SessionActivity;
