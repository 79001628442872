import { GlowScroll } from '@faxi/web-component-library';
import api from 'api';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { FC, useMemo } from 'react';

import { API_ROUTES } from '../../api/routes';
import { CampaignItem } from '../../models';
import { useCampaignProvider } from '../../providers/Campaign';
import CampaignEntity from './components/CampaignEntity';

const navigateTo = (id: string) =>
  APP_URI.CAMPAIGNS_CAMPAIGN.replace(':campaign', id);

const Campaigns: FC = () => {
  const { isMutating } = api.useMutation(API_ROUTES.CAMPAIGNS.BASE());

  const { isMutatingRootCampaign: isEditingCampaign } = useCampaignProvider();

  const { campaigns, isLoadingCampaigns, errorCampaigns } =
    useCampaignProvider();

  const rootCampaignItem = useMemo<CampaignItem>(
    () => ({ children: campaigns || [], type: 'campaign' }) as CampaignItem,
    [campaigns]
  );

  return (
    <BlockUI loading={isLoadingCampaigns} error={!!errorCampaigns}>
      <GlowScroll variant="gray">
        <CampaignEntity
          navigateTo={navigateTo}
          campaignItem={rootCampaignItem as CampaignItem}
          entityType="campaign"
          modalProps={{ loading: isEditingCampaign || isMutating }}
        />
      </GlowScroll>
    </BlockUI>
  );
};

export default Campaigns;
