import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledInputPreview = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};
  gap: ${theme.sizes.SIZE_16};
  width: 100%;

  .esg-input-preview {
    &__title {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_12};
      font-size: ${theme.sizes.SIZE_16};
      font-weight: 500;
    }

    &__tooltip {
      ${flex('row', 'center', 'center')};
      flex: 0 0 ${theme.sizes.SIZE_16};

      .wcl-icon,
      svg {
        ${size('100%')};
      }
    }
  }
  .input,
  .esg-textarea-field,
  .wcl-dropdown {
    flex: 0 1 ${theme.sizes.SIZE_320};
    align-items: flex-end;
  }
  .input__container {
    input {
      width: ${theme.sizes.SIZE_320};
    }
  }
`;

export { StyledInputPreview };
