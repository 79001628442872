import { Organisation, TreeNodeElement } from 'models';
import { createContext } from 'react';
import { KeyedMutator } from 'swr';

export type OrganisationContextProps = {
  loading: boolean;
  error?: boolean;
  activeOrganisation?: Organisation;
  organisationTree?: TreeNodeElement;
  deleteOrganisation: (
    org: Organisation,
    trigger?: HTMLButtonElement,
    callback?: () => void
  ) => Promise<void>;
  mutateOrganisations: KeyedMutator<{ data: Organisation }>;
};

export default createContext<OrganisationContextProps>({
  error: false,
  loading: false,
  activeOrganisation: undefined,
  deleteOrganisation: () => new Promise(() => {}),
  mutateOrganisations: () => new Promise(() => {}),
});
