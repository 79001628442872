import {
  Button,
  Divider,
  Expander,
  MenuRef,
  useEffectOnceWhen,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import useMutation from 'api/hooks/useMutation';
import { API_ROUTES } from 'api/routes';
import {
  ApprovalProgressBar,
  AssignedContributor,
  DeadlineExpander,
  ViewGuard,
} from 'components';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { BlockUI } from 'helpers';
import { useContributors } from 'hooks';
import {
  ApiData,
  AssignmentType,
  AssignTarget,
  CampaignItem,
  OrganisationSession,
  PermissionSections,
} from 'models';
import { useSelectedSession } from 'pages/Sessions/context/SelectedSession';
import useSessionBrowser from 'pages/Sessions/hooks/useSessionBrowser';
import { FC, Fragment, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FEATURES } from '../../../../../config';
import { DATE_FORMAT } from '../../../../../constants';
import { FormProgressEnum } from '../../../../../models/FormProgressStatus';
import DueReminderFlag from '../../../../components/ProfileReminders/components';
import AssignContributors from '../AssignContributors';
import FormActivity from '../FormActivity';
import FormStatus from '../FormStatus';
import StatusProgress from '../StatusProgress';
import ViewAllContributorsModal from '../ViewAllContributorsModal';
import { StyledCampaignBrowserSidePanel } from './CampaignBrowserSidePanel.styled';

type CampaignBrowserSidePanelProps = { organisationSessionId: string };

const CampaignBrowserSidePanel: FC<CampaignBrowserSidePanelProps> = ({
  organisationSessionId,
}) => {
  const { campaignItemId = '' } = useParams();

  const { search } = useLocation();

  const selectedSession = useSelectedSession();

  const {
    selectedCampaignItem,
    isLoading: isLoadingCampaignItem,
    selectedCompany,
    mutate,
  } = useSessionBrowser(false);

  const { showSnackBar } = useUtilities();

  const { closeModal, openModal, open } = useModalUtilities();

  const menuRef = useRef<MenuRef>(null);

  const isDataCollectionItem = useMemo(
    () => new URLSearchParams(search).get('form') === 'yes',
    [search]
  );

  const {
    isAssignmentsLoading,
    assignees,
    assignContributors,
    contributors,
    nodeMenuItems,
    updateAssignees,
  } = useContributors({ organisationSessionId });

  const { trigger: triggerSetDeadline, error } = useMutation<
    ApiData<OrganisationSession>
  >(
    campaignItemId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES[
        `ORGANISATION_SESSION_${isDataCollectionItem ? 'FORM' : 'CAMPAIGN_ITEM'}`
      ](organisationSessionId, campaignItemId),
    {
      onSuccess: (data) =>
        showSnackBar({
          text: `Deadline has been successfully ${data ? 'set' : 'cleared'} for the ${isDataCollectionItem ? 'form' : 'campaign item'}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  const modalTitleAndButtonText = useMemo(() => {
    if (!campaignItemId) {
      return {
        btnText: 'Assign to Organisation',
        assignTarget: AssignTarget.Organisation,
      };
    }
    if (selectedCampaignItem?.isForm) {
      return { btnText: 'Assign to Form', assignTarget: AssignTarget.Form };
    }

    return {
      btnText: 'Assign to Campaign Item',
      assignTarget: AssignTarget.CampaignItem,
    };
  }, [campaignItemId, selectedCampaignItem]);

  useEffectOnceWhen(() => {
    if (!selectedCampaignItem?.deadline) return;

    showSnackBar(
      {
        text: 'Reminder',
        variant: 'alert',
        actionButtonText: 'Close',
        className: 'esg-reminder-alert',
        additionalContent: (
          <div className="esg-reminder-alert__additional-content">
            <div>
              Your assignment to <em>{selectedCampaignItem.name}</em> is due on{' '}
              <strong>
                {dayjs(selectedCampaignItem.deadline).format(DATE_FORMAT)}
              </strong>
            </div>
            <DueReminderFlag
              dueDate={new Date(selectedCampaignItem.deadline)}
            />
          </div>
        ),
      },
      { disappearAfter: 5000 }
    );
  }, !!selectedCampaignItem);

  return (
    <StyledCampaignBrowserSidePanel className="campaign-browser-side-panel">
      <Expander
        icon={<Icon name="chevron-down" />}
        title="Status"
        bodyAs="div"
        open
        body={
          <div className="campaign-browser-side-panel__status">
            <ApprovalProgressBar
              title="Progress"
              status={FormProgressEnum.InProgress}
              progress={
                selectedCampaignItem?.progress || selectedCompany?.progress
              }
            />

            {isDataCollectionItem && selectedCampaignItem ? (
              <FormStatus
                organisationSessionId={organisationSessionId}
                campaignItem={selectedCampaignItem as CampaignItem}
              />
            ) : (
              <StatusProgress
                statusProgresses={
                  selectedCompany?.statusProgresses ||
                  selectedCampaignItem?.statusProgresses
                }
              />
            )}
            <Divider />
          </div>
        }
      />
      <Expander
        icon={<Icon name="chevron-down" />}
        title="Contributors"
        bodyAs="div"
        open
        body={
          <Fragment>
            <AssignContributors
              contributors={contributors}
              btnText={modalTitleAndButtonText.btnText}
              assignTarget={modalTitleAndButtonText.assignTarget}
              loading={assignContributors.isMutating}
              onSubmit={async ({ contributors }) => {
                const { data: newAssignments } =
                  await assignContributors.trigger({
                    data: {
                      assigneeIds: contributors.map(({ value }) => value),
                      organisationSessionId,
                      campaignItemId,
                      assignmentType: campaignItemId
                        ? AssignmentType.CampaignItem
                        : AssignmentType.OrganisationSession,
                    },
                    method: 'POST',
                  });

                updateAssignees(newAssignments);
              }}
              className="campaign-browser-side-panel__assign-button"
            />
            <div className="campaign-browser-side-panel__assignees">
              <BlockUI loading={isAssignmentsLoading}>
                <small className="campaign-browser-side-panel__assignees__subtitle">
                  {assignees.length > 0
                    ? 'Contributors'
                    : 'No contributors assigned'}
                </small>

                {assignees.slice(0, 9).map(({ name, role, src, id }) => (
                  <AssignedContributor
                    id={id}
                    key={id}
                    src={src}
                    name={name}
                    role={role}
                    menuRef={menuRef}
                    menuItems={nodeMenuItems(id, name)}
                  />
                ))}
              </BlockUI>
              {assignees.length >= 10 && (
                <Fragment>
                  <Button
                    className="campaign-browser-side-panel__assignees__view-all"
                    onClick={openModal}
                  >
                    View All Contributors
                  </Button>
                  {open && (
                    <ViewAllContributorsModal
                      assignees={assignees}
                      closeModal={closeModal}
                      nodeMenuItems={nodeMenuItems}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </Fragment>
        }
      />

      <Divider />

      {!!campaignItemId && (
        <Fragment>
          <BlockUI loading={isLoadingCampaignItem}>
            <DeadlineExpander
              // TODO: temporary hack (passing the `error` prop), SWR caches values, so if an error occurs,
              // the component is not rerendered, and the calendar will not display the correct value
              error={error}
              deadline={
                selectedCampaignItem?.deadline
                  ? dayjs(selectedCampaignItem.deadline)
                  : undefined
              }
              onChange={async (newDeadline) => {
                const currentDeadline =
                  newDeadline || selectedCampaignItem?.deadline;

                const { data } = await triggerSetDeadline({
                  url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_CAMPAIGN_ITEM_DEADLINE(
                    organisationSessionId,
                    campaignItemId,
                    isDataCollectionItem ? 'forms' : 'topics'
                  ),
                  method: 'PUT',
                  data: {
                    deadline: currentDeadline,
                    clearDeadline: !newDeadline,
                  },
                });

                mutate(
                  (prev) => ({
                    data: !prev?.data
                      ? []
                      : prev.data.map((previousData) =>
                          data.id === previousData.id ? data : previousData
                        ),
                  }),
                  { revalidate: false }
                );
              }}
              disablePast={false}
              rightLimitDate={dayjs(selectedSession?.endDate)}
              leftLimitDate={dayjs(selectedSession?.startDate)}
            />
          </BlockUI>
          <Divider />
        </Fragment>
      )}

      {selectedCampaignItem?.isForm && FEATURES.SESSION_ACTIVITY_LOGS && (
        <ViewGuard section={PermissionSections.LOGS} permissions={['read']}>
          <Expander
            icon={<Icon name="chevron-down" />}
            title="History"
            bodyAs="div"
            open
            body={
              <FormActivity
                organisationSessionId={organisationSessionId}
                formId={campaignItemId}
              />
            }
          />
        </ViewGuard>
      )}
    </StyledCampaignBrowserSidePanel>
  );
};

export default CampaignBrowserSidePanel;
