import { SelectOption } from '@faxi/web-component-library';
import { FormContext, FormField, validators } from '@faxi/web-form';
import { SelectField } from 'components';
import {
  APIIntegration,
  APIIntegrationField,
  IntegrationDataModule,
} from 'models';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import { StyledBaseIntegrationFieldConfiguration } from './BaseIntegrationFieldConfiguration.styled';

export type BaseIntegrationFieldConfigurationProps = IntegrationDataModule;

const BaseIntegrationFieldConfiguration: FC<
  BaseIntegrationFieldConfigurationProps
> = ({ config }) => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<APIIntegration>();

  const { updateValueField } = useContext(FormContext);

  const {
    data: { data: apiIntegrations = [] } = {},
    isLoading: isLoadingApiIntegrations,
    isValidating: isValidatingApiIntegrations,
  } = useSWR<{ data: APIIntegration[] }>(
    API_ROUTES.INTEGRATIONS.API_INTEGRATIONS
  );

  const apiIntegrationsOptions = useMemo<SelectOption[]>(
    () => apiIntegrations.map(({ name, id }) => ({ label: name, value: id })),
    [apiIntegrations]
  );

  const {
    data: { data: apiIntegrationFields = [] } = {},
    isLoading: isLoadingApiIntegrationFields,
    isValidating: isValidatingApiIntegrationFields,
  } = useSWR<{ data: APIIntegrationField[] }>(
    selectedIntegration &&
      API_ROUTES.INTEGRATIONS.API_INTEGRATIONS_INTEGRATION_FIELDS(
        selectedIntegration.id
      )
  );

  const apiIntegrationFieldOptions = useMemo<SelectOption[]>(
    () =>
      apiIntegrationFields.map(({ name, id }: any) => ({
        label: name,
        value: id,
      })),
    [apiIntegrationFields]
  );

  useEffect(() => {
    if (selectedIntegration) return;

    const activeIntegration = apiIntegrations.find(
      (el) => el.id === config?.apiIntegrationId
    );

    if (activeIntegration) {
      setSelectedIntegration(activeIntegration);
    }
  }, [apiIntegrations, config?.apiIntegrationId, selectedIntegration]);

  return (
    <StyledBaseIntegrationFieldConfiguration className="esg-integration-field-configuration">
      <FormField
        name="apiIntegrationId"
        renderAsPortal
        skipFocus
        portalClassName="esg-integration-field"
        placeholder="Select Integration"
        autoComplete="off"
        component={SelectField}
        loading={isLoadingApiIntegrations || isValidatingApiIntegrations}
        options={apiIntegrationsOptions}
        hasClearAction
        clearTitle="Clear"
        onChange={(v) => {
          setSelectedIntegration(apiIntegrations.find(({ id }) => id === v));
          updateValueField('apiIntegrationFieldId', '');
        }}
        validate={validators.general.required('Integration field is required')}
      />
      <FormField
        name="apiIntegrationFieldId"
        renderAsPortal
        skipFocus
        portalClassName="esg-integration-field"
        placeholder="Select integration field"
        autoComplete="off"
        component={SelectField}
        hasClearAction
        clearTitle="Clear"
        options={apiIntegrationFieldOptions}
        loading={
          isLoadingApiIntegrationFields || isValidatingApiIntegrationFields
        }
        validate={validators.general.required('Integration field is required')}
      />
    </StyledBaseIntegrationFieldConfiguration>
  );
};

export default BaseIntegrationFieldConfiguration;
