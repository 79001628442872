import { AxiosRequestConfig } from 'axios';
import { Campaign, CampaignWithItems, TreeNodeElement } from 'models';
import { createContext, MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';

export type CampaignProviderContextProps = {
  campaigns?: Campaign[];
  deletedCampaign: MutableRefObject<boolean>;
  errorCampaigns: boolean;
  isError: boolean;
  isLoadingCampaigns: boolean;
  isLoadingRootCampaign: boolean;
  isMutatingRootCampaign: boolean;
  isValidatingCampaigns: boolean;
  isValidatingRootCampaign: boolean;
  rootCampaign?: CampaignWithItems;
  tree?: TreeNodeElement;
  createCampaign: (data: Partial<CampaignWithItems>) => Promise<void>;
  deleteCampaign: (id: string) => Promise<void>;
  editCampaign: (id: string, data: Partial<CampaignWithItems>) => Promise<void>;
  mutateCampaigns: KeyedMutator<{ data: Campaign[] }>;
  mutateRootCampaign: KeyedMutator<{ data: CampaignWithItems }>;
  rootCampaignMutationRequest: (data: AxiosRequestConfig) => Promise<void>;
};

export default createContext<CampaignProviderContextProps>({
  deletedCampaign: { current: false },
  errorCampaigns: false,
  isError: false,
  isLoadingCampaigns: false,
  isLoadingRootCampaign: false,
  isMutatingRootCampaign: false,
  isValidatingCampaigns: false,
  isValidatingRootCampaign: false,
  createCampaign: () => new Promise(() => {}),
  deleteCampaign: () => new Promise(() => {}),
  editCampaign: () => new Promise(() => {}),
  mutateCampaigns: () => Promise.resolve(undefined),
  mutateRootCampaign: () => new Promise(() => {}),
  rootCampaignMutationRequest: () => new Promise(() => {}),
});
