import api from 'api';
import { Campaign, CampaignWithItems } from 'models';
import { FC, ReactNode, useCallback, useContext, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../api/routes';
import { mapBaseCampaignToTreeNodeElement } from '../../utils';
import CampaignProviderContext, {
  CampaignProviderContextProps,
} from './Campaign.context';

type CampaignProviderProps = { children?: ReactNode };

const CampaignProvider: FC<CampaignProviderProps> = (props) => {
  const { children } = props;

  const { campaign = '' } = useParams<{ campaign: string }>();

  const deletedCampaign = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { data: campaigns } = {},
    error: errorCampaigns,
    mutate: mutateCampaigns,
    isLoading: isLoadingCampaigns,
    isValidating: isValidatingCampaigns,
  } = useSWR<{ data: Campaign[] }>(API_ROUTES.CAMPAIGNS.BASE());

  const {
    data: { data: rootCampaign } = {},
    error,
    mutate: mutateRootCampaign,
    isValidating: isValidatingRootCampaign,
    isLoading: isLoadingRootCampaign,
  } = useSWR<{ data: CampaignWithItems }>(
    !deletedCampaign.current && campaign
      ? API_ROUTES.CAMPAIGNS.CAMPAIGN(campaign)
      : null
  );

  // MUTATION WILL BE TRIGGERED IN A COMPONENT WHICH EXTENDS THIS ONE
  const {
    trigger: rootCampaignMutationRequest,
    isMutating: isMutatingRootCampaign,
  } = api.useMutation<void>(API_ROUTES.CAMPAIGNS.BASE(), {
    revalidate: false,
    // THIS HAS AN EDGE CASE AND THAT IS WHEN A CAMPAIGN IS DELETED (ROOT), THEN MUTATE TREE SHOULD NOT BE CALLED
    // BUT IT IS, AND IT IS AVOIDED WITH deletedCampaign REF
    onSuccess: () => {
      mutateCampaigns();
    },
  });

  const editCampaign = useCallback(
    async (id: string, data: Partial<CampaignWithItems>) => {
      delete data.type;

      await rootCampaignMutationRequest({
        url: API_ROUTES.CAMPAIGNS.CAMPAIGN(id),
        method: 'PATCH',
        data,
      });
    },
    [rootCampaignMutationRequest]
  );

  const createCampaign = useCallback(
    async (data: Partial<CampaignWithItems>) => {
      // don't send empty `taxonomyId` to the API
      if ([null, undefined, ''].includes(data.taxonomyId))
        delete data.taxonomyId;

      await rootCampaignMutationRequest({
        url: API_ROUTES.CAMPAIGNS.BASE(),
        method: 'POST',
        data,
      });
    },
    [rootCampaignMutationRequest]
  );

  const deleteCampaign = useCallback(
    async (id: string) => {
      await rootCampaignMutationRequest({
        url: API_ROUTES.CAMPAIGNS.CAMPAIGN(id),
        method: 'DELETE',
      });
    },
    [rootCampaignMutationRequest]
  );

  const tree = useMemo(
    () =>
      rootCampaign
        ? mapBaseCampaignToTreeNodeElement(rootCampaign, navigate, location)
        : undefined,
    [rootCampaign, location, navigate]
  );

  return (
    <CampaignProviderContext.Provider
      value={{
        deletedCampaign,
        tree,
        rootCampaign,
        isError: error,
        isMutatingRootCampaign,
        isValidatingRootCampaign,
        isLoadingRootCampaign,
        campaigns,
        errorCampaigns,
        mutateCampaigns,
        isLoadingCampaigns,
        isValidatingCampaigns,
        mutateRootCampaign,
        editCampaign,
        rootCampaignMutationRequest,
        createCampaign,
        deleteCampaign,
      }}
    >
      {children}
    </CampaignProviderContext.Provider>
  );
};

const useCampaignProvider = (): CampaignProviderContextProps =>
  useContext(CampaignProviderContext);

export { CampaignProvider, useCampaignProvider };
