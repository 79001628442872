import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { DATE_FORMAT } from '../../../../constants';
import { BlockUI } from '../../../../helpers';
import { useSessionProvider } from '../../../../providers/Session';
import { useSelectedSession } from '../../context/SelectedSession';
import { StyledSessionDashboard } from './SessionDashboard.styled';

ChartJS.register(ArcElement, Tooltip, Legend);

const SessionDashboard: FC = () => {
  const selectedSession = useSelectedSession();

  const { isLoading } = useSessionProvider();

  const startDate = useMemo(
    () => dayjs(selectedSession?.startDate).format(DATE_FORMAT),
    [selectedSession]
  );

  const endDate = useMemo(
    () => dayjs(selectedSession?.endDate).format(DATE_FORMAT),
    [selectedSession]
  );

  const progressChartData = useMemo(
    () =>
      selectedSession?.progress
        ? {
            labels: ['Progress %', 'Remaining %'],
            datasets: [
              {
                data: [
                  selectedSession.progress.progress || 0,
                  100 - selectedSession.progress.progress,
                ],
                backgroundColor: ['#298DFF', '#EFF0F0'],
                hoverBackgroundColor: ['#298DFF80', '#EFF0F0'],
              },
            ],
          }
        : null,
    [selectedSession]
  );

  const completionChartData = useMemo(
    () =>
      selectedSession?.statusProgresses
        ? {
            labels: ['Completion %', 'Remaining %'],
            datasets: [
              {
                data: [
                  selectedSession.statusProgresses.completion.progress,
                  100 - selectedSession.statusProgresses.completion.progress,
                ],
                backgroundColor: ['#06C9DA', '#EFF0F0'],
                hoverBackgroundColor: ['#06C9DA80', '#EFF0F0'],
              },
            ],
          }
        : null,
    [selectedSession]
  );

  const approvalChartData = useMemo(
    () =>
      selectedSession?.statusProgresses
        ? {
            labels: ['Approved %', 'Remaining %'],
            datasets: [
              {
                data: [
                  selectedSession.statusProgresses.approval.progress || 0,
                  100 - selectedSession.statusProgresses.approval.progress,
                ],
                backgroundColor: ['#127A3C', '#ECEDED'],
                hoverBackgroundColor: ['#127A3C80', '#ECEDED'],
              },
            ],
          }
        : null,
    [selectedSession]
  );

  const auditChartData = useMemo(
    () =>
      selectedSession?.statusProgresses
        ? {
            labels: ['Approved %', 'Remaining %'],
            datasets: [
              {
                data: [
                  selectedSession.statusProgresses.audit.progress || 0,
                  100 - selectedSession.statusProgresses.audit.progress,
                ],
                backgroundColor: ['#3D41A1', '#ECEDED'],
                hoverBackgroundColor: ['#3D41A180', '#ECEDED'],
              },
            ],
          }
        : null,
    [selectedSession]
  );

  return (
    <StyledSessionDashboard title="Dashboard" className="esg-session-dashboard">
      <BlockUI loading={isLoading}>
        <div className="esg-session-dashboard__container">
          <div className="esg-session-dashboard__container__description">
            {selectedSession?.description || 'This session has no description'}
          </div>

          <div className="esg-session-dashboard__container__charts">
            <div className="esg-session-dashboard__container__charts__chart">
              <h2>Progress</h2>
              {progressChartData && <Doughnut data={progressChartData} />}
            </div>

            <div className="esg-session-dashboard__container__charts__chart">
              <h2>Completion</h2>
              {completionChartData && <Doughnut data={completionChartData} />}
            </div>

            <div className="esg-session-dashboard__container__charts__chart">
              <h2>Approval</h2>
              {approvalChartData && <Doughnut data={approvalChartData} />}
            </div>

            <div className="esg-session-dashboard__container__charts__chart">
              <h2>Audit</h2>
              {auditChartData && <Doughnut data={auditChartData} />}
            </div>
          </div>

          <div className="esg-session-dashboard__container__info">
            <div className="esg-session-dashboard__container__info__date">
              <strong>Campaign:</strong> {selectedSession?.campaignName}
            </div>
            <div className="esg-session-dashboard__container__info__date">
              <strong>Session started:</strong> {startDate}
            </div>
            <div className="esg-session-dashboard__container__info__date">
              <strong>Session ends:</strong> {endDate}
            </div>
            <div className="esg-session-dashboard__container__info__date">
              <strong>Number of companies:</strong>{' '}
              {
                (selectedSession?.settings || []).filter((v) => v.isIncluded)
                  ?.length
              }
            </div>
          </div>
        </div>
      </BlockUI>
    </StyledSessionDashboard>
  );
};

export default SessionDashboard;
