import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledIntegrationCanvasModule = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_8};
  flex-grow: 1;

  .esg-integration-canvas-module {
    &__selected-field {
      margin-bottom: ${theme.sizes.SIZE_16};
    }
  }
`;

export { StyledIntegrationCanvasModule };
