import { useCallbackRef } from '@faxi/web-component-library';
import { FC, ReactNode, useEffect, useState } from 'react';

import { useHeightAnimation } from '../../../../../hooks';

type CheckboxConditionalElProps = { children?: ReactNode; isChecked: boolean };

const CheckboxConditionalEl: FC<CheckboxConditionalElProps> = ({
  children,
  isChecked,
}) => {
  const [optionElement, optionElementRef] = useCallbackRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState<boolean>(isChecked);

  useHeightAnimation({ element: optionElement, isOpen });

  useEffect(() => {
    setIsOpen(isChecked);
  }, [isChecked]);

  return (
    <span className="esg-checkbox-conditional-el" ref={optionElementRef}>
      {children}
    </span>
  );
};

export default CheckboxConditionalEl;
