import { CSSProperties } from 'react';

export enum RemainingDaysEnum {
  Overdue,
  DueToday,
  OneDayLeft,
  MoreDaysLeft,
  OneWeekLeft,
  MoreWeeksLeft,
}

const COMMON_STYLE = {
  backgroundColor: 'var(--RED-FC)',
  color: 'var(--RED-8F)',
};

export const generateRemainingDaysObj = (
  remainingDays: number
): { text: string; style: CSSProperties } => {
  let text = '';
  let style = COMMON_STYLE;

  if (remainingDays < 0) text = 'Overdue';
  else if (remainingDays === 0) text = 'Due today';
  else if (remainingDays === 1) text = '1 day left';
  else if (remainingDays < 7) text = `${remainingDays} days left`;
  else if (remainingDays === 7) {
    text = '1 week left';
    style = { backgroundColor: 'var(--KHAKI-FF)', color: 'var(--BROWN-A3)' };
  } else {
    text = `${Math.ceil(remainingDays / 7)} weeks left`;
    style = { backgroundColor: 'var(--GRAY-EF)', color: 'var(--GRAY-54)' };
  }

  return { text, style };
};
