const nextColumn = (lastLabel: string): string => {
  if (lastLabel.length === 0) return 'A';

  const lastChar = lastLabel[lastLabel.length - 1];

  return lastChar === 'Z'
    ? nextColumn(lastLabel.slice(0, -1)) + 'A'
    : lastLabel.slice(0, -1) + String.fromCharCode(lastChar.charCodeAt(0) + 1);
};

const isExcelColumnLabel = (label: string): boolean =>
  /^[A-Z]+$/.test(label) && label.length <= 3;

export const generateExcelColumnLabels = (
  existingLabels: string[],
  count: number
): string[] => {
  const excelColumns = existingLabels.filter(isExcelColumnLabel);
  const excelColumnsSet = new Set(excelColumns);

  let lastLabel =
    excelColumns.length > 0 ? excelColumns[excelColumns.length - 1] : '';

  const newLabels: string[] = [...existingLabels];

  while (newLabels.length < existingLabels.length + count) {
    lastLabel = nextColumn(lastLabel);

    while (excelColumnsSet.has(lastLabel)) lastLabel = nextColumn(lastLabel);

    newLabels.push(lastLabel);
    excelColumnsSet.add(lastLabel);
  }

  return newLabels;
};
