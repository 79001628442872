import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSectionPreview = styled.div`
  ${flex('column', 'center', 'flex-start')};
  ${elevate_l};

  gap: ${theme.sizes.SIZE_24};

  border-radius: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16}
    ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_8};

  padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};
  ${theme.sizes.SIZE_24};

  border-left: ${theme.sizes.SIZE_4} solid var(--BLUE-00);
  width: 100%;

  .esg-section-preview {
    &__element-wrapper {
      ${flex('row', 'flex-end', 'baseline')};
      gap: ${theme.sizes.SIZE_16};

      &__module {
        ${flex('row', 'flex-end', 'baseline')};
        width: calc(100% - ${theme.sizes.SIZE_32});
        gap: ${theme.sizes.SIZE_32};
      }

      align-self: stretch;

      &__comments-button {
        font-size: ${theme.fontSizes.FONT_18};
      }
    }
    &__header {
      width: 100%;
      ${flex('row', 'initial', 'center')};
      flex-wrap: wrap;
      border-bottom: 1px solid var(--GRAY-C1);
      padding-bottom: ${theme.sizes.SIZE_16};
      gap: ${theme.sizes.SIZE_12};

      &__title {
        font-weight: 500;
      }

      &__assignee-list {
        ${flex('row', 'flex-start', 'center')};
        margin-left: auto;
        .esg-contributors-list {
          &:hover ~ div {
            transform: translateX(6px);
          }
        }

        &__view-more {
          transition: transform 0.3s;

          ${flex('row', 'center', 'center')};
          ${fontSize(theme.fontSizes.FONT_11)}
          ${size('24px')};

          padding: 0;
          border-radius: 50%;
          background-color: var(--GRAY-EF);
          cursor: pointer;
          margin-left: -4px;

          &:hover {
            z-index: ${theme.zIndexes.CONTENT_HIGH};
            transform: translate3d(6px, -3px, 0) scale3d(1.15, 1.15, 1.15);
          }
        }
      }
    }

    &__module {
      padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledSectionPreview };
