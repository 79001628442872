import { AxiosError } from 'axios';
import { createContext, Dispatch, SetStateAction } from 'react';

import { DataModuleEnum, IDataModule, SessionForm } from '../../../../models';

export type FormModulesContextProps = {
  setModules: Dispatch<SetStateAction<IDataModule<DataModuleEnum>[]>>;
  isLoadingForm: boolean;
  isValidatingForm: boolean;
  modules: IDataModule[];
  error?: AxiosError;
  formApiEndpoint: string;
  form?: SessionForm & { elements: IDataModule[] };
  organisationSessionId: string;
  campaignItemId: string;
};

export default createContext<FormModulesContextProps>({
  setModules: () => {},
  isLoadingForm: false,
  isValidatingForm: false,
  modules: [],
  formApiEndpoint: '',
  organisationSessionId: '',
  campaignItemId: '',
});
