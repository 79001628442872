import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, MouseEvent, MouseEventHandler } from 'react';

import Icon from '../../../../components/Icon';
import { StyledDueReminderFlag } from './DueReminderFlag.styled';
import { generateRemainingDaysObj } from './utils';

export type DueReminderFlagProps = {
  className?: string;
  dueDate: Date;
  onDelete?: (event: MouseEvent<HTMLElement>) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const DueReminderFlag: FC<DueReminderFlagProps> = ({
  dueDate,
  className,
  onDelete,
  onClick,
}) => {
  const { text, style } = generateRemainingDaysObj(
    dayjs(dueDate).startOf('day').diff(dayjs().startOf('day'), 'days')
  );

  return (
    <StyledDueReminderFlag
      className={classNames('esg-due-reminder-flag', className)}
      style={style}
      onClick={onClick}
    >
      <div>{text}</div>

      {onDelete && (
        <Icon
          name="xmark"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(e);
          }}
        />
      )}
    </StyledDueReminderFlag>
  );
};

export default DueReminderFlag;
