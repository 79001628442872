import { Button, Icon, ModalRef } from '@faxi/web-component-library';
import { FC, Fragment, memo, useRef } from 'react';

import { useCampaignItemProvider } from '../../../../context/CampaignItem';
import { useFormBuilder } from '../../../../context/FormBuilder';
import FormBuilderPreviewModal from './components/FormBuilderPreviewModal';
import { StyledPreviewTool } from './PreviewTool.styled';

const PreviewTool: FC = () => {
  const modalRef = useRef<ModalRef>(null);

  const { isLoadingCampaignItem } = useCampaignItemProvider();

  const { loadingNewModule } = useFormBuilder();

  return (
    <StyledPreviewTool
      open
      body={
        <Fragment>
          <Button
            disabled={isLoadingCampaignItem || loadingNewModule}
            icon={<Icon name="eye" />}
            variant="outline"
            onClick={() => modalRef?.current?.open()}
          >
            Preview Form
          </Button>
          <FormBuilderPreviewModal
            ref={modalRef}
            conditionallyControlled={false}
          />
        </Fragment>
      }
      icon={<Icon name="chevron-down" />}
      title="Preview"
      bodyAs="div"
      bodyClassName="esg-preview-tool"
    />
  );
};

export default memo(PreviewTool);
