import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import { DATE_FORMAT } from '../constants';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const formatRelativeOrFullDate = (date: string | Date): string => {
  const givenDate = dayjs(date);

  if (givenDate.isToday()) {
    return givenDate.format(`[Today], HH:mm`);
  } else if (givenDate.isYesterday()) {
    return givenDate.format(`[Yesterday], HH:mm`);
  } else if (givenDate.isAfter(dayjs().subtract(7, 'days'), 'day')) {
    return givenDate.format(`dddd, HH:mm`);
  } else {
    return givenDate.format(`${DATE_FORMAT}, HH:mm`);
  }
};

export const formatRelativeDate = (date: string | Date): string => {
  const givenDate = dayjs(date);

  if (givenDate.isToday()) {
    return givenDate.format(`[Today]`);
  } else if (givenDate.isYesterday()) {
    return givenDate.format(`[Yesterday]`);
  } else if (givenDate.isAfter(dayjs().subtract(7, 'days'), 'day')) {
    return givenDate.format(`dddd`);
  } else {
    return givenDate.format(DATE_FORMAT);
  }
};

export const isSameDateWithoutTime = (
  date1: string | Date,
  date2: string | Date
) => dayjs(date1).isSame(dayjs(date2), 'day');
