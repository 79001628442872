import { ActivityAction } from '../../../models';

export const NAVIGATE_TO_MAP = {
  organisation: 'companies',
  company: 'topics',
  topic: 'subtopics',
  subtopic: 'subsubtopics',
  subsubtopic: 'reports',
};

export const MAP_ID = {
  organisation: 'sessionId',
  company: 'company',
  topic: 'topic',
  subtopic: 'subtopic',
  subsubtopic: 'subsubtopic',
};

export const LOG_ACTION_MAPPER: Record<ActivityAction, string> = {
  [ActivityAction.InputLocked]: 'Locked module',
  [ActivityAction.InputUnlocked]: 'Unlocked module',
  [ActivityAction.CommentAdded]: 'Left a comment',
  [ActivityAction.InputUpdated]: 'Updated module data',
  [ActivityAction.InputEntered]: 'Entered module data',
  [ActivityAction.InputCleared]: 'Removed module data',
  [ActivityAction.FormStatusUpdated]: 'Updated form status',
  [ActivityAction.FormApprovalUpdated]: 'Updated form approval',
  [ActivityAction.FormAuditUpdated]: 'Updated form audit ',
};
