import { Button } from '@faxi/web-component-library';
import classNames from 'classnames';
import { CollapsibleSidePanel, Loading } from 'components';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { FC, useEffect } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import { ApiData, Role } from '../../../../../models';
import { ColorRoleIdentifier } from '../UserRole/styles';
import { CRUMBS, USER_ROLE_NAME_MAPPER } from './constants';
import { StyledUserRoles } from './UserRoles.styled';

export type UserRolesProps = {};

const UserRoles: FC<UserRolesProps> = () => {
  const { pathname } = useLocation();
  const { roleId } = useParams();
  const navigate = useNavigate();
  //TODO: to be replaced the actual api call for fetching roles
  const { data: { data: roles } = {}, isLoading } = useSWR<
    ApiData<Role[]>,
    Error
  >(API_ROUTES.USERS.ROLES);

  useEffect(() => {
    if (roles?.length && APP_URI.USER_ROLES === pathname) {
      navigate(roles[0].id, { replace: true });
    } else if (!roles?.length && roleId) {
      navigate(APP_URI.USER_ROLES, { replace: true });
    }
  }, [navigate, pathname, roleId, roles]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledUserRoles
      title="User Roles"
      className="esg-user-roles"
      breadcrumbs={CRUMBS}
      padding={0}
    >
      <CollapsibleSidePanel expandDirection="right">
        <Button icon={<Icon name="plus" />} variant="outline" disabled={true}>
          Create New Role
        </Button>
        <nav className="esg-user-roles__nav">
          {roles?.map((role) => (
            <div key={role.id}>
              <NavLink
                className={classNames('esg-user-roles__nav__link', {
                  'esg-user-roles__nav__link--active': role.id === roleId,
                })}
                key={role.id}
                to={role.id}
                replace
              >
                <div>
                  <ColorRoleIdentifier color={role.color} />
                  <span>{USER_ROLE_NAME_MAPPER[role.name]}</span>
                </div>
                {/* TODO: connect when we implement dynamic roles */}
                {/* <ThreeDotMenu menuItems={defaultMenuItems} /> */}
              </NavLink>
            </div>
          ))}
        </nav>
      </CollapsibleSidePanel>
      <div className="esg-user-roles__container">
        <Outlet />
      </div>
    </StyledUserRoles>
  );
};

export default UserRoles;
