import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledEditedOriginalContainer = styled.div`
  ${flex('row', 'flex-start', 'flex-start')};

  gap: ${theme.sizes.SIZE_80};
  padding: ${theme.sizes.SIZE_32};

  width: 100%;
  max-width: ${theme.sizes.SIZE_664};

  max-height: ${theme.sizes.SIZE_424};
  overflow-y: auto;

  .checkbox-list {
    align-self: stretch;

    &--left .highlight-change {
      background-color: var(--ORANGE-FF);
    }

    &--right .highlight-change {
      background-color: var(--GREEN-E3);
    }

    .wcl-checkbox {
      padding: ${theme.sizes.SIZE_4};
      width: 100%;

      .wcl-checkbox__label {
        flex: 1;
        justify-content: flex-start;
      }
    }
  }

  > div:first-of-type > p {
    background-color: var(--ORANGE-FF);
  }

  > div:last-of-type > p {
    background-color: var(--GREEN-E3);
  }

  > div {
    ${flex('column', 'flex-start', 'flex-start')};
    flex: 1;

    gap: ${theme.sizes.SIZE_32};
    min-width: unset;

    p {
      ${fontSize(theme.fontSizes.FONT_14)};

      word-break: break-all;
      padding: ${theme.sizes.SIZE_4};
    }

    .wcl-checkbox-group__checkboxes {
      gap: ${theme.sizes.SIZE_20};
    }
  }
`;

const StyledFormulaContainer = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  padding: ${theme.sizes.SIZE_32};
  width: ${theme.sizes.SIZE_664};
  gap: ${theme.sizes.SIZE_32};
  max-height: ${theme.sizes.SIZE_424};
  overflow-y: auto;

  > span {
    ${fontSize(theme.sizes.SIZE_16)}

    font-weight: 600;
  }

  > div {
    ${flex('column', 'flex-start', 'flex-start')};
    gap: ${theme.sizes.SIZE_8};

    .esg-value-change-preview--orange {
      background-color: var(--ORANGE-FF);
      color: var(--ORANGE-9A);
    }

    > div {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_48};
    }
  }
`;

const StyledTableContainer = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  padding: ${theme.sizes.SIZE_32};
  width: ${theme.sizes.SIZE_664};
  gap: ${theme.sizes.SIZE_32};
  max-height: ${theme.sizes.SIZE_424};
  overflow-y: auto;

  .table-value-log-change {
    ${flex('column', 'flex-start', 'flex-start')}

    gap: ${theme.sizes.SIZE_16};

    > div:not(:last-of-type) {
      > span {
        font-weight: 600;
      }
    }
  }
`;

export {
  StyledEditedOriginalContainer,
  StyledFormulaContainer,
  StyledTableContainer,
};
