import { Breadcrumb } from '@faxi/web-component-library';

import { APP_URI } from '../config';

export const USER_PROFILE_NAV = [
  { label: 'Personal Details', to: APP_URI.PERSONAL_DETAILS },
  { label: 'Notifications', to: APP_URI.NOTIFICATIONS },
  { label: 'Reminders', to: APP_URI.REMINDERS },
  { label: 'Role History', to: APP_URI.ROLE_HISTORY },
];

export const USER_MANAGEMENT_NAV = [
  { label: 'Personal Details', to: 'personal-details' },
  { label: 'Permissions', to: 'permissions' },
  { label: 'Activity Logs', to: 'activity-logs' },
];

export const USER_BY_ID_CRUMBS: Breadcrumb[] = [
  { text: 'Users', href: APP_URI.USERS },
  { text: 'User Profile', href: APP_URI.USER_BY_ID_PROFILE },
];

export const DATE_FORMAT = 'DD.MM.YYYY.';

export const LIMIT = 10;

export const DEFAULT_ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'video/quicktime',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const XBRL_ACCEPTED_FILE_TYPES = [
  'zip',
  'application/octet-stream',
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
];
