import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserActivityLogs = styled.div`
  ${flex('column')};
  ${size('100%')};

  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_48};

  .esg-user-activity-logs {
    &__user {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_8};
    }

    &__property {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_8};
    }

    &__tables__date {
      color: var(--GRAY-98);
      margin-bottom: ${theme.sizes.SIZE_20};
    }
  }

  .esg-data-grid-preview__container {
    grid-template-columns: 224px 1fr 3fr 5fr;
    gap: ${theme.sizes.SIZE_32};
    width: 100%;
    overflow: auto;

    .esg-data-grid-preview__container__column {
      white-space: nowrap;
    }

    strong {
      font-weight: 600;
    }
  }
`;

export { StyledUserActivityLogs };
