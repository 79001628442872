import { FC, PropsWithChildren } from 'react';

import { ActivityLog } from '../../../../../models';
import { LOG_ACTION_MAPPER } from '../../../../../pages/Sessions/constants';
import {
  formatRelativeDate,
  formatRelativeOrFullDate,
} from '../../../../../utils';
import {
  generateActivityLogName,
  isLogValid,
  isStatusChangeAction,
  isValueChangeAction,
} from '../../utils';
import UserMentionOption from '../UserMentionOption';
import ValueChangeLog from '../ValueChangeLog';
import { StyledModuleElementActivityLog } from './ModuleElementActivityLog.styled';

export type ModuleElementActivityLogProps = PropsWithChildren<{
  date: Date;
  activityLogs: ActivityLog[];
  dontShowChange?: boolean;
  onClick?: (elementId: string) => void;
}>;

const ModuleElementActivityLog: FC<ModuleElementActivityLogProps> = ({
  date,
  activityLogs,
  dontShowChange,
  onClick,
}) => {
  return (
    <StyledModuleElementActivityLog className="esg-module-element-activity-log">
      <div className="esg-module-element-activity-log__date">
        {formatRelativeDate(date)}
      </div>
      {activityLogs
        .filter(isLogValid)
        .map(
          ({
            id,
            performedBy: { firstName, lastName },
            action,
            timestamp,
            oldValue,
            newValue,
            type,
            newConfig,
            title,
            oldConfig,
            path,
          }) => (
            <div
              className="esg-module-element-activity-log__log"
              key={id}
              onClick={() => onClick?.(path.elementId ?? '')}
            >
              <UserMentionOption name={`${firstName} ${lastName}`} />
              <div className="esg-module-element-activity-log__log__action-container">
                <p>
                  {LOG_ACTION_MAPPER[action]}
                  {`${dontShowChange && !isStatusChangeAction(action) ? ` in ${generateActivityLogName(title, type)}` : ' '}`}
                </p>
                {!dontShowChange &&
                  isValueChangeAction(action) &&
                  (oldValue || newValue) &&
                  type && (
                    <ValueChangeLog
                      oldValue={oldValue}
                      newValue={newValue}
                      type={type}
                      config={newConfig}
                      oldConfig={oldConfig}
                    />
                  )}
              </div>

              <span>{formatRelativeOrFullDate(timestamp)}</span>
            </div>
          )
        )}
    </StyledModuleElementActivityLog>
  );
};

export default ModuleElementActivityLog;
