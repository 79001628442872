import { Input, InputProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { FC, useState } from 'react';

import Icon from '../../Icon';
import { StyledPasswordField } from './PasswordField.styled';

type ValueOnChange = (event: string) => void;
export type PasswordFieldProps = FieldProps<string, ValueOnChange> &
  Omit<InputProps, 'type' | 'autoComplete'>;

const PasswordField: FC<PasswordFieldProps> = (props) => {
  const { error, dirty, touched, required, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsOn, setIsCapsOn] = useState(false);

  const showError = useFieldError(props);

  return (
    <StyledPasswordField className="password-field">
      <Input
        type={showPassword ? 'input' : 'password'}
        autoComplete="off"
        error={showError}
        onKeyDown={(e) => setIsCapsOn(e.getModifierState('CapsLock'))}
        onKeyUp={(e) => {
          if (e.key === 'CapsLock') {
            setIsCapsOn(e.getModifierState('CapsLock'));
          }
        }}
        errorText={String(error)}
        aria-invalid={showError}
        required={required}
        suffixIcon={
          <Icon
            name={showPassword ? 'eye' : 'eye-slash'}
            onClick={() => setShowPassword((prev) => !prev)}
          />
        }
        {...(required && { requiredLabel: 'Required' })}
        {...rest}
      />
      {isCapsOn && <p>Caps lock is on</p>}
    </StyledPasswordField>
  );
};

export default PasswordField;
