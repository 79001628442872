import { createContext, Dispatch, SetStateAction } from 'react';

import { DataModuleEnum, IDataModule } from '../../../../models';

export type LockDrawerContextProps = {
  handleLockClick: (module: IDataModule) => void;
  setActiveModule: Dispatch<
    SetStateAction<IDataModule<DataModuleEnum> | undefined>
  >;
  openDrawer: () => void | undefined;
};

export default createContext<LockDrawerContextProps>({
  handleLockClick: () => {},
  setActiveModule: () => {},
  openDrawer: () => {},
});
