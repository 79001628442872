import {
  Button,
  useModalUtilities,
  useQueryParams,
} from '@faxi/web-component-library';
import { useInfiniteFetch } from 'api';
import { API_ROUTES } from 'api/routes';
import { BlockUI } from 'helpers';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { ActivityLog } from 'models';
import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import authBus from '../../../../../modules/authBus';
import { isLogValid } from '../../utils';
import FormActivityLog from '../FormActivityLog';
import FormActivityModal from '../FormActivityModal';
import { StyledFormActivity } from './FormActivity.styled';

export type FormActivityProps = PropsWithChildren<{
  organisationSessionId: string;
  formId: string;
}>;

const HISTORY_LOGS_LIMIT = 10;

const FormActivity: FC<FormActivityProps> = ({
  organisationSessionId,
  formId,
}) => {
  const [hasStaleActivityLogs, setHasStaleActivityLogs] = useState(false);

  const { setQueryParam } = useQueryParams();

  const staleActivityLog = useRef<number>();

  const { items, isLoading, nextPage, isValidating, setSize } =
    useInfiniteFetch<ActivityLog>(
      `${API_ROUTES.ACTIVITY_LOGS_ROUTES.FORM(organisationSessionId, formId)}?limit=${HISTORY_LOGS_LIMIT}`,
      {
        refreshInterval: hasStaleActivityLogs ? 5000 : undefined,
        dedupingInterval: 5000,
      }
    );

  useEffect(() => {
    const disableLogsListener = authBus.addEventListener(
      'activity-logs-start-poll',
      () => {
        setHasStaleActivityLogs(true);
        staleActivityLog.current = items[0]?.timestamp
          ? Number(new Date(items[0]?.timestamp))
          : undefined;
      }
    );

    const enableLogsListener = authBus.addEventListener(
      'activity-logs-stop-poll',
      () => {
        setHasStaleActivityLogs(false);
        staleActivityLog.current = undefined;
      }
    );

    return () => {
      authBus.removeEventListener(
        'activity-logs-start-poll',
        disableLogsListener
      );
      authBus.removeEventListener(
        'activity-logs-stop-poll',
        enableLogsListener
      );
    };
  }, [items]);

  const { setContainerRef, isFetching } = useInfiniteScroll<HTMLDivElement>({
    hasNextPage: Boolean(nextPage),
    isLoading: isValidating,
    loadMore: () => setSize((prev) => prev + 1),
  });

  const { openModal, closeModal, open } = useModalUtilities();

  const validLogs = useMemo(() => items.filter(isLogValid), [items]);

  useEffect(() => {
    if (hasStaleActivityLogs && items[0]?.timestamp) {
      const latestLogActivity = Number(new Date(items[0].timestamp));

      if (staleActivityLog.current !== latestLogActivity) {
        authBus.broadcastEvent('activity-logs-stop-poll');
      }
    }
  }, [items, hasStaleActivityLogs]);

  return (
    <BlockUI
      loading={isLoading}
      fallback={<p>No activity logs.</p>}
      fallbackCondition={!items.length}
    >
      <StyledFormActivity className="esg-form-activity">
        {validLogs.slice(0, HISTORY_LOGS_LIMIT - 1).map((log, index) => (
          <FormActivityLog
            key={index}
            {...log}
            onClick={(elementId) => {
              setQueryParam('elementId', elementId, true);
            }}
          />
        ))}
        {(Boolean(nextPage) || items.length > HISTORY_LOGS_LIMIT) && (
          <Button className="esg-form-activity__view-all" onClick={openModal}>
            View All Form Logs
          </Button>
        )}
        {open && (
          <FormActivityModal
            onClose={closeModal}
            logs={validLogs}
            isFetching={isFetching}
            setContainerRef={setContainerRef}
          />
        )}
      </StyledFormActivity>
    </BlockUI>
  );
};

export default FormActivity;
