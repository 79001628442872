import { BaseModel } from './BaseModel';
import { DataModuleEnum } from './IDataModule';
import { User, UserRoleName } from './User';

type ActivityUser = { img?: string; firstName: string; lastName: string };

type ActivityOrganisation = { img?: string; name: string };

export type Activity = {
  id: string;
  isRead: boolean;
  user: ActivityUser;
  campaignName: string;
  organisation: ActivityOrganisation;
  topic: string;
  action: string;
};

export type ModuleElementActivityLog = {
  id: string;
  targetedAt: Date | string;
  user: User;
  summary: string;
};

export type UserActivityLog = {
  id: string;
  organisationId: string;
  user: User;
  timestamp: string;
  event: 'INSERT' | 'MODIFY' | 'REMOVE';
  performedBy: User;
  property: string;
  firstName?: { old?: string; new?: string };
  lastName?: { old?: string; new?: string };
  oldValue: { color: string; id: string; name: UserRoleName };
  newValue: { color: string; id: string; name: UserRoleName };
};

export enum ActivityAction {
  InputEntered = 'input entered',
  InputUpdated = 'input updated',
  InputCleared = 'input cleared',
  InputLocked = 'input locked',
  InputUnlocked = 'input unlocked',
  CommentAdded = 'comment added',
  FormStatusUpdated = 'form status updated',
  FormApprovalUpdated = 'form approval updated',
  FormAuditUpdated = 'form audit updated',
}

export type LogPath = {
  rootOrganisationId: string;
  sessionId: string;
  organisationSessionId: string;
  dataCollectionElementId: string;
  elementId?: string;
};

export type ActivityLog = Pick<BaseModel, 'id'> & {
  timestamp: string;
  event: 'INSERT' | 'MODIFY' | 'REMOVE';
  performedBy: User;
  performedOn?: User;
  action: ActivityAction;
  path: LogPath;
  newValue: any;
  oldValue?: any;
  title?: string;
  type?: DataModuleEnum;
  newConfig?: Record<string, any>;
  oldConfig?: Record<string, any>;
};

export enum ActivityLogStatus {
  None = 'None',
  Completed = 'Completed',
  ToReview = 'To Review',
  Approved = 'Approved',
  Audited = 'Audited',
}
