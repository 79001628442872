import { LabelValuePair, ObjectValues } from 'types';

import { INameExtended } from '../components/Icon';
import { BaseModel } from './BaseModel';
import { Organisation } from './Organisation';
import { PermissionFormSections, PermissionSections } from './Permissions';

export const USER_ROLE = {
  REGULAR_USER: 'basic user',
  ORGANISATION_ADMIN: 'org admin',
  SUPER_ADMIN: 'super admin',
  AUDIT: 'auditor',
} as const;

export type UserRoleName = ObjectValues<typeof USER_ROLE>;

export type UserRole = {
  color: string;
  name: UserRoleName;
  id: string;
  description?: string;
};

export enum Permission {
  CREATE_ORGANISATION,
  CREATE_COMPANY,
  CREATE_REPORT,
  CREATE_TOPIC,
  CREATE_CATEGORY,
  CREATE_TASK,
  SUPERVISE_ACTIVITY,
  HANDLE_REPORT, // APPROVE OR REJECT,
  CHECK_REPORT_STATUS,
  COLLECT_DATA,
  ENTER_DATA,
}

export type User = BaseModel & {
  firstName: string;
  lastName: string;
  img?: string;
  email: string;
  roles: UserRole[];
  jobTitle?: string;
  organisation: Pick<Organisation, 'id' | 'name'>;
  organisationId?: string;
};

export type UserFormData = Pick<User, 'firstName' | 'lastName' | 'jobTitle'> & {
  roleId: string;
};

export type Role = {
  id: string;
  name: UserRoleName;
  color: string;
  description?: string;
  permissions?: string[];
};

type BaseActions = 'read' | 'create' | 'update' | 'delete';

type CampaignsActions = BaseActions;

type CommentsActions = 'read' | 'create';

type ReadAction = 'read';
type UpdateAction = 'update';

export type OrganisationActions = BaseActions;

type SessionActions = BaseActions;

type UserActions = BaseActions;

type ActionMapping = {
  [PermissionSections.CAMPAIGN]: CampaignsActions;
  [PermissionSections.ORGANISATION]: OrganisationActions;
  [PermissionSections.USER]: UserActions;
  [PermissionSections.SESSION]: SessionActions;
  [PermissionSections.LOGS]: ReadAction;
  [PermissionSections.COMMENTS]: CommentsActions;
  [PermissionSections.ASSIGNMENT]: Exclude<BaseActions, 'create' | 'delete'>;
  [PermissionSections.SESSION_STATUS]: UpdateAction;
  [PermissionSections.FILE]: Exclude<BaseActions, 'update'>;
  [PermissionSections.TAXONOMY]: Exclude<BaseActions, 'update' | 'delete'>;
  [PermissionSections.SESSION_DATA_COLLECTION_INPUT]: ReadAction;
};

export type ActionType<T extends PermissionFormSections> =
  T extends keyof ActionMapping ? ActionMapping[T] : BaseActions;

export type ActionProps = {
  label: string;
  name: string;
  excludes?: string[];
  includes?: string[];
};

export type UserRoleFormSectionAction<T extends PermissionFormSections> =
  Record<ActionType<T>, ActionProps>;

export type UserRoleFormSection<T extends PermissionFormSections> = {
  icon: INameExtended;
  label: T;
  actions?: UserRoleFormSectionAction<T>;
};
export type UserRoleForm = {
  [P in PermissionFormSections]: UserRoleFormSection<P>;
};

export type UserDetails = {
  userInformation: LabelValuePair<string>[];
  roleInformation: LabelValuePair<UserRole>;
};

export type ChangePasswordFrom = {
  password: string;
  repeatPassword: string;
  oldPassword: string;
};
