import { Breadcrumb, Breadcrumbs, Heading } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC } from 'react';

import Icon, { INameExtended } from '../../components/Icon';
import { StyledHeader } from './Header.styled';

export type HeaderProps = {
  title: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
};

const mapIconByPage: Record<string, INameExtended> = {
  'Sessions': 'chart-bar',
  'Organisations': 'buildings',
  'Companies': 'buildings',
  'Campaigns': 'leaf',
  'Users': 'users',
  'Dashboard': 'gauge',
  'Account settings': 'user',
  'XBRL Taxonomies': 'tag',
  'Assignments': 'clipboard-list',
  'Integrations': 'gears',
};

const Header: FC<HeaderProps> = ({ title, className, breadcrumbs }) => (
  <StyledHeader className={classNames('esg-header', className)}>
    {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} splitter="/" />}
    <div className="esg-header__title">
      {mapIconByPage[title] && (
        <div
          className={classNames(
            'esg-header__title__badge',
            `esg-header__title__badge--${title.toLowerCase().replaceAll(' ', '-')}`
          )}
        >
          <Icon name={mapIconByPage[title]} />
        </div>
      )}
      <Heading level="1">{title}</Heading>
    </div>
  </StyledHeader>
);

export default Header;
