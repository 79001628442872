import { Button, Icon } from '@faxi/web-component-library';
import { FC, MouseEvent, PropsWithChildren } from 'react';

import OverflowTooltipText from '../OverflowTooltipText';
import { StyledFolderDescription } from './FolderDescription.styled';

export type FolderDescriptionProps = PropsWithChildren<{
  btnText?: string;
  description?: string;
  hasActions?: boolean;
  onChange?: (e: MouseEvent) => void;
}>;

const FolderDescription: FC<FolderDescriptionProps> = (props) => {
  const {
    children,
    description,
    btnText = 'Add Description',
    hasActions = true,
    onChange,
  } = props;

  return (
    <StyledFolderDescription className="esg-folder-description">
      {description ? (
        !hasActions ? (
          <OverflowTooltipText text={description} />
        ) : (
          <OverflowTooltipText text={description}>
            <Button
              variant="ghost"
              icon={<Icon name="pen" />}
              onClick={(e) => {
                e.stopPropagation();
                onChange?.(e);
              }}
            />
          </OverflowTooltipText>
        )
      ) : (
        hasActions && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onChange?.(e);
            }}
            variant="ghost"
            icon={<Icon name="plus" />}
          >
            {btnText}
          </Button>
        )
      )}

      {children}
    </StyledFolderDescription>
  );
};

export default FolderDescription;
