import { Button } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, MouseEvent, PropsWithChildren } from 'react';

import Icon, { INameExtended } from '../../Icon';
import { StyledChosenValue } from './ChosenValue.styled';

export type ChosenValueProps = PropsWithChildren<{
  className?: string;
  label: string;
  value: string;
  icon?: INameExtended;
  onDiscard?: (e: MouseEvent) => void;
}>;

const ChosenValue: FC<ChosenValueProps> = (props) => {
  const { className, icon, label, value, onDiscard } = props;

  return (
    <StyledChosenValue className={classNames('esg-chosen-value', className)}>
      <p className="esg-chosen-value__label">{label}</p>

      <div className="esg-chosen-value__content">
        {icon && <Icon name={icon} />}
        <p className="esg-chosen-value__value">{value}</p>
        <Button
          variant="ghost"
          icon={<Icon name="xmark" />}
          onClick={onDiscard}
        />
      </div>
    </StyledChosenValue>
  );
};

export default ChosenValue;
