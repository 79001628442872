import { OverlayRef } from '@faxi/web-component-library';
import { FC, ReactNode, useContext, useRef, useState } from 'react';

import { IDataModule } from '../../../../models';
import ModuleElementDrawer from '../../../../routes/subrouters/Sessions/components/ModuleElementDrawer';
import ToggleLockFieldModal from '../../components/SessionCampaignForm/components/ToggleLockFieldModal';
import useLock from '../../hooks/useLock';
import { useFormModules } from '../FormModules';
import LockDrawerContext, {
  LockDrawerContextProps,
} from './LockDrawer.context';

type LockDrawerProps = { children?: ReactNode };

const LockDrawer: FC<LockDrawerProps> = (props) => {
  const { children } = props;

  const [activeModule, setActiveModule] = useState<IDataModule>();

  const moduleDrawerRef = useRef<OverlayRef>(null);

  const { setModules, campaignItemId, organisationSessionId } =
    useFormModules();

  const {
    isOpen,
    handleLockClick,
    handleToggleLockField,
    closeLockFieldModal,
  } = useLock({
    organisationSessionId,
    campaignItemId,
    setAsActiveModule: setActiveModule,
  });

  const openDrawer = () => moduleDrawerRef.current?.open();

  return (
    <LockDrawerContext.Provider
      value={{ handleLockClick, setActiveModule, openDrawer }}
    >
      {children}
      <ModuleElementDrawer
        ref={moduleDrawerRef}
        module={activeModule}
        onUpdateNumberOfUnreadComments={(numberOfUnreadComments, id) => {
          setModules((old) =>
            old.map((el) =>
              el.id === id ? { ...el, numberOfUnreadComments } : el
            )
          );

          setActiveModule(
            (prev) => ({ ...prev, numberOfUnreadComments }) as IDataModule
          );
        }}
      />
      {isOpen && (
        <ToggleLockFieldModal
          onClose={closeLockFieldModal}
          onConfirm={(id, isLocked, isLockPopupDisabled) =>
            handleToggleLockField(id, isLocked, isLockPopupDisabled)
          }
          module={activeModule!}
        />
      )}
    </LockDrawerContext.Provider>
  );
};

const useLockDrawer = (): LockDrawerContextProps =>
  useContext(LockDrawerContext);

export { LockDrawer, useLockDrawer };
