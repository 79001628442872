import { Button, Tooltip } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import { NotificationBadgeWrapper } from '../../../../components';
import Icon from '../../../../components/Icon';
import { useUserPermissions, useUserRoles } from '../../../../hooks';
import { IDataModule, PermissionSections } from '../../../../models';
import { useRootProvider } from '../../../../providers/Root';
import { useLockDrawer } from '../../context/LockDrawer';
import { StyledLockDrawerButtons } from './LockDrawerButtons.styled';

export type LockDrawerButtonsProps = PropsWithChildren<{
  module: IDataModule & { lockingUserId?: string };
}>;

const LockDrawerButtons: FC<LockDrawerButtonsProps> = ({ module }) => {
  const { handleLockClick, setActiveModule, openDrawer } = useLockDrawer();

  const {
    user: { id: currentUserId },
  } = useRootProvider();

  const { lockingUserId } = module;

  const { isAdminOrSA } = useUserRoles();
  const hasPermission = useUserPermissions(PermissionSections.SESSION);

  const canLock = hasPermission(['update']);
  const canUnlock =
    hasPermission(['update']) &&
    (isAdminOrSA || currentUserId === lockingUserId);

  return (
    <StyledLockDrawerButtons className="esg-lock-drawer-buttons">
      {module.isLocked ? (
        <Tooltip
          content={`Field has been locked by ${[module.lockingUserFirstName, module.lockingUserLastName].join(' ').trim()}. Only they and admins can unlock it.`}
        >
          <Button
            icon={<Icon name="lock-solid" />}
            onClick={() => handleLockClick(module)}
            variant="ghost"
            disabled={!canUnlock}
          />
        </Tooltip>
      ) : (
        <Button
          icon={<Icon name="lock-open" />}
          onClick={() => handleLockClick(module)}
          variant="ghost"
          disabled={!canLock}
        />
      )}
      <Button
        className="esg-session-campaign-form__form__container__comments-button"
        icon={
          <NotificationBadgeWrapper count={module.numberOfUnreadComments ?? 0}>
            <Icon name="sidebar-flip" />
          </NotificationBadgeWrapper>
        }
        variant="ghost"
        onClick={() => {
          setActiveModule(module);
          openDrawer();
        }}
      />
    </StyledLockDrawerButtons>
  );
};

export default LockDrawerButtons;
