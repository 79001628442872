import {
  GlobalStyle as WCLGlobalStyle,
  SnackBarProps,
  useUtilities,
  UtilitiesProvider,
} from '@faxi/web-component-library';
import swrConfig from 'api/swrConfig';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { Router } from 'routes';
import { SWRConfig } from 'swr';

import { GlobalStyle } from './globalStyles';
import authBus from './modules/authBus';

const SnackBarListener: FC<PropsWithChildren> = ({ children }) => {
  const { showSnackBar } = useUtilities();

  const handleShowSnackBar = useCallback(
    (payload: {
      props: SnackBarProps;
      config?: { constant?: boolean; disappearAfter?: number };
    }) => {
      // lol
      if (payload?.props && payload.config) {
        showSnackBar(payload.props, payload.config);
      }
    },
    [showSnackBar]
  );

  useEffect(() => {
    const handleNumberInputBlurOnScroll = () => {
      const element = document.activeElement as HTMLInputElement;

      if (element?.type === 'number') element.blur();
    };

    document.addEventListener('wheel', handleNumberInputBlurOnScroll);

    return () =>
      document.removeEventListener('wheel', handleNumberInputBlurOnScroll);
  }, []);

  useEffect(() => {
    authBus.addEventListener('show_snackbar', handleShowSnackBar);

    return () => {
      authBus.removeEventListener('show_snackbar', handleShowSnackBar);
    };
  }, [handleShowSnackBar]);

  return children;
};

const App = () => (
  <>
    <WCLGlobalStyle />
    <GlobalStyle />
    <UtilitiesProvider>
      <SWRConfig value={swrConfig}>
        <SnackBarListener>
          <Router />
        </SnackBarListener>
      </SWRConfig>
    </UtilitiesProvider>
  </>
);

export default App;
