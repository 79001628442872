import { Dropdown, DropdownProps } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { CheckboxListField } from '../../../../../components';
import {
  DataModuleEnum,
  FormulaLogConfig,
  TableModuleConfig,
} from '../../../../../models';
import InputValueChange from '../InputValueChange';
import { StyledDetailsTextWrapper } from '../ModuleElementDetails/ModuleElementDetails.styled';
import {
  StyledEditedOriginalContainer,
  StyledFormulaContainer,
  StyledTableContainer,
} from './ValueChangePreview.styled';

export type ValueChangePreviewProps = Omit<DropdownProps, 'body'> & {
  type: DataModuleEnum;
  oldValue: any;
  newValue: any;
  config?: Record<string, any>;
  oldConfig?: Record<string, any>;
};

const ValueChangePreview: FC<ValueChangePreviewProps> = (props) => {
  const { type, oldValue, newValue, oldConfig, config, ...rest } = props;

  const typeSpecificView = useMemo(() => {
    switch (type) {
      case DataModuleEnum.INPUT:
        return (
          <StyledEditedOriginalContainer>
            <div>
              <span>Original</span>
              <p>{oldValue || '-'}</p>
            </div>
            <div>
              <span>Edited</span>
              <p>{newValue || '-'}</p>
            </div>
          </StyledEditedOriginalContainer>
        );

      case DataModuleEnum.CHECKLIST:
        const difference = [
          ...new Set(newValue).symmetricDifference(new Set(oldValue)),
        ];

        const options =
          config?.options?.map((option: { label: string }) => ({
            ...option,
            value: option?.label,
            className: 'nesto',
            ...(difference.includes(option?.label) && {
              className: 'highlight-change',
            }),
          })) || [];

        return (
          <StyledEditedOriginalContainer>
            <div>
              <span>Original</span>
              <CheckboxListField
                className={classNames('checkbox-list', 'checkbox-list--left')}
                options={options}
                value={oldValue}
                disabled
              />
            </div>
            <div>
              <span>Edited</span>
              <CheckboxListField
                className={classNames('checkbox-list', 'checkbox-list--right')}
                options={options}
                value={newValue}
                disabled
              />
            </div>
          </StyledEditedOriginalContainer>
        );

      case DataModuleEnum.FORMULA:
        const { formula } = (config as FormulaLogConfig) || {};

        const { formula: oldFormula } = (oldConfig as FormulaLogConfig) || {};

        return (
          <StyledFormulaContainer>
            <span>Changes in source</span>
            <div>
              {formula?.map(
                (
                  { value, type, elementValue, dataCollectionElementId },
                  index
                ) => {
                  const valueInOldFormula = oldFormula.find(
                    (el) =>
                      el.dataCollectionElementId === dataCollectionElementId
                  )?.elementValue;

                  const doesElementHaveSameValue =
                    valueInOldFormula === elementValue;

                  return type === 'regular' ? (
                    <div key={dataCollectionElementId}>
                      <StyledDetailsTextWrapper
                        className={classNames({
                          'esg-value-change-preview--orange':
                            !doesElementHaveSameValue,
                        })}
                      >
                        {value}
                      </StyledDetailsTextWrapper>
                      {!doesElementHaveSameValue && (
                        <InputValueChange
                          oldValue={valueInOldFormula}
                          newValue={elementValue}
                          type={DataModuleEnum.INPUT}
                        />
                      )}
                    </div>
                  ) : (
                    <span key={index}>{value}</span>
                  );
                }
              )}
            </div>
          </StyledFormulaContainer>
        );

      case DataModuleEnum.TABLE:
        const newValues = Object.entries(newValue);
        const { rows = [], columns = [] } = (config as TableModuleConfig) || {};

        return (
          <StyledTableContainer>
            {newValues.map(([key, value]) => {
              const colSplit = key.split('_column_');
              if (!colSplit.length) return null;

              const rowId = colSplit[0].slice(4);
              const rowLabel = rows.find((el) => el.id === rowId)?.label;

              const colId = colSplit[1].split('_cell')[0];
              const colLabel = columns.find((el) => el.id === colId)?.label;

              return (
                <div className="table-value-log-change" key={key}>
                  <div>
                    <span>Row:</span> {rowLabel}
                  </div>
                  <div>
                    <span>Column:</span> {colLabel}
                  </div>
                  <InputValueChange
                    oldValue={oldValue?.[key]}
                    newValue={value as any}
                    type={DataModuleEnum.INPUT}
                  />
                </div>
              );
            })}
          </StyledTableContainer>
        );

      default:
        return null;
    }
  }, [config, newValue, oldConfig, oldValue, type]);

  return (
    <Dropdown
      className="esg-value-change-preview"
      body={typeSpecificView}
      portalClassName="esg-value-change-preview--body"
      {...rest}
    />
  );
};

export default ValueChangePreview;
