import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTablePreview = styled.div`
  ${flex('column')}
  gap: ${theme.sizes.SIZE_16};
  width: 100%;

  .esg-inline-editable {
    width: 100%;

    p {
      line-height: 48px;
      width: 100%;
      padding: 0 ${theme.sizes.SIZE_8};
    }

    input {
      max-width: 100%;
      width: 100%;
    }
  }

  .esg-table-preview {
    &__title {
      font-size: ${theme.fontSizes.FONT_16};
    }

    &__table {
      position: relative;

      border: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);
      border-right: none;
      border-bottom: none;

      &__row {
        display: grid;
        width: 100%;

        &:first-of-type {
          font-weight: 500;
          background-color: var(--GRAY-FB);
        }

        &__item {
          ${flex('row', 'flex-start', 'center')};
          width: 100%;
          min-height: ${theme.sizes.SIZE_48};
          border-right: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);
          border-bottom: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);

          word-break: break-word;

          .esg-inline-editable__input,
          .input,
          .input__container,
          .esg-inline-editable,
          input,
          p {
            height: 100%;
          }

          &--heading {
            padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};

            &:first-of-type {
              font-weight: 500;
              background-color: var(--GRAY-FB);
            }
          }
        }
      }
    }
  }
`;

export { StyledTablePreview };
