import { FC } from 'react';

import { StyledFlag } from './StatusFlag.styled';

interface StatusFlagProps {
  isRead: boolean;
}

const StatusFlag: FC<StatusFlagProps> = ({ isRead }) => {
  return <StyledFlag $isRead={isRead} />;
};

export default StatusFlag;
