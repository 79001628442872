import classNames from 'classnames';
import { FC } from 'react';

import XBRLFieldGroup from '../../../../components/_molecules/XBRLFieldGroup';
import { BlockUI } from '../../../../helpers';
import { useUserPermissions } from '../../../../hooks';
import { PermissionSections } from '../../../../models';
import { StyledBuilderTools } from './BuilderTools.styled';
import DataModulesTool from './components/DataModulesTool';
import PreviewTool from './components/PreviewTool';

const BuilderTools: FC<{ className?: string; items: string[] }> = ({
  className,
  items,
}) => {
  const hasCampaignPermissions = useUserPermissions(
    PermissionSections.CAMPAIGN
  );

  return (
    <StyledBuilderTools className={classNames('esg-builder-tools', className)}>
      <PreviewTool />
      <div className="esg-builder-tools__divider" />

      <XBRLFieldGroup />
      <div className="esg-builder-tools__divider" />

      <BlockUI blocked={!hasCampaignPermissions(['update'])}>
        <DataModulesTool items={items} />
      </BlockUI>
    </StyledBuilderTools>
  );
};

export default BuilderTools;
