import { useUtilities } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { XBRLTaxonomyField } from '../../../models';
import { useCampaignItemProvider } from '../../../pages/Campaigns/context/CampaignItem';
import SelectField from '../SelectField';
import { StyledXBRLSelectionFields } from './XBRLSelectionFields.styled';

export type XBRLSelectionFieldsProps = PropsWithChildren<{
  className?: string;
  showLabel?: boolean;
  selectedPackage?: string;
  taxonomyFieldGroupId?: string;
}>;

const XBRLSelectionFields: FC<XBRLSelectionFieldsProps> = ({
  className,
  showLabel = true,
  selectedPackage: pSelectedPackage,
  taxonomyFieldGroupId,
  ...rest
}) => {
  const [selectedTag, setSelectedTag] = useState<string>();

  const { dataCollection } = useCampaignItemProvider();
  const { showOverlay, hideOverlay } = useUtilities();

  const {
    data: { data: xbrlFields = [] } = {},
    isLoading: isLoadingFields,
    isValidating: isValidatingFields,
  } = useSWR<{ data: XBRLTaxonomyField[] }>(
    dataCollection?.taxonomyId &&
      API_ROUTES.CAMPAIGNS.XBRL_TAXONOMIES_FIELDS(
        dataCollection?.taxonomyId,
        taxonomyFieldGroupId
      )
  );

  const tagOptions = useMemo(
    () => xbrlFields.map(({ id, label }) => ({ value: id, label })),
    [xbrlFields]
  );

  useEffect(() => {
    (isLoadingFields || isValidatingFields ? showOverlay : hideOverlay)('form');
  }, [hideOverlay, isValidatingFields, isLoadingFields, showOverlay]);

  return (
    <StyledXBRLSelectionFields
      className={classNames(className, 'esg-xbrl-selection-fields')}
      {...rest}
    >
      {showLabel && (
        <label className="esg-xbrl-selection-fields__label">XBRL</label>
      )}

      <FormField
        name="xbrl_package_tag"
        component={SelectField}
        placeholder="Taxonomy Field Group Tag"
        autoComplete="off"
        disabled={isLoadingFields || !tagOptions.length}
        options={tagOptions}
        value={selectedTag}
        onChange={(v) => setSelectedTag(v as string)}
        hasClearAction
        clearTitle="Unselect"
        aria-describedby="xbrl-tag"
        searchable
        loading={isLoadingFields}
      />
    </StyledXBRLSelectionFields>
  );
};

export default XBRLSelectionFields;
