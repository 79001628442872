import { DataModuleEnum, ModuleMapperType } from 'models';

import {
  ChecklistPreview,
  ChoicePreview,
  DividerPreview,
  DropdownPreview,
  FormulaPreview,
  InputPreview,
  IntegrationPreview,
  SectionPreview,
  SwitchPreview,
  TablePreview,
  TextPreview,
  UploadPreview,
} from '../components/_organisms/BuilderCanvas/components/previewElements';

const modulePreviewMapper: ModuleMapperType = {
  [DataModuleEnum.CHECKLIST]: ChecklistPreview,
  [DataModuleEnum.CHOICE]: ChoicePreview,
  [DataModuleEnum.FORMULA]: FormulaPreview,
  [DataModuleEnum.DIVIDER]: DividerPreview,
  [DataModuleEnum.DROPDOWN]: DropdownPreview,
  [DataModuleEnum.INPUT]: InputPreview,
  [DataModuleEnum.INTEGRATION]: IntegrationPreview,
  [DataModuleEnum.SECTION]: SectionPreview,
  [DataModuleEnum.SWITCH]: SwitchPreview,
  [DataModuleEnum.TEXT_BOX]: TextPreview,
  [DataModuleEnum.UPLOAD]: UploadPreview,
  [DataModuleEnum.TABLE]: TablePreview,
};

export default modulePreviewMapper;
