import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import classNames from 'classnames';
import { Loading } from 'components';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../../../api/routes';
import { APP_URI } from '../../../../../../../config';
import { BlockUI } from '../../../../../../../helpers';
import { useSWRCache, useUserPermissions } from '../../../../../../../hooks';
import {
  ApiData,
  PermissionSections,
  User,
  UserFormData,
} from '../../../../../../../models';
import { useRootProvider } from '../../../../../../../providers/Root';
import ProfileDetails from '../../../../../../components/ProfileDetails';
import UserProfileActions from '../../../../components/UserProfileActions';
import { StyledUserDetails } from './UserByIdDetails.styled';

const UserByIdDetails: FC<{ className?: string }> = ({ className }) => {
  const { userId } = useParams();

  const { showSnackBar, hideOverlay, showOverlay } = useUtilities();

  const {
    data: { data: user } = {},
    mutate: mutateUser,
    isLoading: isLoadingRoles,
  } = useSWR<ApiData<User>, Error>(
    userId && API_ROUTES.USERS.USER_ROLES(userId)
  );

  const hasPermissions = useUserPermissions(PermissionSections.USER);

  const { userTokenInformation: currentUser } = useRootProvider();
  const { mutateCacheKeys } = useSWRCache();

  const navigate = useNavigate();

  const { trigger: deleteUser } = useMutation(API_ROUTES.USERS.USER);

  const { isMutating: isMutatingUpdateUser, trigger: updateUser } =
    useMutation<{ data: User }>(userId && API_ROUTES.USERS.USER_ID(userId));

  const { trigger: updateUserRole, isMutating: isMutatingRoleUpdate } =
    api.useMutation<ApiData<User>>(
      userId && API_ROUTES.USERS.USER_ROLES(userId),
      { revalidate: false }
    );

  const handleEditUser = useCallback(
    async (data: UserFormData) => {
      await updateUser({ method: 'PATCH', data }, { revalidate: false });

      const updatedUser = await updateUserRole({
        method: 'PUT',
        data: { rolesIds: [data.roleId] },
      });

      mutateCacheKeys(
        (key) => key === API_ROUTES.ACTIVITY_LOGS_ROUTES.USER(userId!)
      );

      showSnackBar({
        text: `Successfully updated ${data.firstName} ${data.lastName}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });

      mutateUser(updatedUser, { revalidate: false });
    },
    [
      mutateCacheKeys,
      mutateUser,
      showSnackBar,
      updateUser,
      updateUserRole,
      userId,
    ]
  );

  const handleDeleteUser = useCallback(async () => {
    showOverlay('body');
    await deleteUser({
      method: 'DELETE',
      url: API_ROUTES.USERS.USER_ID(userId!),
    });

    showSnackBar({
      text: `Successfully deleted ${user?.firstName} ${user?.lastName}.`,
      variant: 'success',
      actionButtonText: 'Dismiss',
    });

    hideOverlay('body');
    navigate(APP_URI.USERS, { replace: true });
  }, [
    deleteUser,
    hideOverlay,
    navigate,
    showOverlay,
    showSnackBar,
    user?.firstName,
    user?.lastName,
    userId,
  ]);

  if (isLoadingRoles) {
    return <Loading />;
  }

  return (
    <StyledUserDetails
      className={classNames('esg-user-by-id-details', className)}
    >
      <BlockUI loading={!user}>
        <ProfileDetails user={user!} />
        <UserProfileActions
          user={user}
          isLoading={isMutatingUpdateUser || isMutatingRoleUpdate}
          {...(hasPermissions(['update']) && { onEdit: handleEditUser })}
          {...(hasPermissions(['delete']) && { onDelete: handleDeleteUser })}
          isEditingOwnProfile={userId === currentUser?.id}
        />
      </BlockUI>
    </StyledUserDetails>
  );
};

export default UserByIdDetails;
