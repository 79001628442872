import { GlowScroll } from '@faxi/web-component-library';
import { APP_URI } from 'config';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BlockUI } from '../../../../helpers';
import { CampaignItem } from '../../../../models';
import { useCampaignProvider } from '../../../../providers/Campaign';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import CampaignEntity from '../CampaignEntity';

const Campaign: FC = () => {
  const { campaign = '' } = useParams<{ campaign: string }>();

  const { rootCampaign, isError, isLoadingCampaigns } = useCampaignProvider();

  const { mutating } = useCampaignFormEntitySubmit(campaign, rootCampaign);

  const navigateTo = useCallback(
    (id: string) =>
      APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC.replace(':topic', id).replace(
        ':campaign',
        campaign
      ),
    [campaign]
  );

  return (
    <BlockUI loading={isLoadingCampaigns} error={isError}>
      <GlowScroll variant="gray">
        <CampaignEntity
          campaignItem={rootCampaign as CampaignItem}
          modalProps={{ loading: mutating }}
          navigateTo={navigateTo}
          entityType={campaign ? 'topic' : 'campaign'}
        />
      </GlowScroll>
    </BlockUI>
  );
};

export default Campaign;
