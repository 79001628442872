import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledLockDrawerButtons = styled.div`
  ${flex('row', 'space-between')};
  width: ${theme.sizes.SIZE_64};
  gap: ${theme.sizes.SIZE_10};

  z-index: ${theme.zIndexes.FORM_FIELD + 2};
`;

export { StyledLockDrawerButtons };
