import {
  Button,
  StatusElement,
  Tooltip,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormRef } from '@faxi/web-form';
import classNames from 'classnames';
import { useHeightAnimation, useSWRCache } from 'hooks';
import {
  ApiData,
  DataModuleEnum,
  ModuleElementDetailsProps,
  TreeNodeElement,
} from 'models';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../../../api/routes';
import ModuleElement from '../../../../../../../components/_organisms/BuilderCanvas/components/ModuleElement';
import Icon from '../../../../../../../components/Icon';
import { BlockUI } from '../../../../../../../helpers';
import { useFileDownload } from '../../../../../../../hooks/useFileDownload';
import { useFormFileUpload } from '../../../../../../../hooks/useFormFileUpload';
import { generateInitialFormModulesData } from '../../../../../../../utils';
import { transformFormData } from '../../../../../../../utils/transformFormData';
import FormulaPreview from '../../../FormulaPreview';
import { StyledConsolidatedDataTree } from './ConsolidatedDataTree.styled';

type ConsolidatedDataTreeProps = {
  className?: string;
  level: number;
  node: TreeNodeElement;
  isLocked: boolean;
};

const getFormFieldsPrefix = (organisationId?: string) =>
  `consolidated+${organisationId ?? ''}`;

const ConsolidatedDataTree: FC<ConsolidatedDataTreeProps> = (props) => {
  const { level, node, className, isLocked } = props;

  const [subFolders, subFoldersRef] = useCallbackRef<HTMLDivElement>();
  const [form, formRef] = useCallbackRef<FormRef>();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [fileKey, setFileKey] = useState<string>();

  const { showSnackBar } = useUtilities();

  const { isLoadingDownloadUrl } = useFileDownload(fileKey);

  useHeightAnimation({ element: subFolders, isOpen: isExpanded });
  const { campaignItemId, sessionId } = useParams();

  const { trigger: submitDataConsolidateTree } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
      node?.module?.organisationSessionId!,
      campaignItemId!
    ),
    {
      onSuccess: () =>
        showSnackBar({
          text: `${node.module?.title ?? node.module?.type} successfully updated!`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );
  const {
    data: { data: formulaData } = {},
    isLoading,
    error,
  } = useSWR<ApiData<ModuleElementDetailsProps<DataModuleEnum.FORMULA>>>(
    node.module &&
      node.module.type === DataModuleEnum.FORMULA &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.DETAILS(
        node.module.organisationSessionId!,
        campaignItemId!,
        node.module.id
      )
  );

  const { initialFiles, isLoadingFiles, submitFileUpload } = useFormFileUpload(
    sessionId!,
    node?.id!,
    node.module?.organisationSessionId!,
    campaignItemId!,
    'data-consolidation'
  );

  const initialFormData = useMemo(
    () =>
      generateInitialFormModulesData(
        [node.module!],
        initialFiles,
        getFormFieldsPrefix(node.id)
      ),
    [initialFiles, node.id, node.module]
  );

  const { mutateCacheKeys } = useSWRCache();

  const handleToggle = useCallback(() => setIsExpanded((prev) => !prev), []);

  const onSubmit = useCallback(
    async (formData: Record<string, File>) => {
      const consolidatedData = transformFormData(
        formData,
        getFormFieldsPrefix(node.id)
      );

      if (node.module?.type === 'upload') {
        await submitFileUpload(
          consolidatedData.elements,
          node.id,
          'esg-consolidation-data-tree'
        );
      } else {
        await submitDataConsolidateTree({
          method: 'POST',
          data: consolidatedData,
        });

        mutateCacheKeys(
          (key) =>
            key ===
            API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_DATA_CONSOLIDATION(
              node.module?.organisationSessionId!,
              campaignItemId!,
              node?.module?.id!
            )
        );
      }
    },
    [
      campaignItemId,
      mutateCacheKeys,
      node.id,
      node.module?.id,
      node.module?.organisationSessionId,
      node.module?.type,
      submitDataConsolidateTree,
      submitFileUpload,
    ]
  );

  return (
    <StyledConsolidatedDataTree
      className={classNames('esg-consolidated-data-tree', className)}
      $level={level}
      id="esg-consolidation-data-tree"
    >
      <div className="esg-consolidated-data-tree__node">
        {level === 1 && (
          <div className="esg-consolidated-data-tree__node__header">
            <span>Company</span>
            <span>Data</span>
          </div>
        )}
        <div className="esg-consolidated-data-tree__node__container">
          <div
            tabIndex={0}
            onKeyDown={(e) => {
              if (['Enter', 'Space'].includes(e.code)) {
                handleToggle();
              }
            }}
            data-uniq-id={node.uniqName}
            className={classNames(
              'esg-consolidated-data-tree__node__container__organisation'
            )}
          >
            {!!node.children?.length && (
              <Icon
                className={classNames('esg-chevron-icon', {
                  'esg-chevron-icon--open': isExpanded,
                })}
                name="chevron-right"
                onClick={handleToggle}
              />
            )}
            {node.name}
            {level === 1 && (
              <StatusElement status="approved">Current Module</StatusElement>
            )}
          </div>
          {node.module && (
            <Form
              onSubmit={onSubmit}
              initialData={initialFormData}
              className="esg-consolidated-data-tree__node__container__form"
              ref={formRef}
            >
              {level !== 1 && node.module.type === DataModuleEnum.UPLOAD ? (
                <div className="esg-consolidated-data-tree__node__upload">
                  <BlockUI loading={isLoadingFiles || isLoadingDownloadUrl}>
                    {Object.values(initialFiles)[0]?.length ? (
                      Object.values(initialFiles)[0].map(({ name, key }) => (
                        <div
                          key={name}
                          className="esg-consolidated-data-tree__node__upload__files"
                          onClick={() => setFileKey(key)}
                        >
                          {name}
                        </div>
                      ))
                    ) : (
                      <div>Not provided yet</div>
                    )}
                  </BlockUI>
                </div>
              ) : node.module.type === DataModuleEnum.FORMULA ? (
                <div className="esg-consolidated-data-tree__node__container__form__formula">
                  {node.module?.value || 'No result.'}

                  <Tooltip
                    appendTo="parent"
                    theme="custom-tooltip"
                    content={
                      <BlockUI loading={isLoading} error={error}>
                        <div className="esg-consolidated-data-tree__node__tooltip">
                          <div className="esg-consolidated-data-tree__node__tooltip__header">
                            {node.name}
                          </div>
                          {formulaData && <FormulaPreview {...formulaData} />}
                        </div>
                      </BlockUI>
                    }
                  >
                    <div className="esg-consolidated-data-tree__node__formula">
                      See formula
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <ModuleElement
                  prefix={getFormFieldsPrefix(node.id)}
                  modulesType="preview-module"
                  type={node.module.type}
                  module={node.module}
                  key={node.module.id}
                  isInlineEditableDisabled={
                    node.module.type !== 'upload' && (level !== 1 || isLocked)
                  }
                />
              )}

              {level === 1 && node.module.type !== DataModuleEnum.FORMULA && (
                <Button
                  type="submit"
                  disabled={
                    !form?.formIsDirty() || !form.isFormChanged() || isLocked
                  }
                >
                  Submit
                </Button>
              )}
            </Form>
          )}
        </div>
      </div>

      {node.children && (
        <div
          ref={subFoldersRef}
          className="esg-consolidated-data-tree__sub-folders"
        >
          {node.children.map((child) => (
            <ConsolidatedDataTree
              key={child.id}
              node={child}
              level={level + 1}
              isLocked={isLocked}
            />
          ))}
        </div>
      )}
    </StyledConsolidatedDataTree>
  );
};

export default memo(ConsolidatedDataTree);
