import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementDetails = styled.div`
  ${flex('column')};

  .esg-module-element-details {
    &__xbrl {
      ${flex('column')};

      margin-bottom: ${theme.sizes.SIZE_32};
      gap: ${theme.sizes.SIZE_24};
    }
  }
`;

const StyledDetailsTextWrapper = styled.span`
  ${flex('row', 'center', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};

  padding: ${theme.sizes.SIZE_6} ${theme.sizes.SIZE_8};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--GRAY-EF);
  border: 1px solid var(--GRAY-C1);
  color: var(--GRAY-54);
  width: fit-content;
`;

const StyledDetailsHeader = styled.div`
  ${fontSize(theme.fontSizes.FONT_16)};
`;

export {
  StyledDetailsHeader,
  StyledDetailsTextWrapper,
  StyledModuleElementDetails,
};
