import { DataModuleEnum } from '../models';

/* This function is used to map form module elements by extracting the type and id from `expandedId`,  
since `expandedId` follows the format `id+type`. */
export const transformFormData = <T>(
  formData: Record<string, T>,
  formFieldsNamePrefix = ''
) => {
  return {
    elements: Object.entries(formData)
      .map(([expandedId, value]) => {
        const idWithoutPrefix = expandedId.replace(formFieldsNamePrefix, '');
        const [id, type] = idWithoutPrefix.split('+');

        return { id, type, value };
      })
      .reduce((acc: { id: string; type: string; value: T }[], curr) => {
        const existingItem = acc.find(({ id }) => id === curr.id);

        if (!existingItem) return [...acc, curr];

        if (
          existingItem &&
          [DataModuleEnum.DROPDOWN, `${DataModuleEnum.DROPDOWN}Other`].includes(
            existingItem.type
          )
        ) {
          const value =
            existingItem.value === 'Other' ? curr.value : existingItem.value;

          const newItem = { id: curr.id, type: DataModuleEnum.DROPDOWN, value };

          const filteredAcc = acc.filter(({ id }) => id !== curr.id);

          return [...filteredAcc, newItem];
        }

        return [...acc, curr];
      }, []),
  };
};
