import { useCallbackRef } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { SwitchField } from 'components';
import { DataModuleEnum, SwitchDataModule } from 'models';
import { FC, useState } from 'react';

import { useHeightAnimation } from '../../../../../../hooks';
import { generateModuleFieldName } from '../../../../../../utils';
import {
  StyledConditionalField,
  StyledSwitchPreview,
} from './SwitchPreview.styled';

export type SwitchPreviewProps = SwitchDataModule & { prefix?: string };

const SwitchPreview: FC<SwitchPreviewProps> = ({
  title,
  id,
  conditionalElements,
  config,
  moduleElement: ModuleElement,
  disabled,
  value,
  prefix,
}) => {
  const [checked, setChecked] = useState<boolean>(
    value !== undefined ? value : !!config?.defaultValue
  );

  const { on: moduleOn, off: moduleOff } = conditionalElements ?? {};
  const [switchOnField, switchOnFieldRef] = useCallbackRef<HTMLDivElement>();

  useHeightAnimation({ element: switchOnField, isOpen: checked });

  const [switchOffField, switchOffFieldRef] = useCallbackRef<HTMLDivElement>();

  useHeightAnimation({ element: switchOffField, isOpen: !checked });

  return (
    <StyledSwitchPreview className="esg-switch-preview">
      <div className="esg-switch-preview__main">
        <div className="esg-switch-preview__main__title">
          {title || 'Switch field'}
        </div>

        <FormField
          name={generateModuleFieldName(id, DataModuleEnum.SWITCH, prefix)}
          component={SwitchField}
          onChange={(v) => setChecked(v as boolean)}
          disabled={disabled}
        />
      </div>

      {(moduleOn || moduleOff) && (
        <div className="width-100">
          <StyledConditionalField ref={switchOnFieldRef} $open={checked}>
            {moduleOn && ModuleElement && (
              <ModuleElement
                modulesType="preview-module"
                type={moduleOn.type}
                module={moduleOn}
              />
            )}
          </StyledConditionalField>

          <StyledConditionalField ref={switchOffFieldRef} $open={!checked}>
            {moduleOff && ModuleElement && (
              <ModuleElement
                modulesType="preview-module"
                type={moduleOff.type}
                module={moduleOff}
              />
            )}
          </StyledConditionalField>
        </div>
      )}
    </StyledSwitchPreview>
  );
};

export default SwitchPreview;
