import { Table, Tooltip, useUtilities } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import UserImage from '../../../../components/_atoms/UserImage';
import Icon from '../../../../components/Icon';
import { DATE_FORMAT } from '../../../../constants';
import { BlockUI } from '../../../../helpers';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { Document, SessionsPageParams } from '../../../../models';
import SessionHeader from '../SessionHeader';
import { StyledSessionDocuments } from './SessionDocuments.styled';
import { formatCampaignItemPath, formatOrganisationPath } from './utils';

dayjs.extend(isToday);

const SessionDocuments: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();

  const [fileProps, setFileProps] = useState<{ key: string; name: string }>();

  const { showSnackBar, hideSnackBar } = useUtilities();
  const snackBarId = useRef('');

  const {
    data: { data: documents } = { data: [] },
    error: filesError,
    isLoading: isLoadingFiles,
  } = useSWR<{ data: Document[] }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_DOCUMENTS(sessionId!)
  );

  const { isLoadingDownloadUrl, downloadUrlError } = useFileDownload(
    fileProps?.key
  );

  const tableData = documents.map(
    (
      {
        key,
        name,
        campaignItemPath,
        createdAt,
        userInfo,
        organisationPath,
        fileType,
      },
      index
    ) => ({
      id: index + 1,
      fileName: (
        <div
          onClick={() => setFileProps({ key, name })}
          className="esg-session-document-name"
        >
          <Icon name="file-doc" />
          {name}
        </div>
      ),
      uploadedBy: (
        <div className="esg-session-document-user">
          <UserImage
            src={userInfo.src}
            name={[userInfo.firstName, userInfo.lastName].join(' ').trim()}
          />
          {userInfo.firstName} {userInfo.lastName}
        </div>
      ),
      organisationPath: (
        <Tooltip
          content={`Organisation path: ${formatOrganisationPath(organisationPath)}`}
          placement="bottom"
        >
          <div>{organisationPath.split('#').at(-1)}</div>
        </Tooltip>
      ),
      campaignItemPath: (
        <Tooltip
          content={`Campaign path: ${formatCampaignItemPath(campaignItemPath)}`}
          placement="bottom"
        >
          <div>{campaignItemPath.split('#').at(-1)}</div>
        </Tooltip>
      ),
      fileOrigin: fileType.replace('_', ' '),
      createdAt: dayjs(createdAt).isToday()
        ? `Today, ${dayjs(createdAt).format('HH:mm')}`
        : dayjs(createdAt).format(`${DATE_FORMAT}, HH:mm`),
    })
  );

  useEffect(() => {
    if (!fileProps) return;

    if (isLoadingDownloadUrl) {
      snackBarId.current = showSnackBar(
        {
          text: `Downloading ${fileProps?.name}...`,
          variant: 'loading',
          className: 'esg-snack-bar-loading',
        },
        { constant: true }
      );
    } else {
      hideSnackBar(snackBarId.current);
      snackBarId.current = '';
      setFileProps(undefined);
    }
  }, [fileProps, hideSnackBar, isLoadingDownloadUrl, showSnackBar]);

  return (
    <StyledSessionDocuments>
      <BlockUI
        loading={isLoadingFiles}
        error={!!filesError || !!downloadUrlError}
        fallbackCondition={!tableData?.length}
      >
        <SessionHeader heading="Documents" icon="cabinet-filling" />

        <Table
          tableId="session-documents-table"
          tableData={tableData}
          excludeColumns={['id']}
          disableSort
          translationKeys={{
            id: 'Document ID',
            fileName: 'Document Name',
            uploadedBy: 'Uploaded By',
            organisationPath: 'Organization Path',
            campaignItemPath: 'Campaign Item Path',
            fileOrigin: 'File Origin',
            createdAt: 'Uploaded At',
          }}
        />
      </BlockUI>
    </StyledSessionDocuments>
  );
};
export default SessionDocuments;
