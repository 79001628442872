import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import { CampaignCommon, CampaignItem, CampaignWithItems } from 'models';
import { useCallback } from 'react';

import { API_ROUTES } from '../../../api/routes';
import { useCampaignProvider } from '../../../providers/Campaign';
import { useCampaignItemProvider } from '../context/CampaignItem';

const useCampaignFormEntitySubmit = (
  campaignItemId: string,
  currentCampaignItem?: CampaignWithItems | CampaignItem
) => {
  const {
    dataCollection,
    isMutatingCampaignItem,
    editCampaignItem,
    mutateCampaignItem,
  } = useCampaignItemProvider();

  const { editCampaign, rootCampaign, mutateRootCampaign } =
    useCampaignProvider();

  const { showSnackBar } = useUtilities();

  const { trigger: campaignMutation, isMutating: createMutating } =
    api.useMutation<{ data?: CampaignItem }>(
      API_ROUTES.CAMPAIGNS.CAMPAIGN(campaignItemId)
    );

  const { trigger: campaignItemMutation } = api.useMutation<{
    data?: CampaignItem;
  }>(
    rootCampaign &&
      API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(rootCampaign.id, campaignItemId)
  );

  const submitForm = useCallback(
    async (data: Record<string, any>, campaignItem?: CampaignCommon) => {
      if (!currentCampaignItem) return;

      if (campaignItem) {
        if (campaignItem.type === 'campaign') {
          await editCampaign(campaignItem.id, data);
        } else {
          await editCampaignItem(rootCampaign!.id, campaignItem.id, data);

          await mutateRootCampaign();
          await mutateCampaignItem();
        }
      } else {
        if (currentCampaignItem.type === 'campaign') {
          await campaignMutation({
            method: 'POST',
            data: { ...data, type: data?.type ?? 'topic' },
          });
        } else {
          await campaignItemMutation({
            url: API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(
              rootCampaign!.id,
              campaignItemId
            ),
            method: 'POST',
            data: { ...data, type: data?.type ?? 'topic' },
          });
        }
      }
      showSnackBar({
        text: `${data?.name || dataCollection?.name} successfully ${dataCollection?.id ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [
      currentCampaignItem,
      showSnackBar,
      dataCollection,
      editCampaign,
      editCampaignItem,
      rootCampaign,
      mutateRootCampaign,
      mutateCampaignItem,
      campaignMutation,
      campaignItemMutation,
      campaignItemId,
    ]
  );

  return { submitForm, mutating: createMutating || isMutatingCampaignItem };
};

export default useCampaignFormEntitySubmit;
