import { applyRef, ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { FormFooter, InputField } from 'components';
import { useValidations } from 'hooks';
import { APIIntegration } from 'models';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
} from 'react';

import { StyledIntegrationModal } from './IntegrationModal.styled';

export type IntegrationModalProps = PropsWithChildren<
  ModalProps & {
    initialData?: Pick<APIIntegration, 'name'>;
    onSubmit: (data: APIIntegration) => Promise<void>;
  }
>;

const IntegrationModal: ForwardRefRenderFunction<
  ModalRef,
  IntegrationModalProps
> = (props, ref) => {
  const { onSubmit, initialData, ...rest } = props;

  const isEdit = !!initialData;

  const modalRef = useRef<ModalRef>();

  const { validations } = useValidations();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={onSubmit}
      />
    ),
    [initialData, onSubmit]
  );

  return (
    <StyledIntegrationModal
      className="esg-manage-user-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title={isEdit ? `Edit ${initialData.name}` : 'Create New Integration'}
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel={isEdit ? 'Save' : 'Create'}
        />
      }
      {...rest}
    >
      <FormField
        key="name"
        name="name"
        component={InputField}
        autoComplete="off"
        placeholder="Name"
        validate={validations.name}
      />
    </StyledIntegrationModal>
  );
};

export default forwardRef(IntegrationModal);
