import { RouterPermissionSections } from 'models';
import { FC } from 'react';
import {
  createBrowserRouter,
  redirect,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { APP_URI } from '../../config';
import { PERMISSIONS_SECTION_ROUTER_MAPPER } from '../../constants/Permissions';
import authStorageService from '../../services/authStorageService';
import storageService from '../../services/storageService';
import {
  getUserInformationFromToken,
  hasRouteAllPermissions,
} from '../../utils';
import routes, { ExtendRouteObject } from '../Routes';

/**Function will reset ls state for now, in future
 * will reset state for roles and isLoggedIn provided
 * from auth provider
 */

const protectRoutes = (routes: ExtendRouteObject[]): RouteObject[] => {
  return routes.map(
    ({
      children,
      authorized = false,
      onlyPublic,
      permissionsGroup,
      loader: routeLoader,
      ...route
    }) => ({
      ...route,
      children: children
        ? protectRoutes(children as ExtendRouteObject[])
        : null,
      loader: async (args) => {
        const userIdToken = storageService.getItem<string>('ID_TOKEN');

        const user = getUserInformationFromToken(userIdToken);

        const doesUserHaveAnyPermissions =
          !!user?.userRouterPermissions?.length;

        const isLoggedIn = authStorageService.isLoggedIn();
        const isLoggedInNewUser = authStorageService.isLoggedInNewUser();

        if (authorized && !(isLoggedIn || isLoggedInNewUser))
          throw redirect(APP_URI.AUTH_LOGIN);

        if (isLoggedIn && !doesUserHaveAnyPermissions && permissionsGroup) {
          throw redirect(APP_URI.PERSONAL_DETAILS);
        }

        if (isLoggedInNewUser && permissionsGroup !== 'pending') {
          throw redirect(APP_URI.NEW_PASSWORD_REQUIRED);
        }

        if (
          doesUserHaveAnyPermissions &&
          permissionsGroup &&
          permissionsGroup !== 'pending' &&
          user?.userRouterPermissions &&
          !hasRouteAllPermissions(user.userRouterPermissions, permissionsGroup)
        ) {
          const firstAllowedRoute = user
            ?.userRouterPermissions?.[0] as RouterPermissionSections;

          throw redirect(
            firstAllowedRoute
              ? PERMISSIONS_SECTION_ROUTER_MAPPER[firstAllowedRoute]
              : APP_URI.ASSIGNMENTS
          );
        }

        if ((onlyPublic || permissionsGroup === 'pending') && isLoggedIn) {
          throw redirect('/');
        }

        return {
          ...(typeof routeLoader === 'boolean'
            ? routeLoader
            : routeLoader?.(args)),
        };
      },
    })
  ) as RouteObject[];
};

const Router: FC = () => {
  const router = createBrowserRouter(protectRoutes(routes));
  return <RouterProvider router={router} />;
};

export default Router;
