import { GlowScroll } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { ApiData, Notification } from 'models';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import ProfileNotifications from 'routes/components/ProfileNotifications';

import { useInfiniteFetch } from '../../../../../../../api';
import useMutation from '../../../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../../../api/routes';
import InfiniteScrollContainer from '../../../../../../../components/_molecules/InfiniteScrollContainer';
import { BlockUI } from '../../../../../../../helpers';
import useInfiniteScroll from '../../../../../../../hooks/useInfiniteScroll';
import { groupByDate } from '../../../../../../../utils/groupByDate';

export type UserNotificationsProps = PropsWithChildren<{}>;

const UserNotifications: FC<UserNotificationsProps> = (props) => {
  const {
    items,
    isLoading: isLoadingNotifications,
    nextPage,
    setSize,
    isValidating: isValidatingNotifications,
    mutate: mutateNotifications,
  } = useInfiniteFetch<Notification>(
    API_ROUTES.NOTIFICATIONS_ROUTES.NOTIFICATIONS
  );

  const { isFetching, setContainerRef } = useInfiniteScroll<HTMLDivElement>({
    hasNextPage: Boolean(nextPage),
    isLoading: isValidatingNotifications,
    loadMore: () => setSize((prev) => prev + 1),
  });

  const { trigger: readNotifications, isMutating } = useMutation<
    ApiData<{ numberOfUnreadComments: number }>
  >(API_ROUTES.NOTIFICATIONS_ROUTES.NOTIFICATIONS_READ);

  const notifications = useMemo(() => {
    const grouped = groupByDate<Notification>(items, 'createdAt');

    return Object.entries(grouped).map(([date, notifs]) => ({
      date: dayjs(date).toDate(),
      notifications: notifs,
    }));
  }, [items]);

  const markAsRead = useCallback(
    async (unreadIds: string[]) => {
      await readNotifications({ method: 'POST', data: unreadIds });
      await mutateNotifications();
    },
    [mutateNotifications, readNotifications]
  );

  useEffect(() => {
    if (isValidatingNotifications || isMutating) return;

    const unreadIds = items
      ?.filter(({ isRead }) => !isRead)
      .map(({ id }) => id);

    if (unreadIds?.length) {
      markAsRead(unreadIds);
    }
  }, [isMutating, isValidatingNotifications, items, markAsRead]);

  return (
    <BlockUI loading={isLoadingNotifications}>
      <GlowScroll variant="gray">
        <InfiniteScrollContainer ref={setContainerRef} isFetching={isFetching}>
          <ProfileNotifications notifications={notifications} />
        </InfiniteScrollContainer>
      </GlowScroll>
    </BlockUI>
  );
};

export default UserNotifications;
