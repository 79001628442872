import classNames from 'classnames';
import Lottie from 'lottie-react';
import { FC } from 'react';

import animation from './animation.json';
import { StyledErrorBoundary } from './ErrorBoundary.styled';

export type ErrorBoundaryProps = { className?: string };

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ className }) => (
  <StyledErrorBoundary className={classNames('esg-error-boundary', className)}>
    <Lottie
      animationData={animation}
      className="esg-error-boundary__animation"
    />
  </StyledErrorBoundary>
);

export default ErrorBoundary;
