export const moveItem = <T = Object>(
  array: T[],
  index: number,
  direction: 'up' | 'down' | 'left' | 'right',
  startIndex = 0
) => {
  const newArray = [...array];
  let newIndex = index;

  if (direction === 'left') {
    if (index > startIndex) newIndex--;
    else newIndex = newArray.length - 1;
  } else if (direction === 'right') {
    if (index < newArray.length - 1) newIndex++;
    else newIndex = startIndex;
  } else if (direction === 'up') {
    if (index > startIndex) newIndex--;
    else newIndex = newArray.length - 1;
  } else if (direction === 'down') {
    if (index < newArray.length - 1) newIndex++;
    else newIndex = startIndex;
  }

  const [movedItem] = newArray.splice(index, 1);
  newArray.splice(newIndex, 0, movedItem);

  return newArray;
};
