import {
  Button,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../api/routes';
import { FileUploadModalField, TextareaField } from '../../../../../components';
import Icon from '../../../../../components/Icon';
import { DEFAULT_ACCEPTED_FILE_TYPES } from '../../../../../constants';
import { BlockUI } from '../../../../../helpers';
import { useUserPermissions } from '../../../../../hooks';
import { useFileDownload } from '../../../../../hooks/useFileDownload';
import { useFormFileUpload } from '../../../../../hooks/useFormFileUpload';
import {
  DataModuleElementDrawerComponentProps,
  DocumentResponseType,
  IDataModule,
  PermissionSections,
} from '../../../../../models';
import { FormApprovalEnum } from '../../../../../models/FormProgressStatus';
import { useFormModules } from '../../../../../pages/Sessions/context/FormModules';
import { formatRelativeOrFullDate } from '../../../../../utils';
import { StyledModuleElementDataLineage } from './ModuleElementDataLineage.styled';

type DataLineage = {
  dataLineageInformation: string;
  files: DocumentResponseType[];
};

const DOWNLOAD_FILE_MESSAGE = 'Click to download this file.';

const ModuleElementDataLineage: FC<DataModuleElementDrawerComponentProps> = ({
  elementId,
  organisationSessionId,
  organisationId,
}) => {
  const { campaignItemId, sessionId } = useParams();

  const {
    data: { data: campaignItem } = {},
    isLoading: isLoadingCampaignItem,
  } = useSWR<{ data: { approval: FormApprovalEnum } }>(
    campaignItemId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
        organisationSessionId,
        campaignItemId
      )
  );

  const hasSessionsPermission = useUserPermissions(PermissionSections.SESSION);

  const [fileProps, setFileProps] = useState<{ key: string; name: string }>();

  const [form, formRef] = useCallbackRef<FormRef>();

  const { setModules } = useFormModules();

  const { data, isLoading } = useSWR<{ data: DataLineage }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_DATA_LINEAGE(
      organisationSessionId,
      campaignItemId!,
      elementId
    )
  );

  const { trigger: submitDataLineage } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_DATA_LINEAGE(
      organisationSessionId,
      campaignItemId!,
      elementId
    ),
    {
      onSuccess: () => {
        showSnackBar({
          text: 'Successfully added information.',
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      },
    },
    true
  );

  const initialData: {
    files: (File & { key: string; id: string; updatedAt: Date })[];
    dataLineageInformation: string;
  } = useMemo(() => {
    if (!data?.data?.files?.length) {
      return {
        files: [],
        dataLineageInformation: data?.data?.dataLineageInformation ?? '',
      };
    }

    const newFiles = data.data.files.map((fileData) => {
      const file = new File(
        [new Blob([''], { type: fileData.contentType })],
        fileData.name,
        { type: fileData.contentType }
      ) as File & { key: string; id: string; updatedAt: Date };

      file.key = fileData.key;
      file.id = fileData.id;
      file.updatedAt = fileData.updatedAt;
      return file;
    });

    return {
      files: newFiles,
      dataLineageInformation: data.data.dataLineageInformation,
    };
  }, [data]);

  const { isLoadingDownloadUrl } = useFileDownload(fileProps?.key);

  const { showSnackBar, hideSnackBar } = useUtilities();
  const snackBarId = useRef('');

  const { submitFileUpload } = useFormFileUpload(
    sessionId!,
    organisationId,
    organisationSessionId,
    campaignItemId!,
    'data_lineage',
    initialData.files
  );

  useEffect(() => {
    if (!fileProps) return;

    if (isLoadingDownloadUrl) {
      snackBarId.current = showSnackBar(
        {
          text: `Downloading ${fileProps?.name}...`,
          variant: 'loading',
          className: 'esg-snack-bar-loading',
        },
        { constant: true }
      );
    } else {
      hideSnackBar(snackBarId.current);
      snackBarId.current = '';
      setFileProps(undefined);
    }
  }, [fileProps, hideSnackBar, isLoadingDownloadUrl, showSnackBar]);

  const updateModules = useCallback(
    (
      modules: IDataModule[],
      elementId: string,
      dataLineageInformation: string
    ): IDataModule[] => {
      const updateModule = (module: IDataModule): IDataModule => {
        if (module.id === elementId) {
          return { ...module, dataLineageInformation };
        }

        if (module.type === 'section' && module.elements) {
          return { ...module, elements: module.elements.map(updateModule) };
        }

        return module;
      };

      return modules.map(updateModule);
    },
    []
  );

  return (
    <StyledModuleElementDataLineage
      className="esg-module-element-data-lineage"
      id="esg-module-element-data-lineage"
    >
      <BlockUI
        loading={isLoading || isLoadingCampaignItem}
        blocked={
          !isLoadingCampaignItem &&
          campaignItem?.approval === FormApprovalEnum.Approved
        }
      >
        <Form
          className="esg-module-element-data-lineage__form"
          onSubmit={async (data) => {
            const transformedData = [
              { id: elementId, type: 'upload', value: data.files },
            ];
            await submitFileUpload(
              transformedData,
              organisationId,
              'esg-module-element-data-lineage'
            );
            await submitDataLineage({
              method: 'POST',
              data: { dataLineageInformation: data.dataLineageInformation },
            });

            if (data.dataLineageInformation) {
              setModules((prevModules) =>
                updateModules(
                  prevModules,
                  elementId,
                  data.dataLineageInformation
                )
              );
            }
          }}
          initialData={initialData}
          ref={formRef}
        >
          <div className="esg-module-element-data-lineage__form__info">
            Please provide any information about this data and a description of
            the documents you upload.
          </div>
          {!initialData.dataLineageInformation && (
            <div className="esg-module-element-data-lineage__form__warning">
              <Icon name="triangle-exclamation" />
              Please provide a justification if there is no information
              available.
            </div>
          )}
          <FormField
            name="dataLineageInformation"
            component={TextareaField}
            autoComplete="off"
            placeholder="Enter Information / Justification"
          />
          <div className="esg-module-element-data-lineage__form__info">
            Please upload any relevant documentation to support this data.
          </div>
          <FormField
            name="files"
            component={FileUploadModalField}
            confirmBtnText="Submit"
            acceptedFileTypesText="Accepted file types: "
            maxFileSize={100}
            multiple={true}
            maxFiles={3}
            dropZoneText="Drag & drop files here or "
            clickToUploadText="click to upload"
            uploadingText="Uploading"
            filesCountError="Maximum number of files exceeded. Max allowed is 3."
            fileTypeError="File type not allowed"
            fileSizeError="File size too large"
            alreadyExistError="File with this name already exists"
            acceptedFileTypes={DEFAULT_ACCEPTED_FILE_TYPES}
            showPreview={false}
          />
          <div
            className={classNames(
              'esg-module-element-data-lineage__form__files',
              'esg-bypass-grayout'
            )}
          >
            {!!Object.values(initialData.files)?.length &&
              Object.values(initialData.files).map(
                ({ name, key, updatedAt }) => (
                  <div className="esg-module-element-data-lineage__form__files__container">
                    <Button
                      className="esg-module-element-data-lineage__form__files__file"
                      onClick={() => setFileProps({ name, key })}
                      key={name}
                      aria-label={DOWNLOAD_FILE_MESSAGE}
                      title={DOWNLOAD_FILE_MESSAGE}
                      variant="ghost"
                      type="button"
                    >
                      <Icon name="file-doc" />
                      {name}
                    </Button>
                    {formatRelativeOrFullDate(updatedAt)}
                  </div>
                )
              )}
          </div>
          <Button
            type="submit"
            disabled={
              !form?.isFormChanged() ||
              !form?.formIsDirty() ||
              !hasSessionsPermission(['update'])
            }
          >
            Submit
          </Button>
        </Form>
      </BlockUI>
    </StyledModuleElementDataLineage>
  );
};

export default ModuleElementDataLineage;
