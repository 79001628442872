import { KeyboardEvent, PointerEvent, TouchEvent } from 'react';

export const handler = ({
  nativeEvent: { target },
}: PointerEvent | TouchEvent | KeyboardEvent) => {
  if (isInteractiveElement(target as Element)) {
    return false;
  }

  return true;
};

const checkBypassDraggable = (element: HTMLElement | null): boolean =>
  !!element &&
  element.dataset &&
  (!!element.dataset.noDnd || checkBypassDraggable(element.parentElement));

const isInteractiveElement = (element?: Element) => {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
  ];

  if (
    element?.tagName &&
    interactiveElements.includes(element.tagName.toLowerCase())
  ) {
    if (element.classList.contains('bypass-draggable')) {
      return false;
    }
    return true;
  }

  const bypassDraggable = checkBypassDraggable(element as HTMLElement);

  if (bypassDraggable) return true;

  return false;
};
