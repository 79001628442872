import { useModalUtilities, useUtilities } from '@faxi/web-component-library';
import useMutation from 'api/hooks/useMutation';
import { API_ROUTES } from 'api/routes';
import dayjs from 'dayjs';
import { BlockUI } from 'helpers';
import { useContributors, useUserPermissions, useUserRoles } from 'hooks';
import {
  AssignmentType,
  AssignTarget,
  DataModuleEnum,
  ModuleWithConfig,
  PermissionSections,
  SectionDataModule,
} from 'models';
import LockDrawerButtons from 'pages/Sessions/components/LockDrawerButtons';
import { useSelectedSession } from 'pages/Sessions/context/SelectedSession';
import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import AssignContributors from 'routes/subrouters/Sessions/components/AssignContributors';
import DeadlineCalendar from 'routes/subrouters/Sessions/components/DeadlineCalendar';
import ViewAllContributorsModal from 'routes/subrouters/Sessions/components/ViewAllContributorsModal';

import ContributorsList from '../../../../../_molecules/ContributorsList';
import MissingInfoTooltip from '../../../../../_molecules/MissingInfoTooltip/MissingInfoTooltip.component';
import { StyledSectionPreview } from './SectionPreview.styled';

export type SectionPreviewProps = SectionDataModule;

// TODO: this needs clean-up, rush for demo
const SectionPreview: FC<SectionPreviewProps> = ({
  moduleElement: ModuleElement,
  elements,
  disabled,
  id: sectionId,
  deadline,
  organisationSessionId,
  title,
  setActiveModule,
  openDrawer,
}) => {
  const { showSnackBar } = useUtilities();

  const { closeModal, open, openModal } = useModalUtilities();

  const selectedSession = useSelectedSession();

  const params = useParams();
  const { campaignItemId } = params;

  const {
    isAssignmentsLoading,
    assignContributors,
    assignees,
    contributors,
    nodeMenuItems,
    updateAssignees,
  } = useContributors({
    organisationSessionId,
    sectionId,
    blockEndpoint: !organisationSessionId,
  });

  const hasPermission = useUserPermissions(PermissionSections.SESSION);
  const { isAdminOrSA } = useUserRoles();

  const canSetDeadline = hasPermission(['update']) && isAdminOrSA;

  const { trigger: triggerSetSectionDeadline } = useMutation<{
    data: { deadline: string };
  }>(
    organisationSessionId &&
      campaignItemId &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
        organisationSessionId,
        campaignItemId
      ),
    {
      onSuccess: ({ data: { deadline } = {} }) =>
        showSnackBar({
          text: `Deadline has been successfully ${deadline ? 'set' : 'cleared'} for the section.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  if (!elements?.length || !ModuleElement) return <></>;

  return (
    <StyledSectionPreview className="esg-section-preview">
      <div className="esg-section-preview__header">
        <div className="esg-section-preview__header__title">
          {title || 'Section element'}
        </div>
        {organisationSessionId && (
          <Fragment>
            <div className="esg-section-preview__header__assignee-list">
              <BlockUI loading={isAssignmentsLoading} onlySpinner>
                {!!assignees?.length && (
                  <Fragment>
                    <ContributorsList
                      contributors={assignees}
                      max={5}
                      onClick={openModal}
                    />
                    {open && (
                      <ViewAllContributorsModal
                        assignees={assignees}
                        nodeMenuItems={nodeMenuItems}
                        closeModal={closeModal}
                      />
                    )}
                  </Fragment>
                )}
              </BlockUI>
            </div>
            <AssignContributors
              contributors={contributors}
              btnText="Assign to Section"
              assignTarget={AssignTarget.Section}
              loading={assignContributors.isMutating}
              onSubmit={async ({ contributors }) => {
                const { data: newAssignments } =
                  await assignContributors.trigger({
                    data: {
                      assigneeIds: contributors.map(({ value }) => value),
                      organisationSessionId,
                      campaignItemId,
                      sectionId,
                      assignmentType: AssignmentType.Section,
                    },
                    method: 'POST',
                  });

                await updateAssignees(newAssignments);
              }}
              className="esg-section-preview__header__assign"
            />

            <DeadlineCalendar
              onChange={(newDeadline) => {
                if (!organisationSessionId) return;

                const finalDeadline = newDeadline || deadline;

                triggerSetSectionDeadline({
                  url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM_SECTION_DEADLINE(
                    organisationSessionId!,
                    campaignItemId!,
                    sectionId!
                  ),
                  method: 'PUT',
                  data: {
                    deadline: finalDeadline,
                    clearDeadline: !newDeadline,
                  },
                });
              }}
              triggerText="Set Deadline"
              value={deadline ? dayjs(deadline) : undefined}
              leftLimitDate={dayjs(selectedSession?.startDate)}
              rightLimitDate={dayjs(selectedSession?.endDate)}
              disablePast={false}
              disabled={!canSetDeadline}
            />
          </Fragment>
        )}
      </div>

      {elements.map((module) => (
        <div key={module.id} className="esg-section-preview__element-wrapper">
          {(module as ModuleWithConfig).config?.isDataLineageRequired &&
            !module.dataLineageInformation &&
            ![
              DataModuleEnum.SECTION,
              DataModuleEnum.TEXT_BOX,
              DataModuleEnum.FORMULA,
              DataModuleEnum.DIVIDER,
            ].includes(module.type) && (
              <MissingInfoTooltip
                module={module}
                setActiveModule={setActiveModule}
                openDrawer={openDrawer}
              />
            )}
          <div className="esg-section-preview__element-wrapper__module">
            <ModuleElement
              modulesType="preview-module"
              type={module.type}
              module={module}
              className="esg-section-preview__module"
              isInlineEditableDisabled={disabled}
              setActiveModule={setActiveModule}
              openDrawer={openDrawer}
            />
            {organisationSessionId &&
              ![DataModuleEnum.DIVIDER].includes(module.type) && (
                <LockDrawerButtons module={module} />
              )}
          </div>
        </div>
      ))}
    </StyledSectionPreview>
  );
};

export default SectionPreview;
