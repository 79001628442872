import { FC } from 'react';

import {
  DataModuleEnum,
  ModuleElementDetailsProps,
} from '../../../../../models';
import { StyledDetailsTextWrapper } from '../ModuleElementDetails/ModuleElementDetails.styled';
import { StyledFormulaPreview } from './FormulaPreview.styled';

const FormulaPreview: FC<ModuleElementDetailsProps<DataModuleEnum.FORMULA>> = (
  props
) => {
  const {
    calculation: { formula },
  } = props;

  return (
    <StyledFormulaPreview className="esg-formula-preview">
      {!!formula.length && (
        <div className="esg-formula-preview__formula--wrapper">
          <div className="esg-formula-preview__formula">
            {formula.map(({ value, type }, index) =>
              type === 'regular' ? (
                <StyledDetailsTextWrapper key={index}>
                  {value}
                </StyledDetailsTextWrapper>
              ) : (
                <span key={index}>{value}</span>
              )
            )}
          </div>
        </div>
      )}
    </StyledFormulaPreview>
  );
};

export default FormulaPreview;
