import {
  CampaignItem,
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import { createContext } from 'react';

export type ModuleActionsContextType = {
  campaignItem?: CampaignItem;
  handleDuplicateModule: (module: IDataModule) => Promise<void>;
  handleDeleteModule: (module: IDataModule) => Promise<void>;
  handleUpdateModule: (
    module: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => Promise<void>;
  handleConfigModule: (
    module: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => void;
};

export default createContext<ModuleActionsContextType>({
  handleConfigModule: () => Promise.resolve(),
  handleDeleteModule: () => Promise.resolve(),
  handleDuplicateModule: () => Promise.resolve(),
  handleUpdateModule: () => Promise.resolve(),
});
