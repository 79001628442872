import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledStatusChangeLog = styled.div`
  ${flex('row', 'flex-start', 'center')};

  gap: ${theme.sizes.SIZE_16};
`;

export { StyledStatusChangeLog };
