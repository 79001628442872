import {
  Button,
  FormButtonsType,
  ModalRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { FormContext } from '@faxi/web-form';
import { FC, MouseEvent, RefObject, useContext, useMemo } from 'react';
import { FormActions } from 'styles';

import Icon, { INameExtended } from '../../Icon';

type FormFooterProps = {
  condition?: boolean;
  className?: string;
  modal?: RefObject<ModalRef>;
  deleteLabel?: string;
  submitIcon?: INameExtended;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
  isModuleChanged?: boolean;
} & Partial<Pick<FormButtonsType, 'submitLabel' | 'cancelLabel'>>;

const FormFooter: FC<FormFooterProps> = (props) => {
  const {
    className,
    modal,
    condition = true,
    submitLabel = 'Save',
    cancelLabel = 'Cancel',
    deleteLabel = 'Delete',
    submitIcon,
    onDelete,
    isModuleChanged,
  } = props;

  const { isFormChanged, syncFormValid, asyncFormValid } =
    useContext(FormContext);

  const [FormButtons] = useFormButtons({ cancelLabel, submitLabel });

  const canSubmitForm = useMemo(() => {
    /**
     * we need to check if the form has been changed ("isFormChanged" method),
     * but also if any conditional elements of the module have been changed ("isModuleChanged" flag)
     */
    const isChanged = isModuleChanged || isFormChanged();

    return isChanged && syncFormValid && asyncFormValid;
  }, [syncFormValid, isFormChanged, isModuleChanged, asyncFormValid]);

  if (!condition) return null;

  return (
    <FormActions className={className}>
      {modal && (
        <FormButtons.Cancel
          variant="outline"
          onClick={() => {
            modal.current?.close();
          }}
        />
      )}

      <FormButtons.Submit
        iconPosition="left"
        disabled={!canSubmitForm}
        {...(submitIcon && { icon: <Icon name={submitIcon} /> })}
      />

      {onDelete && (
        <Button
          className="delete-btn"
          variant="delete-outline"
          onClick={onDelete}
        >
          {deleteLabel}
        </Button>
      )}
    </FormActions>
  );
};

export default FormFooter;
