import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionDMAExclusion = styled.div`
  height: 100%;

  .esg-session-exclusion {
    &__user,
    &__item {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_10};
    }

    &__item {
      .wcl-icon {
        ${fontSize(theme.fontSizes.FONT_16)};
      }
    }

    &__table {
      ${flex('column')}
      gap: ${theme.sizes.SIZE_24};
      padding: ${theme.sizes.SIZE_24};
      max-width: ${theme.sizes.SIZE_1200};

      .wcl-table {
        &__data {
          min-width: ${theme.sizes.SIZE_374};

          * {
            white-space: normal;
          }
        }
      }
    }
  }
`;

export { StyledSessionDMAExclusion };
