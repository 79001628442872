import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import env from 'env';

import { ApiData, AuthBody } from '../models';
import authBus, { AUTH_BUS_EVENTS } from '../modules/authBus';
import authStorageService from '../services/authStorageService';
import credentialsService from '../services/credentialsService';
import { getErrorMessage } from '../utils';
import { API_ROUTES } from './routes';

const GET_REQUEST_WHITELIST = ['/report'];

//Extend AxiosRequestConfig to include the _retry property
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

type CustomAxiosError<T = any> = AxiosError<T> & {
  config: CustomAxiosRequestConfig; //Override the config property with our custom config type
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: env.VITE_API_ENDPOINT,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = credentialsService.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

const requestErrorsCounter: Map<string, { counter: number }> = new Map();

let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: CustomAxiosError) => {
    const { config: request, response } = error;
    const requestKey = `${request.url}-${request.method}`;
    try {
      if (response) {
        if (
          [401, 403].includes(response.status) &&
          !isRefreshing &&
          !request._retry
        ) {
          isRefreshing = true;
          request._retry = true;

          const { data } = await axiosInstance.post<ApiData<AuthBody>>(
            API_ROUTES.USERS.REFRESH_TOKEN,
            { refreshToken: credentialsService.refreshToken }
          );

          authStorageService.loginUser({
            ...data.data,
            refreshToken: credentialsService.refreshToken,
          });

          return axiosInstance(request);
        }
      }
    } catch (e) {
      console.error('Token refresh failed');
      authBus.broadcastEvent(AUTH_BUS_EVENTS.LOGOUT, { withoutApiCall: true });
      return Promise.reject(e);
    } finally {
      isRefreshing = false;
    }

    const requestErrorsCount = requestErrorsCounter.get(requestKey) || {
      counter: 0,
    };

    const isWhitelistedRequest = GET_REQUEST_WHITELIST.some((entry) =>
      response?.config?.url?.includes(entry)
    );

    if (
      requestErrorsCount.counter === 0 ||
      (response && response.config.method?.toLowerCase() !== 'get') ||
      isWhitelistedRequest
    ) {
      authBus.broadcastEvent('show_snackbar', {
        props: {
          text: getErrorMessage(error),
          variant: 'error',
          actionButtonText: 'Dismiss',
        },
        config: { constant: false, disappearAfter: 3000 },
      });
    }

    ++requestErrorsCount.counter;
    requestErrorsCounter.set(requestKey, requestErrorsCount);
    if (requestErrorsCount.counter >= 3) {
      requestErrorsCounter.delete(requestKey);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
