import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DroppableContainer,
  MeasuringStrategy,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { RectMap } from '@dnd-kit/core/dist/store';
import { arrayMove } from '@dnd-kit/sortable';
import {
  GlowScroll,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { BuilderCanvas, EntityFormModal, FolderNavHeader } from 'components';
import { BlockUI } from 'helpers';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import {
  CampaignItem,
  DataModuleEnum,
  IDataModule,
  PermissionSections,
} from 'models';
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { BuilderCanvasRef } from '../../../../components/_organisms/BuilderCanvas/BuilderCanvas.component';
import { SmartKeyboardSensor } from '../../../../components/_organisms/BuilderCanvas/classes/SmartKeyboardSensor/SmartKeyboardSensor.class';
import { SmartPointerSensor } from '../../../../components/_organisms/BuilderCanvas/classes/SmartPointerSensor/SmartPointerSensor.class';
import { SmartTouchSensor } from '../../../../components/_organisms/BuilderCanvas/classes/SmartTouchSensor/SmartTouchSensor.class';
import ModuleElement from '../../../../components/_organisms/BuilderCanvas/components/ModuleElement';
import { useModuleActionsProvider } from '../../../../components/_organisms/BuilderCanvas/providers/ModuleActions.provider';
import { useURLCampaignItemId, useUserPermissions } from '../../../../hooks';
import { useCampaignProvider } from '../../../../providers/Campaign';
import { DndDataContext } from '../../../../providers/DndData/DndData.context';
import { coordinateGetter } from '../../../../utils';
import { useCampaignItemProvider } from '../../context/CampaignItem';
import { useFormBuilder } from '../../context/FormBuilder';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import {
  campaignItemTypeSelectOptions,
  dataModuleTextMapper,
  generateCampaignItemCrumbs,
} from '../../utils';
import BuilderTools from '../BuilderTools';
import CanvasModule from '../Dnd/components/CanvasModule';
import DataModule from '../Dnd/components/DataModule';
import {
  addNewModuleToDragArea,
  findModule,
  getModuleIdsAndTypes,
  hasCollisionWithChildren,
  moveModuleIntoSection,
  moveModuleOutOfSection,
} from '../Dnd/utils';
import SubTopic from '../SubTopic';
import { StyledSubSubTopic } from './SubSubTopic.styled';
import {
  filterModulesAndElementsByType,
  getAllModuleIdsWithLevelInfo,
  ModuleIdLevel,
} from './utils';

const SubSubTopic: FC<{ level: 0 | 1 | 2 | 3 }> = ({ level }) => {
  const { campaignItemId: dataCollectionElementId } = useURLCampaignItemId();

  const { showOverlay, hideOverlay } = useUtilities();

  const { pathname } = useLocation();

  const hasPermission = useUserPermissions(PermissionSections.CAMPAIGN);

  const hasUpdatePermission = hasPermission(['update']);

  const { draggingModule, setDraggingModule, droppedInDataModules } =
    useContext(DndDataContext);

  const { modules, setModules } = useFormBuilder();

  // given that manual rearrangement is applied, the DnD returns "active" and "over" as a same element
  // thus we have to have a flag that the elements have indeed been rearranged
  const orderChanged = useRef(false);

  const { rootCampaign } = useCampaignProvider();
  const { campaignItem: moduleCampaignItem } = useModuleActionsProvider();

  const {
    dataCollection,
    isForm,
    isLoadingCampaignItem,
    isValidatingCampaignItem,
  } = useCampaignItemProvider();

  const activeCampaignItem = useMemo(
    () => (isForm ? dataCollection : moduleCampaignItem),
    [moduleCampaignItem, dataCollection, isForm]
  );

  // TODO: check if this state is needed, moved from campaign item provider
  const [campaignItem, setCampaignItem] = useState(activeCampaignItem);

  const [builderCanvas, builderCanvasRef] = useCallbackRef<BuilderCanvasRef>();

  const draggingModuleInitialWidth = useRef<number>();

  const [builderToolsItems, setBuilderToolsItems] = useState(
    Object.keys(dataModuleTextMapper)
  );

  const modalRef = useRef<ModalRef>(null);

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    dataCollectionElementId,
    campaignItem
  );

  const openEditModal = useCallback(
    (item: CampaignItem) => {
      setCampaignItem(item);
      modalRef?.current?.open();
    },
    [setCampaignItem]
  );

  const crumbs = useMemo(
    () => generateCampaignItemCrumbs(rootCampaign, campaignItem),
    [campaignItem, rootCampaign]
  );

  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(SmartTouchSensor),
    useSensor(SmartKeyboardSensor, { coordinateGetter })
  );

  const modulesIdsWithLevels = useMemo(
    () => getAllModuleIdsWithLevelInfo(modules),
    [modules]
  );

  const initialModulesIdsWithLevels = useRef<ModuleIdLevel[] | null>(
    modulesIdsWithLevels
  );

  const lastActiveModuleCenter = useRef(0);

  const moveOutOfSectionDirection = useRef<'up' | 'down'>();

  const movedDataModuleIntoASection = useRef(false);

  useEffect(() => {
    return () => {
      initialModulesIdsWithLevels.current = null;
      lastActiveModuleCenter.current = 0;
      moveOutOfSectionDirection.current = undefined;
      movedDataModuleIntoASection.current = false;
      orderChanged.current = false;
      draggingModuleInitialWidth.current = undefined;
    };
  }, [pathname]);

  useEffect(() => {
    (isValidatingCampaignItem ? showOverlay : hideOverlay)('#builder-canvas');
  }, [hideOverlay, isValidatingCampaignItem, showOverlay]);

  useEffect(() => {
    setCampaignItem(activeCampaignItem);
  }, [activeCampaignItem]);

  return (
    <DndContext
      measuring={{ droppable: { strategy: MeasuringStrategy.WhileDragging } }}
      sensors={sensors}
      collisionDetection={(ev) => {
        const canvasDroppableBaseRects: RectMap = new Map();
        const canvasDroppableSectionRects: RectMap = new Map();

        for (const [key, value] of ev.droppableRects.entries()) {
          const id = `${key}`;

          if (id.startsWith('canvas-module_') && id !== ev.active.id) {
            (id.endsWith('+section')
              ? canvasDroppableSectionRects
              : canvasDroppableBaseRects
            ).set(key, value);
          }
        }

        const canvasDroppableBaseContainers: DroppableContainer[] = [];
        const canvasDroppableSectionContainers: DroppableContainer[] = [];

        for (let i = 0; i < ev.droppableContainers.length; ++i) {
          const id = `${ev.droppableContainers[i].id}`;

          if (id.startsWith('canvas-module_') && id !== ev.active.id) {
            (id.endsWith('+section')
              ? canvasDroppableSectionContainers
              : canvasDroppableBaseContainers
            ).push(ev.droppableContainers[i]);
          }
        }

        const evCanvasBaseModules = {
          ...ev,
          droppableContainers: canvasDroppableBaseContainers,
          droppableRects: canvasDroppableBaseRects,
        };

        const pointerWithinCollisionsWithoutSections =
          pointerWithin(evCanvasBaseModules);

        if (pointerWithinCollisionsWithoutSections.length) {
          return pointerWithinCollisionsWithoutSections;
        }

        const collisions: { id: string }[] = [];

        for (const [
          sectionId,
          section,
        ] of canvasDroppableSectionRects.entries()) {
          const pointerX = Number(ev.pointerCoordinates?.x);
          const pointerY = Number(ev.pointerCoordinates?.y);

          const lastActiveCenterValue = lastActiveModuleCenter.current;

          const isXInside = pointerX < section.right;

          if (!isXInside) return collisions;

          /**
           * I case => from above section, get into section
           */
          if (
            pointerY > lastActiveCenterValue &&
            lastActiveCenterValue < section.top &&
            pointerY > section.top &&
            pointerY < section.bottom
          ) {
            collisions.push({ id: `${sectionId}` });
          } else if (
            /**
             * II case => from section, get below section
             */
            lastActiveCenterValue > section.top &&
            lastActiveCenterValue < section.bottom &&
            pointerY > section.bottom
          ) {
            moveOutOfSectionDirection.current = 'down';
            collisions.push({ id: `${sectionId}` });
          } else if (
            /**
             * III case => from section, get above section
             */
            pointerY < lastActiveCenterValue &&
            lastActiveCenterValue > section.top &&
            lastActiveCenterValue < section.bottom &&
            pointerY < section.top
          ) {
            moveOutOfSectionDirection.current = 'up';
            collisions.push({ id: `${sectionId}` });
          } else if (
            /**
             * IV case => from below section, get into section
             */
            lastActiveCenterValue > section.bottom &&
            pointerY > section.top &&
            pointerY < section.bottom
          ) {
            collisions.push({ id: `${sectionId}` });
          } else if (
            /**
             * V case => from right (data modules) into section
             */
            !movedDataModuleIntoASection.current &&
            `${ev.active.id}`.startsWith('data-module_') &&
            pointerY > section.top &&
            pointerY < section.bottom &&
            pointerX < section.right &&
            pointerX > section.left
          ) {
            collisions.push({ id: `${sectionId}` });
          }
        }

        return collisions;
      }}
      onDragStart={(ev) => {
        if (!`${ev.active.id}`.startsWith('data-module_')) {
          const el = document.getElementById(`${ev.active.id}`);

          const { top = 0, bottom = 0 } = el?.getBoundingClientRect() ?? {};

          lastActiveModuleCenter.current = (top + bottom) / 2;
        }

        const { activeModuleId, activeModuleType } = getModuleIdsAndTypes(
          ev as DragOverEvent
        );

        // the width seems to be 2px smaller than reality
        // at the moment of development, did not investigate the reason
        draggingModuleInitialWidth.current =
          activeModuleType === 'data-module'
            ? document.getElementById('builder-canvas')?.clientWidth
            : Number(
                document.getElementById(`canvas-module_${activeModuleId}`)
                  ?.clientWidth
              ) + 2;

        // this is a module which is attached to the cursor while dragging
        setDraggingModule(() => {
          if (activeModuleType === 'data-module') {
            return {
              type: 'data-module',
              dataModuleType: activeModuleId as DataModuleEnum,
            };
          } else {
            const { module: activeModule } = findModule(
              activeModuleId,
              modules
            );

            return {
              type: 'canvas-module',
              canvasModuleDetails: {
                ...activeModule,
                isNew: false,
              } as IDataModule<DataModuleEnum>,
            };
          }
        });
      }}
      onDragEnd={(ev) => {
        if (!orderChanged.current) return;

        orderChanged.current = false;
        movedDataModuleIntoASection.current = false;

        const { activeModuleId, activeModuleType, overModuleType } =
          getModuleIdsAndTypes(ev);

        if (
          activeModuleType === 'data-module' &&
          overModuleType === 'data-module'
        ) {
          setModules((old) => old.filter(({ isNew }) => !isNew));
          setDraggingModule(undefined);

          return;
        }

        setModules((old) => {
          old.forEach((m) => {
            m.isNew = false;

            m.elements?.forEach((e) => {
              e.isNew = false;
            });
          });
          return old;
        });

        setTimeout(() => {
          if (!droppedInDataModules.current) {
            if (
              !isEqual(
                modulesIdsWithLevels,
                initialModulesIdsWithLevels.current
              )
            ) {
              builderCanvas?.handleDragEnd(ev);
              initialModulesIdsWithLevels.current = [...modulesIdsWithLevels];
            }
          } else {
            const draggingModuleType =
              draggingModule?.canvasModuleDetails?.type;

            setModules(filterModulesAndElementsByType(draggingModuleType));
          }
        }, 0);

        setDraggingModule(undefined);

        if (activeModuleType === 'data-module') {
          setBuilderToolsItems((old) =>
            old.filter((i) => i !== activeModuleId)
          );

          setTimeout(() => {
            setBuilderToolsItems(Object.keys(dataModuleTextMapper));
          }, 0);
        }
      }}
      onDragOver={(ev) => {
        // this is complete id, including prefix 'data-module_'
        // and 'canvas-module_'
        if (ev.active.id === ev.over?.id || !ev.over) return;

        const {
          activeModuleId,
          activeModuleType,
          overModuleId,
          overModuleType,
        } = getModuleIdsAndTypes(ev);

        // even though the first line in function prevents same ids
        // this is the case when data module enters canvas, id may be the same
        // without looking at the prefix
        if (activeModuleId === overModuleId || !overModuleId) return;

        const isOverModuleDropArea = overModuleId === 'drop-area';

        if (!isOverModuleDropArea) {
          setTimeout(() => {
            const el = document.getElementById(
              activeModuleType !== 'data-module'
                ? `${ev.active.id}`
                : `canvas-module_${activeModuleType}`
            );

            const { top = 0, bottom = 0 } = el?.getBoundingClientRect() ?? {};

            lastActiveModuleCenter.current = (top + bottom) / 2;
            // 10ms is to leave room for rerendering glitch
          }, 10);
        }

        // transformation occurs only if active module is a data-module
        if (
          (!draggingModule && activeModuleType === 'data-module') ||
          (!(
            activeModuleType === 'canvas-module' &&
            overModuleType === 'data-module'
          ) &&
            draggingModule &&
            overModuleType !== draggingModule.type)
        ) {
          // this is a module which is attached to the cursor while dragging
          setDraggingModule(() => ({
            ...(overModuleType === 'canvas-module'
              ? {
                  type: 'canvas-module',
                  canvasModuleDetails: {
                    id: activeModuleType,
                    index: modules.length + 1,
                    title: '',
                    type: activeModuleId as DataModuleEnum,
                  },
                }
              : {
                  type: 'data-module',
                  dataModuleType: activeModuleId as DataModuleEnum,
                }),
          }));
        }

        setModules((oldModules) => {
          const isNewModule = Object.values(DataModuleEnum).includes(
            activeModuleId as DataModuleEnum
          );

          if (!isOverModuleDropArea || isNewModule) {
            orderChanged.current = true;
          }

          const clonedModules = cloneDeep(oldModules);

          const {
            parent: parentModuleOfActive,
            module: activeModule,
            moduleIndex: activeModuleIndex,
          } = findModule(activeModuleId, clonedModules);

          const {
            parent: parentModuleOfOver,
            module: overModule,
            moduleIndex: overModuleIndex,
          } = findModule(overModuleId, clonedModules);

          // ignore collision of section with its children
          if (
            hasCollisionWithChildren(overModuleId, activeModule?.elements || [])
          ) {
            return oldModules;
          }

          const isOverModuleSection = overModuleId?.endsWith('+section');

          // add a new element to drag area
          if (activeModuleIndex === -1 && !isOverModuleSection) {
            const newElIndex = isOverModuleDropArea ? clonedModules.length : 0;

            return addNewModuleToDragArea(
              activeModuleId,
              newElIndex,
              clonedModules
            );
          } else {
            if (isOverModuleSection) {
              // the active module is already in section, move it out
              // and move it in proper location
              if (parentModuleOfActive && activeModule && overModule) {
                const isModuleChildOfOver = !!findModule(
                  activeModuleId,
                  overModule.elements || []
                ).module;

                moveModuleOutOfSection(activeModuleIndex, parentModuleOfActive);
                moveModuleIntoSection(
                  activeModule,
                  isModuleChildOfOver
                    ? parentModuleOfOver?.elements || clonedModules
                    : overModule.elements!,
                  overModuleIndex,
                  moveOutOfSectionDirection.current!
                );

                draggingModuleInitialWidth.current = Number(
                  document.getElementById(
                    `section-canvas_${isModuleChildOfOver && parentModuleOfOver ? parentModuleOfOver.id : overModuleId}`
                  )?.clientWidth
                );

                return clonedModules;
              }

              // move it in section
              if (
                overModule &&
                !findModule(activeModuleId, overModule.elements ?? []).module
              ) {
                moveModuleIntoSection(
                  activeModule || {
                    id: activeModuleId,
                    index: overModule.elements?.length || 0,
                    title: '',
                    type: activeModuleId as DataModuleEnum,
                    isNew: true,
                  },
                  overModule.elements!,
                  overModule.elements?.length || 0,
                  moveOutOfSectionDirection.current!
                );

                draggingModuleInitialWidth.current = Number(
                  document.getElementById(`section-canvas_${overModuleId}`)
                    ?.clientWidth
                );

                movedDataModuleIntoASection.current = true;
              }

              // remove the active module from its previous location
              if (activeModuleIndex > -1 && !parentModuleOfActive) {
                clonedModules.splice(activeModuleIndex, 1);
              }

              return clonedModules;
            } else {
              // just rearrange
              if (!parentModuleOfActive && !parentModuleOfOver) {
                draggingModuleInitialWidth.current = Number(
                  document.getElementById('builder-canvas')?.clientWidth
                );

                return arrayMove(
                  clonedModules,
                  activeModuleIndex,
                  overModuleIndex
                );
              } else {
                if (
                  parentModuleOfActive &&
                  parentModuleOfOver &&
                  activeModule
                ) {
                  // moving elements within the same section
                  if (parentModuleOfActive === parentModuleOfOver) {
                    parentModuleOfActive.elements = arrayMove(
                      parentModuleOfActive.elements!,
                      activeModuleIndex,
                      overModuleIndex
                    );
                  } else {
                    // moving elements between sections
                    const x = cloneDeep(activeModule);

                    parentModuleOfActive.elements =
                      parentModuleOfActive?.elements?.filter(
                        ({ id }) => activeModuleId !== id
                      );

                    parentModuleOfOver.elements?.splice(overModuleIndex, 0, x);
                  }
                }

                return clonedModules;
              }
            }
          }
        });
      }}
    >
      <GlowScroll variant="gray">
        <BlockUI
          loading={
            isLoadingCampaignItem || isValidatingCampaignItem || !campaignItem
          }
        >
          {campaignItem?.type === 'data_collection' ? (
            <StyledSubSubTopic className="esg-sub-sub-topic">
              {campaignItem && (
                <FolderNavHeader
                  crumbs={crumbs}
                  title={campaignItem.name}
                  icon="clipboard-list"
                  description={campaignItem.description}
                  updateDescription={() => openEditModal(campaignItem)}
                  hasActions={hasUpdatePermission}
                />
              )}

              <BuilderCanvas
                ref={builderCanvasRef}
                hasUpdatePermission={hasUpdatePermission}
              />

              <EntityFormModal
                ref={modalRef}
                title={`Edit ${campaignItem?.name}`}
                loading={mutating}
                onSubmit={(data) => submitForm(data, campaignItem)}
                initialData={campaignItem}
                // onClose={() => setCampaignItem(undefined)}
                fieldProps={{
                  type: { options: campaignItemTypeSelectOptions(level) },
                }}
                taxonomyId={rootCampaign?.taxonomyId}
                fieldsConfiguration={{
                  TYPE: true,
                  NAME: true,
                  DESCRIPTION: true,
                  EMAIL: false,
                  ROLE: false,
                  CAMPAIGN: false,
                  TAXONOMY_ID: false,
                  TAXONOMY_GROUP: true,
                }}
              />
            </StyledSubSubTopic>
          ) : (
            <SubTopic level={level} />
          )}
        </BlockUI>
      </GlowScroll>

      {isForm && (
        <BuilderTools
          className="esg-campaigns__tools"
          items={builderToolsItems}
        />
      )}

      {createPortal(
        <DragOverlay>
          {draggingModule ? (
            <Fragment>
              {/* MOVING AN ELEMENT FROM THE TOOLS SECTION INTO FORM BUILDER */}
              {draggingModule.type === 'data-module' ? (
                <DataModule type={draggingModule.dataModuleType!} isGrabbing />
              ) : (
                <CanvasModule
                  id={draggingModule.canvasModuleDetails!.id}
                  module={draggingModule.canvasModuleDetails!}
                  onClickDelete={() => {}}
                  isGrabbing
                  width={draggingModuleInitialWidth.current}
                  hasUpdatePermission={hasUpdatePermission}
                >
                  <ModuleElement
                    type={draggingModule.canvasModuleDetails?.type!}
                    module={draggingModule.canvasModuleDetails!}
                    isInlineEditableDisabled={!hasUpdatePermission}
                  />
                </CanvasModule>
              )}
            </Fragment>
          ) : undefined}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default SubSubTopic;
