import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import { CollapsibleSidePanel } from 'components';
import styled from 'styled-components';

const StyledSessionSidebar = styled(CollapsibleSidePanel)`
  hr {
    margin: ${theme.sizes.SIZE_20} 0;
  }

  h1 {
    margin-top: ${theme.sizes.SIZE_20};
    font-weight: 400;
  }

  .esg-tree-node-component .esg-tree-node-component {
    padding-left: ${theme.sizes.SIZE_24};
  }

  .esg-collapsible-side-panel {
    ${flex('column', 'space-between', 'center')};
    ${fontSize(theme.fontSizes.FONT_14)};
    padding: unset;
    scroll-padding-bottom: 20px;

    height: 100%;
  }

  .esg-session-sidebar {
    &__nav-container {
      ${flex('column')};
      ${size('100%')};
      scroll-padding-bottom: ${theme.sizes.SIZE_16};

      &__top {
        padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_24} 0;
      }

      &__report-download {
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_10};

        border-bottom: ${theme.sizes.SIZE_20} solid transparent;
        border-top: ${theme.sizes.SIZE_20} solid transparent;
        width: 100%;
        padding: 0 ${theme.sizes.SIZE_24};

        button {
          width: 100%;
        }

        &__tooltip-icon {
          font-size: ${theme.fontSizes.FONT_20};
        }
      }

      &__nav {
        overflow: auto;
        padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};

        button {
          margin-bottom: ${theme.sizes.SIZE_24};
        }

        &__search {
          margin-bottom: ${theme.sizes.SIZE_32};
        }
      }

      &__report {
        a {
          ${flex('row', 'flex-start', 'center')};
          gap: ${theme.sizes.SIZE_8};

          color: var(--BLACK-00);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &__tree {
          width: 100%;
          resize: none;
          border: none;
          padding: ${theme.sizes.SIZE_16} 0;
        }
      }

      &__subtitle {
        margin-bottom: ${theme.sizes.SIZE_16};
      }

      &__not-browser-view {
        padding: 0 ${theme.sizes.SIZE_24};

        &__details {
          ${flex('column', 'space-between', 'normal')};
          gap: ${theme.sizes.SIZE_16};

          > * {
            height: ${theme.sizes.SIZE_24};
          }

          p {
            ${fontSize(theme.fontSizes.FONT_14)};
          }

          &__date {
            ${flex('row', 'space-between', 'center')};

            p {
              margin: 0;
            }

            &:not(:first-of-type) {
              margin-bottom: ${theme.sizes.SIZE_20};
            }
          }
        }

        &__links {
          ${flex('column', 'space-between', 'normal')};
          gap: ${theme.sizes.SIZE_16};
        }

        &__subtitle {
          margin-bottom: ${theme.sizes.SIZE_16};
        }
      }

      .esg-approval-chip {
        max-width: ${theme.sizes.SIZE_208};
      }

      .esg-approval-progress-bar {
        justify-content: space-between;

        .kinto-progress-bar {
          max-width: ${theme.sizes.SIZE_208};
        }
      }
    }
  }
`;

export { StyledSessionSidebar };
