import {
  Button,
  GlowScroll,
  Tabs,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import { API_ROUTES } from 'api/routes';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import {
  ApiData,
  PermissionSections,
  User,
  UserFormData,
  UserRole,
} from 'models';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import api from '../../api';
import { ViewGuard } from '../../components';
import { BlockUI } from '../../helpers';
import { useLayoutPreviewMode } from '../../hooks';
import { previewTabs } from '../../hooks/useLayoutPreviewMode';
import { LayoutPreview } from '../../models/LayoutPreview';
import { useRootProvider } from '../../providers/Root';
import ManageUserModal from './components/ManageUserModal';
import UsersPreview from './components/UsersPreview.component';
import { StyledUsers } from './Users.styled';

export type NewSessionForm = { name: string; description: string };

const Users: FC = () => {
  const [selectedUser, setSelectedUser] = useState<User>();

  const navigate = useNavigate();
  const { open, openModal, closeModal } = useModalUtilities();
  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const { user } = useRootProvider();

  const {
    data: { data: users } = {},
    isLoading,
    isValidating,
    mutate: mutateUsers,
    error,
  } = useSWR<ApiData<User[]>, Error>(API_ROUTES.USERS.USER);

  const { view, setView } = useLayoutPreviewMode('grid');

  const { trigger: triggerCreateUser, isMutating: isMutatingCreateUser } =
    api.useMutation<{ data: User }>(API_ROUTES.USERS.USER, {
      revalidate: false,
    });

  const { trigger: triggerUpdateUser, isMutating: isMutatingUpdateUser } =
    api.useMutation<{ data: User }>(API_ROUTES.USERS.USER_ID(''), {
      revalidate: false,
    });

  const { trigger: triggerUserRoles, isMutating: isMutatingRoles } =
    api.useMutation<{ data: User }>(
      API_ROUTES.USERS.USER_ROLES(selectedUser?.id || ''),
      { revalidate: false }
    );

  const updateUser = useCallback(
    async (data: UserFormData, role?: UserRole) => {
      if (!selectedUser) return;

      await triggerUpdateUser({
        url: API_ROUTES.USERS.USER_ID(selectedUser.id),
        method: 'PATCH',
        data,
      });

      const { data: updatedUserWithRole } = await triggerUserRoles({
        method: 'PUT',
        url: API_ROUTES.USERS.USER_ROLES(selectedUser.id),
        data: { rolesIds: [role?.id] },
      });

      mutateUsers(
        (cache) => {
          if (!cache) return;
          return {
            data: cache.data.map((user) =>
              user.id === updatedUserWithRole.id ? updatedUserWithRole : user
            ),
          };
        },
        { revalidate: false }
      );

      showSnackBar({
        text: `Successfully updated ${data.firstName} ${data.lastName}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
      setSelectedUser(undefined);
      closeModal();
    },
    [
      closeModal,
      mutateUsers,
      selectedUser,
      showSnackBar,
      triggerUpdateUser,
      triggerUserRoles,
    ]
  );

  const createUser = useCallback(
    async (data: UserFormData, role?: UserRole) => {
      const { data: newUser } = await triggerCreateUser({
        url: API_ROUTES.USERS.USER,
        method: 'POST',
        data,
      });

      mutateUsers(
        (prev) => ({
          data: [
            ...(prev?.data ?? []),
            { ...newUser, ...(role && { roles: [role] }) },
          ],
        }),
        { revalidate: false }
      );

      showSnackBar({
        text: `Successfully created ${data.firstName} ${data.lastName}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });

      const container = document.getElementsByClassName(
        'esg-users__container'
      )[0];

      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });

      closeModal();
    },
    [closeModal, mutateUsers, showSnackBar, triggerCreateUser]
  );

  const onSubmit = useCallback(
    async (data: UserFormData, role?: UserRole) => {
      try {
        if (selectedUser) {
          closeModal();
          await updateUser(data, role);
        } else {
          await createUser(data, role);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [closeModal, createUser, selectedUser, updateUser]
  );

  const initialData = useMemo(
    () =>
      selectedUser
        ? {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            jobTitle: selectedUser.jobTitle,
            roleId: selectedUser.roles?.[0]?.id,
          }
        : undefined,
    [selectedUser]
  );

  return (
    <BlockUI loading={isLoading || isValidating} error={!!error}>
      <StyledUsers
        title="Users"
        direction="column"
        className="esg-users"
        padding={0}
      >
        <GlowScroll variant="gray">
          <div className="esg-users__container">
            <div className="esg-users__container__header">
              <div className="esg-users__container__header__buttons">
                <ViewGuard
                  section={PermissionSections.USER}
                  permissions={['create']}
                >
                  <Button
                    icon={<Icon name="plus" />}
                    variant="outline"
                    onClick={openModal}
                  >
                    Create New User
                  </Button>
                </ViewGuard>
                <Button
                  icon={<Icon name="user-gear" />}
                  variant="outline"
                  onClick={() => navigate(APP_URI.USER_ROLES)}
                >
                  User Roles
                </Button>
              </div>
              {users?.length! > 1 && (
                <Tabs<LayoutPreview>
                  value={view}
                  className="esg-users-tabs"
                  labelPosition="right"
                  tabs={previewTabs}
                  onChange={setView}
                  changeOrientationOnResponsive={false}
                />
              )}
            </div>

            <BlockUI loading={isMutatingCreateUser}>
              {open && (
                <ManageUserModal
                  initialData={initialData}
                  onClose={() => {
                    setSelectedUser(undefined);
                    closeModal();
                  }}
                  onSubmit={onSubmit}
                />
              )}
            </BlockUI>

            <div className="esg-users__container__cards">
              <UsersPreview
                users={users}
                view={view}
                onEdit={(user) => {
                  setSelectedUser(user);
                  openModal();
                }}
                onClick={({ id }) => {
                  navigate(user.id === id ? APP_URI.PERSONAL_DETAILS : id);
                }}
                loadingUserId={selectedUser?.id}
                isMutatingRoles={isMutatingRoles}
                isMutatingUpdateUser={isMutatingUpdateUser}
                onDelete={async (user, e) =>
                  await prompts.delete({
                    type: 'delete',
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                    title: `Delete ${user.firstName} ${user.lastName}`,
                    content: `Are you sure you want to delete ${user.firstName} ${user.lastName} from users?`,
                    buttonIcon: 'trash-can',
                    iconPosition: 'left',
                    confirmButtonVariant: 'delete-ghost',
                    titleIcon: <Icon name="triangle-exclamation" />,
                    triggerRef: e.target as HTMLButtonElement,
                    onConfirm: async () => {
                      showOverlay('.esg-users');
                      await triggerUpdateUser({
                        method: 'DELETE',
                        url: API_ROUTES.USERS.USER_ID(user.id),
                      });
                      mutateUsers((cache) => {
                        if (!cache) return;
                        return {
                          data: cache.data.filter((u) => u.id !== user.id),
                        };
                      }, false);

                      showSnackBar({
                        text: `Successfully deleted ${user.firstName} ${user.lastName}.`,
                        variant: 'success',
                        actionButtonText: 'Dismiss',
                      });

                      hideOverlay('.esg-users');
                    },
                  })
                }
              />
            </div>
          </div>
        </GlowScroll>
      </StyledUsers>
    </BlockUI>
  );
};

export default Users;
