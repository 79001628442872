import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Icon from 'components/Icon';
import { DataModuleEnum } from 'models';
import { FC, memo, useContext, useMemo } from 'react';

import { DndDataContext } from '../../../../../../providers/DndData/DndData.context';
import DataModule from '../../../Dnd/components/DataModule';
import { StyledDataModulesTool } from './DataModulesTool.styled';

type DataModulesToolProps = { items: string[] };

const DataModulesTool: FC<DataModulesToolProps> = ({ items }) => {
  const { droppedInDataModules } = useContext(DndDataContext);

  const itemsWithProperIds = useMemo(
    () => items.map((id) => `data-module_${id}`),
    [items]
  );

  return (
    <StyledDataModulesTool
      className="esg-data-modules-tool--wrapper"
      open
      body={
        <div
          className="esg-data-modules-tool__list"
          onMouseEnter={() => {
            droppedInDataModules.current = true;
          }}
          onMouseLeave={() => {
            droppedInDataModules.current = false;
          }}
        >
          <SortableContext
            items={itemsWithProperIds}
            strategy={verticalListSortingStrategy}
          >
            {items.map((module) => (
              <DataModule type={module as DataModuleEnum} key={module} />
            ))}
          </SortableContext>
        </div>
      }
      icon={<Icon name="chevron-down" />}
      title="Data Module"
      bodyAs="div"
      bodyClassName="esg-data-modules-tool"
    />
  );
};

export default memo(DataModulesTool);
