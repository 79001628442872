import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionCampaignHeader = styled.div`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_16};
  gap: ${theme.sizes.SIZE_12};
  .esg-session-campaign-header {
    &__header {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_12};

      svg {
        font-size: ${theme.fontSizes.FONT_34};
      }
    }
  }
`;

export { StyledSessionCampaignHeader };
