import { USER_ROLE } from '../models';
import { useRootProvider } from '../providers/Root';

/**
 * This hook checks whether current user has specific role combinations.
 */
const useUserRoles = () => {
  const {
    user: { roles },
  } = useRootProvider();

  const userRoleName = roles[0].name;

  const isSuperAdmin = userRoleName === USER_ROLE.SUPER_ADMIN;
  const isAdmin = userRoleName === USER_ROLE.ORGANISATION_ADMIN;
  const isRegular = userRoleName === USER_ROLE.REGULAR_USER;
  const isAuditor = userRoleName === USER_ROLE.AUDIT;

  return {
    isAdminOrSA: isSuperAdmin || isAdmin,
    isRegularOrSA: isSuperAdmin || isRegular,
    isAuditorOrSA: isSuperAdmin || isAuditor,
    isRegularOrAdminOrSA: isSuperAdmin || isAdmin || isRegular,
    isSuperAdmin,
    isAdmin,
    isRegular,
    isAuditor,
  };
};

export default useUserRoles;
