import { useUtilities } from '@faxi/web-component-library';
import { useCallback, useMemo } from 'react';
import useSWR, { mutate } from 'swr';

import { API_ROUTES } from '../api/routes';
import { Loading } from '../components';
import { DocumentResponseType } from '../models';
import { useFileUpload } from './useFileUpload';

export function useFormFileUpload(
  sessionId: string,
  companyId: string,
  organisationSessionId: string,
  campaignItemId: string,
  type: string,
  files?: (File & { key: string; id: string })[]
) {
  const { showOverlay, hideOverlay } = useUtilities();

  const {
    uploadDocuments,
    presignedUpload,
    handlePresignedUrlsFileUpload,
    deleteFile,
  } = useFileUpload(sessionId!, companyId!);

  // Get uploaded files
  const { data: uploadedFiles, isLoading: isLoadingFiles } = useSWR<{
    data: Record<string, DocumentResponseType[]>;
  }>(
    type !== 'data_lineage' &&
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS(
        sessionId!,
        companyId!,
        campaignItemId!
      )
  );

  // Cast the data to Record<string, File[]> because the fileUploadModal requires a File[] type.
  const initialFiles: Record<
    string,
    (File & { key: string; path: string; relativePath: string })[]
  > = useMemo(() => {
    if (!uploadedFiles?.data) return {};

    return Object.keys(uploadedFiles.data).reduce(
      (acc, id) => {
        acc[id] = uploadedFiles.data[id].map((fileData) => {
          const file = new File(
            [new Blob([], { type: fileData.contentType })],
            fileData.name,
            { type: fileData.contentType }
          ) as File & { key: string; path: string; relativePath: string };

          file.key = fileData.key;
          return file;
        });

        return acc;
      },
      {} as Record<
        string,
        (File & { key: string; path: string; relativePath: string })[]
      >
    );
  }, [uploadedFiles?.data]);

  const checkFileChanges = useCallback(
    (currentFiles: Record<string, File[]>) => {
      const deletedFiles: string[] = [];
      const addedFiles: { file: File; formElementId: string }[] = [];

      if (type !== 'data_lineage') {
        if (!uploadedFiles) return { deletedFiles, addedFiles };

        Object.entries(currentFiles).forEach(([key, fileList]) => {
          const localFileNames = new Set(
            initialFiles[key]?.map((file) => file.name) || []
          );
          fileList.forEach((file) => {
            if (!localFileNames.has(file.name))
              addedFiles.push({ file, formElementId: key });
          });
        });

        if (type === 'data_consolidation') {
          // If it's data_consolidation, we compare only one module element

          const [consolidationKey] = Object.keys(currentFiles);
          if (uploadedFiles?.data[consolidationKey]) {
            const fileRecordNames = new Set(
              currentFiles[consolidationKey]?.map((file) => file.name) || []
            );
            uploadedFiles.data[consolidationKey].forEach(({ id, name }) => {
              if (!fileRecordNames.has(name)) deletedFiles.push(id);
            });
          }
        } else {
          //if it's forn_attachment

          Object.entries(uploadedFiles?.data).forEach(
            ([key, uploadedFileList]) => {
              const fileRecordNames = new Set(
                currentFiles[key]?.map((file) => file.name) || []
              );
              uploadedFileList.forEach(({ id, name }) => {
                if (!fileRecordNames.has(name)) deletedFiles.push(id);
              });
            }
          );
        }
      } else {
        //if it's data_lineage
        Object.entries(currentFiles).forEach(([key, fileList]) => {
          const localFileNames = new Set(files?.map((file) => file.name) || []);
          fileList.forEach((file) => {
            if (!localFileNames.has(file.name))
              addedFiles.push({ file, formElementId: key });
          });
        });
        files?.forEach((file) => {
          const fileExists = Object.values(currentFiles)
            .flat()
            .some((f) => f.name === file.name);
          if (!fileExists) deletedFiles.push(file.id);
        });
      }

      return { deletedFiles, addedFiles };
    },
    [files, initialFiles, type, uploadedFiles]
  );

  const uploadFormFiles = useCallback(
    async (
      files: {
        fileType?: string;
        type: string;
        name: string;
        formElementId?: string;
      }[],
      fileList: File[]
    ) => {
      if (!files.length) return;

      const response = await uploadDocuments({
        method: 'POST',
        data: {
          files: files.map((file) => ({
            fileType:
              type === 'data_lineage' ? 'data_lineage' : 'form_attachment',
            contentType: file.type,
            fileName: file.name,
            formElementId: file.formElementId,
          })),
          organisationSessionId,
          sessionId,
          organisationId: companyId,
          campaignItemId,
        },
      });

      handlePresignedUrlsFileUpload(
        fileList,
        response.data.presignedUrls,
        campaignItemId,
        type === 'data_lineage' ? 'data_lineage' : 'form_attachment'
      );
    },
    [
      campaignItemId,
      companyId,
      handlePresignedUrlsFileUpload,
      organisationSessionId,
      sessionId,
      uploadDocuments,
      type,
    ]
  );

  const removeFiles = useCallback(
    async (deletedFiles: string[]) => {
      await Promise.all(
        deletedFiles.map((id) =>
          deleteFile({
            method: 'DELETE',
            url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS_DELETE(
              id
            ),
          })
        )
      );
    },
    [deleteFile]
  );

  //submit files
  const submitFileUpload = useCallback(
    async (
      data: { id: string; type: string; value: unknown }[],
      companyId: string,
      overlayParentId: string
    ) => {
      showOverlay(`#${overlayParentId}`, 'fixed', <Loading />);

      const currentFiles = data.filter(
        ({ type, value }) =>
          type === 'upload' && Array.isArray(value) && value.length
      ) as { id: string; type: string; value: File[] & { id: string } }[];

      const currentFileMap: Record<string, File[]> = currentFiles.reduce<
        Record<string, File[] & { id: string }>
      >((acc, { id, value }) => ({ ...acc, [id]: value }), {});

      // Check if there are any deleted files or new ones added
      const { deletedFiles, addedFiles } = checkFileChanges(currentFileMap);

      // Convert the newly added files to the type we need for API calls.
      const newFilesList: File[] = Object.values(addedFiles)
        .flat()
        .map(({ file }) => file);

      const filesToUpload = addedFiles.map(({ file, formElementId }) => ({
        fileType: 'form_attachment',
        type: file.type,
        name: file.name,
        formElementId,
      }));

      if (filesToUpload.length > 0) {
        await uploadFormFiles(filesToUpload, newFilesList);
      }

      if (deletedFiles.length > 0) {
        await removeFiles(deletedFiles);
      }

      mutate(
        API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS(
          sessionId!,
          companyId,
          campaignItemId!
        )
      );

      hideOverlay(`#${overlayParentId}`);
    },
    [
      campaignItemId,
      checkFileChanges,
      hideOverlay,
      removeFiles,
      sessionId,
      showOverlay,
      uploadFormFiles,
    ]
  );

  return {
    uploadDocuments,
    presignedUpload,
    uploadFormFiles,
    removeFiles,
    uploadedFiles,
    isLoadingFiles,
    initialFiles,
    checkFileChanges,
    submitFileUpload,
  };
}
