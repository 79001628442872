import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { StyledBasicTreeView } from '../../../../../../../components/_molecules/BasicTreeView/BasicTreeView.styled';

const StyledConsolidatedDataTree = styled(StyledBasicTreeView)<{
  $level: number;
}>`
  ${flex('column', 'flex-start', 'baseline')};
  padding: ${theme.sizes.SIZE_4};

  .esg-consolidated-data-tree {
    &__node {
      width: 100%;
      border-bottom: 1px solid var(--GRAY-EF);

      &__header,
      &__container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${theme.sizes.SIZE_20};
      }

      &__header {
        border-bottom: 1px solid var(--GRAY-EF);
        ${fontSize(theme.fontSizes.FONT_16)};
        font-weight: 600;
        padding: ${theme.sizes.SIZE_12};
      }

      &__upload {
        width: ${theme.sizes.SIZE_224};
        ${flex('column', 'center', 'center')};
        gap: ${theme.sizes.SIZE_10};

        &__files {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &__formula {
        cursor: pointer;
        text-decoration: underline;
        width: fit-content;
      }

      .tippy-box[data-theme~='custom-tooltip'] {
        .esg-consolidated-data-tree__node__tooltip {
          ${flex('column', 'center', 'center')};
          gap: ${theme.sizes.SIZE_16};

          &__header {
            font-weight: 600;
            margin-right: auto;
          }
        }
      }

      &__container {
        padding: ${theme.sizes.SIZE_12};

        &__organisation {
          ${flex('row', 'flex-start', 'center')};
          ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};
          gap: ${theme.sizes.SIZE_8};
          border-radius: ${theme.sizes.SIZE_8};
          padding: ${theme.sizes.SIZE_4};
          padding-left: ${({ $level }) => `calc(20px * ${$level - 1})`};
          ${focusStyles};
        }

        &__form {
          ${flex('row', 'space-between', 'center')}
          gap:${theme.sizes.SIZE_20};

          &__formula {
            display: grid;
            grid-template-columns: ${theme.sizes.SIZE_160} 1fr;
            gap: ${theme.sizes.SIZE_16};
            width: 100%;
          }

          .button {
            height: ${theme.sizes.SIZE_20};
          }
          .esg-input-preview__title,
          .esg-dropdown-preview__content__title,
          .esg-switch-preview__main__title,
          .esg-upload-preview__main__title,
          .esg-integration-preview__title,
          .esg-upload-preview__conditional-el,
          .esg-dropdown-preview__conditional-el,
          .esg-formula-preview__title {
            display: none;
          }
          .esg-integration-preview__content {
            justify-content: flex-start;
          }
          .input__container__input--disabled,
          .textarea__container__textarea--disabled,
          .wcl-radio-button--disabled > .wcl-radio-button__label {
            color: var(--SHADE_1_1);
          }
        }
      }
    }

    &__sub-folders {
      width: 100%;
      overflow: hidden;
    }
  }
`;

export { StyledConsolidatedDataTree };
