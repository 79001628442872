import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSimpleInput = styled.div`
  ${flex('column')};
  margin-top: ${theme.sizes.SIZE_32};
`;

const StyledSimpleInputField = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${theme.sizes.SIZE_20};
  margin-top: ${theme.sizes.SIZE_32};

  .esg-simple-input-field {
    &__conditional-field {
      margin-left: auto;
      width: ${theme.sizes.SIZE_320};
    }
  }
`;

export { StyledSimpleInput, StyledSimpleInputField };
