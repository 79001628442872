import {
  Button,
  Divider,
  GlowScroll,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import classNames from 'classnames';
import { APP_URI } from 'config';
import dayjs from 'dayjs';
import { BlockUI } from 'helpers';
import cloneDeep from 'lodash.clonedeep';
import {
  CheckStatus,
  OrganisationSession,
  Session,
  TreeNodeElement,
} from 'models';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import {
  BasicTreeView,
  CalendarField,
  CheckboxTreeView,
  EmptyFolder,
  EntityFormModal,
  FolderNavHeader,
  Loading,
} from '../../../../components';
import {
  toggleCheck,
  toggleCheckByIds,
} from '../../../../components/_organisms/CheckboxTreeView/utils';
import Icon from '../../../../components/Icon';
import { useFileUpload } from '../../../../hooks/useFileUpload';
import useOrganisationTree from '../../../../providers/hooks/useOrganisationTree';
import { useSessionProvider } from '../../../../providers/Session';
import {
  createTreeWithParentReferences,
  findFirstNonDisabledNode,
  findNode,
  mapCampaignToTreeNodeElement,
} from '../../../../utils';
import { NewSessionForm } from '../../../Users/Users.page';
import { useCampaignOptions } from '../../hooks';
import { ExcludeCampaignItemModal } from './components';
import { DataCollectElementForm } from './components/ExcludeCampaignItemModal/ExcludeCampaignItemModal.component';
import { StyledRunSession } from './RunSession.styled';

const validations = {
  startDate: validators.general.required('Start date is required'),
  endDate: validators.general.required('Start date is required'),
  campaign: validators.general.required('Campaign is required'),
  companies: validators.general.required('Please select at least one row'),
};

const RunSession: FC = () => {
  const location = useLocation();
  const [isGeneratingCampaignTree, setIsGeneratingCampaignTree] =
    useState(true);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { sessionId } = useParams<{ sessionId: string }>();

  const initiateExcludeRedirect = useRef(false);
  const [allOrganisationsExcluded, setAllOrganisationsExcluded] =
    useState(false);

  const { mutateSessions } = useSessionProvider();

  const organisationId = useMemo(
    () => searchParams.get('organisationId'),
    [searchParams]
  );

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();
  const { uploadDocuments, handlePresignedUrlsFileUpload } = useFileUpload(
    sessionId!,
    organisationId!
  );

  const {
    data: { data: session } = {},
    isLoading,
    mutate: mutateSession,
  } = useSWR<{ data: Session }>(
    sessionId && API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId)
  );

  const {
    data: { data: campaign } = {},
    isLoading: isLoadingCampaign,
    error: errorCampaign,
  } = useSWR<{ data: Session }>(
    session?.campaignId &&
      API_ROUTES.CAMPAIGNS.CAMPAIGN(session.campaignId, true)
  );

  const { trigger: triggerUpdateSession } = useMutation<{
    data: OrganisationSession;
  }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId!),
    {
      onSuccess: ({ data }) => {
        showSnackBar({
          text: `Successfully updated ${data.name} session.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        mutateSessions();
        modalRef?.current?.close();
      },
    },
    true
  );

  const { campaignsOptions, loadingCampaigns } = useCampaignOptions();

  const initialData = useMemo(
    () => ({
      name: session?.name,
      campaign: campaignsOptions.find(
        ({ label }) => label === session?.campaignName
      ),
      description: session?.description,
    }),
    [session, campaignsOptions]
  );

  const organisationsIsIncludedMap: Map<string, boolean> = useMemo(
    () =>
      session?.settings.reduce(
        (acc: Map<string, boolean>, curr) =>
          acc.set(curr.organisationId, curr.isIncluded),
        new Map<string, boolean>()
      ) || new Map(),
    [session]
  );

  const { organisationTree, isLoading: isLoadingOrganisationTree } =
    useOrganisationTree('sessions', organisationsIsIncludedMap);

  const [campaignTree, setCampaignTree] = useState<TreeNodeElement>();

  const initialCampaignTree = useRef<TreeNodeElement>();

  const [form, formRef] = useCallbackRef<FormRef>();
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {
    if (!campaign || !session) return;

    setIsGeneratingCampaignTree(true);

    const campaignTreeWithParentReferences = createTreeWithParentReferences(
      mapCampaignToTreeNodeElement(campaign as any),
      null
    );

    let campaignTreeWithCheckedNodes = campaignTreeWithParentReferences;

    const checkedCampaignItemsIds = session.settings
      .find((el) => el.organisationId === organisationId)
      ?.dataCollectionElements.filter(({ isIncluded }) => isIncluded)
      .map(({ id }) => id);

    if (checkedCampaignItemsIds) {
      campaignTreeWithCheckedNodes = toggleCheckByIds(
        campaignTreeWithParentReferences,
        checkedCampaignItemsIds
      );
    }

    setCampaignTree(campaignTreeWithCheckedNodes);
    setIsGeneratingCampaignTree(false);

    initialCampaignTree.current = cloneDeep(campaignTreeWithCheckedNodes);
  }, [session, organisationId, campaign]);

  const { trigger: runCurrentSession } = useMutation<{
    data: OrganisationSession;
  }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_RUN(sessionId!),
    {
      onSuccess: ({ data }) => {
        showSnackBar({
          text: `Successfully started ${data.name} session.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        mutateSessions();
        navigate(`/sessions/${sessionId}/dashboard`);
      },
    },
    true
  );

  const handleRunSession = useCallback(
    async (data: Pick<Session, 'startDate' | 'endDate'>) => {
      if (!sessionId) return;

      const { startDate, endDate } = data;

      runCurrentSession({
        method: 'POST',
        data: {
          startDate: dayjs(startDate).toISOString(),
          endDate: dayjs(endDate).toISOString(),
        },
      });
    },
    [sessionId, runCurrentSession]
  );

  const [excludingNode, setExcludingNode] = useState<TreeNodeElement>();

  const updateSession = useCallback(
    async (data: NewSessionForm) => {
      triggerUpdateSession({ method: 'PATCH', data });
    },
    [triggerUpdateSession]
  );

  const { isLoading: isLoadingSessions } = useSWR<{ data: Session[] }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE()
  );

  const { trigger: updateDataCollectElement } = useMutation('sessions', {
    onSuccess: async () => {
      await mutateSession();

      showSnackBar({
        variant: 'success',
        text: `Data collection element updated successfully.`,
      });

      hideOverlay('body');
    },
    onError: () => hideOverlay('body'),
  });

  const updateDataCollectElementFn = useCallback(
    (
      node: TreeNodeElement,
      data: { isIncluded: boolean; exclusionJustification?: string }
    ) => {
      if (!sessionId || !organisationId) return;
      showOverlay('body');

      updateDataCollectElement({
        url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_ORGANISATION_DATA_COLLECTION_ELEMENTS(
          sessionId,
          organisationId
        ),
        method: 'POST',
        data: { ...data, campaignItemId: node.id },
      });
    },
    [organisationId, sessionId, showOverlay, updateDataCollectElement]
  );

  const uploadJustificationFiles = useCallback(
    async (node: TreeNodeElement, data: { justificationFiles?: File[] }) => {
      if (!sessionId || !organisationId) return;
      showOverlay('body');
      const files = data.justificationFiles?.map((file) => ({
        fileName: file.name,
        contentType: file.type,
        fileType: 'exclude_justification',
      }));

      const response = await uploadDocuments({
        method: 'POST',
        data: {
          files,
          sessionId: sessionId,
          organisationId: organisationId,
          campaignItemId: node.id,
        },
      });

      handlePresignedUrlsFileUpload(
        data.justificationFiles!,
        response.data.presignedUrls,
        node.id,
        'exclude_justification'
      );
    },
    [
      handlePresignedUrlsFileUpload,
      organisationId,
      sessionId,
      showOverlay,
      uploadDocuments,
    ]
  );

  const onExcludeCampaignItemSubmit = useCallback(
    async ({
      exclusionJustification,
      justificationFiles,
    }: DataCollectElementForm) => {
      if (!excludingNode) return;

      updateDataCollectElementFn(excludingNode, {
        isIncluded: false,
        exclusionJustification,
      });
      justificationFiles?.length > 0 &&
        uploadJustificationFiles(excludingNode, { justificationFiles });

      setExcludingNode(undefined);
    },
    [excludingNode, updateDataCollectElementFn, uploadJustificationFiles]
  );

  const { startDate = '', endDate = '' } = useFormRefValues(
    form,
    'startDate',
    'endDate'
  );

  const currentSelectedOrganisation = useMemo(
    () =>
      organisationId && organisationTree
        ? findNode(organisationId, [organisationTree])
        : undefined,
    [organisationId, organisationTree]
  );

  const atLeastOneOrganisationIncluded = useMemo(
    () => [...organisationsIsIncludedMap.values()].some(Boolean),
    [organisationsIsIncludedMap]
  );

  const firstNonDisabledNode = useMemo(
    () =>
      organisationTree
        ? findFirstNonDisabledNode([organisationTree])
        : undefined,
    [organisationTree]
  );

  useEffect(() => {
    if (!organisationTree || !initiateExcludeRedirect.current) return;

    if (firstNonDisabledNode) {
      navigate({
        pathname: '',
        search: `${createSearchParams({
          organisationId: firstNonDisabledNode.id,
        })}`,
      });
      initiateExcludeRedirect.current = false;
    } else {
      setAllOrganisationsExcluded(true);
    }
  }, [
    firstNonDisabledNode,
    initiateExcludeRedirect,
    navigate,
    organisationTree,
  ]);

  useEffect(() => {
    setAllOrganisationsExcluded(!firstNonDisabledNode);
  }, [firstNonDisabledNode]);

  return (
    <StyledRunSession
      direction="column"
      title="Sessions"
      className="esg-run-session"
    >
      {excludingNode && (
        <ExcludeCampaignItemModal
          onClose={() => {
            setExcludingNode(undefined);
            toggleCheck(excludingNode, CheckStatus.Checked);
          }}
          node={excludingNode}
          onSubmit={onExcludeCampaignItemSubmit}
        />
      )}
      {/* TODO: even though loading is true, children seem to try to render, thus erroring the UI. For example, try session!.name below */}
      <BlockUI loading={isLoading || isLoadingSessions}>
        <FolderNavHeader
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: session?.name || '', href: location.pathname },
          ]}
          title={session?.name || ''}
          description={session?.description}
          updateDescription={() => modalRef.current?.open()}
          icon="chart-bar-solid"
        />

        <Divider className="esg-run-session__divider" />

        <div className="esg-run-session__content">
          <GlowScroll variant="gray">
            <div
              className={classNames(
                'esg-run-session__content__container',
                'esg-run-session__content__container--left'
              )}
            >
              <div
                className="esg-run-session__content__header"
                aria-describedby="organisation-description"
              >
                Organisation
              </div>
              <div
                id="organisation-description"
                className="esg-run-session__content__description"
              >
                All the companies in your organisations are included in this
                session by default. Hover over a company to exclude it from the
                session.
              </div>

              {organisationTree && organisationId ? (
                <BasicTreeView
                  onAction={(node) => {
                    // node is getting excluded
                    if (!node.action?.disabledStyle) {
                      initiateExcludeRedirect.current = true;
                    }
                  }}
                  withMenu
                  data={{ ...organisationTree }}
                  showRootNode={false}
                  activeNodeId={organisationId}
                />
              ) : (
                <Loading />
              )}
            </div>
          </GlowScroll>

          <Divider orientation="vertical" />

          <BlockUI
            warning={allOrganisationsExcluded && !isLoadingOrganisationTree}
            warningTitle="Warning"
            warningText="It looks like you've excluded all organisations, please include at least one."
          >
            <GlowScroll variant="gray">
              <div
                className={classNames(
                  'esg-run-session__content__container',
                  'esg-run-session__content__container--right'
                )}
              >
                <div
                  className="esg-run-session__content__header"
                  aria-describedby="campaign-description"
                >
                  Campaign
                  <div className="esg-run-session__content__header__badge">
                    <Icon name="leaf" />
                    <span>{session?.campaignName}</span>
                  </div>
                  of organisation
                  <div className="esg-run-session__content__header__badge">
                    <Icon name="building" />
                    <span>{currentSelectedOrganisation?.name}</span>
                  </div>
                </div>
                <div
                  className="esg-run-session__content__description"
                  id="campaign-description"
                >
                  All the topics and subtopics of the selected campaign are
                  included in this session by default. You can exclude specific
                  topics and subtopics for each organisation using the
                  checkboxes
                </div>

                <div className="esg-run-session__content__campaign-tree">
                  <BlockUI
                    loading={
                      isLoadingCampaign ||
                      (!errorCampaign && isGeneratingCampaignTree)
                    }
                    fallback={
                      <EmptyFolder
                        title="No attached campaigns."
                        iconName="file"
                      />
                    }
                    fallbackCondition={!campaignTree || errorCampaign}
                  >
                    <CheckboxTreeView
                      filteredData={campaignTree!}
                      onCheck={async (node, checked) => {
                        try {
                          if (checked === CheckStatus.Unchecked) {
                            setExcludingNode(node);
                          } else {
                            updateDataCollectElementFn(node, {
                              isIncluded: true,
                            });
                          }
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    />
                  </BlockUI>
                </div>
              </div>
            </GlowScroll>
          </BlockUI>
        </div>

        <Divider className="esg-run-session__divider" />

        <Form
          onSubmit={handleRunSession}
          ref={formRef}
          className="esg-run-session__form"
        >
          <div className="esg-run-session__dates">
            Dates
            <div className="esg-run-session__dates__container">
              <FormField
                name="startDate"
                component={CalendarField}
                validate={validations.startDate}
                placeholder="Set Start Date"
                disablePast
                onChange={(value) => {
                  if (dayjs(value).isAfter(dayjs(endDate))) {
                    form.updateValueField('endDate', '');
                  }
                }}
              />
              <FormField
                name="endDate"
                component={CalendarField}
                validate={validations.endDate}
                placeholder="Set End Date"
                leftLimitDate={dayjs(startDate || undefined)}
              />
            </div>
          </div>
          <div className="esg-run-session__buttons">
            <Button
              type="submit"
              disabled={
                !form?.syncFormValid ||
                !form?.isFormChanged() ||
                !atLeastOneOrganisationIncluded
              }
            >
              Start Session
            </Button>
          </div>
        </Form>

        <EntityFormModal
          ref={modalRef}
          renderAsPortal
          className="esg-company-modal"
          onClose={() => modalRef.current?.close()}
          initialData={initialData}
          title={`Edit ${session?.name} session`}
          onSubmit={updateSession}
          fieldProps={{
            campaign: { options: campaignsOptions, loading: loadingCampaigns },
          }}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
            CAMPAIGN: true,
            TAXONOMY_ID: false,
            TAXONOMY_GROUP: false,
          }}
        />
      </BlockUI>
    </StyledRunSession>
  );
};

export default RunSession;
