import { useUtilities } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { FormFooter, InputField } from 'components';
import { useCallbackAsync, useValidations } from 'hooks';
import { FC } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../../api/routes';
import { APP_URI } from '../../../../../config';
import { StyledForgotPassword } from './ForgotPassword.styled';

const ForgotPassword: FC = () => {
  const { validations } = useValidations();

  const { showSnackBar } = useUtilities();

  const { trigger } = useMutation<void>(API_ROUTES.USERS.FORGOT_PASSWORD);

  const navigate = useNavigate();

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    callback: async (data: { email: string }) => {
      await trigger({ method: 'POST', data });
      showSnackBar({
        text: `Check your email for confirmation code`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
      navigate({
        pathname: `${APP_URI.AUTH_RESET_PASSWORD}`,
        search: `${createSearchParams({ email: data.email })}`,
      });
    },
  });

  return (
    <StyledForgotPassword
      className="esg-forgot-password"
      onSubmit={handleSubmit}
    >
      <h2 className="esg-forgot-password__password-title">Forgot Password</h2>
      <p className="esg-forgot-password__password-content">
        Enter your email and we will send you a reset link
      </p>

      <FormField
        name="email"
        type="text"
        placeholder="Email"
        autoComplete="off"
        component={InputField}
        validate={validations.email}
      />

      <FormFooter submitLabel="Send" />

      <Link to="../" className="auth-text">
        I Remembered It
      </Link>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
