import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { BlockUI } from '../../../../../helpers';
import { DataModuleElementDrawerComponentProps } from '../../../../../models';
import { useConsolidatedData } from '../../hooks';
import ConsolidatedDataTree from './components/ConsolidatedDataTree';
import { StyledModuleElementDataConsolidation } from './ModuleElementDataConsolidation.styled';

const ModuleElementDataConsolidation: FC<
  DataModuleElementDrawerComponentProps
> = ({ elementId, organisationSessionId, organisationId, isLocked }) => {
  const { campaignItemId = '' } = useParams();

  const { isLoading, consolidatedDataTreeView } = useConsolidatedData(
    organisationSessionId,
    campaignItemId,
    elementId,
    organisationId
  );
  return (
    <BlockUI loading={isLoading}>
      <StyledModuleElementDataConsolidation className="esg-module-element-data-consolidation">
        <div>
          The table below shows the value entered for this module in the current
          company and its subcompanies.
        </div>
        <ConsolidatedDataTree
          level={1}
          node={consolidatedDataTreeView!}
          isLocked={isLocked}
        />
      </StyledModuleElementDataConsolidation>
    </BlockUI>
  );
};
export default ModuleElementDataConsolidation;
