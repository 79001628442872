import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormActivityLog = styled.div`
  ${flex('column', 'flex-start', 'stretch')};

  gap: ${theme.sizes.SIZE_6};
  padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_4};
  border-bottom: 1px solid var(--GRAY-EF);

  .esg-form-activity-log {
    &__top {
      ${flex('row', 'space-between', 'center')};

      &__name {
        ${flex('row', 'flex-start', 'center')};

        gap: ${theme.sizes.SIZE_6};

        > span {
          ${fontSize(theme.fontSizes.FONT_14)};
          font-weight: 600;
        }

        .esg-user-image {
          ${size(theme.sizes.SIZE_24)};
          font-size: ${theme.sizes.SIZE_16};
          min-width: ${theme.sizes.SIZE_24};
        }
      }

      &__date {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--GRAY-70);
      }
    }

    &__bottom {
      font-size: ${theme.fontSizes.FONT_14};

      margin-left: 30px;
    }
  }
`;

export { StyledFormActivityLog };
