import { DataModuleEnum, DetailsMapperType } from '../../../../models';
import FormulaDetailsPreview from '../components/FormulaDetailsPreview/FormulaDetailsPreview.component';

export const MODULE_DETAILS_MAPPER: DetailsMapperType = {
  [DataModuleEnum.SECTION]: null,
  [DataModuleEnum.TEXT_BOX]: null,
  [DataModuleEnum.SWITCH]: null,
  [DataModuleEnum.UPLOAD]: null,
  [DataModuleEnum.INPUT]: null,
  [DataModuleEnum.CHOICE]: null,
  [DataModuleEnum.FORMULA]: FormulaDetailsPreview,
  [DataModuleEnum.CHECKLIST]: null,
  [DataModuleEnum.DIVIDER]: null,
  [DataModuleEnum.INTEGRATION]: null,
  [DataModuleEnum.DROPDOWN]: null,
  [DataModuleEnum.TABLE]: null,
};
