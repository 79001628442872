import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledInfiniteScrollContainer = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_32};
  margin-bottom: auto;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 0px;
  scroll-padding-bottom: 30px;
  padding: 3px;

  .spinner {
    margin: 0 auto;
  }
`;

export { StyledInfiniteScrollContainer };
