import {
  ButtonProps,
  StatusElement,
  StatusElementStatus,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import Icon, { INameExtended } from 'components/Icon';
import { CampaignSessionStatus, PermissionSections, Session } from 'models';
import { FC, memo, MouseEvent, PropsWithChildren, useMemo } from 'react';

import { useUserPermissions } from '../../../hooks';
import StatusProgress from '../../../routes/subrouters/Sessions/components/StatusProgress';
import ApprovalProgressBar from '../ApprovalProgressBar';
import ThreeDotMenu from '../ThreeDotMenu';
import { StyledSessionCard } from './SessionCard.styled';

export type SessionCardProps = PropsWithChildren<{
  cardData: Pick<
    Session,
    'progress' | 'statusProgresses' | 'name' | 'description' | 'id'
  > & { status?: CampaignSessionStatus };
  onSelect: () => void;
  onDelete?: (ev: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  iconName?: INameExtended;
  iconColor?: string;
}>;

const SESSION_STATUS_MAPPER: Record<
  CampaignSessionStatus,
  StatusElementStatus
> = {
  [CampaignSessionStatus.Active]: 'active',
  [CampaignSessionStatus.Draft]: 'draft',
  [CampaignSessionStatus.Finished]: 'approved',
};

const SessionCard: FC<SessionCardProps> = ({
  cardData,
  onSelect,
  onDelete,
  className,
  iconName,
  iconColor,
}) => {
  const hasSessionsPermission = useUserPermissions(PermissionSections.SESSION);

  const defaultMenuItems = useMemo<ButtonProps[]>(
    () => [
      {
        children: 'Delete',
        icon: <Icon name="trash-can" className="color-secondary" />,
        iconPosition: 'left',
        variant: 'delete-ghost',
        onClick: (ev) => {
          ev.stopPropagation();
          onDelete?.(ev);
        },
      },
    ],
    [onDelete]
  );

  return (
    <StyledSessionCard
      onClick={onSelect}
      className={classNames('esg-session-card', 'hover-effect', className)}
    >
      {hasSessionsPermission(['delete']) && (
        <ThreeDotMenu
          className="esg-session-card__menu"
          menuItems={defaultMenuItems}
        />
      )}

      <div
        className={classNames(
          'esg-session-card__column',
          'esg-session-card__column--name'
        )}
      >
        <strong className="esg-session-card__column__name">
          <Icon
            className="esg-session-card__column__name__icon"
            name={iconName ?? 'file'}
            color={iconColor}
          />
          <span>{cardData.name}</span>
          {cardData.status && (
            <StatusElement
              small
              status={SESSION_STATUS_MAPPER[cardData.status]}
              className="esg-session-card__column__name__draft"
            >
              {cardData.status}
            </StatusElement>
          )}
        </strong>
        <p className="esg-session-card__column__description">
          {cardData.description || '-'}
        </p>
      </div>

      <div
        className={classNames(
          'esg-session-card__column',
          'esg-session-card__column--status'
        )}
      >
        <ApprovalProgressBar
          title="Progress"
          status="In Progress"
          progress={cardData.progress}
        />

        <StatusProgress statusProgresses={cardData.statusProgresses} />
      </div>
    </StyledSessionCard>
  );
};

export default memo(SessionCard);
