import { ACTIVITY_LOGS_ROUTES } from './ActivityLogs';
import { CAMPAIGNS } from './Campaigns';
import { CAMPAIGN_SESSIONS_ROUTES } from './CampaignSessions';
import { INTEGRATIONS } from './Integrations';
import { NOTIFICATIONS_ROUTES } from './Notifications';
import { ORGANISATIONS } from './Organisations';
import { SESSIONS } from './Sessions';
import { USERS } from './Users';

export const API_ROUTES = {
  ACTIVITY_LOGS_ROUTES,
  CAMPAIGN_SESSIONS_ROUTES,
  CAMPAIGNS,
  INTEGRATIONS,
  NOTIFICATIONS_ROUTES,
  ORGANISATIONS,
  SESSIONS,
  USERS,
};
