import { Divider } from '@faxi/web-component-library';
import { BasicTag } from 'components';
import { User } from 'models';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from 'pages/Users/constants';
import { FC, PropsWithChildren, useMemo } from 'react';

import UserProfileImage from '../../subrouters/Users/components/UserProfileImage';
import { USER_FILED_TO_ROLE_MAPPER } from '../../subrouters/Users/subrouters/UserProfile/constants';
import { UserFieldKey } from '../../subrouters/Users/subrouters/UserProfile/types';
import { StyledProfileDetails } from './ProfileDetails.styled';

type ProfileDetailsProps = PropsWithChildren<{ user: User }>;

const ProfileDetails: FC<ProfileDetailsProps> = ({
  user: { roles, organisation, firstName, lastName, email, jobTitle },
}) => {
  const roleInformation = useMemo(
    () => ({ label: 'Role', value: roles[0] }),
    [roles]
  );

  const userInformation = useMemo(
    () =>
      Object.entries({
        email,
        firstName,
        lastName,
        jobTitle,
        ...(organisation?.name && { organisation: organisation.name }),
      }).map(([key, value]) => ({
        label: USER_FILED_TO_ROLE_MAPPER[key as UserFieldKey],
        value,
      })),
    [email, firstName, jobTitle, lastName, organisation]
  );

  return (
    <StyledProfileDetails className="esg-profile-details">
      <h2>Personal Details</h2>
      {/* TODO: implement BE for user profile image */}

      <UserProfileImage onClick={() => {}} />
      {userInformation && (
        <ul>
          {userInformation.map(({ label, value }) => (
            <li key={label}>
              <span>{label}</span>
              <span>{value}</span>
            </li>
          ))}
        </ul>
      )}
      <Divider />
      {roleInformation && (
        <div className="esg-profile-details__role">
          <span>{roleInformation.label}</span>
          <BasicTag
            text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[roleInformation.value.name]}
            color={roleInformation.value.color}
          />
        </div>
      )}
    </StyledProfileDetails>
  );
};

export default ProfileDetails;
