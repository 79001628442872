import { Tooltip } from '@faxi/web-component-library';
import { FormulaDataModule } from 'models';
import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import Icon from '../../../../../Icon';
import * as Styled from './FormulaPreview.styled';

export type FormulaPreviewProps = FormulaDataModule;

const FORMULA_TOOLTIP_MESSAGE =
  'When a linked field is updated, it may take some time to load the result of the formula. Reload the page if you expect a new value.';

const FormulaPreview: FC<FormulaPreviewProps> = ({
  title,
  config: { formula } = {},
  value,
}) => {
  const { sessionId } = useParams();

  return (
    <Styled.StyledFormulaPreview className="esg-formula-preview">
      <div className="esg-formula-preview__title">
        {title || 'Formula field'}
      </div>

      <div className="esg-formula-preview__formula">
        <div>
          {formula?.map((tag) => tag.value).join(' ') ||
            'No formula configured.'}{' '}
          = {value ?? 'No result'}
        </div>

        {!!sessionId && (
          <Tooltip content={FORMULA_TOOLTIP_MESSAGE}>
            <span
              tabIndex={0}
              role="tooltip"
              aria-label={FORMULA_TOOLTIP_MESSAGE}
            >
              <Icon name="circle-info" />
            </span>
          </Tooltip>
        )}
      </div>
    </Styled.StyledFormulaPreview>
  );
};

export default memo(FormulaPreview);
