import dayjs from 'dayjs';

export const groupByDate = <T extends Record<string, any>>(
  items: T[],
  itemsDateKey: keyof T = 'timestamp',
  dateFormat = 'YYYY-MM-DD'
): Record<string, T[]> =>
  items.reduce<Record<string, T[]>>((acc, item) => {
    const dateKey = dayjs(item[itemsDateKey]).format(dateFormat);
    if (!acc[dateKey]) acc[dateKey] = [];

    acc[dateKey].push(item);
    return acc;
  }, {});

export const groupByDateHeader = (
  date: string | Date,
  dateFormat = 'MMMM D, YYYY'
): string => {
  const givenDate = dayjs(date);
  if (givenDate.isToday()) return 'Today';

  return givenDate.isYesterday() ? 'Yesterday' : givenDate.format(dateFormat);
};
