import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledInlineEditable = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isEditing'].includes(prop),
})<{ isEditing: boolean }>`
  ${flex('row', 'flex-start', 'center')};

  width: ${({ isEditing }) => (isEditing ? '50%' : 'fit-content')};
  min-height: ${theme.sizes.SIZE_48};
  max-width: 100%;

  > p {
    display: ${({ isEditing }) => (isEditing ? 'none' : 'block')};

    cursor: text;
    margin: 0;
    word-break: break-word;
  }

  .input {
    flex: 1;

    &__container {
      input {
        border: none;
        border-radius: unset;
      }
    }
  }

  .esg-inline-editable {
    &__input {
      width: ${({ isEditing }) => (isEditing ? '100%' : 'fit-content')};
      min-height: ${theme.sizes.SIZE_48};
      max-width: 100%;
      display: ${({ isEditing }) => (!isEditing ? 'none' : 'block')};

      position: relative;

      &__actions {
        ${flex('row')};
        position: absolute;
        z-index: ${theme.zIndexes.CONTENT_LOW};
        right: 0;
        bottom: -6px;
        transform: translateY(100%);
        gap: ${theme.sizes.SIZE_8};

        > button {
          ${size(theme.sizes.SIZE_32)};

          padding: 0;
          min-height: ${theme.sizes.SIZE_32};
          position: relative;
        }
      }
    }
  }
`;

export { StyledInlineEditable };
