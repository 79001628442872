import { MouseEvent, ReactNode } from 'react';
import { To } from 'react-router-dom';

import { INameExtended } from '../components/Icon';
import { BaseModelExtended } from './BaseModel';
import { IDataModule } from './IDataModule';

export enum CheckStatus {
  Checked = 2,
  Indeterminate = 1,
  Unchecked = 0,
}

export type TreeNodeElement = BaseModelExtended & {
  color?: string;
  uniqName?: string;
  iconName?: INameExtended;
  children?: TreeNodeElement[];
  parent?: TreeNodeElement | null;
  checked?: CheckStatus;
  element?: ReactNode;
  to?: To;
  action?: {
    icon: INameExtended;
    onClick: (e: MouseEvent) => void;
    message: string;
    showOnHover: boolean;
    disabledStyle: boolean;
  };
  disabled?: boolean;
  emphasized?: boolean;
  module?: IDataModule;
  id?: string;
};

export type PathNode = { ids: string[]; names: string[] };

export type TreeConversionParams<T extends TreeNodeElement> = {
  currentNode: T;
  targetNode: T & { path: PathNode };
  depth: number;
};

export type TreeNodeElementWithPath = TreeNodeElement & { path: PathNode };
