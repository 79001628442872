import {
  DataModuleEnum,
  IDataModule,
  InlineModuleElement,
  ModuleElementType,
  ModuleMapperType,
} from 'models';
import { FC, memo, useMemo, useState } from 'react';

import moduleMapper from '../../../../../utils/moduleMapper';
import modulePreviewMapper from '../../../../../utils/modulePreviewMapper';

const ModuleElement: FC<ModuleElementType<DataModuleEnum>> = (props) => {
  const {
    className,
    type,
    module,
    modulesType = 'canvas-module',
    onModuleUpdate,
    isInlineEditableDisabled,
    ...restProps
  } = props;

  const mapper = useMemo<ModuleMapperType>(
    () =>
      modulesType === 'canvas-module' ? moduleMapper : modulePreviewMapper,
    [modulesType]
  );

  const Module = useMemo(
    () => mapper[type] as FC<InlineModuleElement<IDataModule<typeof type>>>,
    [mapper, type]
  );

  const { isNew, createdBy, updatedBy, ...restModuleKeys } = module;

  const [value, setValue] = useState(module.title);

  return (
    <Module
      {...restModuleKeys}
      {...restProps}
      {...(modulesType === 'canvas-module' && { value, onChange: setValue })}
      className={className}
      onSave={(value?: string | Record<string, any>, updateConfig = false) => {
        const moduleUpdates = !updateConfig ? { title: value } : value;

        onModuleUpdate?.({
          ...module,
          ...(moduleUpdates as Record<string, any>),
        });
      }}
      moduleElement={ModuleElement}
      disabled={isInlineEditableDisabled}
    />
  );
};

export default memo(ModuleElement);
