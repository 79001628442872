import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import CollapsibleSidePanel from '../../../../../components/_molecules/CollapsibleSidePanel';

const StyledCampaignBrowserSidePanel = styled(CollapsibleSidePanel)`
  .esg-collapsible-side-panel {
    ${flex('column')};
    gap: ${theme.sizes.SIZE_20};
  }

  .esg-collapsible-side-panel--wrapper {
    height: 100%;
  }

  .expander__content {
    padding: 3px;
  }

  .expander {
    height: unset;

    &__trigger {
      width: 100%;
      margin-bottom: ${theme.sizes.SIZE_16};

      &__title {
        ${flex('row', 'space-between', 'center')};
      }
    }

    &__container {
      width: 100%;
    }

    .calendar-trigger-with-value {
      justify-content: space-between;
    }
  }

  .campaign-browser-side-panel {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_24};

      strong {
        font-weight: 600;
      }
    }
    &__status {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_16};
      min-width: ${theme.sizes.SIZE_256};

      .esg-approval-progress-bar {
        justify-content: space-between;
        &__title {
          flex: 0 0 ${theme.sizes.SIZE_96};
        }
      }
    }

    &__assign-button {
      width: 100%;
    }

    &__assignees {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_12};

      &__view-all {
        ${flex('row', 'center', 'center')};
        ${fontSize(theme.fontSizes.FONT_14)};

        border: 1px solid var(--GRAY-C1);
        padding: ${theme.sizes.SIZE_8};
        background-color: var(--GRAY-EF);
        border-radius: ${theme.sizes.SIZE_8};
        cursor: pointer;
        width: 100%;
        color: unset;

        &::after {
          content: unset;
        }

        &:active {
          transform: scale(1.01);
        }
      }

      .spinner {
        margin-top: ${theme.sizes.SIZE_16};
      }

      &__subtitle {
        margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_4};
        ${fontSize(theme.fontSizes.FONT_14)};
      }
    }

    &__deadline {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_12};
      margin-top: ${theme.sizes.SIZE_16};
    }
  }
`;

export { StyledCampaignBrowserSidePanel };
