import { Button, useUtilities } from '@faxi/web-component-library';
import classNames from 'classnames';
import Icon from 'components/Icon';
import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { StyledFileInput } from './FileInput.styled';

export type FileInputProps = {
  label?: string;
  icon?: ReactNode;
  className?: string;
  value?: Array<UploadedFileType>;
  onChange?: (values: Array<UploadedFileType>) => void;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  DropzoneOptions;

export type UploadedFileType = { key: string; name: string };

const MAX_FILES = 1;

const FileInput: FC<FileInputProps> = (props) => {
  const {
    label = 'Upload file',
    icon = <Icon name="upload" />,
    className,
    value,
    onChange,
    disabled,
    ...rest
  } = props;

  const uploadedFiles = useMemo(() => value || [], [value]);

  const { showSnackBar } = useUtilities();

  const handleOnDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
      // Upload API request

      if (fileRejections.length) {
        showSnackBar({
          text: 'Unable to upload file. Please try again.',
          variant: 'error',
          actionButtonText: 'Dismiss',
        });

        return;
      }

      const fileUrls = acceptedFiles.map((file) => ({
        key: uuidv4(),
        name: file.name,
      }));

      onChange?.([...uploadedFiles, ...fileUrls]);
    },
    [uploadedFiles, onChange, showSnackBar]
  );

  const handleOnDeleteFile = useCallback(
    (file: UploadedFileType) => {
      // Delete API request
      onChange?.(uploadedFiles.filter((f) => f.key !== file.key));
    },
    [onChange, uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    maxFiles: MAX_FILES,
    disabled,
    ...rest,
  });

  return (
    <StyledFileInput
      className={classNames(
        'esg-file-input',
        { 'esg-file-input--disabled': disabled },
        className
      )}
    >
      {uploadedFiles.length > 0 ? (
        <div className="esg-file-input__file">
          <Icon name="file-check" />
          <span>{uploadedFiles[0].name}</span>
          <Button
            variant="ghost"
            icon={<Icon name="xmark" />}
            onClick={() => handleOnDeleteFile(uploadedFiles[0])}
          />
        </div>
      ) : (
        <div className="esg-file-input__input" {...getRootProps()}>
          <label htmlFor="file-upload">
            {icon} {label}
          </label>
          <input
            id="file-upload"
            type="file"
            {...getInputProps()}
            disabled={disabled}
          />
        </div>
      )}
    </StyledFileInput>
  );
};

export default FileInput;
