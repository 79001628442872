import { FC, PropsWithChildren } from 'react';

import { StyledGrayoutOverlay } from './GrayoutOverlay.styles';

const GrayoutOverlay: FC<PropsWithChildren> = ({ children }) => (
  <StyledGrayoutOverlay className="esg-grayout">
    <div className="esg-grayout__overlay" />
    {children}
  </StyledGrayoutOverlay>
);

export default GrayoutOverlay;
