import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const StyledUserAssignmentSessions = styled.div`
  ${flex('column')};
  ${size('100%')};
  padding: ${theme.sizes.SIZE_32};

  gap: ${theme.sizes.SIZE_32};

  .esg-user-assignment-sessions {
    &__cards {
      width: 100%;
      gap: ${theme.sizes.SIZE_24};

      &[data-view='list'] {
        ${flex('column')};
      }

      &[data-view='grid'] {
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(${theme.sizes.SIZE_374}, 1fr)
        );
      }
    }

    &__actions {
      ${flex('row', 'space-between', 'center')};
      width: 100%;
    }
  }
`;
