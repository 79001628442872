import { Modal, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledChangePasswordModal = styled(Modal)`
  .wcl-modal {
    &__main {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_32};
    }

    &__close-btn {
      display: none;
    }
  }
`;

export { StyledChangePasswordModal };
