import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementDataLineage = styled.div`
  height: 100%;

  .esg-module-element-data-lineage {
    &__form {
      ${flex('column')}
      gap: ${theme.sizes.SIZE_32};
      ${fontSize(theme.fontSizes.FONT_14)};

      &__info {
        font-weight: 600;
      }

      &__warning {
        ${flex('row')};
        gap: ${theme.sizes.SIZE_10};
      }

      &__files {
        ${flex('column')}
        gap: ${theme.sizes.SIZE_10};

        &__container {
          ${flex('row', 'space-between', 'center')};
          gap: ${theme.sizes.SIZE_10};
        }

        &__file {
          ${flex('row')};
          gap: ${theme.sizes.SIZE_10};
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`;
export { StyledModuleElementDataLineage };
