import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import { BlockUI } from '../../../../../helpers';
import {
  ApiData,
  DataModuleElementDrawerComponentProps,
  DataModuleEnum,
  ModuleElementDetailsProps,
} from '../../../../../models';
import { MODULE_DETAILS_MAPPER } from '../../constants';
import {
  StyledDetailsHeader,
  StyledDetailsTextWrapper,
  StyledModuleElementDetails,
} from './ModuleElementDetails.styled';

//TODO: replace mocked data when BE is ready
const xbrl =
  '<us-gaap:Revenues contextRef="CurrentYear" unitRef="USD" decimals="0">5000000</us-gaap:Revenues>';

const ModuleElementDetails: FC<DataModuleElementDrawerComponentProps> = ({
  organisationSessionId,
  elementId,
  type,
}) => {
  const { campaignItemId = '' } = useParams();

  const { mutate } = useSWRConfig();

  const endpoint = useMemo(
    () =>
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.DETAILS(
        organisationSessionId,
        campaignItemId,
        elementId
      ),
    [campaignItemId, elementId, organisationSessionId]
  );

  const {
    data: { data } = {},
    isValidating,
    error,
  } = useSWR<ApiData<ModuleElementDetailsProps<DataModuleEnum.FORMULA>>>(
    endpoint
  );

  const DetailsModule = MODULE_DETAILS_MAPPER[type!];

  useEffect(() => {
    return () => {
      mutate(endpoint);
    };
  }, [endpoint, mutate]);

  //Todo update handling of fallbackCondition after fetching xbrl from
  return (
    <StyledModuleElementDetails className="esg-module-element-details">
      <BlockUI loading={isValidating} fallbackCondition={!data} error={!!error}>
        <div className="esg-module-element-details__xbrl">
          <StyledDetailsHeader>XBRL Tag</StyledDetailsHeader>
          <StyledDetailsTextWrapper>{xbrl}</StyledDetailsTextWrapper>
        </div>
        {DetailsModule && data && <DetailsModule {...data} />}
      </BlockUI>
    </StyledModuleElementDetails>
  );
};

export default ModuleElementDetails;
