import {
  Button,
  Modal,
  ModalRef,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';

import { useValidations } from '../../../../../../../hooks';
import authBus from '../../../../../../../modules/authBus';
import { FormActions } from '../../../../../../../styles';
import { capitalizeFirstLetter } from '../../../../../../../utils';
import InputField from '../../../../../../_fields/InputField';
import Icon from '../../../../../../Icon';
import { StyledTableModuleSizing } from './TableModuleSizing.styled';

export type TableModuleSizingProps = PropsWithChildren<{
  rowsLength: number;
  columnsLength: number;
  disabled: boolean;
  onAddRows: (size: number) => void;
  onAddColumns: (size: number) => void;
}>;

const COMMON_BUTTON_PROPS = {
  icon: <Icon name="circle-plus" />,
  variant: 'ghost',
} as const;

const MAX_TABLE_SIZE = 10;

const TableModuleSizing: FC<TableModuleSizingProps> = ({
  children,
  onAddColumns,
  onAddRows,
  rowsLength,
  columnsLength,
  disabled,
}) => {
  const [type, setType] = useState<'column' | 'row'>();
  const [form, formRef] = useCallbackRef<FormRef>();

  const modalRef = useRef<ModalRef>(null);
  const { validations } = useValidations();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        onSubmit={async ({ size }) => {
          (type === 'row' ? onAddRows : onAddColumns)(size);

          modalRef?.current?.close();
        }}
        children={children}
        className={className}
        ref={formRef}
      />
    ),
    [formRef, onAddColumns, onAddRows, type]
  );

  const [FormButtons] = useFormButtons({
    cancelLabel: 'Cancel',
    submitLabel: 'Submit',
  });

  const openFormModal = useCallback(
    (type: 'column' | 'row') => () => {
      setType(type);
      modalRef.current?.open();
    },
    []
  );

  return (
    <StyledTableModuleSizing className="esg-table-module-sizing">
      <div className="esg-table-module-sizing__add-cols">
        {children}

        {columnsLength < MAX_TABLE_SIZE && (
          <Button
            disabled={disabled}
            onClick={openFormModal('column')}
            {...COMMON_BUTTON_PROPS}
          />
        )}
      </div>

      {rowsLength < MAX_TABLE_SIZE && (
        <Button
          disabled={disabled}
          className="esg-table-module-sizing__add-rows"
          onClick={openFormModal('row')}
          {...COMMON_BUTTON_PROPS}
        />
      )}

      <Modal
        ref={modalRef}
        renderAsPortal
        conditionallyControlled={false}
        closeOnEscape
        onOpen={() => authBus.broadcastEvent('disable_dnd')}
        onClose={() => authBus.broadcastEvent('enable_dnd')}
        title={`Add ${type === 'column' ? 'Columns' : 'Rows'}`}
        childrenWrapper={formWrapper}
        footer={
          <FormActions>
            <FormButtons.Cancel
              variant="outline"
              onClick={() => modalRef.current?.close()}
            />
            <FormButtons.Submit
              type="submit"
              disabled={form?.disableSubmitButton || !form?.asyncFormValid}
            />
          </FormActions>
        }
      >
        <FormField
          component={InputField}
          name="size"
          placeholder={`Number of New ${capitalizeFirstLetter(type!)}s`}
          required
          requiredLabel="Required"
          type="number"
          validate={validations.tableSize(
            MAX_TABLE_SIZE - (type === 'column' ? columnsLength : rowsLength)
          )}
        />
      </Modal>
    </StyledTableModuleSizing>
  );
};

export default TableModuleSizing;
