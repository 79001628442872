import { GlowScroll } from '@faxi/web-component-library';
import { FC } from 'react';

import { BlockUI } from '../../../../helpers';
import { useContributors } from '../../../../hooks';
import { AssignmentType, AssignTarget } from '../../../../models';
import AssignContributors from '../../../../routes/subrouters/Sessions/components/AssignContributors';
import UsersPreview from '../../../Users/components/UsersPreview.component';
import SessionHeader from '../SessionHeader';
import { StyledSessionContributors } from './SessionContributors.styled';

const SessionContributors: FC = () => {
  const {
    contributors,
    isAssignmentsLoading,
    assignContributors,
    assignedUsers,
    updateAssignees,
    nodeMenuItems,
  } = useContributors();

  return (
    <GlowScroll variant="gray">
      <StyledSessionContributors className="esg-session-contributors">
        <div className="esg-session-contributors__header-container">
          <SessionHeader heading="Contributors" icon="users" />
          <AssignContributors
            contributors={contributors}
            btnText="Assign to Session"
            assignTarget={AssignTarget.Session}
            loading={assignContributors.isMutating}
            onSubmit={async ({ contributors }) => {
              const { data: newAssignments } = await assignContributors.trigger(
                {
                  data: {
                    assigneeIds: contributors.map(({ value }) => value),
                    assignmentType: AssignmentType.Session,
                  },
                  method: 'POST',
                }
              );
              await updateAssignees(newAssignments);
            }}
            className="campaign-browser-side-panel__assign-button"
          />
        </div>
        <div className="esg-session-contributors__content">
          <BlockUI loading={isAssignmentsLoading}>
            <UsersPreview
              users={assignedUsers}
              view="grid"
              menuItems={nodeMenuItems}
              includeDefaultMenuItems={false}
              noUsersTitle="No contributors assigned to this session."
              noUsersIconName="users"
            />
          </BlockUI>
        </div>
      </StyledSessionContributors>
    </GlowScroll>
  );
};

export default SessionContributors;
