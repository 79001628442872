import { CalendarProps } from '@faxi/web-component-library';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { FC, PropsWithChildren } from 'react';

import { DATE_FORMAT } from '../../../constants';
import { useUserPermissions, useUserRoles } from '../../../hooks';
import { PermissionSections } from '../../../models';
import DeadlineCalendar from '../../../routes/subrouters/Sessions/components/DeadlineCalendar';
import Icon from '../../Icon';
import { StyledDeadlineExpander } from './DeadlineExpander.styled';

export type DeadlineExpanderProps = PropsWithChildren<{
  onChange: (date: Dayjs | null) => void;
  dateFormat?: string;
  deadline?: Dayjs;
  className?: string;
  error?: AxiosError;
}> &
  Pick<CalendarProps, 'disablePast' | 'leftLimitDate' | 'rightLimitDate'>;

const DeadlineExpander: FC<DeadlineExpanderProps> = ({
  deadline,
  onChange,
  className,
  dateFormat = DATE_FORMAT,
  disablePast,
  leftLimitDate,
  rightLimitDate,
}) => {
  const hasPermission = useUserPermissions(PermissionSections.SESSION);
  const { isAdminOrSA } = useUserRoles();

  const canSetDeadline = hasPermission(['update']) && isAdminOrSA;

  return (
    <StyledDeadlineExpander
      className={classNames('esg-deadline-expander', className)}
      icon={<Icon name="chevron-down" />}
      title="Deadline"
      bodyAs="div"
      open
      body={
        <DeadlineCalendar
          onChange={onChange}
          triggerText="Set Deadline"
          value={deadline}
          dateFormat={dateFormat}
          disablePast={disablePast}
          leftLimitDate={leftLimitDate}
          rightLimitDate={rightLimitDate}
          disabled={!canSetDeadline}
        />
      }
    />
  );
};

export default DeadlineExpander;
