import { Button } from '@faxi/web-component-library';
import { FC } from 'react';

import Icon from '../../../../../components/Icon';
import { StyledUserRolesNotFound } from './UserRolesNotFound.styled';

const UserRolesNotFound: FC = () => {
  return (
    <StyledUserRolesNotFound className="esg-user-roles-not-found">
      <p>This is empty state for user roles. Start by creating new role!</p>
      <Button variant="outline" icon={<Icon name="plus" />}>
        Create New Role
      </Button>
    </StyledUserRolesNotFound>
  );
};

export default UserRolesNotFound;
