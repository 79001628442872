import { Spinner } from '@faxi/web-component-library';
import { EmptyFolder, Loading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { GrayoutOverlay } from '../styles';
import ErrorFallback from './components/ErrorFallback';
import WarningFallback from './components/WarningFallback';
import { WarningFallbackProps } from './components/WarningFallback/WarningFallback.component';

const BlockUI: FC<
  PropsWithChildren & {
    blocked?: boolean;
    error?: boolean;
    warning?: boolean;
    fallback?: ReactNode;
    fallbackCondition?: boolean;
    loading?: boolean;
    onlySpinner?: boolean;
    spinnerSize?: number;
  } & Partial<WarningFallbackProps>
> = ({
  children,
  error = false,
  blocked = false,
  fallback = <EmptyFolder title="No data" />,
  fallbackCondition,
  loading,
  warning,
  warningText,
  warningTitle,
  onlySpinner,
  spinnerSize = 14,
}) => {
  if (loading)
    return onlySpinner ? <Spinner size={spinnerSize} /> : <Loading />;

  if (error) return <ErrorFallback />;

  if (warning && warningText && warningTitle)
    return (
      <WarningFallback warningTitle={warningTitle} warningText={warningText} />
    );

  return fallbackCondition ? (
    fallback
  ) : blocked ? (
    <GrayoutOverlay>{children}</GrayoutOverlay>
  ) : (
    children
  );
};

export default BlockUI;
