import { FC, PropsWithChildren } from 'react';

import { BasicTreeView } from '../../../components';
import { UserAssignementsTreeNodeProps } from '../../../models/Assignments';
import { StyledProfileAssignment } from './ProfileAssignment.styled';

export type ProfileAssignmentProps = PropsWithChildren<{
  data: UserAssignementsTreeNodeProps;
  onCheck?: () => void;
}>;

const ProfileAssignment: FC<ProfileAssignmentProps> = (props) => {
  const { data, onCheck } = props;

  // TODO
  // User assignemets are mocked. (Missing BE for functionalities)
  return (
    <StyledProfileAssignment className="esg-profile-assignment">
      <h2>Assignments</h2>
      <BasicTreeView data={data} onCheck={onCheck} />
    </StyledProfileAssignment>
  );
};

export default ProfileAssignment;
