import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormActivity = styled.div`
  ${flex('column', 'flex-start', 'stretch')};

  .esg-form-activity-log {
    cursor: pointer;
  }

  .esg-form-activity {
    &__view-all {
      ${flex('row', 'center', 'center')};
      ${fontSize(theme.fontSizes.FONT_14)};

      border: 1px solid var(--GRAY-C1);
      padding: ${theme.sizes.SIZE_8};
      background-color: var(--GRAY-EF);
      border-radius: ${theme.sizes.SIZE_8};
      cursor: pointer;
      width: 100%;
      color: unset;
      margin-top: ${theme.sizes.SIZE_16};

      &::after {
        content: unset;
      }

      &:active {
        transform: scale(1.01);
      }
    }
  }
`;

export { StyledFormActivity };
