export const AUTH_BUS_EVENTS = {
  SESSION_EXPIRED: 'session_expired',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_FAIL: 'refresh_token_failed',
  SHOW_SNACKBAR: 'show_snackbar',
  UPDATE_USER: 'update_user',
  LOGOUT: 'logout',
  ACTIVITY_LOGS_START_POLL: 'activity-logs-start-poll',
  ACTIVITY_LOGS_STOP_POLL: 'activity-logs-stop-poll',
  ENABLE_DND: 'enable_dnd',
  DISABLE_DND: 'disable_dnd',
} as const;
type EventName = (typeof AUTH_BUS_EVENTS)[keyof typeof AUTH_BUS_EVENTS];

export default {
  listeners: {} as Record<EventName, Function[]>,
  addEventListener<T = any>(event: EventName, callback: (data: T) => void) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);
    return () => {
      this.removeEventListener(event, callback);
    };
  },
  removeEventListener<T = any>(event: EventName, callback: (data: T) => void) {
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback
    );
  },
  broadcastEvent<T = any>(event: EventName, payload?: T) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((cb) => cb(payload));
    }
  },
};
