import { CampaignItem, TreeNodeElement } from '../models';

export const campaignPathCheckpoints = [
  'campaigns',
  'topics',
  'subtopics',
  'subsubtopics',
  'subsubsubtopics',
  'subsubsubsubtopics',
];

export const mapCampaignToTreeNodeElement = (
  { id, name, createdAt, updatedAt, children, type }: CampaignItem,
  depth = 0,
  path = ''
): TreeNodeElement => ({
  id,
  name,
  createdAt,
  updatedAt,
  children:
    children?.map((c) =>
      mapCampaignToTreeNodeElement(
        c,
        depth + 1,
        `${path}/${campaignPathCheckpoints[depth]}/${id}`
      )
    ) || [],

  iconName: type === 'data_collection' ? 'magnifying-glass-chart' : undefined,
  color: type === 'data_collection' ? '#0066D5' : undefined,
});
