import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormulaPreview = styled.div`
  ${flex('column', 'flex-start', 'stretch')};

  gap: ${theme.sizes.SIZE_24};

  .esg-formula-preview {
    &__formula {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_14)};
      gap: ${theme.sizes.SIZE_6};
      row-gap: ${theme.sizes.SIZE_6};
      flex-wrap: wrap;

      &--wrapper {
        ${flex('column', 'flex-start', 'stretch')};
        gap: ${theme.sizes.SIZE_24};
      }
    }
  }
`;

export { StyledFormulaPreview };
