import { Button } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { FC, PropsWithChildren } from 'react';

import { BasicTag } from '../../../../../../../components';
import { UserRoleName } from '../../../../../../../models';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../../../../../pages/Users/constants';
import { USER_ROLE_NAME_MAPPER } from '../../../UserRoles/constants';
import { StyledUserRoleHeader } from './UserRoleHeader.styled';

export type UserRoleHeaderProps = PropsWithChildren<{
  name: UserRoleName;
  color: string;
  description?: string;
  onEdit?: () => void;
}>;

const UserRoleHeader: FC<UserRoleHeaderProps> = (props) => {
  const { name, description, color, onEdit } = props;

  return (
    <StyledUserRoleHeader className="esg-user-role-header">
      <div className="esg-user-role-header__title-container">
        <h2>{USER_ROLE_NAME_MAPPER[name]}</h2>

        <BasicTag color={color} text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[name]} />
        {onEdit && (
          <Button onClick={onEdit} variant="ghost" icon={<Icon name="pen" />} />
        )}
      </div>
      {description && <p>{description}</p>}
    </StyledUserRoleHeader>
  );
};

export default UserRoleHeader;
