import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionCampaignForm = styled.div`
  height: 100%;

  .esg-grayout {
    height: 100%;
    min-height: fit-content;
  }

  .esg-session-campaign-form {
    &__form {
      ${flex('column')};
      ${size('100%')};
      position: relative;

      min-height: fit-content;

      .glow-scroll {
        height: unset;
        flex: 1;

        > div {
          overflow-y: auto;
        }
      }

      &__container {
        ${flex('row', 'flex-end', 'baseline')};
        gap: ${theme.sizes.SIZE_16};
        padding: ${theme.sizes.SIZE_16};
        &__comments-button {
          font-size: ${theme.fontSizes.FONT_18};
        }

        .circle-exclamation-icon {
          color: var(--RED-D5);
        }
        &__module {
          ${flex('row', 'flex-start', 'baseline')}
          width: calc(100% - ${theme.sizes.SIZE_32});
          gap: ${theme.sizes.SIZE_32};
        }
      }

      &__actions {
        ${flex('row', 'flex-end', 'center')};
        padding: ${theme.sizes.SIZE_16};
      }
    }
  }
`;

export { StyledSessionCampaignForm };
