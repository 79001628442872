import {
  Button,
  Dropdown,
  DropdownRef,
  Icon,
  Modal,
  ModalRef,
  theme,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import {
  FC,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';

import { BlockUI } from '../../../../../../helpers';
import { useValidations } from '../../../../../../hooks';
import authBus from '../../../../../../modules/authBus';
import { FormActions } from '../../../../../../styles';
import { capitalizeFirstLetter } from '../../../../../../utils';
import InputField from '../../../../../_fields/InputField';
import { StyledTableColumnRowActions } from '../TableCanvasModule.styled';

const TableConfigModal: FC<{
  type?: 'row' | 'column';
  itemLabel: string;
  children?: ReactNode;
  moveItemPrev: () => void;
  moveItemNext: () => void;
  addItem: () => void;
  editItem: (label: string) => void;
  deleteItem: () => void;
  splitItem: (splitTimes: number) => void;
  onOpenModal: () => void;
  disabled: boolean;
}> = ({
  type = 'row',
  itemLabel,
  splitItem,
  children,
  addItem,
  editItem,
  deleteItem,
  moveItemPrev,
  moveItemNext,
  onOpenModal,
  disabled,
}) => {
  const [formType, setFormType] = useState<'split' | 'edit'>('split');
  const modalRef = useRef<ModalRef>(null);
  const dropdownRef = useRef<DropdownRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const [FormButtons] = useFormButtons({
    cancelLabel: 'Cancel',
    submitLabel: 'Submit',
  });

  const { validations } = useValidations();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        onSubmit={async ({ itemLabel, splitTimes }) => {
          formType === 'edit' ? editItem(itemLabel) : splitItem(splitTimes);

          modalRef?.current?.close();
        }}
        children={children}
        className={className}
        ref={formRef}
      />
    ),
    [formRef, formType, editItem, splitItem]
  );

  return (
    <BlockUI blocked={disabled}>
      <StyledTableColumnRowActions
        data-no-dnd="true"
        className="esg-table-column-row-actions"
      >
        {children}

        <Button
          icon={<Icon name={`chevron-${type === 'column' ? 'left' : 'up'}`} />}
          onClick={moveItemPrev}
          variant="ghost"
        />
        <Button
          icon={
            <Icon name={`chevron-${type === 'column' ? 'right' : 'down'}`} />
          }
          onClick={moveItemNext}
          variant="ghost"
        />

        <Button
          icon={<Icon name="pen" />}
          onClick={() => {
            onOpenModal();
            modalRef.current?.open();

            setFormType('edit');
          }}
          variant="ghost"
        />

        <Button icon={<Icon name="plus" />} onClick={addItem} variant="ghost" />
        <Button
          icon={<Icon name="trash-can" />}
          onClick={deleteItem}
          variant="ghost"
        />

        <Dropdown
          renderAsPortal
          ref={dropdownRef}
          hasCloudArrow={false}
          trigger={
            <Button
              icon={<Icon name="ellipsis-vertical" />}
              variant="ghost"
              data-no-dnd="true"
            />
          }
          body={
            <div
              style={{ display: 'flex', padding: theme.sizes.SIZE_10 }}
              data-no-dnd="true"
            >
              <Button
                icon={<Icon name="table-columns" />}
                variant="ghost"
                data-no-dnd="true"
                onClick={() => {
                  onOpenModal();
                  dropdownRef.current?.setOpen(false);
                  modalRef.current?.open();
                  setFormType('split');
                }}
              >
                Split {capitalizeFirstLetter(type)}
              </Button>
            </div>
          }
        />
      </StyledTableColumnRowActions>

      <Modal
        ref={modalRef}
        renderAsPortal
        conditionallyControlled={false}
        closeOnEscape
        onOpen={() => {
          authBus.broadcastEvent('disable_dnd');
        }}
        onClose={() => {
          authBus.broadcastEvent('enable_dnd');
        }}
        title={`${formType === 'split' ? 'Split' : 'Edit'} ${capitalizeFirstLetter(type)} ${itemLabel}`}
        childrenWrapper={formWrapper}
        footer={
          <FormActions>
            <FormButtons.Cancel
              variant="outline"
              onClick={() => modalRef.current?.close()}
            />
            <FormButtons.Submit
              type="submit"
              disabled={form?.disableSubmitButton || !form?.asyncFormValid}
            />
          </FormActions>
        }
      >
        <FormField
          component={InputField}
          name={formType === 'edit' ? 'itemLabel' : 'splitTimes'}
          placeholder={
            formType === 'edit'
              ? 'Label'
              : `Number of Sub-${capitalizeFirstLetter(type)}s`
          }
          required
          requiredLabel="Required"
          type={formType === 'edit' ? 'text' : 'number'}
          validate={
            validations[formType === 'split' ? 'splitTimes' : 'tableLabel']
          }
        />
      </Modal>
    </BlockUI>
  );
};

export default memo(TableConfigModal);
