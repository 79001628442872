import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementActivityLog = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_24};

  .esg-module-element-activity-log {
    &__date {
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
      font-weight: 600;
    }

    &__log {
      display: grid;
      align-items: center;
      grid-template-columns: ${theme.sizes.SIZE_152} 1fr auto;
      grid-column-gap: ${theme.sizes.SIZE_24};
      border-bottom: 1px solid var(--GRAY-C1);
      padding: ${theme.sizes.SIZE_24} 0;

      .esg-user-mention-option {
        width: ${theme.sizes.SIZE_152};
      }

      &__action-container {
        ${flex('row', 'flex-start', 'center')};

        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

        gap: ${theme.sizes.SIZE_24};
        border-left: 1px solid var(--GRAY-C1);
        padding-left: ${theme.sizes.SIZE_12};
      }

      > div {
        width: fit-content;

        span {
          ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
        }
      }

      > p {
        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
      }

      > span {
        ${fontSize(theme.fontSizes.FONT_12, '17px')};

        color: var(--GRAY-70);
      }
    }
  }
`;

export { StyledModuleElementActivityLog };
