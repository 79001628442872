import { ButtonProps } from '@faxi/web-component-library';
import classNames from 'classnames';
import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';

import { CONFIGURABLE_MODULES } from '../../../../../pages/Campaigns/components/Dnd/components/CanvasModule/CanvasModule.component';
import { mapDataModuleTypeToIcon } from '../../../../../pages/Campaigns/utils';
import { ThreeDotMenu } from '../../../../_molecules';
import Icon from '../../../../Icon';
import { StyledConditionalCanvasModule } from './ConditionalCanvasModule.styled';

export type ConditionalCanvasModuleProps = PropsWithChildren<{
  module: ModuleConfig<ModuleConfigType, DataModuleEnum>;
  onClickDelete: (id: string) => void;
  onConfig?: (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => void;
  className?: string;
}>;

const ConditionalCanvasModule: FC<ConditionalCanvasModuleProps> = (props) => {
  const { module, children, onClickDelete, onConfig, className } = props;

  const isConfigurable = useMemo(
    () => CONFIGURABLE_MODULES.includes(module.type),
    [module.type]
  );

  const nodeMenuItems = useMemo(
    () =>
      [
        ...(isConfigurable
          ? [
              {
                children: 'Configure',
                icon: <Icon name="wrench" />,
                variant: 'ghost',
                onClick: (e) => {
                  e.stopPropagation();
                  onConfig?.(module);
                },
              } as ButtonProps,
            ]
          : []),

        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          variant: 'delete-ghost',
          onClick: () => onClickDelete(module.id),
        },
      ] as ButtonProps[],
    [isConfigurable, module, onConfig, onClickDelete]
  );

  return (
    <StyledConditionalCanvasModule
      className={classNames('esg-conditional-canvas-module', className)}
    >
      <div className="esg-conditional-canvas-module__type">
        <Icon name={mapDataModuleTypeToIcon[module.type]} />
      </div>

      {children}

      <ThreeDotMenu
        data-no-dnd="true"
        className="esg-conditional-canvas-module__menu"
        menuItems={nodeMenuItems}
        renderAsPortal
      />
    </StyledConditionalCanvasModule>
  );
};

export default ConditionalCanvasModule;
