import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/integrations/${apiVersion}`;

export const INTEGRATIONS = {
  API_INTEGRATIONS: `${baseUrl}/api-integrations`,

  API_INTEGRATIONS_INTEGRATION: (integrationId: string) =>
    `${baseUrl}/api-integrations/${integrationId}`,

  API_INTEGRATIONS_INTEGRATION_FIELDS: (integrationId: string) =>
    `${baseUrl}/api-integrations/${integrationId}/fields`,
};
