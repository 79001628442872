import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledInputValueChange = styled.div`
  ${flex('row', 'flex-start', 'center')};

  > span {
    ${flex('row', 'center', 'center')};
    ${fontSize(theme.fontSizes.FONT_14)};

    padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};
    min-width: unset;
    max-width: ${theme.sizes.SIZE_224};
    word-break: break-all;
  }

  gap: ${theme.sizes.SIZE_12};

  .esg-input-value-change {
    &__old {
      background-color: var(--KHAKI-FF);
    }

    &__new {
      background-color: var(--GREEN-E3);
    }
  }
`;

export { StyledInputValueChange };
