import {
  Button,
  ButtonProps,
  GlowScroll,
  Icon,
  Tabs,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import { FC, useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';

import useMutation from '../../api/hooks/useMutation';
import { API_ROUTES } from '../../api/routes';
import { SectionCard, ViewGuard } from '../../components';
import { BlockUI } from '../../helpers';
import { useLayoutPreviewMode, useUserPermissions } from '../../hooks';
import { previewTabs } from '../../hooks/useLayoutPreviewMode';
import {
  APIIntegration,
  LayoutPreview,
  PermissionSections,
} from '../../models';
import IntegrationModal from './components/IntegrationModal';
import { StyledIntegrations } from './Integrations.styled';

const Integrations: FC = () => {
  const { view, setView } = useLayoutPreviewMode('grid');

  const { open, openModal, closeModal } = useModalUtilities();

  const [selectedIntegration, setSelectedIntegration] =
    useState<APIIntegration>();

  const hasPermission = useUserPermissions(PermissionSections.API_INTEGRATION);

  const {
    isLoading: isLoadingApiIntegrations,
    isValidating: isValidatingApiIntegrations,
    data: { data: apiIntegrations = [] } = {},
    mutate: mutateApiIntegrations,
  } = useSWR<{ data: APIIntegration[] }>(
    API_ROUTES.INTEGRATIONS.API_INTEGRATIONS
  );

  const { trigger: integrationsTrigger } = useMutation(
    API_ROUTES.INTEGRATIONS.API_INTEGRATIONS
  );

  const { trigger: integrationTrigger } = useMutation(
    API_ROUTES.INTEGRATIONS.API_INTEGRATIONS_INTEGRATION('')
  );

  const { prompt, showOverlay, hideOverlay } = useUtilities();

  const menuItems = useCallback(
    (integration: APIIntegration): ButtonProps[] => [
      ...(hasPermission(['update'])
        ? [
            {
              children: 'Edit',
              icon: <Icon name="pen" />,
              iconPosition: 'left',
              variant: 'ghost',
              onClick: async (ev) => {
                ev.stopPropagation();

                setSelectedIntegration(integration);

                openModal();
              },
            } as ButtonProps,
          ]
        : []),
      ...(hasPermission(['delete'])
        ? [
            {
              children: 'Delete',
              icon: <Icon name="trash-can" className="color-secondary" />,
              iconPosition: 'left',
              variant: 'delete-ghost',
              onClick: async (ev) => {
                ev.stopPropagation();

                const confirm = await prompt({
                  type: 'delete',
                  title: `Delete ${integration.name}?`,
                  content: `Are you sure you want to delete ${integration.name} from integrations?`,
                  cancelButtonText: 'Cancel',
                  buttonIcon: 'trash-can',
                  confirmButtonText: 'Delete',
                });

                if (!confirm) return;

                try {
                  showOverlay('#main');

                  await integrationTrigger({
                    url: API_ROUTES.INTEGRATIONS.API_INTEGRATIONS_INTEGRATION(
                      integration.id
                    ),
                    method: 'DELETE',
                  });

                  await mutateApiIntegrations();
                } catch (e) {
                  console.error(e);
                } finally {
                  hideOverlay('#main');
                }
              },
            } as ButtonProps,
          ]
        : []),
    ],
    [
      hasPermission,
      hideOverlay,
      integrationTrigger,
      mutateApiIntegrations,
      openModal,
      prompt,
      showOverlay,
    ]
  );

  const onSubmit = useCallback(
    async (data: APIIntegration) => {
      try {
        showOverlay('.wcl-modal');

        if (selectedIntegration) {
          await integrationTrigger({
            url: API_ROUTES.INTEGRATIONS.API_INTEGRATIONS_INTEGRATION(
              selectedIntegration.id
            ),
            data,
            method: 'PATCH',
          });
          setSelectedIntegration(undefined);
        } else {
          await integrationsTrigger({ data, method: 'POST' });
        }

        await mutateApiIntegrations();

        closeModal();
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal');
      }
    },
    [
      closeModal,
      hideOverlay,
      integrationsTrigger,
      integrationTrigger,
      mutateApiIntegrations,
      showOverlay,
      selectedIntegration,
    ]
  );

  const initialData = useMemo(
    () =>
      selectedIntegration ? { name: selectedIntegration.name } : undefined,
    [selectedIntegration]
  );

  return (
    <StyledIntegrations
      title="Integrations"
      className="esg-integrations"
      padding={0}
    >
      <BlockUI
        loading={isLoadingApiIntegrations || isValidatingApiIntegrations}
      >
        <GlowScroll variant="gray">
          <div className="esg-integrations__container">
            <div className="esg-integrations__header">
              <div className="esg-integrations__header__buttons">
                <ViewGuard
                  section={PermissionSections.API_INTEGRATION}
                  permissions={['create']}
                >
                  <Button
                    icon={<Icon name="plus" />}
                    variant="outline"
                    onClick={openModal}
                  >
                    Create New Integration
                  </Button>
                </ViewGuard>
              </div>
              <Tabs<LayoutPreview>
                value={view}
                className="esg-xbrl-tabs"
                labelPosition="right"
                tabs={previewTabs}
                onChange={setView}
                changeOrientationOnResponsive={false}
              />
            </div>

            <div
              className="esg-integrations__container__cards"
              data-view={view}
            >
              {apiIntegrations.map((integration) => (
                <SectionCard
                  title={integration.name}
                  view={view}
                  withMenu
                  key={integration.id}
                  iconName="circle-plus"
                  hasDetails={false}
                  menuItems={menuItems(integration)}
                />
              ))}
            </div>
          </div>
        </GlowScroll>
      </BlockUI>

      {open && (
        <IntegrationModal
          initialData={initialData}
          onClose={() => {
            setSelectedIntegration(undefined);
            closeModal();
          }}
          onSubmit={onSubmit}
        />
      )}
    </StyledIntegrations>
  );
};

export default Integrations;
