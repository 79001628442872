import { CampaignItem, CampaignWithItems } from '../models';

const findCampaignItem = (
  id: string,
  item: CampaignWithItems | CampaignItem | undefined
): CampaignWithItems | CampaignItem | undefined => {
  if (!item) return undefined;

  const tmp = [item];

  while (tmp.length) {
    const element = tmp.pop()!;

    if (element.id === id) return element;

    if (element.children.length) tmp.push(...element.children);
  }

  return undefined;
};

export { findCampaignItem };
