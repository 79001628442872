import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledHaveNotificationsIconWrapper = styled.div<{ $hasCount?: boolean }>`
  position: relative;

  &::before {
    position: absolute;
    ${flex('column', 'center', 'center')}
    ${fontSize(theme.fontSizes.FONT_11, '13px')};
    color: var(--WHITE-FF);
    min-height: ${({ $hasCount }) =>
      $hasCount ? theme.sizes.SIZE_16 : theme.sizes.SIZE_8};
    min-width: ${({ $hasCount }) =>
      $hasCount ? theme.sizes.SIZE_16 : theme.sizes.SIZE_8};
    content: attr(data-count);
    background-color: var(--RED-D5);
    max-height: ${theme.sizes.SIZE_16};
    max-width: ${theme.sizes.SIZE_16};
    border-radius: 50%;
    right: 0;
    top: 0;
    transform: translate(55%, -55%);
  }
`;

export { StyledHaveNotificationsIconWrapper };
