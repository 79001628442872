import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormulaDetailsPreview = styled.div`
  ${flex('column', 'flex-start', 'stretch')};

  gap: ${theme.sizes.SIZE_24};

  .esg-formula-details-preview {
    &__inputs {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_24};

      &__input {
        ${flex('row', 'space-between', 'center')};

        &__value {
          ${fontSize(theme.fontSizes.FONT_14)};

          &--empty {
            color: var(--GRAY-70);
          }
        }

        &__title {
          ${fontSize(theme.fontSizes.FONT_14)};
        }
      }
    }
  }
`;

export { StyledFormulaDetailsPreview };
