import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserRoleHistory = styled.div`
  ${flex('column')};

  padding: ${theme.sizes.SIZE_32};
  width: 100%;

  .esg-role-history {
    &__user {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_16};
    }

    &__table {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_20};
      padding-bottom: ${theme.sizes.SIZE_32};
      color: var(--GRAY-98);
      max-width: ${theme.sizes.SIZE_1200};
    }
  }
`;

export { StyledUserRoleHistory };
