import { useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import { IDataModule, TreeNodeElement } from '../../../../models';
import { insertNodeInTree } from '../../../../utils';

/**
 * A custom hook for organising organisations into a structured tree format.
 *
 * @returns
 * - `consolidatedDataTreeView` (TreeNodeElement | undefined): A hierarchical tree view of the organization, its companies, and their modules.
 */

type ConsolidatedData = {
  organisationName: string;
  organisationNamedPath: string;
  organisationDescription: string;
  organisationPath: string;
  organisationId: string;
} & IDataModule;

const useConsolidatedData = (
  organisationSessionId: string,
  campaignItemId: string,
  elementId: string,
  organisationId: string
) => {
  const { data: { data = [] } = {}, isLoading } = useSWR<{
    data: ConsolidatedData[];
  }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_DATA_CONSOLIDATION(
      organisationSessionId,
      campaignItemId,
      elementId
    )
  );

  const reorderedData = useMemo(() => {
    if (!data.length) return [];

    const formattedData = data.map(
      ({
        organisationId,
        organisationName,
        organisationNamedPath,
        organisationDescription,
        organisationPath,
        ...module
      }) => ({
        organisationId,
        organisationName,
        organisationNamedPath,
        organisationDescription,
        organisationPath,
        module,
      })
    );

    const matchedIndex = formattedData.findIndex(
      (org) => org.organisationId === organisationId
    );

    const reorderedData =
      matchedIndex === -1
        ? formattedData
        : [
            formattedData[matchedIndex],
            ...formattedData.slice(0, matchedIndex),
            ...formattedData.slice(matchedIndex + 1),
          ];

    return reorderedData;
  }, [data, organisationId]);

  const consolidatedDataTree = useMemo(
    () =>
      reorderedData?.length
        ? {
            organisation: {
              id: reorderedData[0].organisationId,
              name: reorderedData[0].organisationName,
              module: reorderedData[0].module,
            },
            companies: reorderedData
              .map(
                ({
                  module,
                  organisationPath,
                  organisationNamedPath,
                  organisationName,
                  organisationId,
                }) => {
                  const namesArray = organisationNamedPath.split('#');
                  const idsArray = organisationPath.split('#');

                  const nameStartIndex = namesArray?.indexOf(
                    reorderedData[0].organisationName
                  );
                  const idStartIndex = idsArray?.indexOf(
                    reorderedData[0].organisationId
                  );

                  const filteredNames =
                    nameStartIndex !== -1
                      ? namesArray.slice(nameStartIndex)
                      : [organisationName];
                  const filteredIds =
                    idStartIndex !== -1
                      ? idsArray.slice(idStartIndex)
                      : [organisationId];

                  const childrensCount =
                    idStartIndex !== -1 ? idsArray.length - idStartIndex : 0;

                  return {
                    organisationSessionId: module.organisationSessionId,
                    path: { ids: filteredIds, names: filteredNames },
                    name: organisationName,
                    id: organisationId,
                    module: module,
                    childrensCount,
                  };
                }
              )
              .sort((a, b) => b.childrensCount - a.childrensCount),
          }
        : undefined,
    [reorderedData]
  );

  const consolidatedDataTreeView: TreeNodeElement | undefined = useMemo(() => {
    if (!consolidatedDataTree) return;

    const root: TreeNodeElement = {
      id: consolidatedDataTree.organisation.id,
      name: consolidatedDataTree.organisation.name,
      module: consolidatedDataTree.organisation.module,
    };
    consolidatedDataTree.companies.forEach((company) => {
      insertNodeInTree({
        currentNode: root,
        targetNode: { ...company },
        depth: 0,
      });
    });

    return root;
  }, [consolidatedDataTree]);

  return { isLoading, consolidatedDataTreeView };
};

export default useConsolidatedData;
