import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../api/routes';
import { APIIntegration, APIIntegrationField } from '../models';

const useApiIntegrations = (
  apiIntegrationId?: string,
  apiIntegrationFieldId?: string
) => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<APIIntegration>();
  const [selectedIntegrationField, setSelectedIntegrationField] =
    useState<APIIntegrationField>();

  const {
    data: { data: apiIntegrations = [] } = {},
    isLoading: isLoadingApiIntegrations,
    isValidating: isValidatingApiIntegrations,
  } = useSWR<{ data: APIIntegration[] }>(
    API_ROUTES.INTEGRATIONS.API_INTEGRATIONS
  );

  const {
    data: { data: apiIntegrationFields = [] } = {},
    isLoading: isLoadingApiIntegrationFields,
    isValidating: isValidatingApiIntegrationFields,
  } = useSWR<{ data: APIIntegrationField[] }>(
    apiIntegrationId &&
      API_ROUTES.INTEGRATIONS.API_INTEGRATIONS_INTEGRATION_FIELDS(
        apiIntegrationId
      )
  );

  useEffect(() => {
    if (selectedIntegration) return;

    const activeIntegration = apiIntegrations.find(
      (el) => el.id === apiIntegrationId
    );

    if (activeIntegration) {
      setSelectedIntegration(activeIntegration);
    }
  }, [apiIntegrationId, apiIntegrations, selectedIntegration]);

  useEffect(() => {
    if (selectedIntegrationField || !apiIntegrationFieldId) return;

    const activeIntegrationField = apiIntegrationFields.find(
      (el) => el.id === apiIntegrationFieldId
    );

    if (activeIntegrationField) {
      setSelectedIntegrationField(activeIntegrationField);
    }
  }, [
    apiIntegrationFieldId,
    apiIntegrationFields,
    apiIntegrations,
    selectedIntegration,
    selectedIntegrationField,
  ]);

  return {
    selectedIntegration,
    selectedIntegrationField,
    apiIntegrations,
    apiIntegrationFields,
    isLoadingApiIntegrations,
    isLoadingApiIntegrationFields,
    isValidatingApiIntegrations,
    isValidatingApiIntegrationFields,
  };
};

export default useApiIntegrations;
