import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '..';
import { StyledAssignmentsLayout } from './AssignmentsLayout.styled';

type AssignmentsLayoutProps = PropsWithChildren<{ className?: string }>;

const AssignmentsLayout: FC<AssignmentsLayoutProps> = ({ className }) => (
  <StyledAssignmentsLayout
    className={classNames('esg-assignments-layout', className)}
  >
    <Header title="Assignments" className="esg-assignments-layout__header" />
    <div className="esg-assignments-layout__content">
      <Outlet />
    </div>
  </StyledAssignmentsLayout>
);

export default AssignmentsLayout;
