import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/notifications/${apiVersion}`;

export const NOTIFICATIONS_ROUTES = {
  NOTIFICATIONS: baseUrl,
  NOTIFICATIONS_READ: `${baseUrl}/read`,
};
