import {
  applyRef,
  ModalProps,
  ModalRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { FormFooter, InputField, SelectField } from 'components';
import { useValidations } from 'hooks';
import useRoleOptions from 'hooks/useRoleOptions';
import { UserFormData, UserRole } from 'models';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import UserProfileImage from 'routes/subrouters/Users/components/UserProfileImage';

import { StyledManageUserModal } from './ManageUserModal.styled';

export type ManageUserModalProps = PropsWithChildren<
  ModalProps & {
    initialData?: UserFormData;
    onSubmit: (data: UserFormData, role?: UserRole) => Promise<void>;
    isEditingOwnProfile?: boolean;
  }
>;

const ManageUserModal: ForwardRefRenderFunction<
  ModalRef,
  ManageUserModalProps
> = (props, ref) => {
  const {
    onSubmit: pOnSubmit,
    initialData,
    isEditingOwnProfile = false,
    ...rest
  } = props;

  const isEdit = !!initialData;

  const modalRef = useRef<ModalRef>();

  const { roleOptions, isLoading, isValidating, roles } = useRoleOptions();

  const { showOverlay, hideOverlay } = useUtilities();

  const { validations } = useValidations();

  const onSubmit = useCallback(
    async (data: UserFormData) => {
      const role = roles?.find((e) => e.id === data.roleId);
      await pOnSubmit(data, role);
    },
    [pOnSubmit, roles]
  );

  const initialDataUsed = useMemo(
    () =>
      initialData
        ? {
            firstName: initialData.firstName,
            lastName: initialData.lastName,
            jobTitle: initialData.jobTitle,
            ...(!isEditingOwnProfile && { roleId: initialData.roleId }),
          }
        : undefined,
    [initialData, isEditingOwnProfile]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialDataUsed}
        onSubmit={onSubmit}
      />
    ),
    [initialDataUsed, onSubmit]
  );

  useEffect(() => {
    setTimeout(() => {
      (isLoading || isValidating ? showOverlay : hideOverlay)('.wcl-modal');
    }, 0);
  }, [hideOverlay, isLoading, isValidating, showOverlay]);

  return (
    <StyledManageUserModal
      className="esg-manage-user-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title={
        isEdit
          ? `Edit ${[initialData.firstName, initialData.lastName].join(' ').trim()}`
          : 'Create New User'
      }
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel={isEdit ? 'Save' : 'Create'}
        />
      }
      {...rest}
    >
      <UserProfileImage />
      <FormField
        key="firstName"
        name="firstName"
        component={InputField}
        autoComplete="off"
        placeholder="First Name"
        validate={validations.name}
      />
      <FormField
        key="lastName"
        name="lastName"
        component={InputField}
        autoComplete="off"
        placeholder="Last Name"
        validate={validations.name}
      />
      {!isEdit && (
        <FormField
          key="email"
          name="email"
          component={InputField}
          autoComplete="off"
          placeholder="Email"
          validate={validations.email}
        />
      )}
      <FormField
        key="jobTitle"
        name="jobTitle"
        component={InputField}
        autoComplete="off"
        placeholder="Job Title"
        validate={validations.name}
      />

      {!isEditingOwnProfile && (
        <FormField
          key="roleId"
          name="roleId"
          component={SelectField}
          loading={isLoading || isValidating}
          autoComplete="off"
          placeholder="Role"
          options={roleOptions}
          validate={validations.role}
          portalClassName="role-select-portal"
        />
      )}
    </StyledManageUserModal>
  );
};

export default forwardRef(ManageUserModal);
