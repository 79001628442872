import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserRoleHeader = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_24};

  .esg-user-role-header {
    &__title-container {
      ${flex('row', 'flex-start', 'center')}
      gap: ${theme.sizes.SIZE_12};

      button {
        &:hover {
          background-color: transparent;
        }
      }

      h2 {
        margin: 0;
      }
    }
  }
`;

export { StyledUserRoleHeader };
