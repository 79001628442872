import { RouteObject } from 'react-router-dom';

import AccountSettings from '../../../../../pages/AccountSettings';
import UserDetails from './pages/UserDetails';
import UserNotifications from './pages/UserNotifications';
import UserReminders from './pages/UserReminders';
import UserRoleHistory from './pages/UserRoleHistory/UserRoleHistory.component';

export default [
  { path: 'account-settings', element: <AccountSettings /> },
  { path: 'personal-details', element: <UserDetails /> },
  { path: 'notifications', element: <UserNotifications /> },
  { path: 'reminders', element: <UserReminders /> },
  { path: 'role-history', element: <UserRoleHistory /> },
] as RouteObject[];
