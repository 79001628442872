import { CampaignItem, CampaignWithItems, IDataModule } from 'models';
import { createContext } from 'react';
import { KeyedMutator } from 'swr';

export type CampaignItemContextProps = {
  isMutatingCampaignItem: boolean;
  isLoadingCampaignItem: boolean;
  isValidatingCampaignItem: boolean;
  dataCollection?: CampaignItem;
  isForm: boolean;
  editCampaignItem: (
    campaignId: string,
    campaignItemId: string,
    data: Partial<CampaignWithItems | CampaignItem>,
    onSuccess?: (data: CampaignItem) => void
  ) => Promise<void>;
  createCampaignItem: (
    campaignId: string,
    data: Partial<CampaignWithItems & { campaignItemId: string }>
  ) => Promise<void>;
  duplicateCampaignItem: (
    data: IDataModule,
    onSuccess?: (data: CampaignItem) => void
  ) => Promise<void>;
  deleteCampaignItem: (
    campaignId: string,
    campaignItemId: string
  ) => Promise<void>;
  mutateCampaignItem: KeyedMutator<{ data: CampaignItem }>;
};

export default createContext<CampaignItemContextProps>({
  isForm: false,
  isMutatingCampaignItem: false,
  isLoadingCampaignItem: false,
  isValidatingCampaignItem: false,
  duplicateCampaignItem: () => Promise.resolve(),
  editCampaignItem: () => Promise.resolve(),
  createCampaignItem: () => Promise.resolve(),
  deleteCampaignItem: () => Promise.resolve(),
  mutateCampaignItem: () => Promise.resolve({} as any),
});
