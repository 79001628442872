import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { BaseLayout } from '../../layouts';

const StyledIntegrations = styled(BaseLayout)`
  .esg-integrations {
    &__header {
      ${flex('row', 'space-between', 'center')};
      gap: ${theme.sizes.SIZE_16};
      @media screen and (max-width: 520px) {
        ${flex('column', 'center', 'flex-start')};
      }

      &__buttons {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_16};
      }

      .wcl-tabs {
        width: ${theme.sizes.SIZE_208};
        height: ${theme.sizes.SIZE_46};
        i {
          margin: 0 !important;
        }
        &::before,
        label {
          width: ${theme.sizes.SIZE_104};
        }
      }
    }

    &__container {
      padding: ${theme.sizes.SIZE_32};

      &__cards {
        margin-top: ${theme.sizes.SIZE_48};
        gap: ${theme.sizes.SIZE_24};

        .esg-section-card {
          max-width: ${theme.sizes.SIZE_600};

          &--list {
            justify-content: center;
          }
        }

        &[data-view='list'] {
          ${flex('column')};
        }

        &[data-view='grid'] {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(${theme.sizes.SIZE_288}, 1fr)
          );
        }
      }
    }
  }
`;

export { StyledIntegrations };
