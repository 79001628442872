import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledXBRLTag = styled.div`
  ${flex('row', 'space-between', 'center')};
  gap: ${theme.sizes.SIZE_16};

  .esg-xbrl-field-group {
    &__label {
      font-weight: 600;
    }
  }

  .spinner {
    margin: auto;
  }
`;

export { StyledXBRLTag };
