import { ButtonProps } from '@faxi/web-component-library';
import { EmptyFolder } from 'components';
import UserCard from 'components/_molecules/UserCard';
import { INameExtended } from 'components/Icon';
import { LayoutPreview, User } from 'models';
import { FC, MouseEvent, PropsWithChildren } from 'react';

import { useRootProvider } from '../../../providers/Root';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../constants';
import { StyledUsersPreview } from './UsersPreview.styled';

export type UsersPreviewProps = PropsWithChildren<{
  users?: User[];
  view?: LayoutPreview;
  onClick?: (user: User) => void;
  onEdit?: (user: User, event: MouseEvent) => void;
  onDelete?: (user: User, event: MouseEvent) => void;
  loadingUserId?: string;
  isMutatingUpdateUser?: boolean;
  isMutatingRoles?: boolean;
  menuItems?: (id: string, name: string) => ButtonProps[];
  includeDefaultMenuItems?: boolean;
  noUsersTitle?: string;
  noUsersIconName?: INameExtended;
}>;

const UsersPreview: FC<UsersPreviewProps> = (props) => {
  const {
    users,
    onClick,
    onEdit,
    onDelete,
    view = 'list',
    includeDefaultMenuItems = true,
    menuItems,
    loadingUserId,
    isMutatingRoles,
    isMutatingUpdateUser,
    noUsersIconName,
    noUsersTitle,
  } = props;

  const { user: rootUser } = useRootProvider();

  if (!users?.length)
    return <EmptyFolder title={noUsersTitle} iconName={noUsersIconName} />;

  return (
    <StyledUsersPreview className="esg-users-preview" data-view={view}>
      {users.map((user) => (
        <UserCard
          id={user.id}
          key={user.id}
          title={`${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()}
          titleTag={{
            text: ROLE_VALUE_TO_DESCRIPTION_MAPPER[user.roles[0]?.name],
            color: user.roles[0]?.color,
          }}
          description={user.email}
          hasActions={false}
          withMenu={rootUser.id !== user.id}
          thisIsYou={rootUser.id === user.id}
          menuItems={menuItems?.(user.id, `${user.firstName} ${user.lastName}`)}
          includeDefaultMenuItems={includeDefaultMenuItems}
          view={view}
          onClick={() => onClick?.(user)}
          onEdit={(e) => onEdit?.(user, e)}
          onDelete={(e) => onDelete?.(user, e)}
          onAddDescriptionCallback={(e) => onEdit?.(user, e)}
          loading={
            loadingUserId === user.id &&
            (isMutatingRoles || isMutatingUpdateUser)
          }
        />
      ))}
    </StyledUsersPreview>
  );
};

export default UsersPreview;
