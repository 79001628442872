import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserDetails = styled.div`
  ${flex('column', 'flex-start', 'stretch')};

  padding: ${theme.sizes.SIZE_32};
  padding-right: 0;
  width: 100%;
`;

export { StyledUserDetails };
