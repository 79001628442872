import {
  Button,
  ButtonProps,
  Image,
  MenuRef,
} from '@faxi/web-component-library';
import { Contributor, PermissionSections } from 'models';
import { FC, LegacyRef, PropsWithChildren } from 'react';

import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import { BasicTag, ViewGuard } from '../../_atoms';
import Icon from '../../Icon';
import ThreeDotMenu from '../ThreeDotMenu';
import { StyledAssignedContributor } from './AssignedContributor.styled';

export type AssignedContributorProps = PropsWithChildren<
  Contributor & {
    menuItems?: ButtonProps[];
    menuRef?: LegacyRef<MenuRef>;
    onRemove?: () => void;
  }
>;

const AssignedContributor: FC<AssignedContributorProps> = (props) => {
  const { menuRef, isAdmin, name, menuItems, onRemove, role } = props;

  return (
    <StyledAssignedContributor
      role="button"
      className="esg-assigned-contributor"
    >
      <div>
        <Image src="/assets/webp/avatar.webp" alt="" />
        <div>{name}</div>
        {isAdmin && <Icon color="var(--BLUE-06)" name="crown" />}
      </div>
      <BasicTag
        text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[role.name]}
        color={role.color}
      />
      <ViewGuard
        section={PermissionSections.ASSIGNMENT}
        permissions={['update']}
      >
        {!!menuItems?.length && (
          <ThreeDotMenu
            ref={menuRef}
            className="esg-assigned-contributor__menu"
            menuItems={menuItems}
          />
        )}

        {!menuItems && (
          <Button
            icon={<Icon name="xmark" />}
            onClick={onRemove}
            variant="ghost"
          />
        )}
      </ViewGuard>
    </StyledAssignedContributor>
  );
};

export default AssignedContributor;
