import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledIntegrationPreview = styled.div`
  ${flex('row', 'space-between', 'center')};
  width: 100%;

  .esg-integration-preview {
    &__title {
      font-size: ${theme.sizes.SIZE_16};
      font-weight: 500;
    }
  }
`;

export { StyledIntegrationPreview };
