import { PermissionSections, UserRoleForm } from 'models';

export const USER_ROLE_FORM_DATA: UserRoleForm = {
  [PermissionSections.USER]: {
    icon: 'users',
    label: PermissionSections.USER,
    actions: {
      read: {
        label: 'Read users',
        name: 'read',
        excludes: ['update', 'delete', 'create'],
        includes: [],
      },
      create: {
        label: 'Create users',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      update: {
        label: 'Edit users',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete users',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.ORGANISATION]: {
    icon: 'shield',
    label: PermissionSections.ORGANISATION,
    actions: {
      read: {
        label: 'Read organisations and companies',
        name: 'read',
        excludes: ['create', 'update', 'delete'],
        includes: [],
      },
      create: {
        label: 'Create organisations and companies',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      update: {
        label: 'Edit organisations and companies',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete organisations and companies',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.CAMPAIGN]: {
    icon: 'leaf',
    label: PermissionSections.CAMPAIGN,
    actions: {
      read: {
        label: 'Read campaigns, topics and subtopics',
        name: 'read',
        excludes: ['create', 'update', 'delete'],
        includes: [],
      },
      create: {
        label: 'Create campaigns, topics and subtopics',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      update: {
        label: 'Edit campaigns, topics and subtopics',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete campaigns, topics and subtopics',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.TAXONOMY]: {
    icon: 'tag',
    label: PermissionSections.TAXONOMY,
    actions: {
      read: {
        label: 'Read taxonomy',
        name: 'read',
        excludes: ['create'],
        includes: [],
      },
      create: {
        label: 'Create taxonomy',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.SESSION]: {
    icon: 'chart-bar',
    label: PermissionSections.SESSION,
    actions: {
      read: {
        label: 'Read sessions',
        name: 'read',
        excludes: ['create', 'update', 'delete'],
        includes: [],
      },
      create: {
        label: 'Create sessions',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      update: {
        label: 'Edit sessions',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete sessions',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.SESSION_STATUS]: {
    icon: 'chart-bar',
    label: PermissionSections.SESSION_STATUS,
    actions: {
      update: {
        label: 'Manage session progress and status',
        name: 'update',
        excludes: [],
        includes: [],
      },
    },
  },

  [PermissionSections.SESSION_DATA_COLLECTION_INPUT]: {
    icon: 'chart-bar',
    label: PermissionSections.SESSION_DATA_COLLECTION_INPUT,
    actions: {
      read: {
        label: 'Read session data collection input',
        name: 'read',
        excludes: [],
        includes: [],
      },
    },
  },
  [PermissionSections.FILE]: {
    icon: 'file',
    label: PermissionSections.FILE,
    actions: {
      read: {
        label: 'Read files',
        name: 'read',
        excludes: ['delete', 'create'],
        includes: [],
      },
      create: {
        label: 'Create files',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete files',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },

  [PermissionSections.ASSIGNMENT]: {
    icon: 'clipboard-list',
    label: PermissionSections.ASSIGNMENT,
    actions: {
      read: {
        label: 'Read contributors to different part of session',
        name: 'read',
        excludes: ['update'],
        includes: [],
      },
      update: {
        label: 'Manage contributors to different part of session',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
    },
  },

  [PermissionSections.COMMENTS]: {
    icon: 'message',
    label: PermissionSections.COMMENTS,
    actions: {
      read: {
        label: 'Read comments',
        name: 'read',
        excludes: ['create'],
        includes: [],
      },
      create: {
        label: 'Post comments',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
    },
  },

  [PermissionSections.API_INTEGRATION]: {
    icon: 'gears',
    label: PermissionSections.API_INTEGRATION,

    actions: {
      read: {
        label: 'Read integrations',
        name: 'read',
        excludes: ['update', 'delete', 'create'],
        includes: [],
      },
      create: {
        label: 'Create integrations',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      update: {
        label: 'Edit integrations',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete integrations',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },

  [PermissionSections.LOGS]: {
    icon: 'wave-pulse',
    label: PermissionSections.LOGS,
    actions: {
      read: { label: 'Read logs', name: 'read', excludes: [], includes: [] },
    },
  },
};
