import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledValueChangeLog = styled.div`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_12};

  .esg-value-change-log {
    &__modal-trigger {
      ${fontSize(theme.fontSizes.FONT_14)};
      font-weight: normal;
      text-decoration: underline;
    }
  }
`;

export { StyledValueChangeLog };
