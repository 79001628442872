import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

// TODO: clean up
const StyledContributorsList = styled.div`
  .esg-contributors-list {
    &__wrapper {
      transition: transform 0.3s;
      width: fit-content;

      &:hover {
        transform: translateX(-6px);
      }

      &__content {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        gap: ${theme.sizes.SIZE_4};

        img {
          ${size('24px')}
          border-radius: 50%;
          object-fit: cover;
        }

        li {
          cursor: pointer;
          margin-left: -4px;
          transition: transform 0.3s;
        }

        li:hover {
          z-index: ${theme.zIndexes.CONTENT_HIGH};
          transform: translate3d(6px, -3px, 0) scale3d(1.25, 1.25, 1.25);
        }

        li:hover ~ li {
          transform: translateX(12px);
        }
      }

      &__extra {
        ${fontSize(theme.fontSizes.FONT_11)}
        ${size('24px')}
        
        padding: 0;
        border-radius: 50%;
        background-color: var(--GRAY-EF);
        cursor: default;

        > span {
          ${flex('row', 'center', 'center')};
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export { StyledContributorsList };
