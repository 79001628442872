import { Breadcrumb } from '@faxi/web-component-library';
import { Role, UserRoleName } from 'models';

import { APP_URI } from '../../../../../../config';

export const ROLES_MOCK: Role[] = [
  { id: '1', name: 'super admin', permissions: [], color: '#FFA500' },
  { id: '2', name: 'org admin', permissions: [], color: '#0000FF' },
  { id: '3', name: 'basic user', permissions: [], color: '#808080' },
  { id: '4', name: 'auditor', permissions: [], color: '#008000' },
];

export const CRUMBS: Breadcrumb[] = [
  { text: 'Users', href: APP_URI.USERS },
  { text: 'User roles', href: APP_URI.USER_ROLES },
];

export const USER_ROLE_NAME_MAPPER: Record<UserRoleName, string> = {
  'super admin': 'Super Admin',
  'basic user': 'Basic User',
  'org admin': 'Organisation Admin',
  auditor: 'Auditor',
};

export const USER_ROLE_NAME_MAPPER_SHORT: Record<UserRoleName, string> = {
  'super admin': 'Super',
  'basic user': 'Basic',
  'org admin': 'Admin',
  auditor: 'Auditor',
};
