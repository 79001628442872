import {
  Button,
  getColor,
  Modal,
  ModalRef,
  Tooltip,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import classNames from 'classnames';
import {
  FC,
  Fragment,
  memo,
  PropsWithChildren,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { BlockUI } from '../../../helpers';
import { useUserPermissions, useValidations } from '../../../hooks';
import { PermissionSections, XBRLTaxonomyFieldGroup } from '../../../models';
import { useCampaignItemProvider } from '../../../pages/Campaigns/context/CampaignItem';
import { useCampaignProvider } from '../../../providers/Campaign';
import { SelectField } from '../../_fields';
import { FormFooter } from '../../_forms';
import Icon from '../../Icon';
import { StyledXBRLTag } from './XBRLFieldGroup.styled';

export type XBRLFieldGroupProps = PropsWithChildren<{ className?: string }>;

const XBRLFieldGroup: FC<XBRLFieldGroupProps> = ({ className }) => {
  const modalRef = useRef<ModalRef>(null);

  const { rootCampaign } = useCampaignProvider();

  const { dataCollection, editCampaignItem } = useCampaignItemProvider();

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const taxonomyId = dataCollection?.taxonomyId || rootCampaign?.taxonomyId;

  const hasTaxonomyPermissions = useUserPermissions(
    PermissionSections.TAXONOMY
  );

  const {
    data: { data: xbrlFieldGroups } = { data: [] },
    isLoading: isLoadingFieldGroups,
    isValidating: isValidatingFieldGroups,
  } = useSWR<{ data: XBRLTaxonomyFieldGroup[] }>(
    hasTaxonomyPermissions(['read']) &&
      taxonomyId &&
      API_ROUTES.CAMPAIGNS.XBRL_TAXONOMIES_FIELD_GROUPS(taxonomyId)
  );

  const fieldGroupsOptions = useMemo(
    () =>
      (xbrlFieldGroups || []).map(({ id, label }) => ({ value: id, label })),
    [xbrlFieldGroups]
  );

  const { validations } = useValidations();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        onSubmit={async ({ taxonomyFieldGroupId }) => {
          if (!dataCollection) return;

          try {
            showOverlay('body');

            await editCampaignItem(
              dataCollection.campaignId,
              dataCollection.id,
              { taxonomyFieldGroupId }
            );

            modalRef.current?.close();

            showSnackBar({
              text: `Successfully updated ${dataCollection.name}.`,
              variant: 'success',
              actionButtonText: 'Dismiss',
            });
          } catch (e) {
            console.error(e);
          } finally {
            hideOverlay('body');
          }
        }}
      />
    ),
    [dataCollection, showOverlay, editCampaignItem, showSnackBar, hideOverlay]
  );

  const taxonomyFieldGroup = useMemo(() => {
    if (!dataCollection) return undefined;

    return xbrlFieldGroups.find(
      ({ id }) => id === dataCollection.taxonomyFieldGroupId
    );
  }, [dataCollection, xbrlFieldGroups]);

  const hasCampaignPermissions = useUserPermissions(
    PermissionSections.CAMPAIGN
  );

  return (
    <StyledXBRLTag className={classNames(className, 'esg-xbrl-field-group')}>
      <strong className="esg-xbrl-field-group__label">
        Taxonomy Field Group
      </strong>

      <BlockUI
        loading={
          !dataCollection || isLoadingFieldGroups || isValidatingFieldGroups
        }
        onlySpinner
        spinnerSize={24}
      >
        {taxonomyFieldGroup?.name ?? (
          <Fragment>
            <Button
              variant="outline"
              onClick={() => modalRef.current?.open()}
              icon={<Icon name="plus" />}
              iconPosition="left"
              disabled={
                !rootCampaign?.taxonomyId || !hasCampaignPermissions(['update'])
              }
            >
              Set Field Group
            </Button>

            {!rootCampaign?.taxonomyId && (
              <Tooltip content="A campaign must have a taxonomy in order to set field groups on forms">
                <span
                  tabIndex={0}
                  role="tooltip"
                  aria-label="A campaign must have a taxonomy in order to set field groups on forms"
                >
                  <Icon name="circle-info" color={getColor('--GRAY-40')} />
                </span>
              </Tooltip>
            )}
          </Fragment>
        )}
      </BlockUI>

      <Modal
        ref={modalRef}
        className={classNames(className, 'esg-add-xbrl-tag-modal')}
        conditionallyControlled={false}
        onClose={() => modalRef.current?.close()}
        childrenWrapper={formWrapper}
        title="Set Taxonomy Field Group"
        footer={<FormFooter modal={modalRef as RefObject<ModalRef>} />}
      >
        <FormField
          name="taxonomyFieldGroupId"
          component={SelectField}
          placeholder="Taxonomy Field Group"
          autoComplete="off"
          disabled={
            isLoadingFieldGroups ||
            isValidatingFieldGroups ||
            !fieldGroupsOptions.length
          }
          options={fieldGroupsOptions}
          validate={validations.taxonomyFieldGroupId}
          hasClearAction
          clearTitle="Unselect"
          aria-describedby="xbrl-tag"
          searchable
          required
          requiredLabel="Required"
          loading={isLoadingFieldGroups || isValidatingFieldGroups}
        />
      </Modal>
    </StyledXBRLTag>
  );
};

export default memo(XBRLFieldGroup);
