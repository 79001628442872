import {
  Button,
  GlowScroll,
  Spinner,
  Tabs,
  useModalUtilities,
} from '@faxi/web-component-library';
import { FC, useEffect, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../api/routes';
import { ViewGuard } from '../../components';
import Icon from '../../components/Icon';
import { BlockUI } from '../../helpers';
import { useLayoutPreviewMode } from '../../hooks';
import { previewTabs } from '../../hooks/useLayoutPreviewMode';
import { LayoutPreview, PermissionSections, XBRLTaxonomy } from '../../models';
import XbrlTaxonomyCard from './components/XBRLTaxonomyCard';
import XBRLUploadModal from './components/XBRLUploadModal';
import { StyledXBRL } from './XBRL.styled';

const XBRL: FC = () => {
  const { view, setView } = useLayoutPreviewMode('grid');

  const { open, openModal, closeModal } = useModalUtilities();

  const [isXBRLDataReady, setIsXBRLDataReady] = useState(false);

  const {
    data: { data = [] } = {},
    isLoading,
    mutate,
  } = useSWR<{ data: XBRLTaxonomy[] }>(API_ROUTES.CAMPAIGNS.XBRL_TAXONOMIES, {
    refreshInterval: !isXBRLDataReady ? 5000 : undefined,
    dedupingInterval: 5000,
  });

  useEffect(() => {
    if (data) setIsXBRLDataReady(data.every((xbrl) => xbrl.meta));
  }, [data]);

  return (
    <BlockUI loading={isLoading}>
      <StyledXBRL title="XBRL Taxonomies" className="esg-xbrl" padding={0}>
        <GlowScroll variant="gray">
          <div className="esg-xbrl__container">
            <div className="esg-xbrl__container__header">
              <div className="esg-xbrl__container__header__buttons">
                <ViewGuard
                  section={PermissionSections.TAXONOMY}
                  permissions={['create']}
                >
                  <Button
                    icon={<Icon name="plus" />}
                    variant="outline"
                    onClick={openModal}
                  >
                    Upload New XBRL Taxonomy Package
                  </Button>
                </ViewGuard>
              </div>
              <Tabs<LayoutPreview>
                value={view}
                className="esg-xbrl-tabs"
                labelPosition="right"
                tabs={previewTabs}
                onChange={setView}
                changeOrientationOnResponsive={false}
              />
            </div>

            <div className="esg-xbrl__container__cards" data-view={view}>
              {(data || []).map(({ id, meta: { name } = {} }) => (
                <XbrlTaxonomyCard key={id}>
                  {name || (
                    <div className="esg-xbrl__container__cards__processing">
                      <Spinner size={24} color="#000" />
                      <span>Processing...</span>
                    </div>
                  )}
                </XbrlTaxonomyCard>
              ))}
            </div>
          </div>

          {open && (
            <XBRLUploadModal
              title="New XBRL Taxonomy Package"
              onClose={closeModal}
              onSubmit={mutate}
            />
          )}
        </GlowScroll>
      </StyledXBRL>
    </BlockUI>
  );
};

export default XBRL;
