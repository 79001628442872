import { ChoiceDataModule } from 'models';
import { FC, PropsWithChildren } from 'react';

import SelectModuleField from '../../../../../components/_fields/SelectModuleField';
import { useRemoveConditionalModule } from '../../../../../hooks';
import { BASE_CONFIGURABLE_MODULES } from '../../baseModules';
import BaseChoiceFieldConfiguration from '../../baseModules/BaseChoiceFieldConfiguration/BaseChoiceFieldConfiguration.component';
import { StyledConditionalChoiceFieldConfiguration } from './ConditionalChoiceFieldConfiguration.styled';

export type ConditionalChoiceFieldConfigurationProps =
  PropsWithChildren<ChoiceDataModule>;

const ConditionalChoiceFieldConfiguration: FC<
  ConditionalChoiceFieldConfigurationProps
> = (props) => {
  const baseClass = 'esg-choice-field-configuration';

  const { handleRemoveModule } = useRemoveConditionalModule(props.id);
  return (
    <StyledConditionalChoiceFieldConfiguration className={baseClass}>
      <BaseChoiceFieldConfiguration
        {...props}
        onRemoveModule={(v) => handleRemoveModule(v)}
        renderModuleField={(fieldName) => (
          <SelectModuleField
            name={fieldName}
            label="If Selected"
            parentId={props.id}
            className={`${baseClass}__select-module`}
            configurableModules={BASE_CONFIGURABLE_MODULES}
          />
        )}
      />
    </StyledConditionalChoiceFieldConfiguration>
  );
};

export default ConditionalChoiceFieldConfiguration;
