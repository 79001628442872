import { Modal, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledViewAllContributorsModal = styled(Modal)`
  &.wcl-overlay[class] {
    .wcl-modal {
      width: ${theme.sizes.SIZE_752};
      max-width: unset;
    }
  }

  .wcl-modal__main {
    padding: unset;
    height: ${theme.sizes.SIZE_504};
  }
  .esg-view-all-contributors-modal {
    &--main-wrapper {
      ${flex('column', 'flex-start', 'stretch')};
      gap: ${theme.sizes.SIZE_32};
      padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_40};

      > div:first-of-type {
        align-self: flex-start;
      }
    }
    &__assignees {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.sizes.SIZE_32};
      row-gap: ${theme.sizes.SIZE_16};
    }
  }
`;

export { StyledViewAllContributorsModal };
