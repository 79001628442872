import { theme } from '@faxi/web-component-library';
import { position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const StyledGrayoutOverlay = styled.div`
  position: relative;

  .esg-grayout {
    &__overlay {
      ${position('absolute', 'top 0 right 0 bottom 0 left 0')}
      z-index: ${theme.zIndexes.FORM_FIELD + 1};

      background-color: rgba(255, 255, 255, 0.6);
      cursor: no-drop;
    }
  }

  .esg-bypass-grayout {
    z-index: ${theme.zIndexes.FORM_FIELD + 2};
  }
`;
