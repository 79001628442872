import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaigns/${apiVersion}`;

export const CAMPAIGNS = {
  BASE: (onlyWithDataCollection?: boolean) =>
    `${baseUrl}${onlyWithDataCollection ? '?onlyWithDataCollection=' + onlyWithDataCollection : ''}`,

  CAMPAIGN: (id: string, onlyWithDataCollection?: boolean) =>
    `${baseUrl}/${id}${onlyWithDataCollection ? '?onlyWithDataCollection=' + onlyWithDataCollection : ''}`,

  CAMPAIGN_ITEM: (campaignId: string, campaignItemId: string) =>
    `${baseUrl}/${campaignId}/campaign-items/${campaignItemId}`,

  CAMPAIGN_DATA_COLLECTION_ELEMENTS: (
    campaignId: string,
    searchString?: string
  ) =>
    `${baseUrl}/${campaignId}/campaign-items/data-collection-elements${searchString ? `?searchString=${searchString}` : ''}`,

  DUPLICATE: (
    campaignId: string,
    campaignItemId: string,
    dataCollectionElementId: string
  ) =>
    `${baseUrl}/${campaignId}/campaign-items/${campaignItemId}/data-collection-elements/${dataCollectionElementId}/duplicate`,

  // TAXONOMIES
  CREATE_UPLOAD_URL: `${baseUrl}/taxonomies/create-upload-url`,

  XBRL_TAXONOMIES: `${baseUrl}/taxonomies`,

  XBRL_TAXONOMIES_FIELD_GROUPS: (taxonomyId: string) =>
    `${baseUrl}/taxonomies/${taxonomyId}/field-groups`,

  XBRL_TAXONOMIES_FIELDS: (taxonomyId: string, taxonomyFieldGroupId?: string) =>
    `${baseUrl}/taxonomies/${taxonomyId}/fields${taxonomyFieldGroupId ? `?taxonomyFieldGroupId=${taxonomyFieldGroupId}` : ''}`,

  XBRL_TAXONOMIES_CONFIRM_UPLOAD: (taxonomyId: string) =>
    `${baseUrl}/taxonomies/${taxonomyId}/confirm-upload`,
};
